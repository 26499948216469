import * as React from 'react';
import { styled, useTheme, Theme, CSSObject, makeStyles, createStyles } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DescriptionIcon from '@mui/icons-material/Description';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import TaskIcon from '@mui/icons-material/Task';
import ReceiptIcon from '@mui/icons-material/Receipt';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import PersonIcon from '@mui/icons-material/Person';
import CategoryIcon from '@mui/icons-material/Category';
import WidgetsIcon from '@mui/icons-material/Widgets';
import StoreIcon from '@mui/icons-material/Store';
import PaidIcon from '@mui/icons-material/Paid';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import SettingsIcon from '@mui/icons-material/Settings';



import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { selectDrawerOpen, setDrawerOpen } from "./miniDrawerSlice";
import { selectTranslations } from "../i18n/i18nSlice";


// import { makeStyles, createStyles } from '@mui/styles'


// import {
//   useNavigate,
// } from "react-router-dom";
import CustomerList from './CustomerList';
import ProductList from './productList';
import { router } from '../../App';
import { Link } from 'react-router-dom';
import InvoiceList from './InvoiceList';
import MiniDrawerMenuItem from './MiniDrawerMenuItem';
import { permReadCustomers, permReadExpenses, permReadInvoices, permReadProducts, permReadStock, isOnline, permSupperAdmin, permAdmin, permReadFinanceReports, permCashierOnly } from '../login/loginSlice';

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));


// const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
//   ({ theme, open }) => ({
//     position: 'relative',
//     whiteSpace: 'nowrap',
//     width: drawerWidth,
//     // flexShrink: 0,
//     boxSizing: 'border-box',
//     ...(open && {

//       ...openedMixin(theme),
//       '& .MuiDrawer-paper': openedMixin(theme),
//     }),
//     ...(!open && {
//       ...closedMixin(theme),
//       '& .MuiDrawer-paper': closedMixin(theme),
//     }),
//   }),
// );

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);




export default function MiniDrawer() {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const open = useAppSelector(selectDrawerOpen);
  const trans = useAppSelector(selectTranslations);

  const viewInvoice = useAppSelector(permReadInvoices)
  const viewProducts = useAppSelector(permReadProducts)
  const viewStock = useAppSelector(permReadStock)
  const viewExpenses = useAppSelector(permReadExpenses)
  const viewCustomers = useAppSelector(permReadCustomers)
  const viewFinanceReports = useAppSelector(permReadFinanceReports)
  
  const isSupperAdmin = useAppSelector(permSupperAdmin)
  const isAdmin = useAppSelector(permAdmin)
  const isCashierOnly = useAppSelector(permCashierOnly)
  const onlineMode = useAppSelector(isOnline);
  // const navigate = useNavigate();



  const handleDrawerClose = () => {
    dispatch(setDrawerOpen(false));
  };

  return (

    <Drawer variant="permanent" open={open} >
      <DrawerHeader>
        <IconButton onClick={handleDrawerClose}>
          {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
        </IconButton>
      </DrawerHeader>
      <Divider />
      <List>

      {(isSupperAdmin || isAdmin || viewInvoice || isCashierOnly || !onlineMode) && <MiniDrawerMenuItem
          key="invoices"
          title={trans.drawer_invoices_button}
          isOpen={open}
          color= '#2979ff'
          onClick={() => router.navigate("/")}
          >
          <ReceiptIcon />
        </MiniDrawerMenuItem>}

        {(isSupperAdmin || isAdmin || viewCustomers) && onlineMode && <MiniDrawerMenuItem
          key="customers"
          title={trans.drawer_customers_menu}
          isOpen={open}
          color= '#64dd17'
          onClick={() => router.navigate("/customers")}
          >
          <PersonIcon />
        </MiniDrawerMenuItem>}

        {(isSupperAdmin || isAdmin || viewProducts) && onlineMode && <MiniDrawerMenuItem
          key="products"
          title={trans.drawer_products_menu}
          isOpen={open}
          color= 'red'
          onClick={() => router.navigate("/products")}
          >
          <WidgetsIcon />
        </MiniDrawerMenuItem>}
     
        {(isSupperAdmin || isAdmin || viewStock) && onlineMode && <MiniDrawerMenuItem
          key="stock"
          title={trans.drawer_stock_List}
          isOpen={open}
          color= '#651fff'
          onClick={() => router.navigate("/stock")}
          >
          <StoreIcon />
        </MiniDrawerMenuItem> }

        {(isSupperAdmin || isAdmin || viewExpenses) && onlineMode && <MiniDrawerMenuItem
          key="financial"
          title={trans.drawer_finance_List}
          isOpen={open}
          color= '#ff5722'
          onClick={() => router.navigate("/finance")}
          >
          <PaidIcon />
        </MiniDrawerMenuItem>}

        {(isSupperAdmin || isAdmin || viewFinanceReports) && onlineMode && <MiniDrawerMenuItem
          key="reports"
          title={trans.drawer_profit_List}
          isOpen={open}
          color= '#00c853'
          onClick={() => router.navigate("/reports")}
          >
          <TrendingUpIcon />
        </MiniDrawerMenuItem>}


        {onlineMode &&<MiniDrawerMenuItem
          key="myaccount"
          title={trans.drawer_my_account}
          isOpen={open}
          color= '#ffb300'
          onClick={() => router.navigate("/myaccount")}
          >
          <AccountBoxIcon />
        </MiniDrawerMenuItem>}


        {(isSupperAdmin || isAdmin ) && onlineMode && <MiniDrawerMenuItem
          key="users"
          title={trans.drawer_users}
          isOpen={open}
          color= '#00bcd4'
          onClick={() => router.navigate("/users")}
          >
          <ManageAccountsIcon />
        </MiniDrawerMenuItem>}

        {(isSupperAdmin || isAdmin ) && onlineMode && <MiniDrawerMenuItem
          key="settings"
          title={trans.drawer_settings}
          isOpen={open}
          color= '#00bcd4'
          onClick={() => router.navigate("/settings")}
          >
          <SettingsIcon />
        </MiniDrawerMenuItem>}
    

      </List>



    </Drawer>
  );
}


