import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { selectDirection, selectTranslations } from "../i18n/i18nSlice";
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { customerCallStatus, customerData, initialBalance, setInitialBalance, setCustomerData, customerTypeList, addCustomerData, resetCustomerData, getCustomerTypeList } from './customerSlice'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux';
import { height } from '@mui/system';
import LinearProgress from '@mui/material/LinearProgress';
import SaveIcon from '@mui/icons-material/Save';
import { AppBar, Divider, FormControl, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, Toolbar } from '@mui/material';
import { Broker, SalesPerson } from '../../models/Customer';
import { Phone } from '@mui/icons-material';
import api from '../services/api';
import { reSyncData } from '../login/loginSlice';


interface ApiStatus {
  error: string;
  status: 'idle' | 'loading' | 'succeeded' | 'failed'
}


type Props = {}
const theme = createTheme();

export default function AddSalesPerson() {

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [salesPerson, setSalesPerson] = React.useState<SalesPerson>({
    id: undefined,
    sales_person_name: '',
    phone: '',
    email:'',
    comment:''
  } as SalesPerson)

  const [changesNotSaved, setChangesNotSaved] = React.useState<boolean>(false);

  const [apiCallStatus, setApiCallStatus] = React.useState<ApiStatus>({ error: "", status: "idle" })
  const isLoading = () => apiCallStatus.status === "loading";
  const isSucceeded = () => apiCallStatus.status === "succeeded";
  const isIdle = () => apiCallStatus.status === "idle";
  const isFailed = () => apiCallStatus.status === "failed";

  const trans = useAppSelector(selectTranslations);




  const onInputValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSalesPerson({ ...salesPerson, [event.target.name]: event.target.value })
    setChangesNotSaved(true)
  }

  const onAdd = () => {
    addSalesPerson()
  }


  const addSalesPerson = async () => {
    setApiCallStatus({ error: "", "status": "loading" })
    try {
      const response = await api.post(
        '/salesperson',
        salesPerson,
        { headers: { "Content-Type": "application/json" } }
      )

      if (response.status === 200) {
        dispatch(reSyncData())
        navigate(-1)
        setApiCallStatus({ error: "", "status": "succeeded" })
      } else {
        setApiCallStatus({ error: response.statusText, "status": "failed" })
      }

    } catch (err: any) {
      setApiCallStatus({ error: err.message, "status": "failed" })
    }
  };



  return (
    <ThemeProvider theme={theme}>
      <Container>
      <AppBar position="static" sx={{ backgroundColor: "#283593" }}>
        <Toolbar component="nav" variant="dense" sx={{ justifyContent: 'space-between', overflowX: 'auto' }}  >
          <Typography component="h2" variant="h5">
          {trans.sales_person_form.add_title}
          </Typography>
        </Toolbar>
      </AppBar>

        
        {(isLoading()) && <LinearProgress />}
        {(isSucceeded() || isIdle()  || isFailed()) && (
          <>
            <Grid container>
              <Grid item sm={12} md={6} lg={6} padding={2}>
                <Box sx={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                  <TextField id="sales_person_name" name="sales_person_name" label={trans.sales_person_form.sales_person_name} variant="standard" size='small' onChange={onInputValueChange} value={salesPerson.sales_person_name} required />
                  <TextField id="phone" name="phone" label={trans.sales_person_form.phone} variant="standard" size='small' onChange={onInputValueChange} value={salesPerson.phone} />
                  <TextField id="email" name="email" type='email' label={trans.sales_person_form.email} variant="standard" size='small' onChange={onInputValueChange} value={salesPerson.email} />
                  <TextField id="comment" name="comment" label={trans.sales_person_form.comment} variant="standard" size='small' onChange={onInputValueChange} value={salesPerson.comment} />
                </Box>
              </Grid>
              <Grid item sm={12} md={6} lg={6} padding={2}>
                <Box sx={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                </Box>
              </Grid>
            </Grid>

            <Paper elevation={1} sx={{ marginY: 2 }}>
              <Box sx={{ display: 'flex', flexWrap: 'wrap', padding: 2, justifyContent: 'space-around' }}>
                <Button variant="contained" startIcon={<SaveIcon sx={{ marginRight: 0, marginLeft: 1 }} />} onClick={onAdd} sx={{ margin: '2px' }} size="small" disabled={!changesNotSaved}>
                  {trans.sales_person_form.add_button}
                </Button>
              </Box>
            </Paper>

          </>)}
      </Container>
    </ThemeProvider>
  );
}