import { Link, Navigate, Outlet, useLocation } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../app/hooks";
import { selectDirection } from "../features/i18n/i18nSlice";
import { isAuthenticated } from "../features/login/loginSlice";


import React, { useEffect } from "react";

import { createTheme } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";

import { Box, Container, CssBaseline, PaletteMode, ThemeOptions } from "@mui/material";
import { ThemeProvider } from "@emotion/react";
import {selectPaletteMode } from "../features/appconfig/appConfigSlice";
import PrimaryAppBar from '../features/appBar/PrimaryAppBar'
import MiniDrawer from '../features/drawer/MiniDrawer'

import rtlPlugin from 'stylis-plugin-rtl';
import {prefixer} from 'stylis';
import {CacheProvider} from '@emotion/react';
import createCache from '@emotion/cache';

type Props = {
  children: JSX.Element;
};

const ProtectedRoute = () => {
  const langDirection = useAppSelector(selectDirection);
  const paletteMode = useAppSelector(selectPaletteMode);
  let location = useLocation();

  const Authenticated: boolean = useAppSelector(isAuthenticated);
  

  // if (!Authenticated) {
  //   // user is not authenticated
  //   return <Navigate to="/login" state={{ from: location }} replace />;
  // }


  useEffect(() => {
    // the handler for actually showing the prompt
    // https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/onbeforeunload
    const handler = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      event.returnValue = "";
    };

    // if the form is NOT unchanged, then set the onbeforeunload
    if (Authenticated) {
      window.addEventListener("beforeunload", handler);
      // clean it up, if the dirty state changes
      return () => {
        window.removeEventListener("beforeunload", handler);
      };
    }
    // since this is not dirty, don't do anything
    return () => {};
  }, [Authenticated]);
  
  
  // const Authenticated: boolean = useAppSelector(isAuthenticated);
  // if (!Authenticated) {
  //   return <Navigate to="/login" state={{ from: location }} replace />;
  // }

  const cachConfig = langDirection === 'rtl'? {
    key: 'muirtl',
    stylisPlugins: [prefixer, rtlPlugin],
  }:{
    key: 'mui',
    stylisPlugins: [prefixer],
  };
  const cacheRtl = createCache(cachConfig);

  const theme = createTheme({
    palette: {
      mode: paletteMode as PaletteMode,
    },
    direction: (langDirection === 'rtl'?'rtl':'ltr'),
    typography:{
      h5: {
        fontSize: 12,
      },
      fontSize:18,
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
    }
  });


theme.typography.h5 = {
  fontSize: 24,
  [theme.breakpoints.down('md')]: {
    fontSize:14,
  },
};

  return (
<>
    {!Authenticated? <Navigate to="/login" state={{ from: location }} replace /> :
    <CacheProvider value={cacheRtl}>
    <ThemeProvider theme={theme}>
      <Box sx={{ direction:'ltr', display: "flex", backgroundColor:paletteMode === 'light'? '#F0F2F5':null }}>
        <CssBaseline />
        <PrimaryAppBar />
        <MiniDrawer />
        <Box
          component="main"
          sx={{
              flexGrow: 1,
              height: '100vh',
              overflow: 'auto',
            
           
          }}
        >
          <Toolbar />
          <Container  sx={{ mt: 1, mb: 1, ml:0, mr:0, padding:0}} maxWidth={false}>
          <Outlet />
  
            
          </Container>
        </Box>
        
      </Box>
    </ThemeProvider>
    </CacheProvider> }
    </>
  );
};

export default ProtectedRoute;
