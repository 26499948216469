import React, { useEffect, useState } from 'react'

import { useNavigate, useParams } from "react-router-dom";

import { ApiStatus } from '../../models/ApiStatus';
import { db } from '../../db';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { LinearProgress } from '@mui/material';
import RepoAG from '../../features/reports/RepoAG _v5';
import { ro } from 'date-fns/locale';
import useDraftList from './useDraftList';
import { selectTranslations } from '../../features/i18n/i18nSlice';



//*1-add model
interface Row {
  id: number;
  invoice_type: number;
  invoice_type_name: string;
  customer_name: string;
  invoice_date: string;
  subtotal: number;
  discount: number;
  salesTotal: number;
  tax: number;
  additions: number;
  freight: number;
  total: number;
  invoice_comment: string;
}



export default function Drafts() {

  const params = useParams();
  const { productId, startDate, endDate } = params

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const trans = useAppSelector(selectTranslations);
  // const apiCallStatus = useAppSelector(commissionCallStatus);
  // const apiStatusBusyCount = useAppSelector(commissionCallBusyCount);

  // const isLoading = React.useMemo(() => (apiCallStatus === "loading" || apiStatusBusyCount > 0), [apiCallStatus, apiStatusBusyCount]);
  // const isSucceeded = React.useMemo(() => (apiCallStatus === "succeeded" && apiStatusBusyCount === 0), [apiCallStatus, apiStatusBusyCount]);
  // const isIdle = React.useMemo(() => (apiCallStatus === "idle" && apiStatusBusyCount === 0), [apiCallStatus, apiStatusBusyCount]);
  // const isFailed = React.useMemo(() => (apiCallStatus === "failed" && apiStatusBusyCount === 0), [apiCallStatus, apiStatusBusyCount]);

  // const employeesReportData = useAppSelector(employeesReport);


  const [apiCallStatus, setApiCallStatus] = React.useState<ApiStatus>({ error: "", status: "idle" })
  const isLoading = apiCallStatus.status === "loading";
  const isSucceeded = apiCallStatus.status === "succeeded";
  const isIdle = apiCallStatus.status === "idle";
  const isFailed = apiCallStatus.status === "failed";

  // const [rows, setRows] = useState<any[]>([])

  const rows = useDraftList().map( item => ({
    id: item.draft_id,
    invoice_type: item.invoice_type,
    invoice_type_name: trans.new_invoice.type['title_name' + item.invoice_type],
    customer_name: item.customer_name,
    invoice_date: item.invoice_date,
    subtotal: item.subtotal,
    discount: item.discount,
    salesTotal: item.salesTotal,
    tax: item.tax,
    additions: item.additions,
    freight: item.freight,
    total: item.total,
    invoice_comment: item.invoice_comment
  } as Row))


  //*2- set on click open
  const onOpenClick = (row: any) => {
    navigate("/draft/" + row.invoice_type.toString() + "/" + row.id.toString());
  }




  // const getReport = async () => {
  //   setApiCallStatus({ error: "", "status": "loading" })
  //     const products = await db.productCatalog.toArray()
  //     const productCat = await db.productCategory.toArray()
  //     // const newItem =  {...item, account_type_name: trans.finance_eports.profit, account_name: trans.finance_eports.profit , ...Object.assign({},{},...Object.keys(item).map( (key) =>  key.toLowerCase().includes("change") ? {[key]: (item[key]- expense![key]).toFixed(2)}:null))}

  //     const productCatObj = { ...Object.assign({}, {}, ...productCat.map(item => ({ [item.id]: item.category_name }))) }

  //     const newRep = products.map(item => ({ id: item.id, product_name: item.product_name, product_category_name: productCatObj[item.product_category], international_code: item.international_code  , user_defined_code: item.user_defined_code?.toString() + '-' + item.size_id.toString() + '-' + item.color_id.toString() }))
  //     // setRows(newRep as Row[])
  //     setRows(newRep)
  //     setApiCallStatus({ error: "", "status": "succeeded" })
  //     console.log(newRep)
  //   }






  //*3- define get report
  // const getReport = async (setRowsFun: React.Dispatch<React.SetStateAction<Row[]>>, setApiCallStatusFun: React.Dispatch<React.SetStateAction<ApiStatus>>) => {
  //   setApiCallStatusFun({ error: "", "status": "loading" })

  //   // setRows(newRep as Row[])
  //   setRowsFun(employeesReportData as Row[])
  //   setApiCallStatusFun({ error: "", "status": "succeede  d" })
  // }


  //*4- default column data
  // const columns = {
  //   id: 0,
  //   fullname: '',
  //   balance: 0,
  // }
  const columns = [
    { field: 'draft_id', type: 'number' },
    { field: 'invoice_type_name', type: 'string' },
    { field: 'customer_name', type: 'string' },
    { field: 'invoice_date', type: 'date' },
    { field: 'subtotal', type: 'number' },
    { field: 'discount', type: 'number' },
    { field: 'tax', type: 'number' },
    { field: 'additions', type: 'number' },
    { field: 'freight', type: 'number' },
    { field: 'total', type: 'number' },
    { field: 'invoice_comment', type: 'string' },
  ]


  //*5- generate report
  // const genRep = useRepoAG<Row>(columns, 'product_rep1', getReport, onOpenClick, 'id')
  // const genRep = useRepoAG<Row>(columns, 'all_employee_report', 'all_employee_report', trans.commission_dashboard.employees, employeesReportData, onOpenClick, 'id')


  return (
    <>
      {isLoading && <LinearProgress />}
      {(isSucceeded || isIdle || isFailed) && (
        <>
          <RepoAG columns={columns} componentName={trans.invoice_rep1.title_drafts} transRep='invoice_rep1' title={trans.invoice_rep1.title_drafts} rows={rows} onOpenClick={onOpenClick}  />
        </>

      )}
    </>
  );


}


