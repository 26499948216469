import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { loginRequest, isAuthenticated, useVerfication, needVerification, actionCallStatus } from './loginSlice'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux';
import { height } from '@mui/system';
import CountDown from './CountDown';
import { LinearProgress } from '@mui/material';

function Copyright(props: any) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://softpuzzles.com/">
        Soft Puzzles
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

type Props = {}
const theme = createTheme();

export default function SignIn() {
  const dispatch = useAppDispatch();
  const authenticated = useSelector(isAuthenticated);
  const viewVerifyCode = useSelector(useVerfication);
  const navigate = useNavigate();

  const actionStatus = useAppSelector(actionCallStatus);
  const isLoading = (status: any) => status === "loading";
  const isSucceeded = (status: any) => status === "succeeded";
  const isIdle = (status: any) => status === "idle";
  const isFailed = (status: any) => status === "failed";



  React.useEffect(() => {
    if (authenticated) {
      navigate('/', { replace: true });
    }
  }, [authenticated]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const data = new FormData(event.currentTarget);
    var email: string = String(data.get('email') ?? '').toLowerCase();
    var pass: string = String(data.get('password') || '');
    var vfcode: string = String(data.get('code') || '');
    if (viewVerifyCode) {
      dispatch(loginRequest(email, pass, vfcode));
    } else {
      dispatch(loginRequest(email, pass, null));
    }



    // console.log({
    //   email: data.get('email'),
    //   password: data.get('password'),
    // });
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        {(isLoading(actionStatus) || authenticated) && <LinearProgress sx={{marginY:10}} />}
          {((isSucceeded(actionStatus) || isIdle(actionStatus) || isFailed(actionStatus)) && !authenticated )&& (
            <>
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <img src='sp9.png' width="110px" style={{ margin: "5px" }} />
          {/* <Avatar  sx={{  width: 115, height: 115, bgcolor: 'lightblue' }}>
         
          </Avatar> */}
          <Typography component="h1" variant="h5" sx={{ marginTop: 2 }}>
            Sign in
          </Typography>
         
              <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  autoFocus
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                />
                {viewVerifyCode ?
                  <>
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      name="code"
                      label="code"
                      type="text"
                      id="code"
                      autoComplete="code"
                    /> <Box sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}><CountDown /></Box>
                  </>
                  : null
                }

                {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            /> */}

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Sign In
                </Button>
                {/* <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="#" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid> */}
              </Box>
        </Box>
            </>)}

        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}