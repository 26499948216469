import { AppBar, Box, Card, CardActionArea, CardContent, Container, Dialog, Grid, Paper, Toolbar, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useAppSelector } from '../../../app/hooks'
import { selectTranslations, selectDirection } from '../../../features/i18n/i18nSlice'
import StockDashboardCard from './StockDashboardCard';
import { useNavigate } from 'react-router-dom';
import CategoryIcon from '@mui/icons-material/Category';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import BarDashboardCard from './BarDashboardCard';
import SummarizeIcon from '@mui/icons-material/Summarize';

import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import RoofingIcon from '@mui/icons-material/Roofing';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import NumberDialog from './NumberDialog';

export default function StockDashboard() {

    const trans = useAppSelector(selectTranslations)
    const langDirection = useAppSelector(selectDirection);

    const navigate = useNavigate();

    const [showNumberDialog, setShowNumberDialog] = useState(false);
    const [showNumberDialogParentId, setShowNumberDialogParentId] = useState(0);
    const [showNumberDialogTitle, setShowNumberDialogTitle] = useState('');
    const [showNumberDialogLable, setShowNumberDialogLable] = useState('');

    const onGetExpiredShowNumberDialog = (parentId: number) => {
        setShowNumberDialogParentId(parentId)
        setShowNumberDialogTitle(trans.dashboards.stock_expired_days)
        setShowNumberDialogLable(trans.dashboards.number_of_days_less_than)
        setShowNumberDialog(true)
      }

      const onGetWithoutSalesShowNumberDialog = (parentId: number) => {
        setShowNumberDialogParentId(parentId)
        setShowNumberDialogTitle(trans.dashboards.stock_without_transaction)
        setShowNumberDialogLable(trans.dashboards.number_of_days_greater_than)
        setShowNumberDialog(true)
      }

      const onShowNumberDialogYes = (parentId: number, result: number) => {

        if(parentId === 1){
            navigate(`/stock/rep07/${result}`)
        }
        if(parentId === 2){
            navigate(`/stock/rep08/${result}`)
        }
      }
    


    return (
        <>
            <Paper>

                <Grid container>
                    <StockDashboardCard itemColor="#4db6ac" itemName={trans.dashboards.items_with_stock_report} onClick={() => navigate("/stock/rep01")} >
                        <img src="stock.png" height={60} style={{ margin: 5 }} />
                        {/* <CategoryIcon sx={{ fontSize: 80, color: 'whitesmoke' }} ></CategoryIcon> */}
                    </StockDashboardCard>
                    <StockDashboardCard itemColor="#4db6ac" itemName={trans.dashboards.stock_report} onClick={() => navigate("/stock/rep11")} >
                        <img src="stock.png" height={60} style={{ margin: 5 }} />
                        {/* <CategoryIcon sx={{ fontSize: 80, color: 'whitesmoke' }} ></CategoryIcon> */}
                    </StockDashboardCard>
                    
                    <StockDashboardCard itemColor="#00e676" itemName={trans.dashboards.stock_average_cost} onClick={() => navigate("/stock/rep05")} >
                        <CategoryIcon sx={{ fontSize: 80, color: 'whitesmoke' }} ></CategoryIcon>
                    </StockDashboardCard>

                    <StockDashboardCard itemColor="#3d5afe" itemName={trans.dashboards.stock_category_cost} onClick={() => onGetExpiredShowNumberDialog(1)} >
                        <CategoryIcon sx={{ fontSize: 80, color: 'whitesmoke' }} ></CategoryIcon>
                    </StockDashboardCard>

                    <StockDashboardCard itemColor="#f4511e" itemName={trans.dashboards.stock_transfer} onClick={() => navigate("/stock/rep09")} >
                        <SwapHorizIcon sx={{ fontSize: 80, color: 'whitesmoke' }} ></SwapHorizIcon>
                    </StockDashboardCard>

                   
                </Grid>
            </Paper>

          


            <Paper sx={{ marginY: 3 }}>
                <Grid container>

                    <StockDashboardCard itemColor="#536dfe" itemName={trans.dashboards.add_store} onClick={() => navigate("/store/add")} >
                        <AddBusinessIcon sx={{ fontSize: 70, color: 'whitesmoke' }} ></AddBusinessIcon>
                    </StockDashboardCard>
                    <StockDashboardCard itemColor="#69f0ae" itemName={trans.dashboards.store_list} onClick={() => navigate("/store/all")} >
                        <RoofingIcon sx={{ fontSize: 70, color: 'whitesmoke' }} ></RoofingIcon>
                    </StockDashboardCard>

                   
                </Grid>
            </Paper>


            <Paper sx={{ marginY: 3 }}>
                <Grid container>
                    <BarDashboardCard itemColor="#90a4ae" itemName={trans.dashboards.products_stock_less_than_min} onClick={() => navigate("/stock/rep03")} >
                        <SummarizeIcon sx={{ fontSize: 70, color: 'whitesmoke' }} ></SummarizeIcon>
                    </BarDashboardCard>
                    <BarDashboardCard itemColor="#90a4ae" itemName={trans.dashboards.products_stock_greater_than_max} onClick={() => navigate("/stock/rep04")} >
                        <SummarizeIcon sx={{ fontSize: 70, color: 'whitesmoke' }} ></SummarizeIcon>
                    </BarDashboardCard>
                    <BarDashboardCard itemColor="#90a4ae" itemName={trans.dashboards.stock_expired_days} onClick={() => onGetExpiredShowNumberDialog(1)} >
                        <SummarizeIcon sx={{ fontSize: 70, color: 'whitesmoke' }} ></SummarizeIcon>
                    </BarDashboardCard>

                    <BarDashboardCard itemColor="#90a4ae" itemName={trans.dashboards.stock_without_transaction} onClick={() => onGetWithoutSalesShowNumberDialog(2)} >
                        <SummarizeIcon sx={{ fontSize: 70, color: 'whitesmoke' }} ></SummarizeIcon>
                    </BarDashboardCard>

                </Grid>
            </Paper>



            <Dialog
            open={showNumberDialog}
            keepMounted
            onClose={() => setShowNumberDialog(false)}
            aria-describedby="alert-dialog-slide-description"
            sx={{ direction: langDirection }}
          >
            <NumberDialog openDialog = {showNumberDialog} setOpenDialog = {setShowNumberDialog} lable={showNumberDialogLable} title={showNumberDialogTitle} parentId={showNumberDialogParentId} onShowNumberDialogYes={onShowNumberDialogYes} />
          </Dialog>
          
        </>
    )
}
