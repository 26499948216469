import React, { useEffect, useState } from 'react';
import { Autocomplete, AutocompleteChangeReason, Box, Checkbox, Dialog, FormControl, FormControlLabel, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, TextField } from '@mui/material'

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import 'dayjs/locale/ar';
import { DateTimeField } from '@mui/x-date-pickers';


import { useAppSelector } from "../../app/hooks";
import { selectDirection, selectTranslations } from "../../features/i18n/i18nSlice";
import { Invoice02, InvoiceType } from '../../models/Invoice02';
import { StoreHouse } from '../../models/StoreHouse';
import { Customer } from '../../models/Customer';
import { useSelectAccountReceivableDialog } from './useSelectAccountReceivableDialog';
import { db } from '../../db'



type Props = {
  currentDraft: Invoice02;
  update: (invoice: Invoice02) => Promise<void>;
  storeList: StoreHouse[];
  setStoreList: Function;
  invoiceTransferStore: number;
  setInvoiceTransferStore: Function;

};



export default function TopPanel({ currentDraft, update, storeList, setStoreList, invoiceTransferStore, setInvoiceTransferStore }: Props) {

  const langDirection = useAppSelector(selectDirection);
  const trans = useAppSelector(selectTranslations);
  const [customers, setCustomers] = React.useState<Customer[]>([]);
  const [useTax, setUseTax] = React.useState<boolean>(false);

  const customersDefaultProps = {
    options: customers,
    getOptionLabel: (option: Customer) => option.fullname,
  };


  const onCustomerAutoCompleteChange = (event: React.SyntheticEvent<Element, Event>, value: Customer | null, reason: AutocompleteChangeReason) => {

    if (value !== null) {
      update(({
        ...currentDraft,
        customer_id: value.id,
        customer_type: value.customer_type,
        customer_account_id: value.account_id,
        customer_name: value.fullname
      } as Invoice02))
    }
  };

  const onStoreChanged = (event: SelectChangeEvent<number>) => {
    const firstValue = storeList?.find((store: StoreHouse) => (store.id !== event.target.value))!.id
    setInvoiceTransferStore(firstValue)
    update(({ ...currentDraft, store: event.target.value } as Invoice02))
  };


  const onTransferStoreChanged = (event: any) => {
    setInvoiceTransferStore(event.target.value)
  };

  const onInputValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    update(({ ...currentDraft, [event.target.name]: event.target.value } as Invoice02))
  }

  const onCheckTaxChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUseTax(event.target.checked)
    update(({
      ...currentDraft,
      tax_percentage: event.target.checked? 14: 0,
      tax: event.target.checked? (currentDraft?.subtotal! - currentDraft?.discount!) * 14 / 100:0,
      total: currentDraft?.subtotal! - currentDraft?.discount! + (event.target.checked? ((currentDraft?.subtotal! - currentDraft?.discount!) * 14 / 100):0)
    } as Invoice02))
  };


  const onCheckChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    // dispatch(setProductData({ ...productObject, [event.target.name]: event.target.checked }))
    // setChangesNotSaved(true)
    if(event.target.checked){
      const cashCust = customers.find(cust => cust.id === 100)

      update(({
        ...currentDraft,
        customer_id: cashCust!.id,
        customer_type: cashCust!.customer_type,
        customer_account_id: cashCust!.account_id,
        customer_name: cashCust!.fullname,
        cash_invoice: true
      } as Invoice02))
      // update(({ ...currentDraft, cash_invoice: true } as Invoice02))

    }else{
      onAccountReceivableButtonClicked()
    }
    // dispatch(setProductData({ ...productObject, [event.target.name]: event.target.checked }))
    // update(({ ...currentDraft, [event.target.name]: event.target.checked } as Invoice02))
  };



  // Account Receivable select Dialog
  const onNewAccountReceivableSelected = (value: Customer) => {

    if (value !== null) {
      update(({
        ...currentDraft,
        customer_id: value.id,
        customer_type: value.customer_type,
        customer_account_id: value.account_id,
        customer_name: value.fullname,
        cash_invoice: false
      } as Invoice02))
      // update(({ ...currentDraft, cash_invoice: false } as Invoice02))
    }

  }
  const {
    openDialog: showAccountReceivableDialog,
    setOpenDialog: setShowAccountReceivableDialog,
    dialogReturn: dialogAccountReceivableReturn,
    SelectDialog: SelectAccountReceivableDialog,
    onDialogOpen: onAccountReceivableDialogOpen } = useSelectAccountReceivableDialog(onNewAccountReceivableSelected)

  const onAccountReceivableButtonClicked = () => {
    onAccountReceivableDialogOpen(currentDraft.sales_person_id)
  }






  useEffect(() => {
    const loadLists = async () => {
      const customerList = await db.customer.toArray()
      setCustomers(customerList)



      if(currentDraft.invoice_type === InvoiceType.TransferStock){
        // to load transfere store initial value
        const stores = await db.storeHouse.toArray()
        setStoreList(stores)
  
        //use stores not storeList because state will not update now
        const foundStore = stores?.find(store => (store.id !== currentDraft?.store))
        if (foundStore) setInvoiceTransferStore(foundStore.id)
      }


    };
    loadLists()
    setUseTax(currentDraft.tax_percentage > 0)

  }, [])


  return (
    <>
      <Paper elevation={1} sx={{ marginY: 2, paddingY: 2 }}>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
          {/* {currentDraft?.customer_id && customers.length>0? */}
          {storeList.length > 0 && currentDraft?.store! > 0 ?
            <FormControl  >
              <InputLabel htmlFor="store">{trans.invoice_item.store}</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="store"
                // defaultValue={unitSelected?.id}
                value={currentDraft?.store}
                name="store"

                label="Type"
                onChange={onStoreChanged}

                size='small'
                sx={{ width: 200, margin: "4px" }}
              >
                {storeList?.map(store => (<MenuItem value={store.id} key={store.id}>{store.name}</MenuItem>))}
              </Select>
            </FormControl> : <></>
          }

          <FormControlLabel label={trans.new_invoice.cash_invoice} control={<Checkbox name="cash_invoice" checked={currentDraft.cash_invoice} onChange={onCheckChanged} tabIndex={-1} disableRipple />} />
          {!currentDraft.cash_invoice && 
          <TextField
          sx={{ flexGrow: 1, margin: "4px" }}
          id="customer" name="customer" 
          label={trans.new_invoice.invoice_customer} 
          variant="standard" 
          size='small' 
          value={currentDraft?.customer_name || ''}
          />
        }
          <TextField 
            sx={{ flexGrow: 1, margin: "4px" }}
            id="invoice_comment"
            name="invoice_comment"
            label={trans.new_invoice.invoice_comment}
            variant="standard"
            size='small'
            value={currentDraft?.invoice_comment || ''}
            onChange={onInputValueChange} 
          />

  {(currentDraft !== null && currentDraft !== undefined  && currentDraft.invoice_type === InvoiceType.Sale) ? 
    <FormControlLabel label={trans.new_invoice.use_tax} control={<Checkbox name="use_tax" checked={useTax} onChange={onCheckTaxChanged} tabIndex={-1} disableRipple />} />
  : <></>}

        </Box>
      </Paper>


      {storeList.length > 0 && currentDraft?.invoice_type! === InvoiceType.TransferStock && invoiceTransferStore > 0 ?
        <Paper elevation={1} sx={{ marginY: 2, paddingY: 2 }}>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
            <FormControl  >
              <InputLabel htmlFor="transfer_store">{trans.invoice_item.transfer_store}</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="transfer_store"
                // defaultValue={unitSelected?.id}
                value={invoiceTransferStore}
                name="transfer_store"

                label="Type"
                onChange={onTransferStoreChanged}

                size='small'
                sx={{ width: 200, margin: "4px" }}
              >
                {storeList?.filter(st => st.id !== currentDraft.store).map(store => (<MenuItem value={store.id} key={store.id}>{store.name}</MenuItem>))}
              </Select>
            </FormControl>
          </Box>
        </Paper> : <></>
      }

      <Dialog
        open={showAccountReceivableDialog}
        keepMounted
        onClose={() => setShowAccountReceivableDialog(false)}
        aria-describedby="alert-dialog-slide-description"
        sx={{ direction: langDirection }}
      >

        <SelectAccountReceivableDialog />
      </Dialog>


    </>

  )
}
