import { AppBar, Box, Card, CardActionArea, CardContent, Container, Dialog, Grid2, LinearProgress, Paper, Toolbar, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useAppSelector, useAppDispatch } from '../../../app/hooks'
import { selectDirection, selectTranslations } from '../../../features/i18n/i18nSlice'
import DashboardCard from './DashboardCard';
import { useNavigate } from 'react-router-dom';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';  
import { refreshDB, actionCallStatus } from '../../../features/login/loginSlice';
import { createTheme, ThemeProvider } from '@mui/material/styles';




// import { selectDirection, selectTranslations } from "../../features/i18n/i18nSlice";
// import { useAppSelector, useAppDispatch } from "../../app/hooks";

const theme = createTheme();

export default function UsersDashboard() {

    const trans = useAppSelector(selectTranslations)
    const langDirection = useAppSelector(selectDirection);

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const apiCallStatus = useAppSelector(actionCallStatus);
    const isLoading = (status: any) => status === "loading";
    const isSucceeded = (status: any) => status === "succeeded";
    const isIdle = (status: any) => status === "idle";
    const isFailed = (status: any) => status === "failed";

    const [showResetPasswordDialog, setShowResetPasswordDialog] = useState(false);

    const onRefreshDB = () => {
        dispatch(refreshDB())
        console.log("finished refresh")
    }


    // Reset password  Dialog
  
//   const {
//     openDialog: showResetPasswordDialog,
//     setOpenDialog: setShowResetPasswordDialog,
//     SelectDialog: ResetPasswordDialog,
//     onDialogOpen: onResetPasswordDialogOpen } = useResetPasswordDialog()

  const onResePasswordClicked = () => {
    setShowResetPasswordDialog(true)
  }



    return (
        // <ThemeProvider theme={theme}>
            <Container>


                {(isLoading(apiCallStatus)) && <LinearProgress />}
                {(isSucceeded(apiCallStatus) || isIdle(apiCallStatus)) && (
                    <>
                        <Paper>
                        
                            <Grid2 container>
                                <DashboardCard itemColor="#c0ca33" itemName={trans.dashboards.privilages_list} onClick={() => navigate("/users/roles")} >
                                    <img src="privilage.png" height={60} style={{margin: 5}}/>
                    {/* <CategoryIcon sx={{ fontSize: 80, color: 'whitesmoke' }} ></CategoryIcon> */}
                                </DashboardCard>
                                <DashboardCard itemColor="#40c4ff" itemName={trans.dashboards.users_data} onClick={() => navigate("/users/userList")} >
                                    <PeopleAltIcon sx={{ fontSize: 70, color: 'whitesmoke' }} ></PeopleAltIcon>
                                </DashboardCard>
                                <DashboardCard itemColor="#ff9800" itemName={trans.dashboards.users_logs} onClick={() => navigate("/userlogs/1")} >
                                    <WorkHistoryIcon sx={{ fontSize: 70, color: 'whitesmoke' }} ></WorkHistoryIcon>
                                </DashboardCard>
                            </Grid2>
                        </Paper>

                        <Paper sx={{ marginY: 3 }}>
                        
                        <Grid2 container>
                            <DashboardCard itemColor="#689f38" itemName={trans.commission_dashboard.employees} onClick={() => navigate("/employee/all")} >
                                <img src="commission.png" height={60} style={{margin: 5}}/>
                {/* <CategoryIcon sx={{ fontSize: 80, color: 'whitesmoke' }} ></CategoryIcon> */}
                            </DashboardCard>
                            <DashboardCard itemColor="#0288d1" itemName={trans.commission_dashboard.commission_categories} onClick={() => navigate("/commissioncat/all")} >
                            <img src="productcom.png" height={60} style={{margin: 5}}/>
                            </DashboardCard>
                            <DashboardCard itemColor="#ff9800" itemName={trans.dashboards.users_logs} onClick={() => navigate("/userlogs/1")} >
                                <WorkHistoryIcon sx={{ fontSize: 70, color: 'whitesmoke' }} ></WorkHistoryIcon>
                            </DashboardCard>
                        </Grid2>
                    </Paper>
                    </>
                )}
            </Container>
        // </ThemeProvider>

    )
}
