import React, { useEffect, useState } from 'react'
import { InvoiceItem } from '../../models/InvoiceItem';
import useGrideColumnFormat from './useGrideColumnFormat';

import { nanoid } from 'nanoid';
import useDraftRepo from './useDraftRepo';
import { useNavigate, useParams } from 'react-router-dom';
import { Invoice02 } from '../../models/Invoice02';
import { InvoiceDiscountModeEnum, InvoiceTaxModeEnum } from '../../models/Invoice';
import { db } from '../../db'
import api from '../../features/services/api';
import { StoreHouse } from '../../models/StoreHouse';


import { useAppDispatch } from '../../app/hooks';
import { updateDraftCount } from '../../features/login/loginSlice';
import { setDraftStock } from '../../features/product/productSlice';
import useGrideColumnFormat2 from './useGrideColumnFormat2';

var CryptoJS = require("crypto-js");




export interface InvoiceApisStatus {
    error: string;
    status: 'idle' | 'loading' | 'succeeded' | 'failed'
}

export interface InvoiceItemWithHash extends InvoiceItem {
    itemHash: string;
}


const useDraft = () => {
    const params = useParams();
    const { invoiceType, draftId } = params
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    // const [orderDraft, setOrderDraft] = useState<Order | null>(null)
    const [storeList, setStoreList] = useState<StoreHouse[]>([])
    const [invoiceTransferStore, setInvoiceTransferStore] = useState(1);


    const [orderQtyTotal, setOrderQtyTotal] = React.useState(0);
    const [decimalScale, setDecimalScale] = useState<number>(2)
    // const [selectionModel, setSelectionModel] = React.useState<any[]>([]);
    const [selectionModel, setSelectionModel] = React.useState<ReadonlySet<string>>(() => new Set());

    // const [customerSelected, setCustomerSelected] = React.useState<Customer | null>(null);

    const [apiCallStatus, setApiCallStatus] = React.useState<InvoiceApisStatus>({ error: "", status: "idle" })
    const isLoading = () => apiCallStatus.status === "loading";
    const isSucceeded = () => apiCallStatus.status === "succeeded";
    const isIdle = () => apiCallStatus.status === "idle";
    const isFailed = () => apiCallStatus.status === "failed";
    const apiCallError = () => apiCallStatus.error;

    const [openSnackbar, setOpenSnackbar] = React.useState(false);
    const [snackbarMessage, setSnackbarMessage] = React.useState('');





    const { draftCount, currentDraft, update, loadDraft, newDraft } = useDraftRepo(invoiceType ? parseInt(invoiceType) : 0)

    const { gridColumns, adjustGridColumns } = useGrideColumnFormat()



    const handleCloseSnackbar = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSnackbar(false);
    };


    const [detailAccordionExpanded, setDetailAccordionExpanded] = useState(false)


    const updateInvoiceDraft = (key: string, value: any) => {
        if (key === "invoice_items") {
            calculateTotals(value as InvoiceItem[])
        } else {
            update(({ ...currentDraft, [key]: value } as Invoice02))
        }
    }

    const calculateTotals = (invoice_items: InvoiceItem[]) => {

        let subtotal = 0
        let discount_percentage = 0
        let discount = 0
        let salesTotal = 0
        let tax_percentage = 0
        let tax = 0
        let additions = 0
        let freight = 0
        let total = 0
        let qtyTotal = 0

        invoice_items.forEach(item => {

            subtotal += item.subtotal
            discount += item.discount
            salesTotal += item.salesTotal
            tax += item.tax
            total += item.total
            qtyTotal += item.quantity

        })

        if (currentDraft?.invoice_discount_mode === InvoiceDiscountModeEnum.PerInvoice) {
            discount = currentDraft.discount_percentage * subtotal / 100
            salesTotal = subtotal - discount
            discount_percentage = currentDraft.discount_percentage
        } else {
            discount_percentage = 100 * discount / subtotal
        }
        if (currentDraft?.invoice_tax_mode === InvoiceTaxModeEnum.PerInvoice) {
            tax = salesTotal * currentDraft.tax_percentage / 100
            tax_percentage = currentDraft.tax_percentage

        } else {
            tax_percentage = 100 * tax / salesTotal
        }

        if (currentDraft?.invoice_discount_mode === InvoiceDiscountModeEnum.PerInvoice || currentDraft?.invoice_tax_mode === InvoiceTaxModeEnum.PerInvoice) {
            total = subtotal - discount + tax
        }

        update(({
            ...currentDraft,
            invoice_items: invoice_items,
            subtotal: subtotal,
            discount: discount,
            discount_percentage: discount_percentage,
            salesTotal: salesTotal,
            tax: tax,
            tax_percentage: tax_percentage,
            total: total
        } as Invoice02))

        setOrderQtyTotal(qtyTotal)
        adjustGridColumns(currentDraft.invoice_type, tax, discount, currentDraft?.invoice_tax_mode!, currentDraft?.invoice_discount_mode!)

    }


    const loadTotals = (invoice_items: InvoiceItem[]) => {
        let subtotal = 0
        let discount = 0
        let salesTotal = 0
        let tax = 0
        let total = 0
        let qtyTotal = 0
        invoice_items.forEach(item => {
            subtotal += item.subtotal
            salesTotal += item.salesTotal
            discount += item.discount
            tax += item.tax
            total += item.total
            qtyTotal += item.quantity
        })


        setOrderQtyTotal(qtyTotal)
        adjustGridColumns(currentDraft.invoice_type, tax, discount, currentDraft?.invoice_tax_mode!, currentDraft?.invoice_discount_mode!)

    }



    const addNewInvoiceItem = (invoiceItem: InvoiceItem) => {
        const newInvoiceItems: InvoiceItemWithHash[] = [...currentDraft?.invoice_items as InvoiceItemWithHash[], { ...invoiceItem, itemHash: nanoid() } as InvoiceItemWithHash]
        updateInvoiceDraft('invoice_items', newInvoiceItems)
    }


    const onDeleteBillItems = () => {
        updateInvoiceDraft('invoice_items',
            [...currentDraft?.invoice_items.filter(item => !selectionModel.has((item as InvoiceItemWithHash).itemHash))!] as InvoiceItemWithHash[]
        )
        setSelectionModel(new Set());
    }



    const onRowSelectionChanged = (selections: ReadonlySet<string>) => {
        setSelectionModel(selections)
    }


    const openNewDraft = async () => {
        await newDraft(invoiceType ? parseInt(invoiceType) : 0, currentDraft.store)
        const foundStore = storeList?.find(store => (store.id !== currentDraft?.store))
        if (foundStore) setInvoiceTransferStore(foundStore.id)
        dispatch(updateDraftCount())
    }


    useEffect(() => {
        // if it is loaded then we are opening new one
        const invType = invoiceType ? parseInt(invoiceType) : 0
        const drafId = draftId && draftId !== '0' ? parseInt(draftId) : undefined
        if (!drafId && (currentDraft.invoice_type !== invType || currentDraft.draft_uid === '')) {
            openNewDraft()
        }
    }, [invoiceType, draftId])



    useEffect(() => {
        const loadLists = async () => {
            setApiCallStatus({ error: "", "status": "loading" })

            const stores = await db.storeHouse.toArray()
            setStoreList(stores)

            const loadedDraft = await loadDraft(draftId ? parseInt(draftId) : undefined)

            if (loadedDraft) loadTotals(loadedDraft?.invoice_items)
            if (draftId === '0') update(({ ...loadedDraft, store: stores[0].id } as Invoice02))

            //use stores not storeList because state will not update now
            const foundStore = storeList?.find(store => (store.id !== loadedDraft?.store))
            if (foundStore) setInvoiceTransferStore(foundStore.id)

            // if (currentDraft) loadTotals(currentDraft?.invoice_items)
            // if (draftId === '0') update(({ ...currentDraft, store: stores[0].id } as Invoice02))

            if (currentDraft) adjustGridColumns(currentDraft.invoice_type, currentDraft?.tax!, currentDraft?.discount!, currentDraft?.invoice_tax_mode!, currentDraft?.invoice_discount_mode!)

            setApiCallStatus({ error: "", "status": "succeeded" })

        };
        loadLists()

    }, [])


    const isValidData = (): boolean => {
        // if (currentDraft.invcustomer_phone.length >= 11 || currentDraft.customer_id != 100){
        //     return true
        // }else{
        //     setDetailAccordionExpanded(true)
        //     setSnackbarMessage("Customer Phone number not inserted")
        //     setOpenSnackbar(true)
        //     return false
        // }
        return true // nothing to validate

    }


    const addInvoiceData = () => async () => {

        if (!isValidData()) {
            return
        }

        setApiCallStatus({ error: "", "status": "loading" })

        try {


            const response = await api.post(
                '/invoice',
                currentDraft,
                { headers: { "Content-Type": "application/json" } }
            )

            if (response.status === 200) {
                db.invoiceDraft.delete(currentDraft?.draft_id!).then(() => {
                    openNewDraft()
                    setApiCallStatus({ error: "", "status": "succeeded" })
                    dispatch(updateDraftCount())
                }).catch(error => {
                    setApiCallStatus({ error: error.message, "status": "succeeded" })
                })



            } else {
                setApiCallStatus({ error: response.statusText, "status": "failed" })
            }

        } catch (err: any) {
            if (err.code === 'ERR_NETWORK' || err.code === 'ERR_BAD_REQUEST') {

                const cashed = await cashInvoiceOffline(currentDraft)

                if(cashed){
                    db.invoiceDraft.delete(currentDraft?.draft_id!).then(() => {
                        openNewDraft()
                        setApiCallStatus({ error: "", "status": "succeeded" })
                        dispatch(updateDraftCount())
                    }).catch(error => {
                        setApiCallStatus({ error: error.message, "status": "succeeded" })
                    })
                }
            }
            else if (err.response.data.msg === "Not enough quantity") {
                // alert(err.response.data.msg)
                setSnackbarMessage(err.response.data.msg)
                setOpenSnackbar(true)
                setApiCallStatus({ error: err.response.data.msg, "status": "failed" })
            } else {
                setSnackbarMessage("Can not Add Invoice")
                setOpenSnackbar(true)
                // alert(err.message)
                setApiCallStatus({ error: err.message, "status": "failed" })
            }

        }
    };


    const cashInvoiceOffline = async (cashInvoice: any) => {
        let foundInvoice: any = await db.encInvoice!.get(cashInvoice?.draft_uid) ?? null

        if (foundInvoice === null || foundInvoice === undefined) {
            const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(cashInvoice), "asdflkower@kS990#$sdfsdf").toString();

            try {
                await db.transaction('rw', db.encInvoice, async () => {
                    await db.encInvoice.add({ id: cashInvoice?.draft_uid, data: encryptedData })
                })
                console.log("Transaction committed");
                return true
            } catch (err: any) {
                console.error(err.stack);
                return false
            }
            // db.transaction('rw', db.encInvoice, async () => {
            //     await db.encInvoice.add({ id: cashInvoice?.draft_uid, data: encryptedData })
            // }).then(() => {
            //     console.log("Transaction committed");
            //     return true
            // }).catch(err => {
            //     console.error(err.stack);
            //     return Promise.reject( false)
            // });
        }
        return false

    };





    const getInvoiceAvailableStock = () => async () => {
        setApiCallStatus({ error: "", "status": "loading" })

        try {


            const response = await api.post(
                '/invoice/stock',
                currentDraft.invoice_items,
                { headers: { "Content-Type": "application/json" } }
            )

            if (response.status === 200) {
                dispatch(setDraftStock(response.data["report"]))
                navigate('/draft/stock')
                setApiCallStatus({ error: "", "status": "succeeded" })

            } else {
                setApiCallStatus({ error: response.statusText, "status": "failed" })
            }

        } catch (err: any) {
        }
    };



    const getInvoiceUnAvailableStock = () => async () => {
        setApiCallStatus({ error: "", "status": "loading" })

        try {


            const response = await api.post(
                '/invoice/stock',
                currentDraft.invoice_items,
                { headers: { "Content-Type": "application/json" } }
            )

            if (response.status === 200) {

                dispatch(setDraftStock(response.data["report"].filter((item: any) => item['main_unit_quantity'] > item['available_stock'])))
                navigate('/draft/stock')
                setApiCallStatus({ error: "", "status": "succeeded" })

            } else {
                setApiCallStatus({ error: response.statusText, "status": "failed" })
            }

        } catch (err: any) {
        }
    };







    return {
        gridColumns, currentDraft, selectionModel,
        addNewInvoiceItem,
        onDeleteBillItems,
        onRowSelectionChanged,
        openNewDraft,

        addInvoiceData,
        isLoading,
        isSucceeded,
        isIdle,
        isFailed,
        apiCallError,


        update,
        storeList,
        setStoreList,
        invoiceTransferStore,
        setInvoiceTransferStore,

        handleCloseSnackbar,
        openSnackbar,
        snackbarMessage,
        getInvoiceAvailableStock,
        getInvoiceUnAvailableStock,

        detailAccordionExpanded,
        setDetailAccordionExpanded,
    }
}


export default useDraft