import React, { useEffect, useMemo, useState, useCallback, useRef } from 'react';
import { LinearProgress, Paper, Button, Box, Container, Typography, AppBar, Toolbar, ToggleButton, IconButton } from '@mui/material';


import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { selectDirection, selectTranslations } from "../i18n/i18nSlice";

import { selectPaletteMode } from '../appconfig/appConfigSlice';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import SaveIcon from '@mui/icons-material/Save';
import * as XLSX from 'xlsx';
import { AgGridReact } from 'ag-grid-react'; // React Grid Logic
import "ag-grid-community/styles/ag-grid.css"; // Core CSS
import "ag-grid-community/styles/ag-theme-quartz.css"; // Theme
// import "ag-grid-community/styles/ag-theme-quartz-dark.css"; // Theme
// import "./styles.css"
import { ColDef, SizeColumnsToContentStrategy, GridReadyEvent, CellValueChangedEvent, CellEditRequestEvent, CellEditingStoppedEvent, CellStyle } from 'ag-grid-community';
import { componentSavedFilter, setComponentFilter } from './reportsSlice';
import { SavedSearch } from '@mui/icons-material';

import _ from 'lodash';

interface ApiStatus {
  error: string;
  status: 'idle' | 'loading' | 'succeeded' | 'failed'
}


const downloadExcel = (data: any) => {
  const worksheet = XLSX.utils.json_to_sheet(data);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  XLSX.writeFile(workbook, "DataSheet1.xlsx");
};


const useRepoAG = <Row extends Record<string, any>>(currentVal: Record<string, any>[], componentName: string, transRep: string, title: string, rows: any[], onOpenClick?: (id: number) => void, openParameterName?: string, onRowsChange?: Function, onSaveChanges?: Function) => {
  const dispatch = useAppDispatch()
  const savedFilter = useAppSelector(componentSavedFilter)[componentName];

  const gridRef = useRef<AgGridReact>(null);
  const [floatingFilter, setFloatingFilter] = useState<boolean>(false)

  const [changedDataNotSaved, setChangedDataNotSaved] = useState<boolean>(false)

  // const [rows, setRows] = useState<Row[]>([])
  const paletteMode = useAppSelector(selectPaletteMode);


  const [apiCallStatus, setApiCallStatus] = React.useState<ApiStatus>({ error: "", status: "idle" })
  const isLoading = () => apiCallStatus.status === "loading";
  const isSucceeded = () => apiCallStatus.status === "succeeded";
  const isIdle = () => apiCallStatus.status === "idle";
  const isFailed = () => apiCallStatus.status === "failed";

  const langDirection = useAppSelector(selectDirection);
  const trans = useAppSelector(selectTranslations);

  // const [currentRows, setCurrentRows] = useState([
  //   {commission: '1', product_id: 2, product_name: 'حنفية بلية 1/2" يونيفال صغيرة180'},
  //   {commission: '1', product_id: 5, product_name: 'حنفية بلية 1/2" يونيفال لوكس'},
  //   {commission: '1', product_id: 6, product_name: 'حنفية بلية 1/2" ريمون'}
  //  ])
  // console.log(typeof rows)
  // const [currentRows, setCurrentRows] = useState<any[]>([])
  // // console.log(currentRows)
  // console.log(Object.isFrozen(rows))
  // console.log('--------------------')

  // const currentRows = useMemo(() => {
  //   return rows
  // },[rows])


  // set background colour on every row, this is probably bad, should be using CSS classes
  const rowStyle = { background: '' };

  // set background colour on even rows again, this looks bad, should be using CSS classes
  const getRowStyle = (params: any) => {
    // if (params.node.rowIndex % 2 === 0) {
    //     return { background: 'red' };
    // }
    // return { border: '1px solid #bdc1c6' };
    return { borderBottom: '1px solid #bdc1c6' };
  };

  // function formatNumber (number: number):ValueFormatterFunc {
  //   // this puts commas into the number eg 1000 goes to 1,000,
  //   // i pulled this from stack overflow, i have no idea how it works
  //   return Math.floor(number)
  //     .toString()
  //     .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  // }

  function formatNumber(params: { value: number }): string {
    // this puts commas into the number eg 1000 goes to 1,000,
    // i pulled this from stack overflow, i have no idea how it works
    return (params.value?.toString() || '')
      // .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
      // .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
      // .replace(/\B(?=(\d{3})+(?!\d))/g, '$1,');
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  }

  function customValueFormatter(params: { value: any }): string {
    // You can perform any custom formatting logic here
    const formattedValue = `$${params.value.toFixed(2)}`; // Example: Format as currency

    return formattedValue;
  }




  const openColumn: ColDef[] = (onOpenClick !== undefined && openParameterName !== undefined) ?
    [{
      field: "open",
      headerName: trans.nsi_table.open,
      pinned: langDirection === "rtl" ? 'right' : 'left',
      floatingFilter: false,
      cellRenderer: typeof onOpenClick !== 'undefined' ? (params: any) => {
        // put the value in bold
        // return <Button style={{ width: 32, minWidth: 32 }} onClick={() => onOpenClick(params.data[openParameterName])}>{trans.nsi_table.open}</Button>;
        // to remove from pinned added !params.node.isRowPinned()
        return !params.node.isRowPinned() && <Button style={{ width: 32, minWidth: 32 }} onClick={() => onOpenClick(params.data[openParameterName!])}>{trans.nsi_table.open}</Button>;
      } : null
    },
    ] : []

  // Column Definitions: Defines & controls grid columns.

  const getCellStyle = (params: any) => { return params.data.isChanged ? { backgroundColor: 'lightgreen' } : null; };

  const colDefs = useMemo(() => {
    return [
      ...openColumn,
      ...currentVal.map(item => item.type === 'string' ?
        {
          field: item.field,
          headerName: (item.field in trans[transRep]) ? trans[transRep][item.field] : item.field,
          floatingFilter: floatingFilter,
          // filter: k==='invoice_type'?'بيع': true,
          filter: true,
          filterParams: {},
          editable: item.editable,
          cellStyle: (params: any) => getCellStyle(params),
        } :
        {
          field: item.field,
          headerName: (item.field in trans[transRep]) ? trans[transRep][item.field] : item.field,
          floatingFilter: floatingFilter,
          filter: 'agNumberColumnFilter',
          filterParams: {},
          // valueFormatter: '"$" + value.toLocaleString()',
          valueFormatter: formatNumber,
          pinned: item.field === 'id' ? langDirection === "rtl" ? 'right' as const : 'left' as const : null,
          editable: item.editable,
          cellStyle: (params: any) => getCellStyle(params),
        }
      )
    ]

  }, [currentVal, floatingFilter])

  console.log(colDefs)

 

  const onFilterChanged = () => {
    setFloatingFilter(!floatingFilter)
  }

  const defaultColDef = useMemo(() => ({
    floatingFilter: floatingFilter,
    cellStyle: { borderRight: '1px solid #bdc1c6' },
  }), [])


  const autoSizeStrategy: SizeColumnsToContentStrategy = {
    type: 'fitCellContents'
  };





  const onGridReady = useCallback((event: GridReadyEvent) => {
    if (typeof savedFilter !== 'undefined') event.api.setFilterModel(savedFilter);

    // setTimeout(()=>{
    //   let pinnedBottomData = generatePinnedBottomData();
    //   // event.api.setPinnedBottomRowData([pinnedBottomData]);
    //   gridRef.current!.api.updateGridOptions({pinnedBottomRowData:[pinnedBottomData]});
    // }, 500)

  }, []);


  const getPrintedData = () => {
    return rows.map(item => (
      Object.keys(currentVal).reduce((accumulator, key) => {
        return { ...accumulator, [trans[transRep][key]]: item[key] }
      }, {})
    ))
  }


  // const onCellValueChanged = (params) => { 
  //   if (params.oldValue !== params.newValue) { 
  //     params.column.setCellCssClasses(params.data, ['changed-cell']); 
  //   } 
  // };

  // const onCellValueChanged = (params: CellValueChangedEvent) => {
  //   const { data, colDef, newValue } = params;
  //   if(onRowsChange !== undefined) {
  //     // params.data.isChanged = true; // Set the flag for styling
  //     onRowsChange({id: data.product_id, field: colDef.field, value: newValue})
  //     params.api.refreshCells({ rowNodes: [params.node], columns: [colDef.field!] });
  //   }

  // }

  const onCellEditingStopped = (params: CellEditingStoppedEvent) => { 
    // const { rowIndex, newValue } = event; updateRow(rowIndex, newValue); 
    const { data, colDef, newValue } = params;
    if(onRowsChange !== undefined) {
      // params.data.isChanged = true; // Set the flag for styling
      onRowsChange({id: data.product_id, field: colDef.field, value: newValue})
      setChangedDataNotSaved(true)
      params.api.refreshCells({ rowNodes: [params.node], columns: [colDef.field!] });
    }
  };

  const onSave = () => {
    if(onSaveChanges !== undefined) {
      onSaveChanges()
    }
  }




  function onCellEditRequest(event: CellEditRequestEvent) {
    const data = event.data;
    const field = event.colDef.field;
    const newValue = event.newValue;

    const oldItem = rows.find((row) => row.id === data.id);

    if (!oldItem || !field) {
      return;
    }

    const newItem = { ...oldItem };

    newItem[field] = newValue;

    console.log("onCellEditRequest, updating " + field + " to " + newValue);

    const newRows = rows.map((oldItem) =>
      oldItem.id === newItem.id ? newItem : oldItem,
    );
    gridRef.current!.api.setGridOption("rowData", newRows);
  }

  const deepCopy = (obj: any) => { return _.cloneDeep(obj); };

  return (
    <Container maxWidth={false} sx={{ margin: 0, padding: 0 }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', height: `calc(100vh - 128px)` }}>
        <Box>

          {rows.length != 0 ?
            <Paper elevation={3} sx={{ marginBottom: 1, padding: 1, direction: langDirection }}>
              {/* <Button size="small" variant="outlined" startIcon={<FileDownloadIcon sx={{ marginRight: 0, marginLeft: 1 }} />} sx={{ marginX: 1 }} onClick={() => downloadExcel(getPrintedData())}>
                Download
              </Button> */}
              <IconButton color="primary" aria-label="download" onClick={() => downloadExcel(getPrintedData())}>
                <FileDownloadIcon />
              </IconButton>
              <ToggleButton size="small"

                value="check"
                selected={floatingFilter}
                onChange={() => onFilterChanged()}
              >

                {/* <FilterAltIcon  sx={{color: floatingFilter ? 'red' : 'disabled' }} fontSize='small' /> */}
                <FilterAltIcon color={floatingFilter ? 'primary' : 'disabled'} fontSize='small' />
              </ToggleButton>
              {changedDataNotSaved?
              <IconButton color="primary" aria-label="download" onClick={() => onSave()}>
                <SaveIcon />
              </IconButton>
              :null
              }
            </Paper>
            : null}
        </Box>
        <Box>
          <AppBar position="static" color="primary" enableColorOnDark>
            <Toolbar component="nav" variant="dense" sx={{ justifyContent: 'space-between', overflowX: 'auto' }}  >
              <Typography component="h2" variant="h5">
                {title}
              </Typography>
            </Toolbar>
          </AppBar>

        </Box>
        <div className={`${paletteMode === 'light' ? 'ag-theme-quartz' : 'ag-theme-quartz-dark'}`} style={{ height: "100%", width: "100%" }}>
          <AgGridReact
            debug 
            ref={gridRef}
            rowData={deepCopy(rows)}
            // rowData={commissionCatAmountsReport}
            columnDefs={colDefs}
            defaultColDef={defaultColDef}
            enableRtl={langDirection === "rtl" ? true : false}
            // onCellValueChanged={onCellValueChanged}
            onCellEditingStopped={onCellEditingStopped}
            // onCellEditRequest={onCellEditRequest}
            // rowStyle={rowStyle}
            getRowStyle={getRowStyle}
            autoSizeStrategy={autoSizeStrategy}
            onGridReady={onGridReady}
            onFilterChanged={() => {
              dispatch(setComponentFilter({ componentName: componentName, componentFilterValue: gridRef.current!.api.getFilterModel() }))
            }}
          />
        </div>
      </Box>
    </Container>
  )


}


export { useRepoAG };
export type { ApiStatus };
