import React, { useContext, useEffect, createContext, useMemo, useState, useCallback } from 'react'
import { LinearProgress, Paper, Button, Box, Container, Typography, Divider, AppBar, Toolbar, TextField } from '@mui/material';
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { selectDirection, selectTranslations } from "../i18n/i18nSlice";

import CloudSyncIcon from '@mui/icons-material/CloudSync';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useNavigate, Link } from "react-router-dom";


import DataGrid, { Column, SortColumn, HeaderRendererProps, useFocusRef, headerRenderer as HeaderRenderer } from 'react-data-grid';
import { DraggableHeaderRenderer } from './DraggableHeaderRenderer'
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useDrag, useDrop } from 'react-dnd';



import { selectPaletteMode } from '../appconfig/appConfigSlice';
import { Customer } from '../../models/Customer';
import { db } from '../../db';
import api from '../services/api';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import * as XLSX from 'xlsx';

interface ApiStatus {
  error: string;
  status: 'idle' | 'loading' | 'succeeded' | 'failed'
}


//*1-add model
interface Row {
  category_name: string;
  average_cost_price: number;
  main_unit_quantity: number;
  total_cost: number;
  total_cost_by_purchase_price: number;

}


// purchase_price
// average_cost_price
// main_unit_quantity
// total_cost
// total_cost_by_purchase_price

//*2- add filter
interface Filter extends Omit<Row,  'average_cost_price' | 'main_unit_quantity' | 'total_cost' | 'total_cost_by_purchase_price'> {
  average_cost_price: number | undefined;
  main_unit_quantity: number | undefined;
  total_cost: number | undefined;
  total_cost_by_purchase_price: number | undefined;

  enabled: boolean;
}
const FilterContext = createContext<Filter | undefined>(undefined);


export default function StockRep006() {

  const navigate = useNavigate();
  const paletteMode = useAppSelector(selectPaletteMode);

  const dispatch = useAppDispatch()

  const langDirection = useAppSelector(selectDirection);
  const trans = useAppSelector(selectTranslations);
  // const rows = useAppSelector(customersReport);
  const [rows, setRows] = useState<Row[]>([])
  const [sortColumns, setSortColumns] = useState<readonly SortColumn[]>([]);

  const [apiCallStatus, setApiCallStatus] = React.useState<ApiStatus>({ error: "", status: "idle" })
  const isLoading = () => apiCallStatus.status === "loading";
  const isSucceeded = () => apiCallStatus.status === "succeeded";
  const isIdle = () => apiCallStatus.status === "idle";
  const isFailed = () => apiCallStatus.status === "failed";


  //*3-add download mapping
  const getPrintedData = () => {
    return rows.map(item => ({
     
      [trans.stock_rep1.category_name]: item["category_name"],
      [trans.stock_rep1.average_cost_price]: item["average_cost_price"],
      [trans.stock_rep1.main_unit_quantity]: item["main_unit_quantity"],
      [trans.stock_rep1.total_cost]: item["total_cost"],
      [trans.stock_rep1.total_cost_by_purchase_price]: item["total_cost_by_purchase_price"]

    }))
  }

  const downloadExcel = (data: any) => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "DataSheet1.xlsx");
  };


  //*4- define get report
  const getReport = async () => {
    setApiCallStatus({ error: "", "status": "loading" })
    try {
      const response = await api.get(
        '/stock/catavg',
        { headers: { "Content-Type": "application/json" } }
      )

      if (response.status === 200) {

        setRows(response.data["stock"])

        setApiCallStatus({ error: "", "status": "succeeded" })
      } else {
        setApiCallStatus({ error: response.statusText, "status": "failed" })
      }

    } catch (err: any) {
      setApiCallStatus({ error: err.message, "status": "failed" })
    }
  };


  const classes = {
    filterColumnClassName: 'filter-cell',
  }


  const filterColumnClassName = 'filter-cell';
  const filterContainerClassname = `
    .${filterColumnClassName} {
      line-height: 35px;
      padding: 0;
      > div {
        padding-block: 0;
        padding-inline: 8px;
        &:first-child {
          border-block-end: 1px solid var(--rdg-border-color);
        }
      }
    }
  `;
  const filterClassname = `
    inline-size: 100%;
    padding: 4px;
    font-size: 14px;
  `;





  function inputStopPropagation(event: React.KeyboardEvent<HTMLInputElement>) {
    if (['ArrowLeft', 'ArrowRight'].includes(event.key)) {
      event.stopPropagation();
    }
  }



  //*5- set default values
  const [filters, setFilters] = useState<Filter>({
    category_name: '',
    average_cost_price: 0,
    main_unit_quantity: 0,
    total_cost: 0,
    total_cost_by_purchase_price: 0, enabled: true
  });



  //*6- set fitlers and ordering
  const filteredRows = useMemo((): readonly Row[] => {

    if (sortColumns.length === 0) {
      let sortedRows: Row[] = rows.filter((r) => {
        return (
          (filters.category_name ? r.category_name.toLowerCase().includes(filters.category_name.toLowerCase()) : true) &&
          (filters.average_cost_price !== undefined ? r.average_cost_price >= filters.average_cost_price : true) &&
          (filters.main_unit_quantity !== undefined ? r.main_unit_quantity >= filters.main_unit_quantity : true) &&
          (filters.total_cost !== undefined ? r.total_cost >= filters.total_cost : true) &&
          (filters.total_cost_by_purchase_price !== undefined ? r.total_cost_by_purchase_price >= filters.total_cost_by_purchase_price : true)


        );
      });
      return sortedRows
    }
    const { columnKey, direction } = sortColumns[0];

    let sortedRows: Row[] = rows.filter((r) => {
      return (
        (filters.category_name ? r.category_name.toLowerCase().includes(filters.category_name.toLowerCase()) : true) &&
        (filters.average_cost_price !== undefined ? r.average_cost_price >= filters.average_cost_price : true) &&
        (filters.main_unit_quantity !== undefined ? r.main_unit_quantity >= filters.main_unit_quantity : true) &&
        (filters.total_cost !== undefined ? r.total_cost >= filters.total_cost : true) &&
        (filters.total_cost_by_purchase_price !== undefined ? r.total_cost_by_purchase_price >= filters.total_cost_by_purchase_price : true)

      );
    });

    switch (columnKey) {
      case 'category_name':
        sortedRows = sortedRows.sort((a, b) => a[columnKey].localeCompare(b[columnKey]));
        break;
      case 'average_cost_price':
        sortedRows = sortedRows.sort((a, b) => a[columnKey] - b[columnKey]);
        break;
      case 'main_unit_quantity':
        sortedRows = sortedRows.sort((a, b) => a[columnKey] - b[columnKey]);
        break;
      case 'total_cost':
        sortedRows = sortedRows.sort((a, b) => a[columnKey] - b[columnKey]);
        break;
      case 'total_cost_by_purchase_price':
        sortedRows = sortedRows.sort((a, b) => a[columnKey] - b[columnKey]);
        break;
      default:
    }

    return direction === 'DESC' ? sortedRows.reverse() : sortedRows;
  }, [rows, filters, sortColumns]);


  const [columnsChanged, setColumnsChanged] = useState(false);

  const filteredColumns = useMemo((): readonly Column<Row>[] => {

    function HeaderRenderer(props: HeaderRendererProps<Row>) {
      return <DraggableHeaderRenderer {...props} onColumnsReorder={handleColumnsReorder} />;
    }

    function handleColumnsReorder(sourceKey: string, targetKey: string) {
      const sourceColumnIndex = columns.findIndex((c) => c.key === sourceKey);
      const targetColumnIndex = columns.findIndex((c) => c.key === targetKey);
      const reorderedColumns = [...columns];

      reorderedColumns.splice(
        targetColumnIndex,
        0,
        reorderedColumns.splice(sourceColumnIndex, 1)[0]
      );

      setColumns(reorderedColumns);
      setColumnsChanged(!columnsChanged)
    }

    //*7- set colums
    return [
      { key: 'id', name: 'id', resizable: true, sortable: true, width: 20 },      
      {
        key: 'category_name', name: trans.stock_rep1.category_name, resizable: true, sortable: true, width: 300,
        headerCellClass: filterColumnClassName,
        headerRenderer: (p: HeaderRendererProps<Row>) => (

          <FilterRenderer<Row, unknown, HTMLInputElement> {...p} onColumnsReorder={handleColumnsReorder}>

            {({ filters, ...rest }) => (
              <>
                <HeaderRenderer {...p}></HeaderRenderer>
                <input
                  {...rest}
                  className={filterClassname!}
                  // variant='outlined'
                  style={{ inlineSize: "100%", padding: "4px", fontSize: "14px" }}
                  value={filters.category_name}
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      category_name: e.target.value
                    })
                  }
                  onKeyDown={inputStopPropagation}
                />
              </>
            )}
          </FilterRenderer>
        )
      },
     
      {
        key: 'average_cost_price', name: trans.stock_rep1.average_cost_price, resizable: true, sortable: true, width: 190,
        headerCellClass: filterColumnClassName,
        headerRenderer: (p: HeaderRendererProps<Row>) => (
          <FilterRenderer<Row, unknown, HTMLInputElement> {...p} onColumnsReorder={handleColumnsReorder}>
            {({ filters, ...rest }) => (
              <>
                <HeaderRenderer {...p}></HeaderRenderer>
                <input
                  {...rest}
                  type="number"
                  style={{ inlineSize: "100%", padding: "4px", fontSize: "14px" }}
                  value={filters.average_cost_price}
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      average_cost_price: Number.isFinite(e.target.valueAsNumber)
                        ? e.target.valueAsNumber
                        : undefined
                    })
                  }
                  onKeyDown={inputStopPropagation}
                />
              </>
            )}
          </FilterRenderer>)
      },
      {
        key: 'main_unit_quantity', name: trans.stock_rep1.main_unit_quantity, resizable: true, sortable: true, width: 120,
        headerCellClass: filterColumnClassName,
        headerRenderer: (p: HeaderRendererProps<Row>) => (
          <FilterRenderer<Row, unknown, HTMLInputElement> {...p} onColumnsReorder={handleColumnsReorder}>
            {({ filters, ...rest }) => (
              <>
                <HeaderRenderer {...p}></HeaderRenderer>
                <input
                  {...rest}
                  type="number"
                  style={{ inlineSize: "100%", padding: "4px", fontSize: "14px" }}
                  value={filters.main_unit_quantity}
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      main_unit_quantity: Number.isFinite(e.target.valueAsNumber)
                        ? e.target.valueAsNumber
                        : undefined
                    })
                  }
                  onKeyDown={inputStopPropagation}
                />
              </>
            )}
          </FilterRenderer>)
      },
      {
        key: 'total_cost', name: trans.stock_rep1.total_cost, resizable: true, sortable: true, width: 120,
        headerCellClass: filterColumnClassName,
        headerRenderer: (p: HeaderRendererProps<Row>) => (
          <FilterRenderer<Row, unknown, HTMLInputElement> {...p} onColumnsReorder={handleColumnsReorder}>
            {({ filters, ...rest }) => (
              <>
                <HeaderRenderer {...p}></HeaderRenderer>
                <input
                  {...rest}
                  type="number"
                  style={{ inlineSize: "100%", padding: "4px", fontSize: "14px" }}
                  value={filters.total_cost}
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      total_cost: Number.isFinite(e.target.valueAsNumber)
                        ? e.target.valueAsNumber
                        : undefined
                    })
                  }
                  onKeyDown={inputStopPropagation}
                />
              </>
            )}
          </FilterRenderer>)
      },
      {
        key: 'total_cost_by_purchase_price', name: trans.stock_rep1.total_cost_by_purchase_price, resizable: true, sortable: true, width: 190,
        headerCellClass: filterColumnClassName,
        headerRenderer: (p: HeaderRendererProps<Row>) => (
          <FilterRenderer<Row, unknown, HTMLInputElement> {...p} onColumnsReorder={handleColumnsReorder}>
            {({ filters, ...rest }) => (
              <>
                <HeaderRenderer {...p}></HeaderRenderer>
                <input
                  {...rest}
                  type="number"
                  style={{ inlineSize: "100%", padding: "4px", fontSize: "14px" }}
                  value={filters.total_cost_by_purchase_price}
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      total_cost_by_purchase_price: Number.isFinite(e.target.valueAsNumber)
                        ? e.target.valueAsNumber
                        : undefined
                    })
                  }
                  onKeyDown={inputStopPropagation}
                />
              </>
            )}
          </FilterRenderer>)
      },

    ]
  }, [columnsChanged])


  const [columns, setColumns] = useState(filteredColumns);


  //*8- set on click open
  const onOpenClick = (id: number) => {
    navigate("/product/" + id.toString());
  }

  useEffect(() => {
    getReport()
  }, [])


  const onSortColumnsChange = useCallback((sortColumns: SortColumn[]) => {
    setSortColumns(sortColumns.slice(-1));
  }, []);


  const draggableColumns = useMemo((): readonly Column<Row>[] => {
    function HeaderRenderer(props: HeaderRendererProps<Row>) {
      return <DraggableHeaderRenderer {...props} onColumnsReorder={handleColumnsReorder} />;
    }

    function handleColumnsReorder(sourceKey: string, targetKey: string) {
      const sourceColumnIndex = columns.findIndex((c) => c.key === sourceKey);
      const targetColumnIndex = columns.findIndex((c) => c.key === targetKey);
      const reorderedColumns = [...columns];

      reorderedColumns.splice(
        targetColumnIndex,
        0,
        reorderedColumns.splice(sourceColumnIndex, 1)[0]
      );

      setColumns(reorderedColumns);
    }

    return columns.map((c) => {
      if (c.key === 'id') return c;
      return { ...c, headerRenderer: HeaderRenderer };
    });
  }, [columns]);



  return (
    <Container maxWidth={false} sx={{margin:0, padding:0}}>
      {isLoading() && <LinearProgress />}
      {isSucceeded() && (
        <>

          <Box sx={{ display: 'flex', flexDirection: 'column', height: `calc(100vh - 128px)` }}>
            <Box>

              {rows.length != 0 ?
                <Paper elevation={3} sx={{ marginBottom: 2, padding: 2, direction: langDirection }}>
                  <Button variant="contained" startIcon={<FileDownloadIcon sx={{ marginRight: 0, marginLeft: 1 }}/>} sx={{ marginX: 1 }} onClick={() => downloadExcel(getPrintedData())}>
                    Download
                  </Button>
                </Paper>
                : null}
            </Box>
            <Box>
              <AppBar position="static" sx={{ backgroundColor: "#283593" }}>
                <Toolbar component="nav" variant="dense" sx={{ justifyContent: 'space-between', overflowX: 'auto' }}  >
                  <Typography component="h2" variant="h5">
                    {trans.stock_rep1.title}
                  </Typography>
                </Toolbar>
              </AppBar>

            </Box>
            <DndProvider backend={HTML5Backend}>
              <FilterContext.Provider value={filters}>
                <DataGrid
                  className={`${paletteMode === 'light' ? 'rdg-light' : 'rdg-dark'} ${filters.enabled ? filterContainerClassname : undefined}`}
                  columns={columns}
                  rows={filteredRows}
                  headerRowHeight={filters.enabled ? 70 : undefined}
                  direction={langDirection === "rtl" ? "rtl" : "ltr"}
                  style={{ flexGrow: 1 }}

                  sortColumns={sortColumns}
                  onSortColumnsChange={onSortColumnsChange}
                />
              </FilterContext.Provider>
            </DndProvider>

          </Box>
        </>
      )}
    </Container>
  )
}



interface DraggableHeaderRendererProps<R, SR> extends HeaderRendererProps<R, SR> {
  onColumnsReorder: (sourceKey: string, targetKey: string) => void;
}


function FilterRenderer<R, SR, T extends HTMLOrSVGElement>({
  onColumnsReorder,
  isCellSelected,
  column,
  children,
  ...props
}: DraggableHeaderRendererProps<R, SR> & {
  children: (args: {
    ref: React.RefObject<T>;
    tabIndex: number;
    filters: Filter;
  }) => React.ReactElement;
}) {
  const filters = useContext(FilterContext)!;
  const { ref, tabIndex } = useFocusRef<T>(isCellSelected);
  const [{ isDragging }, drag] = useDrag({
    type: 'COLUMN_DRAG',
    item: { key: column.key },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  });

  const [{ isOver }, drop] = useDrop({
    accept: 'COLUMN_DRAG',
    drop({ key }: { key: string }) {
      onColumnsReorder(key, column.key);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  });

  return (
    <>
      <div
        ref={(ref) => {
          drag(ref);
          drop(ref);
        }}
        style={{
          opacity: isDragging ? 0.5 : 1,
          backgroundColor: isOver ? '#ececec' : undefined,
          cursor: 'move'
        }}
      >
        {filters!.enabled && <div>{children({ ref, tabIndex, filters })}</div>}
      </div>
    </>
  );
}


