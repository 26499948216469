import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import i18nReducer from '../features/i18n/i18nSlice';
import languageReducer from '../features/language/languageSlice';
import appConfigReducer from '../features/appconfig/appConfigSlice';
import miniDrawerReducer from '../features/drawer/miniDrawerSlice';
import primaryAppBarReducer from '../features/appBar/primaryAppBarSlice';
import counterReducer from '../features/counter/counterSlice';
import loginReducer from '../features/login/loginSlice';
import customerReducer from '../features/customer/customerSlice';
import productReducer from '../features/product/productSlice';
import financeSlice from '../features/finance/financeSlice';
import reportsSlice from '../features/reports/reportsSlice';
import commissionSlice from '../features/commission/commissionSlice';
import invoiceSlice from '../features/invoice/invoiceSlice';
import connectionSlice from '../features/connection/connectionSlice';



export const store = configureStore({
  reducer: {
    i18n: i18nReducer,
    language: languageReducer,
    appConfig: appConfigReducer,
    miniDrawer: miniDrawerReducer,
    primaryAppBar: primaryAppBarReducer,
    counter: counterReducer,
    login: loginReducer,
    customer: customerReducer,
    product: productReducer,
    finance: financeSlice,
    reports: reportsSlice,
    commission: commissionSlice,
    invoiceDraft: invoiceSlice,
    connection: connectionSlice,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
