import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid2 from '@mui/material/Grid2';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { selectDirection, selectTranslations } from "../i18n/i18nSlice";
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { getExpensesTypeList, expensesTypeList, financeCallStatus } from './financeSlice'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux';
import { height } from '@mui/system';
import LinearProgress from '@mui/material/LinearProgress';
import { useParams } from 'react-router-dom';
import SaveIcon from '@mui/icons-material/Save';
import { AppBar, Autocomplete, AutocompleteChangeReason, Divider, FormControl, IconButton, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, Toolbar } from '@mui/material';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateField } from '@mui/x-date-pickers';
import api from '../services/api';
import { SelectColumn } from 'react-data-grid';
import ExpenseDatagride from './ExpenseDatagride';
import AddBoxIcon from '@mui/icons-material/AddBox';
import DeleteIcon from '@mui/icons-material/Delete';

import { nanoid } from 'nanoid';
import ExpenseAGDatagride from './ExpenseAGDatagride';



interface ApiStatus {
  error: string;
  status: 'idle' | 'loading' | 'succeeded' | 'failed'
}


interface ExpenseData {
  finance_account_id: number;
  finance_account_name: string;
  amount: number;
  description: string;
  itemHash: string;
}

interface DocumentData {
  id: number;
  document_date: string;
  description: string;
  document_type: string;
  document_type_id: number;
  // account_type_id: number;
  extended_docuemnt_id: number;
  accounting_months: number;
  comment: string;
  items: ExpenseData[];
}

interface SummaryRow {
  id: string;
  amount: number;
}


interface ExpenseType {
  id: number;
  expense_name: string;
}



type Props = {}
const theme = createTheme();
//{customerAccountId, customerType}: {customerAccountId: number, customerType: number}
export default function AddExpensesDocumentMultiple() {
  const expenseTypeRef = React.useRef<HTMLInputElement>(null);
  const amountRef = React.useRef<HTMLInputElement>(null);
  const descriptionRef = React.useRef<HTMLInputElement>(null);

  const params = useParams();
  const { documentType } = params
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const financeApiCallStatus = useAppSelector(financeCallStatus);
  const [apiCallStatus, setApiCallStatus] = React.useState<ApiStatus>({ error: "", status: "idle" })
  const expensesType: ExpenseType[] = useAppSelector(expensesTypeList);
  const isLoading = (sliceStatus: any) => apiCallStatus.status === "loading" || sliceStatus === "loading";
  const isSucceeded = (sliceStatus: any) => apiCallStatus.status === "succeeded" && !(sliceStatus === "loading") || sliceStatus === "succeeded" && !(apiCallStatus.status === "loading");
  const isIdle = (sliceStatus: any) => apiCallStatus.status === "idle" && !(sliceStatus === "loading") || sliceStatus === "idle" && !(apiCallStatus.status === "loading");
  const isFailed = (sliceStatus: any) => apiCallStatus.status === "failed" && !(sliceStatus === "loading") || sliceStatus === "failed" && !(apiCallStatus.status === "loading");
  const trans = useAppSelector(selectTranslations);
  const langDirection = useAppSelector(selectDirection);
  const [documentData, setDocumentDataData] = React.useState<DocumentData>({
    id: 0,
    document_date: new Date().toISOString().split('.')[0] + "Z",
    description: "",
    document_type: '',
    document_type_id: 15,
    // account_type_id: 0,
    extended_docuemnt_id: 0,
    accounting_months: 1,
    comment: '',
    items: [],
  })


  // const [expenseList, setExpenseList] = React.useState<ExpenseData[]>([])
  // const [selectedFinanceAccountId, setSelectedFinanceAccountId] = React.useState<number>()
  const [selectedExpense, setSelectedExpense] = React.useState<ExpenseData>()

  const [amountValue, setAmountValue] = React.useState<number>(0)
  const [descriptionValue, setDescriptionValue] = React.useState<string>('')
  const [expenseId, setExpenseId] = React.useState<number>(0)
  const [expenseName, setExpenseName] = React.useState<string>('')
  const [total, setTotal] = React.useState<number>(0)
  // const [amount, setAmount] = React.useState(0)
  // const [note, setNote] = React.useState('')
  // const [docDate, setDocDate] = React.useState(Date());

  const [selectionModel, setSelectionModel] = React.useState<ReadonlySet<string>>(() => new Set());
  const onRowSelectionChanged = React.useCallback((selections: ReadonlySet<string>) => {
    setSelectionModel(selections)
  },[])


  
  const formatGrideColumn = () => {
    return [
      // { field: 'id', headerName: 'ID', width: 90 },
      SelectColumn,
      {
        key: 'finance_account_name',
        name: trans.document_form.title_expense_type,
        width: 390,
        // editable: true,
        // headerCellClass: "draf-header",
        sortable: false,
        summaryFormatter({ row }: { row: any; }): JSX.Element {
          return <strong style={{ direction: langDirection }}>Rows: {row.totalCount}</strong>;
        }
      },
      {
        key: 'amount',
        name: trans.document_form.amount,
        type: 'number',
        width: 140,
        // editable: true,
        sortable: false,
        summaryFormatter({ row }: { row: any; }): JSX.Element {
          return <>{row.qtyTotal}</>;
        }
      },
      {
        key: 'description',
        name: trans.document_form.description,
        type: 'number',
        width: '100%',
        // editable: true,
        sortable: false,
        summaryFormatter({ row }: { row: any; }): JSX.Element {
          return <>{row.qtyTotal}</>;
        }
      },
    ]

  }

  const expenseDefaultProps = {
    options: expensesType,
    getOptionLabel: (option: ExpenseType) => option.expense_name,
  };

  const onDeleteBillItems = () => {



    setDocumentDataData({ ...documentData!, items: documentData.items.filter(item => !selectionModel.has(item.itemHash)) })
    // setExpenseList(expenseList.filter(item => !selectionModel.has(item.itemHash)))

    const totalValue = documentData.items.filter(item => !selectionModel.has(item.itemHash)).reduce((acc, ritem) => acc + ritem.amount, 0)
    setTotal(totalValue)

    setSelectionModel(new Set());
  }


  const addCurrentLine = () => {

    if (amountValue > 0) {

      const newList = [...documentData.items!, { finance_account_id: expenseId, finance_account_name: expenseName, amount: amountValue, description: descriptionValue, itemHash: nanoid() } as ExpenseData]
      setDocumentDataData({ ...documentData!, items: newList })
      // setExpenseList(newList)
      setAmountValue(0)
      setDescriptionValue('')
      const totalValue = newList.reduce((acc, ritem) => acc + ritem.amount, 0)
      setTotal(totalValue)
      expenseTypeRef.current!.focus();
    }
  }


  const onExpenseAutoCompleteChange = (event: React.SyntheticEvent<Element, Event>, value: any | null, reason: AutocompleteChangeReason) => {

    if (value !== null) {

      setExpenseId(value.id)
      setExpenseName(value.expense_name)

      //   const newList = [...expenseList! , {...selectedExpense} as ExpenseData ]
      // setExpenseList(newList)

    }
  };




  const onAmountValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const amountValue: number = Number(event.target.value)
    setAmountValue(amountValue)
  }
  const onDescriptionValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDescriptionValue(event.target.value)
  }

  const onNoteValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDocumentDataData({ ...documentData!, comment: event.target.value })
  }

  const onSelectChange = (event: SelectChangeEvent<number>, child: React.ReactNode) => {
    // setSelectedFinanceAccountId(parseInt(event.target.value as string))
    // const expenseObject = expensesType?.find(item => item .finance_account_id = parseInt(event.target.value as string))
    // setSelectedExpense(expenseObject)
  };

  const onSelectAccountingMonthsChange = (event: SelectChangeEvent<number>, child: React.ReactNode) => {
    setDocumentDataData({ ...documentData!, accounting_months: parseInt(event.target.value as string) })
  };


  const onDateChange = (newValue: Date | null) => {
    // setDocDate(newValue!.toISOString().split('.')[0] + "Z")
    setDocumentDataData({ ...documentData!, document_date: newValue!.toISOString().split('.')[0] + "Z" })
  };

  const onExpenseTypePress = (e: any) => {
    if (e.keyCode === 13) {
      amountRef.current?.focus()
    }
  }
  const onExpenseTypeFocus = (e: any) => {
    expenseTypeRef.current?.select()
  }

  const onAmountPress = (e: any) => {
    if (e.keyCode === 13) {
      descriptionRef.current?.focus()
    }
  }

  const onAmountFocus = (e: any) => {
    // priceRef.current?.select()
    setTimeout(function () {
      e.target.select();
    }, 0);

    // e.target.select();
    // console.log('onPriceFocus')
  }

  const onDescriptionPress = (e: any) => {
    if (e.keyCode === 13) {
      addCurrentLine()
    }
  }

  const onDescriptionFocus = (e: any) => {
    // priceRef.current?.select()
    setTimeout(function () {
      e.target.select();
    }, 0);

    // e.target.select();
    // console.log('onPriceFocus')
  }




  const addExpenseDocument = async (document: DocumentData) => {
    setApiCallStatus({ error: "", "status": "loading" })
    try {
      const response = await api.post(
        '/expense/multiple',
        document,
        { headers: { "Content-Type": "application/json" } }
      )

      if (response.status === 200) {

        navigate(-1)
        setApiCallStatus({ error: "", "status": "succeeded" })
      } else {
        setApiCallStatus({ error: response.statusText, "status": "failed" })
      }

    } catch (err: any) {
      setApiCallStatus({ error: err.message, "status": "failed" })
    }
  };



  const onSubmit = () => {



    addExpenseDocument(documentData)
    // if (isSucceeded()) navigate(-1)
  }


  React.useEffect(() => {
    dispatch(getExpensesTypeList())
  }, []);

  return (
    <Container>

      {isLoading(financeApiCallStatus) && <LinearProgress />}
      {(isSucceeded(financeApiCallStatus) || isIdle(financeApiCallStatus) || isFailed(financeApiCallStatus)) && (
        <>
          <AppBar position="static" sx={{ backgroundColor: "#283593" }}>
            <Toolbar component="nav" variant="dense" sx={{ justifyContent: 'space-between', overflowX: 'auto' }}  >
              <Typography component="h2" variant="h5">
                {trans.document_form.title_expense}
              </Typography>
            </Toolbar>
          </AppBar>



          <Box sx={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', justifyContent: 'space-between' }}>
            <TextField id="note" name="note" label={trans.document_form.note} variant="standard" size='small' onChange={onNoteValueChange} value={documentData?.comment} />
            <LocalizationProvider dateAdapter={AdapterDateFns} >
              <DateField
                label={trans.document_form.date}
                format="dd/MM/yyyy"
                value={documentData ? new Date(documentData?.document_date) : new Date()}
                onChange={onDateChange}
                // slotProps={{ textField: { variant: 'standard' } }}
                sx={{ marginX: "6px" }}
              />
            </LocalizationProvider>

          </Box>


          <Paper sx={{ marginY: 3 }}>

            <Box sx={{ display: 'flex', flexDirection: 'row', }}>
              {expensesType.length > 0 ?


                <Autocomplete
                  sx={{ flexGrow: 1, margin: "4px" }}
                  {...expenseDefaultProps}
                  // options={customers}
                  id="expense_type"
                  autoHighlight
                  limitTags={10}
                  onChange={onExpenseAutoCompleteChange}
                  getOptionLabel={(option) => {
                    return option.expense_name || ""
                  }}
                  value={expensesType.find(expense => expense.id === expenseId) ?? null}
                  isOptionEqualToValue={(option, value) => {
                    // console.log(value)
                    if (value !== null) {
                      return option?.id === value?.id;
                    } else {
                      return false;
                    }
                  }}
                  // isOptionEqualToValue={(option, value) =>
                  //   option.iso === value.iso
                  // }
                  renderInput={(params) => (
                    <TextField {...params} label={trans.document_form.title_expense_type} size="small" inputRef={expenseTypeRef} onKeyDown={onExpenseTypePress} onFocus={onExpenseTypeFocus} />

                  )}
                /> : null}

              <TextField id="amount" name="amount" type='number' label={trans.document_form.amount} variant="standard" size='small'
                onChange={onAmountValueChange}
                onKeyDown={onAmountPress}
                onFocus={onAmountFocus}
                value={amountValue} inputRef={amountRef} />

              <TextField id="description" name="description" label={trans.document_form.description} variant="standard" size='small'
                onChange={onDescriptionValueChange}
                onKeyDown={onDescriptionPress}
                onFocus={onDescriptionFocus}
                value={descriptionValue} inputRef={descriptionRef} />

              {expensesType && <IconButton aria-label="delete" onClick={addCurrentLine} sx={{ width: 45, color: "#1976d2" }}>
                <AddBoxIcon />
              </IconButton>}
            </Box>
          </Paper>

          <Grid2 container>
                <Grid2 size={{ sm: 12, md: 12 }} width={"100%"}>
                {(selectionModel.size > 0) && <IconButton aria-label="delete" sx={{ marginX: 2, color: "#ff0000" }}
                onClick={onDeleteBillItems}
              >
                <DeleteIcon fontSize="small" />
                </IconButton>}
                  <ExpenseAGDatagride
                    rows={documentData.items ?? []}
                    onRowSelectionChanged={onRowSelectionChanged} />

                </Grid2>
              </Grid2>


          <Paper elevation={1} sx={{ marginY: 2 }}>
            <TextField id="order_total" label={trans.document_form.total} type="number" variant="standard" size='small' value={total} />
          </Paper>

          <Paper elevation={1} sx={{ marginY: 2 }}>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', padding: 2, justifyContent: 'space-around' }}>
              <Button variant="contained" startIcon={<SaveIcon sx={{ marginRight: 0, marginLeft: 1 }} />} onClick={onSubmit} sx={{ margin: '2px' }} size="small" disabled={documentData.items?.length === 0}>
                {trans.document_form.submit_button}
              </Button>
            </Box>
          </Paper>

        </>)}
    </Container>
  );
}