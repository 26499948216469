import { db } from '../../db'
import { ProductCatalog } from '../../models/ProductCatalog';
import { useState, useEffect } from 'react';
import { nanoid } from 'nanoid';
import { Invoice02 } from '../../models/Invoice02';
import { useAppDispatch } from '../../app/hooks';
import { updateDraftCount } from '../../features/login/loginSlice';


const defaultDraft = {
    draft_id: undefined,
    draft_uid: '',
    id: 0,
    invoice_type: 0,
    cash_invoice: true,
    payment_method: 0,
    customer_name: "",
    customer_id: 100,
    customer_type: 3,
    customer_account_id: 0,
    invoice_date: (new Date()).toISOString().split('.')[0]+"Z",
    invoice_discount_mode: 2,
    discount_percentage: 0,
    invoice_tax_mode: 1, 
    tax_percentage: 0,
    subtotal: 0,
    discount: 0,
    salesTotal: 0, 
    tax: 0,
    additions: 0,
    freight: 0,
    total: 0,
    payment_amount: 0,
    store: 1,
    invoice_comment:'',
    broker_id: 1,
    sales_person_id: 1,
    invcustomer_id: 1,
    invcustomer_phone: '',
    invcustomer_name: '',
    invcustomer_company: '',
    invcustomer_address: '',
    invcustomer_field: '',
    einv_customer: {}, //{"type":"B","id":"100291635","name":"فندق هابيتون","address":{"country":"EG","governate":"القاهرة ","regionCity":"عماد الدين","street":"ش على الكسار","buildingNumber":"10","postalCode":"","floor":"","room":"","landmark":"","additionalInformation":""}},
    insert_date: '',
    update_date:0,
    cancelled: false,
    invoice_items: []

} as Invoice02



enum DraftMode {
    DraftSaving = 1,
    WithouDrafts = 2,
}
const useDraftRepo = (invoice_type: number, mode: DraftMode = DraftMode.DraftSaving) => {
    const dispatch = useAppDispatch();
    const [draftCount, setDraftCount] = useState<number>(0)
    const [currentDraft, setCurrentDraft] = useState<Invoice02>({...defaultDraft, invoice_type: invoice_type})



    const update = async (invoice: Invoice02) => {
        if(mode === DraftMode.DraftSaving && invoice.invoice_items.length > 0){
            
            if(!invoice.draft_id){
                const result = await db.invoiceDraft.put({...invoice})
                if(!invoice.draft_id){
                    setCurrentDraft({...invoice, draft_id: (result as number)})
                }else {
                    setCurrentDraft({...invoice})
                }
                const counfOfDraft = await db.invoiceDraft.count()
                setDraftCount(counfOfDraft)
            }else{
                setCurrentDraft({...invoice})
                db.invoiceDraft.put({...invoice})
            }

            dispatch(updateDraftCount())
        }else{
            setCurrentDraft({...invoice})
        }
    }

    const loadDraft = async (id?: number) => {
        if(mode === DraftMode.DraftSaving && id){
            const invoiceDraft = await db.invoiceDraft.get(id)
            if (invoiceDraft) {
                // setCurrentDraft(invoiceDraft)
                setCurrentDraft({...invoiceDraft, invoice_items: invoiceDraft.invoice_items.map(item => ({...item, itemHash: nanoid() }))})
            }
            return invoiceDraft
        }else{
            const draft_uid =  nanoid()
            setCurrentDraft({...currentDraft, draft_uid: draft_uid})
            return {...currentDraft, draft_uid: draft_uid}
        }
    }

    const newDraft = async (invoice_type: number, current_store: number) => {
        const draft_uid =  nanoid()
        setCurrentDraft({...defaultDraft, store: current_store, invoice_type: invoice_type, draft_uid: draft_uid, invoice_date: (new Date()).toISOString().split('.')[0]+"Z", broker_id: 1, sales_person_id: 1})
        return defaultDraft
    }
    

    return {draftCount, currentDraft, update, loadDraft, newDraft}
  }


export default useDraftRepo