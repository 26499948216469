import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid2 from '@mui/material/Grid2';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import DeleteIcon from '@mui/icons-material/Delete';

import { selectDirection, selectTranslations } from "../i18n/i18nSlice";
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { productCallStatus, productData, setProductData, addProductData, resetProductData, productUnits, addproductUnits, setproductUnits, productPricing, getPriceList, priceList, setproductPricing, getProductTypeList, productTypeList, getProductCategoryList, productCategoryList, getProduct, updateProductData, productCallBusyCount, setproductComponents } from './productSlice'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux';
import { height } from '@mui/system';
import LinearProgress from '@mui/material/LinearProgress';
import { Dialog, Divider, FormControl, IconButton, InputLabel, MenuItem, Paper, Select, SelectChangeEvent } from '@mui/material';
import ProductUnits from './ProductUnits';
import ProductPricing from './ProductPricing';
import { ProductUnit, PriceItem, Colors, Sizes } from '../../models/ProductCatalog';



import SaveIcon from '@mui/icons-material/Save';
import { useParams } from 'react-router-dom';
import { db } from '../../db';
import CustomizedMenus, { StyledMenuItemData } from '../../components/StyledMenu';
import { StoreHouse } from '../../models/StoreHouse';
import DateRangeDialog from '../customer/DateRangeDialog';
import ComponentSelect from './ComponentSelect';
import ComponentAGDatagride from './ComponentAGDatagride';
import { useCallback } from 'react';
import { nanoid } from 'nanoid';



export interface Component {
  component_id: number;
  component_name: string;
  main_unit_quantity: number;
  unite_id: number;
}

export interface ComponentthHash extends Component{
  itemHash: string;
}


const theme = createTheme();

export default function ProductWithCS() {
  const navigate = useNavigate();
  const params = useParams();
  const { productId } = params

  const [changesNotSaved, setChangesNotSaved] = React.useState<boolean>(false);
  const [colorList, setColorList] = React.useState<Colors[]>([])
  const [sizeList, setSizeList] = React.useState<Sizes[]>([])
  const [storeHouseList, setStoreHouseList] = React.useState<StoreHouse[]>([])

  const [components, setComponents] = React.useState<any[]>([])



  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);


  const [selectionModel, setSelectionModel] = React.useState<ReadonlySet<string>>(() => new Set());
  const onRowSelectionChanged = useCallback((selections: ReadonlySet<string>) => {
    setSelectionModel(selections)
  },[])

  // console.log(selectionModel)

  const dispatch = useAppDispatch();
  const apiCallStatus = useAppSelector(productCallStatus);
  const apiStatusBusyCount = useAppSelector(productCallBusyCount);
  const productObject = useAppSelector(productData);
  const productUnitsData = useAppSelector(productUnits)
  const productPricingData = useAppSelector(productPricing);
  const priceListData = useAppSelector(priceList);
  const productTypeListData = useAppSelector(productTypeList);
  const productCategoryListData = useAppSelector(productCategoryList);

  const isLoading = React.useMemo(() => (apiCallStatus === "loading" || apiStatusBusyCount > 0) , [apiCallStatus, apiStatusBusyCount]);
  const isSucceeded = React.useMemo(() => (apiCallStatus === "succeeded" && apiStatusBusyCount === 0) , [apiCallStatus, apiStatusBusyCount]);
  const isIdle = React.useMemo(() => (apiCallStatus === "idle" && apiStatusBusyCount === 0) , [apiCallStatus, apiStatusBusyCount]);
  const isFailed = React.useMemo(() => (apiCallStatus === "failed" && apiStatusBusyCount === 0) , [apiCallStatus, apiStatusBusyCount]);

  // const isLoading = apiCallStatus === "loading" || apiStatusBusyCount > 0
  // const isSucceeded = apiCallStatus === "succeeded" || apiStatusBusyCount === 0
  // const isIdle = apiCallStatus === "idle" || apiStatusBusyCount === 0
  // const isFailed = apiCallStatus === "failed" || apiStatusBusyCount === 0

  // const isSucceeded = (status: any) => status.status === "succeeded" && status.busyCount === 0;
  // const isIdle = (status: any) => status.status === "idle" && status.busyCount === 0;
  // const isFailed = (status: any) => status.status === "failed" && status.busyCount === 0;
  const trans = useAppSelector(selectTranslations);
  const langDirection = useAppSelector(selectDirection);


  const loadLookups = async () => {
    const colors = await db.colors.toArray()
    setColorList(colors)
    const sizes = await db.sizes.toArray()
    setSizeList(sizes)
    const storeHouse = await db.storeHouse.toArray()
    setStoreHouseList(storeHouse)
  };

  const generatePriceListItems: () => { [key: string]: PriceItem } = () => {

    // return priceListData.map(item => ({...item, price: 0}))

    if (Object.keys(productPricingData).length > 0) return productPricingData;

    var result = priceListData.reduce<{ [key: string]: PriceItem }>((map, obj) => {
      let { id, ...nitem } = obj
      map[obj.id] = { ...nitem, price: 0, id: 0 };
      return map;
    }, {});
    return result
  }

  const [showDateRangeDialog, setShowDateRangeDialog] = React.useState(false);

  const onDateRangeDialogYes = (parentId: number, result: any[]) => {
    navigate(`/product/transwithrange/${productObject.id}/${result[0]}/${result[1]}`)
  }

  const openDateRangeDialog = (parentId: number) => {
    setShowDateRangeDialog(true)
  }



  const onInputValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setProductData({ ...productObject, [event.target.name]: event.target.value }))
    setChangesNotSaved(true)
  }

  const onCheckChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setProductData({ ...productObject, [event.target.name]: event.target.checked }))
    setChangesNotSaved(true)
  };

  // const handleChange = (event: { target: { value: string } }) => {
  const onSelectChange = (event: SelectChangeEvent<number>, child: React.ReactNode) => {
    dispatch(setProductData({ ...productObject, [event.target.name]: event.target.value }))
    setChangesNotSaved(true)
  };

  const onAdd = () => {
    dispatch(addProductData())
  }


  const addNewUnit = (productUnit: ProductUnit) => {
    dispatch(addproductUnits(productUnit))
  }

  const setNewProductUnits = (productUnits: ProductUnit[]) => {
    dispatch(setproductUnits(productUnits))
    setChangesNotSaved(true)
  }

  const updatePricing = (pricing: { [key: string]: PriceItem }) => {
    dispatch(setproductPricing(pricing))
    setChangesNotSaved(true)
  }

  const onStoreTransClicked = (storeId: number) => {
    navigate(`/product/storetrans/${productObject.id}/${storeId}`)
  }


  const onUpdate = () => {
    dispatch(updateProductData())
    if (isSucceeded) setChangesNotSaved(false)
  }

  const onAddComponent = (component: Component) => {

    // const foundObject = components.find((item) => item.component_id === component.component_id);
    const foundObject: any = productObject.components.find((item: any) => item.component_id === component.component_id);

    if (foundObject) {
      // setComponents([...components.filter(item => item.component_id !== component.component_id), { ...component, main_unit_quantity: component.main_unit_quantity + foundObject.main_unit_quantity }])
      // dispatch(setproductComponents([...(productObject.components as any[]).filter(item => item.component_id !== component.component_id), { ...component, main_unit_quantity: component.main_unit_quantity + foundObject.main_unit_quantity }]))
      dispatch(setproductComponents((productObject.components as any[]).map(item => ((item.component_id !== component.component_id)? item:{ ...item, main_unit_quantity: component.main_unit_quantity + foundObject.main_unit_quantity }))))

    } else {
      // setComponents([...components, {...component, itemHash: nanoid() } ])
      dispatch(setproductComponents([...(productObject.components as any[]), {...component, id:0, itemHash: nanoid() } ]))
    }

    setChangesNotSaved(true)
  }


  const onDeleteComponentsItems = () => {

    
    dispatch(setproductComponents((productObject.components as any[]).filter((delItem:any) => !(delItem.id === 0 && selectionModel.has(delItem.itemHash))).map( (item: any) => ({ ...item, cancelled: item.cancelled ? true : selectionModel.has(item.itemHash) }))))

    setSelectionModel(new Set());
    setChangesNotSaved(true)
}

  const currentComponents = React.useMemo(() => {
    // return productObject.components.filter((cmp: any) => !cmp.cancelled).map(item => ({...item, itemHash: nanoid()}))
    return productObject.components.filter((item: any) => !item.cancelled)

  },[productObject])
  console.log(productObject.components)


  React.useEffect(() => {
    loadLookups()
    dispatch(getPriceList())
    dispatch(getProductTypeList())
    dispatch(getProductCategoryList())
    // dispatch(getProduct(parseInt(productId!)))
  }, [])





  React.useEffect(() => {
    dispatch(getProduct(parseInt(productId!)))
    // const newInvoiceItems : InvoiceItemWithHash[] =  [...currentDraft?.invoice_items as InvoiceItemWithHash[], {...invoiceItem, itemHash: nanoid() } as InvoiceItemWithHash]
// 
    // setComponents(productObject.components.map(item => ({...item, itemHash: nanoid()})))
  }, [productId]);


  React.useEffect(()=> {
    if(productObject){
      setComponents(productObject.components.map(item => ({...item, itemHash: nanoid()})))
    }
  },[productObject])



  return (
    <Container>
      <Typography variant='h5'>{trans.product_form.title}</Typography>
      <Divider />
      {(isLoading) && <LinearProgress />}
      {(isSucceeded || isIdle || isFailed) && (
        <>
          <Grid2 container>
            <Grid2 size={{ sm: 12, md: 6, lg: 6 }} padding={2} >
              <Box sx={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', justifyContent: 'space-between' }}>

                <TextField id="product_name" name="product_name" label={trans.product_form.product_name} variant="standard" size="small" onChange={onInputValueChange} value={productObject.product_name} required />
                <TextField id="international_code" name="international_code" label={trans.product_form.international_code} variant="standard" size="small" onChange={onInputValueChange} value={productObject.international_code} required />
                <TextField id="user_defined_code" name="user_defined_code" label={trans.product_form.user_defined_code} variant="standard" size="small" onChange={onInputValueChange} value={productObject.user_defined_code} required />
                {/* <TextField id="product_type" name="product_type" label={trans.product_form.product_type} variant="standard" size="small" onChange={onInputValueChange} value={productObject.product_type} required /> */}
                {/* <TextField id="product_category" name="product_category" label={trans.product_form.product_category} variant="standard" size="small" onChange={onInputValueChange} value={productObject.product_category} required /> */}

                {productCategoryListData.length > 0 ?
                  <FormControl variant="standard" fullWidth>
                    <InputLabel id="demo-simple-select-standard-label" >{trans.product_form.product_category}</InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="product_category"
                      // defaultValue={'P'}
                      value={productObject.product_category!}
                      name="product_category"

                      label="Type"
                      onChange={onSelectChange}

                      size='small'
                      sx={{ width: "100%" }}
                    >
                      {productCategoryListData.map(productCategory => (<MenuItem value={productCategory.id} key={productCategory.id}>{productCategory.category_name}</MenuItem>))}
                    </Select>
                  </FormControl>
                  : <></>}

                {productTypeListData.length > 0 ?
                  <FormControl variant="standard" fullWidth>
                    <InputLabel id="demo-simple-select-standard-label" >{trans.product_form.product_type}</InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="product_type"
                      // defaultValue={'P'}
                      value={productObject.product_type!}
                      name="product_type"

                      label="Type"
                      // onChange={handleChange}
                      onChange={onSelectChange}

                      size='small'
                      sx={{ width: "100%" }}
                    >
                      {productTypeListData.map(productType => (<MenuItem value={productType.id} key={productType.id}>{productType.type_name}</MenuItem>))}
                    </Select>

                  </FormControl>
                  : <></>}



                {colorList.length ?
                  <FormControl variant="standard" fullWidth>
                    <InputLabel id="demo-simple-select-standard-label" >{trans.product_form.product_color}</InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="color_id"
                      // defaultValue={'P'}
                      value={productObject.color_id!}
                      name="color_id"

                      label="Type"
                      onChange={onSelectChange}

                      size='small'
                      sx={{ width: "100%" }}
                    >
                      {colorList.map(item => (<MenuItem value={item.id} key={item.id}>{item.color_name}</MenuItem>))}
                    </Select>
                  </FormControl>

                  : <></>}
                {sizeList.length > 0 ?
                  <FormControl variant="standard" fullWidth>
                    <InputLabel id="demo-simple-select-standard-label" >{trans.product_form.product_size}</InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="size_id"
                      // defaultValue={'P'}
                      value={productObject.size_id!}
                      name="size_id"

                      label="Type"
                      onChange={onSelectChange}

                      size='small'
                      sx={{ width: "100%" }}
                    >
                      {sizeList.map(item => (<MenuItem value={item.id} key={item.id}>{item.size_name}</MenuItem>))}
                    </Select>
                  </FormControl>
                  : <></>}
              </Box>
            </Grid2>
            <Grid2 size={{ sm: 12, md: 6, lg: 6 }} padding={2} >
              <Box sx={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                {/* <TextField id="is_medicine" name="is_medicine" label={trans.product_form.is_medicine} variant="standard" size="small" onChange={onInputValueChange} value={productObject.is_medicine} required /> */}
                <FormControlLabel label={trans.product_form.is_medicine} control={<Checkbox name="is_medicine" checked={productObject.is_medicine} onChange={onCheckChanged} tabIndex={-1} disableRipple />} />
                <FormControlLabel label={trans.product_form.is_freez} control={<Checkbox name="is_freez" checked={productObject.is_freez} onChange={onCheckChanged} tabIndex={-1} disableRipple />} />
                {/* <TextField id="is_freez" name="is_freez" label={trans.product_form.is_freez} variant="standard" size="small" onChange={onInputValueChange} value={productObject.is_freez} required /> */}
                <FormControlLabel label={trans.product_form.is_returnable} control={<Checkbox name="is_returnable" checked={productObject.is_returnable} onChange={onCheckChanged} tabIndex={-1} disableRipple />} />
                {/* <TextField id="is_returnable" name="is_returnable" label={trans.product_form.is_returnable} variant="standard" size="small" onChange={onInputValueChange} value={productObject.is_returnable} required /> */}
                <FormControlLabel label={trans.product_form.has_expire_date} control={<Checkbox name="has_expire_date" checked={productObject.has_expire_date} onChange={onCheckChanged} tabIndex={-1} disableRipple />} />

                <TextField id="default_expiry_days" name="default_expiry_days" type="number" label={trans.product_form.default_expiry_days} variant="standard" size="small" onChange={onInputValueChange} value={productObject.default_expiry_days} required />
                <TextField id="min_quantity" name="min_quantity" type='number' label={trans.product_form.min_quantity} variant="standard" size='small' onChange={onInputValueChange} value={productObject.min_quantity} />
                <TextField id="max_quantity" name="max_quantity" type='number' label={trans.product_form.max_quantity} variant="standard" size='small' onChange={onInputValueChange} value={productObject.max_quantity} />
                <TextField id="einv_code" name="einv_code" label={trans.product_form.einv_code} variant="standard" size="small" onChange={onInputValueChange} value={productObject.einv_code} required />

              </Box>
            </Grid2>
          </Grid2>
          <Typography variant='h6'>{trans.product_form.product_price_list}</Typography>
          <Divider />
          <ProductPricing priceList={generatePriceListItems()} updatePricing={updatePricing} />
          <Typography variant='h6'>{trans.product_form.product_units}</Typography>
          <Divider />
          <ProductUnits prodctUnits={productUnitsData} addproductUnits={addNewUnit} setNewProductUnits={setNewProductUnits} />

          {productObject.product_type === 3 ?
            <>
              <Typography variant='h6'>{trans.product_form.product_components}</Typography>
              <Divider />
              <Paper elevation={1} sx={{ marginY: 2, paddingY: 2 }}>
                <ComponentSelect addNewComponentItem={onAddComponent} />

              </Paper>
              <Grid2 container>
                <Grid2 size={{ sm: 12, md: 12 }} width={"100%"}>
                {(selectionModel.size > 0) && <IconButton aria-label="delete" sx={{ marginX: 2, color: "#ff0000" }}
                onClick={onDeleteComponentsItems}
              >
                <DeleteIcon fontSize="small" />
                </IconButton>}
                  <ComponentAGDatagride
                    rows={currentComponents}
                    onRowSelectionChanged={onRowSelectionChanged} />

                </Grid2>
              </Grid2>

            </> : <></>
          }
          <Paper elevation={1} sx={{ marginY: 2 }}>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', padding: 2, justifyContent: 'space-around' }}>
              <Button variant="contained" startIcon={<SaveIcon sx={{ marginRight: 0, marginLeft: 1 }} />} onClick={onUpdate} sx={{ margin: '2px' }} size="small" disabled={!changesNotSaved}>
                {trans.product_form.update_button}
              </Button>
            </Box>
          </Paper>

          <Paper elevation={1} sx={{ marginY: 2 }}>
            <Box
              sx={{ display: 'flex', flexWrap: 'wrap', padding: 2, justifyContent: 'space-around' }}
            >


              <Button variant="contained" sx={{ margin: '2px' }} size="medium" onClick={() => navigate(`/stock/product/${productObject.id}`)}>
                {trans.product_form.stock_in_all_branches}
              </Button>


              <Button variant="contained" sx={{ margin: '2px' }} size="medium" onClick={() => openDateRangeDialog(0)}>
                {trans.product_form.open_all_trans_button}
              </Button>

              <CustomizedMenus items={storeHouseList.map(item => ({ item_id: item.id, item_name: item.name })) as StyledMenuItemData[]} onMenuClick={onStoreTransClicked}>
                {trans.product_form.open_store_trans_button}
              </CustomizedMenus>



            </Box>
          </Paper>

        </>)}

      <Dialog
        open={showDateRangeDialog}
        keepMounted
        onClose={() => setShowDateRangeDialog(false)}
        aria-describedby="alert-dialog-slide-description"
        dir={langDirection}
      >
        <DateRangeDialog openDialog={showDateRangeDialog} setOpenDialog={setShowDateRangeDialog} lable={"Date Range"} title={trans.date_range_dialog.title} parentId={1} onDialogYes={onDateRangeDialogYes} />
      </Dialog>

    </Container>
  );
}