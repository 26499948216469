import React, { useEffect } from 'react'

import { useNavigate } from "react-router-dom";
import { useRepoAG } from '../reports/useRepoAG _v4';


import { commissionCallBusyCount, commissionCallStatus, commissionCatReportData, getAllCommissionCat } from './commissionSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { LinearProgress } from '@mui/material';
import { selectTranslations } from '../i18n/i18nSlice';



//*1-add model
interface Row {
  id: number;
  commission_category: string;
}



export default function CommissionCatReport() {

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const trans = useAppSelector(selectTranslations);
  const apiCallStatus = useAppSelector(commissionCallStatus);
  const apiStatusBusyCount = useAppSelector(commissionCallBusyCount);

  const isLoading = React.useMemo(() => (apiCallStatus === "loading" || apiStatusBusyCount > 0), [apiCallStatus, apiStatusBusyCount]);
  const isSucceeded = React.useMemo(() => (apiCallStatus === "succeeded" && apiStatusBusyCount === 0), [apiCallStatus, apiStatusBusyCount]);
  const isIdle = React.useMemo(() => (apiCallStatus === "idle" && apiStatusBusyCount === 0), [apiCallStatus, apiStatusBusyCount]);
  const isFailed = React.useMemo(() => (apiCallStatus === "failed" && apiStatusBusyCount === 0), [apiCallStatus, apiStatusBusyCount]);

  const commissionCatReport = useAppSelector(commissionCatReportData);



  //*2- set on click open
  const onOpenClick = (id: number) => {
    navigate("/commissioncat/" + id.toString());
  }

  useEffect(() => {
    dispatch(getAllCommissionCat())
  }, [])




  //*3- define get report
  // const getReport = async (setRowsFun: React.Dispatch<React.SetStateAction<Row[]>>, setApiCallStatusFun: React.Dispatch<React.SetStateAction<ApiStatus>>) => {
  //   setApiCallStatusFun({ error: "", "status": "loading" })

  //   // setRows(newRep as Row[])
  //   setRowsFun(employeesReportData as Row[])
  //   setApiCallStatusFun({ error: "", "status": "succeede  d" })
  // }


  //*4- default column data
  // const col = {
  //   id: 0,
  //   fullname: '',
  //   balance: 0,
  // }
  const col = [
    {field: 'id', type: 'number'},
    {field: 'commission_category', type: 'string'},
  ]

  //*5- generate report
  // const genRep = useRepoAG<Row>(col, 'product_rep1', getReport, onOpenClick, 'id')
  const genRep = useRepoAG<Row>(col, 'commission_categories_report', 'commission_categories_report', trans.commission_dashboard.commission_categories, commissionCatReport, onOpenClick, 'id')


  return (
    <>
      {isLoading && <LinearProgress />}
      {(isSucceeded || isIdle || isFailed) && (
        <>
          {genRep}
        </>

      )}
    </>
  );


}


