import React, { useContext, useEffect, createContext, useMemo, useState, useCallback } from 'react'


import { useNavigate, Link, useParams } from "react-router-dom";


import api from '../services/api';
import {useRepo} from '../reports/useRepo'
import { useRepoAG } from '../reports/useRepoAG _v2';


//*1-add model

interface Row {
  id: number;
  product_name: string;
  category_name: string;
  branch_name: string;
  store_name: string;
  main_unit_quantity: number;
}

interface ApiStatus {
  error: string;
  status: 'idle' | 'loading' | 'succeeded' | 'failed'
}



export default function StockRep010Gen() {

  

  const navigate = useNavigate();
  const params = useParams();
  const { productId } = params

  
  //*4- define get report
  const getReport = async (setRowsFun: React.Dispatch<React.SetStateAction<Row[]>>, setApiCallStatusFun: React.Dispatch<React.SetStateAction<ApiStatus>>) => {
    setApiCallStatusFun({ error: "", "status": "loading" })
    try {
      const response = await api.get(
        `/stock/product/${productId}`,
        { headers: { "Content-Type": "application/json" } }
      )

      if (response.status === 200) {

        setRowsFun(response.data["stock"])

        setApiCallStatusFun({ error: "", "status": "succeeded" })
      } else {
        setApiCallStatusFun({ error: response.statusText, "status": "failed" })
      }

    } catch (err: any) {
      setApiCallStatusFun({ error: err.message, "status": "failed" })
    }
  };

  // const genRep = useRepo<Row>({
  //   id:0,
  //   product_name: '',
  //   category_name: '',
  //   branch_name: '',
  //   store_name: '',
  //   main_unit_quantity: 0,
  //   enabled: true
  // }, 'stock_rep1', getReport)

  const col = {
    id:0,
    product_name: '',
    category_name: '',
    branch_name: '',
    store_name: '',
    main_unit_quantity: 0,
    enabled: true
  }
  const genRep = useRepoAG<Row>(col, 'stock_rep1', getReport)

  


  //*8- set on click open
  const onOpenClick = (id: number) => {
    navigate("/product/" + id.toString());
  }

  return genRep


}
