import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../../app/store';
import { db } from "../../db";
import api from '../services/api';

var CryptoJS = require("crypto-js");

interface ConnectionState {
  isConnected: boolean;
}

const initialState: ConnectionState = {
  isConnected: navigator.onLine, // Initial state based on the current status
};

export const connectionSlice = createSlice({
  name: 'connection',
  initialState,
  reducers: {
    setOnline: (state) => {
      state.isConnected = true;
    },
    setOffline: (state) => {
      state.isConnected = false;
    },
    setConnectionStatus: (state, action: PayloadAction<boolean>) => {
      state.isConnected = action.payload;
    },
  },
});



export const { setOnline, setOffline, setConnectionStatus } = connectionSlice.actions;
export const isConnected = (state: RootState) => state.connection.isConnected;



// const syncInvoices = () => {
//   const request = indexedDB.open("POSDatabase", 1); request.onsuccess = event => {
//     const db = event.target.result; const transaction = db.transaction("invoices", "readwrite"); const store = transaction.objectStore("invoices"); const allInvoices = store.getAll(); allInvoices.onsuccess = () => {
//       allInvoices.result.forEach(invoice => { // Send invoice to server fetch("/api/invoices", { method: "POST", headers: { "Content-Type": "application/json" }, body: JSON.stringify(invoice) }).then(response => { if (response.ok) { store.delete(invoice.id); // Remove invoice from IndexedDB } }); }); }; }; };


// export const syncInvoices = (): AppThunk => async (dispatch, getState) => {

//   dispatch(setOnline());


//   db.transaction('rw', db.encInvoice, async () => {


//     const allInvoices = await db.encInvoice.toArray()

//     allInvoices.forEach( async (invoice) => {
//       const bytes = CryptoJS.AES.decrypt(invoice.data, "asdflkower@kS990#$sdfsdf");
//       const inv = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

//       const response = await api.post(
//         '/invoice',
//         inv,
//         { headers: { "Content-Type": "application/json" } }
//       )

//       if (response.status === 200) {
//         await db.encInvoice.delete(inv?.draft_uid!)
//       }
//     })


//   }).then(() => {

//     console.log("Transaction committed");

//   }).catch(err => {

//     console.error(err.stack);
//   });


// };


export default connectionSlice.reducer;
