import { Alert, AppBar, Autocomplete, Box, Button, Container, Dialog, DialogContent, DialogContentText, DialogTitle, FormControl, Grid2, IconButton, InputAdornment, InputLabel, LinearProgress, MenuItem, NativeSelect, Paper, Select, SelectChangeEvent, Slide, Snackbar, TextField, Toolbar, useTheme } from '@mui/material'
import { useEffect, useState } from 'react'
import InvoiceAGDatagride from './InvoiceAGDatagride'
import ItemSelect from './select_item/ItemSelect'
import DeleteIcon from '@mui/icons-material/Delete';

import { useAppSelector } from "../../app/hooks";
import { selectDirection, selectTranslations } from "../../features/i18n/i18nSlice";

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import Typography from '@mui/material/Typography';




import useDraft, { InvoiceItemWithHash } from './useDraft';
import { useYesNoDialog } from './useYesNoDialog';


import ItemSelectByColorSize from './select_item/ItemSelectByColorSize';
import CloseIcon from '@mui/icons-material/Close';
import StoreIcon from '@mui/icons-material/Store';
import InvoiceCustomer from './InvoiceCustomer';
import DiscountTaxMode from './DiscountTaxMode';
import BrokerSalesPerson from './BrokerSalesPerson';
import TotalPanel from './TotalPanelTax';
import TopPanel from './TopPanelTax';

import ItemSelect1 from './select_item/ItemSelect1';
import ItemCoffee from './select_item/ItemCoffee';
import ItemSelect2 from './select_item/ItemSelect2';
import ItemSelect01 from '../select_item01/ItemSelect01';
import ItemSelect3 from './select_item/ItemSelect3';
import ItemSelect4 from './select_item/ItemSelect4';
import EinvCustomer from './EinvCustomer';
import { InvoiceType } from '../../models/Invoice02';




//with all options
export default function Draft() {
  const theme = useTheme();

  const langDirection = useAppSelector(selectDirection);
  const trans = useAppSelector(selectTranslations);






  const { gridColumns, currentDraft, selectionModel,
    addNewInvoiceItem,
    onDeleteBillItems,
    onRowSelectionChanged,
    openNewDraft,
    addInvoiceData,
    isLoading,
    isSucceeded,
    isIdle,
    isFailed,

    update,
    storeList,
    setStoreList,
    invoiceTransferStore,
    setInvoiceTransferStore,

    handleCloseSnackbar,
    openSnackbar,
    snackbarMessage,
    getInvoiceAvailableStock,
    getInvoiceUnAvailableStock,

    detailAccordionExpanded,
    setDetailAccordionExpanded,

  } = useDraft()
  const [decimalScale, setDecimalScale] = useState<number>(2)


  const { dialogReturn, YesNoDialog, onDialogOpen } = useYesNoDialog()


  const onDeleteDraft = () => {
    onDialogOpen(currentDraft?.draft_id!, currentDraft?.invoice_type!)
  }


  

  useEffect(() => {
    if (dialogReturn === 'deleted') {
      openNewDraft()
    }
  }, [dialogReturn])


  const action = (
    <>
      <Button color="secondary" size="small" onClick={handleCloseSnackbar}>
        Close
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseSnackbar}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );



  return (
    <Container maxWidth={false} sx={{ margin: 0, padding: 0 }}>
      {(isLoading()) && <LinearProgress />}
      {(isSucceeded() || isIdle() || isFailed()) && (
        <>
          <Snackbar
            open={openSnackbar}
            autoHideDuration={6000}
            onClose={handleCloseSnackbar}
            // message={snackbarMessage}
            action={action}
          >
            <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%', backgroundColor: '#f06292' }}>
              {snackbarMessage}
            </Alert>
          </Snackbar>
          <AppBar position="static" sx={{ backgroundColor: trans.new_invoice.type['title_color' + currentDraft.invoice_type] }}>
            <Toolbar>
              <Typography variant="h6" component="div">
                {trans.new_invoice.type['title_draft_name' + currentDraft.invoice_type] + ": " + (currentDraft.draft_id ?? '')}
              </Typography>
            </Toolbar>
          </AppBar>

          {(currentDraft !== null && currentDraft !== undefined  && currentDraft.tax_percentage >0 && currentDraft.invoice_type === InvoiceType.Sale) ? <EinvCustomer currentDraft={currentDraft} update={update}/>:<></>}
          
          <TopPanel currentDraft={currentDraft} update={update} storeList={storeList} setStoreList={setStoreList} invoiceTransferStore={invoiceTransferStore} setInvoiceTransferStore={setInvoiceTransferStore} />



          <Paper elevation={1} sx={{ marginY: 2, paddingY: 2 }}>
            {(currentDraft.invoice_type === InvoiceType.Sale || currentDraft.invoice_type === InvoiceType.Purchase || currentDraft.invoice_type === InvoiceType.SalesReturn || currentDraft.invoice_type === InvoiceType.PurchaseReturn) ? 
            <ItemSelect3 priceList={1} addNewInvoiceItem={addNewInvoiceItem} taxPrecent={currentDraft?.tax ?? 0} discountMode={currentDraft?.invoice_discount_mode!} taxMode={currentDraft?.invoice_tax_mode!} store={storeList?.find(store => (currentDraft.invoice_type === 8 ? store.id === invoiceTransferStore : store.id === currentDraft.store))} currentItems={currentDraft.invoice_items} invoiceType={currentDraft.invoice_type} />
            :
            <ItemSelect4 priceList={1} addNewInvoiceItem={addNewInvoiceItem} taxPrecent={currentDraft?.tax ?? 0} discountMode={currentDraft?.invoice_discount_mode!} taxMode={currentDraft?.invoice_tax_mode!} store={storeList?.find(store => (currentDraft.invoice_type === 8 ? store.id === invoiceTransferStore : store.id === currentDraft.store))} sourceStoreId={currentDraft.store} currentItems={currentDraft.invoice_items} invoiceType={currentDraft.invoice_type} />
            }
          </Paper>

          <Grid2 container>
            <Grid2 size={{ sm: 12, md: 9 }}  width={"100%"}>
              {(selectionModel.size > 0) && <IconButton aria-label="delete" sx={{ marginX: 2, color: "#ff0000" }}
                onClick={onDeleteBillItems}
              >
                <DeleteIcon fontSize="small" />
              </IconButton>}
              <InvoiceAGDatagride rows={currentDraft?.invoice_items as InvoiceItemWithHash[] ?? []} columns={gridColumns} onRowSelectionChanged={onRowSelectionChanged} />
            
            </Grid2>
            <Grid2 size={{ sm: 12, md: 3 }} paddingLeft={2} width={"100%"}>
              <Box sx={{ display: 'flex', flexDirection: 'column'}}>
              {(currentDraft.invoice_type === InvoiceType.Sale || currentDraft.invoice_type === InvoiceType.Purchase || currentDraft.invoice_type === InvoiceType.SalesReturn || currentDraft.invoice_type === InvoiceType.PurchaseReturn) ?

                <TotalPanel currentDraft={currentDraft} update={update} decimalScale={decimalScale} />

                :
                <></>
              }
                <Paper elevation={1} sx={{ marginY: 0, paddingY: 2 }}>
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', padding: 2, justifyContent: 'space-around' }}>

                    <Button variant="contained" startIcon={<DeleteForeverIcon sx={{ marginRight: 0, marginLeft: 1 }} />} onClick={onDeleteDraft} sx={{ margin: '2px', backgroundColor: theme.palette.warning.main }} size="small" disabled={currentDraft ? !(currentDraft.draft_id) : true}>
                      {trans.new_invoice.delete_button}
                    </Button>

                    <Button variant="contained" startIcon={<AddCircleOutlineIcon sx={{ marginRight: 0, marginLeft: 1 }} />} onClick={addInvoiceData()} sx={{ margin: '2px' }} size="small" disabled={currentDraft ? !(currentDraft.invoice_items.length > 0) : true}>
                      {trans.new_invoice.add_button}
                    </Button>

                    <Button variant="contained" startIcon={<CreateNewFolderIcon sx={{ marginRight: 0, marginLeft: 1 }} />} onClick={openNewDraft} sx={{ margin: '2px', backgroundColor: theme.palette.info.dark }} size="small">
                      {trans.new_invoice.new_draft_button}
                    </Button>
                    
                    {currentDraft.invoice_items.length > 0 ?
                      <Button variant="contained" startIcon={<StoreIcon sx={{ marginRight: 0, marginLeft: 1 }} />} onClick={getInvoiceAvailableStock()} sx={{ margin: '2px', backgroundColor: '#8e24aa' }} size="small">
                        {trans.new_invoice.stock_button}
                      </Button>
                      : <></>
                    }

                    {currentDraft.invoice_items.length > 0 ?
                      <Button variant="contained" startIcon={<StoreIcon sx={{ marginRight: 0, marginLeft: 1 }} />} onClick={getInvoiceUnAvailableStock()} sx={{ margin: '2px', backgroundColor: '#424242' }} size="small">
                        {trans.new_invoice.uavailable_stock_button}
                      </Button>
                      : <></>
                    }

                  </Box>
                </Paper>

              </Box>
            </Grid2>
          </Grid2>


          <YesNoDialog />



        </>
      )}

    </Container>
  )
}
