import React from 'react'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import AddBoxIcon from '@mui/icons-material/AddBox';
import DeleteIcon from '@mui/icons-material/Delete'; import { ProductUnit } from '../../models/ProductCatalog';
import { Box, FormControlLabel, Paper, TextField } from '@mui/material';
import { selectTranslations } from "../i18n/i18nSlice";
import { useAppSelector, useAppDispatch } from '../../app/hooks';

export default function ProductUnits({ prodctUnits, addproductUnits, setNewProductUnits }: { prodctUnits: ProductUnit[], addproductUnits: (productUnit: ProductUnit) => void, setNewProductUnits: (productUnits: ProductUnit[]) => void }) {
    const trans = useAppSelector(selectTranslations);


    
    const addNewProductUnit = () => {
        addproductUnits({
            id: 0,
            product_catalog: undefined,
            unit_name: 'piece',
            is_main: true,
            is_sales_default: true,
            is_purchase_default: false,
            main_unit_quantity: 1,
        })
    }


    const onCheckChanged = (event: React.ChangeEvent<HTMLInputElement>, modifiedIndex: number) => {
        // console.log(event.target.checked)

        const newList = prodctUnits.map((unit, index) => {
            if (index === modifiedIndex) {

                return { ...unit, [event.target.name]: true}

            }else{
                return { ...unit, [event.target.name]: false }
            }
            return unit;
        })
        setNewProductUnits(newList)
    };



    const onInputValueChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, modifiedIndex: number) => {
        // const newList = prodctUnits.filter((item) => item.id !== id);
        const newList = prodctUnits.map((unit, index) => {
            if (index === modifiedIndex) {
                if (event.target.name === "main_unit_quantity" && unit.is_main === true) {
                    return unit;
                } else {
                    return { ...unit, [event.target.name]: event.target.value }
                }
            }
            return unit;
        })
        setNewProductUnits(newList)
    }

    const onDeleteItem = (deleteIndex: number) => {
        const newList = prodctUnits.filter((item, index) => index !== deleteIndex);
        setNewProductUnits(newList)
    }


    return (
        <Paper elevation={1} sx={{ marginY: 2 }}>
            <IconButton aria-label="delete" sx={{ color: "#1976d2" }} onClick={addNewProductUnit}>
                <AddBoxIcon /> {trans.product_form.units.add_button}
            </IconButton>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', padding: 2, justifyContent: 'space-around' }}>

                <List sx={{ bgcolor: 'background.paper' }}>
                    {prodctUnits.map((productUnit, index) => {
                        const labelId = `checkbox-list-label-${productUnit.unit_name}`;

                        return (
                            <ListItem
                                key={index}

                                disablePadding
                            >


                                {/* <TextField id="id" name="id" label="id" variant="standard" size="small" onChange={onInputValueChange} value={productUnit.id || ''} required /> */}
                                <TextField id="unit_name" name="unit_name" label={trans.product_form.units.unit_name} variant="standard" size="small" onChange={(event) => onInputValueChange(event, index)} value={productUnit.unit_name || ''} required />
                                <TextField id="main_unit_quantity" name="main_unit_quantity" label={trans.product_form.units.main_unit_quantity} type="number" variant="standard" size="small" onChange={(event) => onInputValueChange(event, index)} value={productUnit.main_unit_quantity || ''} required />
                                <FormControlLabel labelPlacement="top" label={trans.product_form.units.is_main} control={<Checkbox name="is_main" edge="start"  checked={productUnit.is_main} tabIndex={-1} disableRipple inputProps={{ 'aria-labelledby': labelId }} />} />
                                <FormControlLabel labelPlacement="top" label={trans.product_form.units.is_sales_default} control={<Checkbox name="is_sales_default" edge="start" onChange={(event) => onCheckChanged(event, index)} checked={productUnit.is_sales_default} tabIndex={-1} disableRipple inputProps={{ 'aria-labelledby': labelId }} />} />
                                <FormControlLabel labelPlacement="top" label={trans.product_form.units.is_purchase_default} control={<Checkbox name="is_purchase_default" edge="start" onChange={(event) => onCheckChanged(event, index)} checked={productUnit.is_purchase_default} tabIndex={-1} disableRipple inputProps={{ 'aria-labelledby': labelId }} />} />
                                {!productUnit.is_main && <IconButton aria-label="delete" onClick={() => onDeleteItem(index)}>
                                    <DeleteIcon />
                                </IconButton>}
                            </ListItem>
                        );
                    })}
                </List>
            </Box>
        </Paper>
    );
}
