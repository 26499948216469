import {createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import type { Property } from "csstype";




export interface PrimaryAppBarConfigration {
    val1: number;
    paletteMode: string;
  }

const initialState: PrimaryAppBarConfigration = {
    val1: 0,
    paletteMode: 'light',
}



export const primaryAppBarSlice = createSlice({
    name: 'primaryAppBar',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        openDrawer: (state: any, action: PayloadAction<number> ) => {
            state.val1 = action.payload
            console.log("openDrawer")
        },
        
    },
    // extraReducers: (builder) => {
    //     "miniDrawer/"
    // }
});
  
export const { openDrawer} = primaryAppBarSlice.actions;
export const selectPaletteMode = (state: RootState) => state.primaryAppBar.paletteMode;

  
export default primaryAppBarSlice.reducer;
  