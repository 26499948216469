import React, { useEffect, useState } from 'react'

import { useNavigate, useParams } from "react-router-dom";
import { useRepoAG } from '../reports/useRepoAG _v4';

import { ApiStatus } from '../../models/ApiStatus';
import { db } from '../../db';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { LinearProgress } from '@mui/material';
import { selectTranslations } from '../i18n/i18nSlice';
import RepoAG from '../reports/RepoAG _v5';
import { ro } from 'date-fns/locale';
import api from '../services/api';



//*1-add model
interface Row {
  id: number;
  fullname: string;
  balance: number;
}



export default function StockRep002() {

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const trans = useAppSelector(selectTranslations);
  // const apiCallStatus = useAppSelector(commissionCallStatus);
  // const apiStatusBusyCount = useAppSelector(commissionCallBusyCount);

  // const isLoading = React.useMemo(() => (apiCallStatus === "loading" || apiStatusBusyCount > 0), [apiCallStatus, apiStatusBusyCount]);
  // const isSucceeded = React.useMemo(() => (apiCallStatus === "succeeded" && apiStatusBusyCount === 0), [apiCallStatus, apiStatusBusyCount]);
  // const isIdle = React.useMemo(() => (apiCallStatus === "idle" && apiStatusBusyCount === 0), [apiCallStatus, apiStatusBusyCount]);
  // const isFailed = React.useMemo(() => (apiCallStatus === "failed" && apiStatusBusyCount === 0), [apiCallStatus, apiStatusBusyCount]);

  // const employeesReportData = useAppSelector(employeesReport);


  const [apiCallStatus, setApiCallStatus] = React.useState<ApiStatus>({ error: "", status: "idle" })
  const isLoading = apiCallStatus.status === "loading";
  const isSucceeded = apiCallStatus.status === "succeeded";
  const isIdle = apiCallStatus.status === "idle";
  const isFailed = apiCallStatus.status === "failed";

  const [rows, setRows] = useState<any[]>([])


  //*2- set on click open
  const onOpenClick = (id: number) => {
    navigate("/product/" + id.toString());
  }


  const getReport = async () => {
    setApiCallStatus({ error: "", "status": "loading" })
    try {
      const response = await api.get(
        '/stock/rep02',
        { headers: { "Content-Type": "application/json" } }
      )

      if (response.status === 200) {

        setRows(response.data["stock"])

        setApiCallStatus({ error: "", "status": "succeeded" })
      } else {
        setApiCallStatus({ error: response.statusText, "status": "failed" })
      }

    } catch (err: any) {
      setApiCallStatus({ error: err.message, "status": "failed" })
    }
  };

  useEffect(() => {
    getReport()
  }, [])




  //*3- define get report
  // const getReport = async (setRowsFun: React.Dispatch<React.SetStateAction<Row[]>>, setApiCallStatusFun: React.Dispatch<React.SetStateAction<ApiStatus>>) => {
  //   setApiCallStatusFun({ error: "", "status": "loading" })

  //   // setRows(newRep as Row[])
  //   setRowsFun(employeesReportData as Row[])
  //   setApiCallStatusFun({ error: "", "status": "succeede  d" })
  // }


  //*4- default column data
  // const columns = {
  //   id: 0,
  //   fullname: '',
  //   balance: 0,
  // }
  const initColumns = [
    { field: 'id', type: 'number' },
    { field: 'product_name', type: 'string' },
    { field: 'user_defined_code', type: 'string' },
    { field: 'category_name', type: 'string' },
    { field: 'main_unit_quantity', type: 'number' },
  ]


  var additinalColumns: string[] = []
  if (rows.length > 0) {
    additinalColumns = Object.keys(rows[0]).filter(item => !['id', 'user_defined_code', 'product_name', 'category_name', 'main_unit_quantity'].includes(item))
  }
  const addedClm = additinalColumns.map(clm => ({ field: clm, type: 'number' }))
  const columns = [...initColumns, ...addedClm]


  //*5- generate report
  // const genRep = useRepoAG<Row>(columns, 'product_rep1', getReport, onOpenClick, 'id')
  // const genRep = useRepoAG<Row>(columns, 'all_employee_report', 'all_employee_report', trans.commission_dashboard.employees, employeesReportData, onOpenClick, 'id')


  return (
    <>
      {isLoading && <LinearProgress />}
      {(isSucceeded || isIdle || isFailed) && (
        <>
          <RepoAG columns={columns} componentName={trans.stock_rep1.title} transRep='stock_rep1' title={trans.stock_rep1.title} rows={rows} onOpenClick={onOpenClick} openParameterName='id' />
        </>

      )}
    </>
  );


}


