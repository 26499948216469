import React, { useEffect, useMemo, useState, useCallback, useRef } from 'react';


import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { selectDirection, selectTranslations } from "../../features/i18n/i18nSlice";

import { selectPaletteMode } from '../../features/appconfig/appConfigSlice'
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import * as XLSX from 'xlsx';
import { AgGridReact } from 'ag-grid-react'; // React Grid Logic
import "ag-grid-community/styles/ag-grid.css"; // Core CSS
import "ag-grid-community/styles/ag-theme-quartz.css"; // Theme
// import "ag-grid-community/styles/ag-theme-quartz-dark.css"; // Theme
import "./styles.css"
import { ColDef, GridOptions, SizeColumnsToContentStrategy, GridReadyEvent, ValueFormatterFunc, IRowNode, RowStyle } from 'ag-grid-community';



export interface Component {
  id: number;
  product_catalog_id: number;
  component_id: number;
  component_name: string;
  main_unit_quantity: number;
  unite_id: number;
}

export interface ComponentthHash extends Component{
  itemHash: string;
}


type Props = {
  rows: any[];
  onRowSelectionChanged: Function;
};


export default function ComponentAGDatagride({ rows, onRowSelectionChanged }: Props) {

  const trans = useAppSelector(selectTranslations);

const columns = [
  {
    field: "component_name",
    headerName: trans.new_invoice.table.product,
    width: 450,
    sortable: false,
  },
  {
    field: 'main_unit_quantity',
    headerName: trans.new_invoice.table.count,
    // type: 'numberColumn',
    width: 80,
    // editable: true,
    sortable: false,
  },
  ]


  // const useRepoAG = <Row extends Record<string, any>>(columnsInfo: Record<string, any>[], transRep: string, getReport: (setRowsFun: React.Dispatch<React.SetStateAction<Row[]>>, setApiCallStatusFun: React.Dispatch<React.SetStateAction<ApiStatus>>) => Promise<void>, onOpenClick?: (id: number) => void, openParameterName?: string) => {

  const gridRef = useRef<AgGridReact>(null);
  // const gridRef = useRef<AgGridReact<IOlympicData>>(null);
  const paletteMode = useAppSelector(selectPaletteMode);

  const langDirection = useAppSelector(selectDirection);


  // set background colour on every row, this is probably bad, should be using CSS classes
  const rowStyle = { background: '' };

  // set background colour on even rows again, this looks bad, should be using CSS classes
  const getRowStyle = (params: any): RowStyle => {
    // if (params.node.rowIndex % 2 === 0) {
    //     return { background: 'red' };
    // }
    // return { border: '1px solid #bdc1c6' };

    if (!params.node.isRowPinned()) {
      return { borderBottom: '1px solid #bdc1c6' };
    } else {
      return { borderBottom: '1px solid #bdc1c6', background: paletteMode === 'light' ? '#e8eaf6' : '#283593' };
    }
  };


  // Column Definitions: Defines & controls grid columns.
  const [colDefs, setColDefs] = useState<ColDef[]>(columns);
  // const defaultColDef = {
  //   resizable: true,
  //   initialWidth: 200,
  //   wrapHeaderText: true,
  //   autoHeaderHeight: true,
  // }

  const [gridOptions] = useState<GridOptions<any> | undefined>({
    rowSelection: 'multiple',
    suppressRowClickSelection: true,
  });

  const defaultColDef = useMemo(() => ({
    // floatingFilter: true,
    // cellStyle: { borderRight: '1px solid #bdc1c6' },
    autoHeaderHeight: true,
  }), [])


  const autoSizeStrategy: SizeColumnsToContentStrategy = {
    type: 'fitCellContents'
  };


  const onGridReady = useCallback((event: GridReadyEvent) => {
    event.api.hideOverlay();

    // setTimeout(()=>{
    //   let pinnedBottomData = generatePinnedBottomData();
    //   // event.api.setPinnedBottomRowData([pinnedBottomData]);
    //   gridRef.current!.api.updateGridOptions({pinnedBottomRowData:[pinnedBottomData]});
    // }, 500)

  }, []);

const onSelectionChanged =  () => {
  const selectedRows = gridRef.current!.api.getSelectedRows();
  const selectedRowsSet: ReadonlySet<string> =  new Set(selectedRows.map(item => item.itemHash));

  onRowSelectionChanged(selectedRowsSet)
  // console.log('Selected rows:', selectedRows);
  // Add your custom logic here
}





  return (
    <div className={`${paletteMode === 'light' ? 'ag-theme-quartz' : 'ag-theme-quartz-dark'}`} style={{ height: "100%", width: "100%", minHeight: "400px" }}>
      <AgGridReact
        ref={gridRef}
        rowData={rows}
        columnDefs={colDefs}
        defaultColDef={defaultColDef}
        enableRtl={langDirection === "rtl" ? true : false}
        // enableRangeSelection={true}
        enableCellTextSelection={true}
        // rowStyle={rowStyle}
        getRowStyle={getRowStyle}

        // autoSizeStrategy={autoSizeStrategy}
        onGridReady={onGridReady}
      

        selection={{
          mode: 'multiRow',
          headerCheckbox: true,
          checkboxes:true,
          enableClickSelection: true,
          
        }}
        // rowSelection={'multiple'}
        // suppressRowClickSelection={true}
        onSelectionChanged={onSelectionChanged}
      />
    </div>
  )

}