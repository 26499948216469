import React, { useEffect, useRef } from "react";
import { Container, Grid, TextField, Autocomplete, Box, LinearProgress, IconButton, AutocompleteChangeReason, FormControl, InputLabel, Select, MenuItem, SelectChangeEvent, NativeSelect } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { selectTranslations } from "../../../features/i18n/i18nSlice";
import { Colors, ProductCatalog, ProductUnit, Sizes } from "../../../models/ProductCatalog";
import AddBoxIcon from '@mui/icons-material/AddBox';

import { InvoiceItem } from "../../../models/InvoiceItem";
import { InvoiceDiscountModeEnum, InvoiceTaxModeEnum } from "../../../models/Invoice";

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateField } from '@mui/x-date-pickers';
import { StoreHouse } from "../../../models/StoreHouse";
import api from "../../../features/services/api";
import useProductListByColorSize from "./productHookByColorSize";
import { isNumber } from "@mui/x-data-grid/internals";
import { permSaleLessThanSalesPrice } from "../../../features/login/loginSlice";
import { db } from "../../../db";


// import {addBillItem} from '../actions/billActions'
type Props = {
  addNewInvoiceItem: Function;
  priceList: number;
  taxPrecent: number;
  taxMode: InvoiceTaxModeEnum;
  discountMode: InvoiceDiscountModeEnum;
  store: StoreHouse | undefined;
  currentItems: InvoiceItem[];
  invoiceType: number;
};

// with all options
export default function ItemSelect3({ addNewInvoiceItem, priceList, taxMode, discountMode, store, currentItems, invoiceType }: Props) {
  const countRef = useRef<HTMLInputElement>(null);
  const codeRef = useRef<HTMLInputElement>(null);
  const descriptionRef = useRef<HTMLInputElement>(null);
  const priceRef = useRef<HTMLInputElement>(null);
  const discountRef = useRef<HTMLInputElement>(null);

  const dispatch = useAppDispatch();

  const trans = useAppSelector(selectTranslations);
  const saleLessThanSalesPrice = useAppSelector(permSaleLessThanSalesPrice)
  //   const formLables = useSelector(state => state.formLangLables.itemSelect)[lang.lang]
  const codeMode: number = 1
  const [productIdValue, setProductIdValue] = React.useState<number | null>(null);
  const [productUserCodeValue, setProductUserCodeValue] = React.useState<string | null>(null);
  const [itemCount, setItemCount] = React.useState(1);
  const [itemTotal, setItemTotal] = React.useState(0);
  const [itemPrice, setitemPrice] = React.useState(0);
  // const [invoiceStore, setInvoiceStore] = React.useState<StoreHouse | null>(null);

  const [discountPrecent, setDiscountPrecent] = React.useState(0);
  const [taxPrecent, setTaxPrecent] = React.useState(0);
  const [expireDate, setExpireDate] = React.useState(Date());

  const [itemSelected, setItemSelected] = React.useState<ProductCatalog | null>(null);
  const [unitSelected, setUnitSelected] = React.useState<ProductUnit | null>(null)

  const [productQty, setProductQty] = React.useState<string>('');
  const { productCatalogs, colors, sizes } = useProductListByColorSize(invoiceType)

  const [expenseToPrice, setExpenseToPrice] = React.useState(0);


  const loadExpenseToPrice = async () => {
    const expeseToPriceRecord = await db.appConfiguration.get(2)
    if (expeseToPriceRecord) {
      // setCurrentDraft(invoiceDraft)
      setExpenseToPrice(parseFloat(expeseToPriceRecord.configuration_value!))
    }
  }
  const defaultProps = {
    options: productCatalogs,
    getOptionLabel: (option: ProductCatalog) => option.product_name,
  };



  const defaultColorProps = {
    options: colors,
    getOptionLabel: (option: Colors) => option.color_name,
  };

  const defaultSizeProps = {
    options: sizes,
    getOptionLabel: (option: Sizes) => option.size_name,
  };

  const onUnitChanged = (event: SelectChangeEvent<number>) => {
    const selectedValue = itemSelected?.product_units.find(unit => (unit.id === event.target.value))!
    setUnitSelected(selectedValue);
    // setitemPrice(parseFloat((itemSelected?.pricing[priceList].price! * selectedValue?.main_unit_quantity * (1 + expenseToPrice)).toFixed(2)));
    // calculateItemTotal(parseFloat((itemSelected?.pricing[priceList].price! * selectedValue?.main_unit_quantity * (1 + expenseToPrice)).toFixed(2)), itemCount, discountPrecent, taxPrecent);
    // setitemPrice(itemSelected?.pricing[priceList].price! * selectedValue?.main_unit_quantity);
    setitemPrice(Math.round((itemSelected?.pricing[priceList].price! * selectedValue?.main_unit_quantity + Number.EPSILON) * 100) / 100);
    calculateItemTotal(itemSelected?.pricing[priceList].price! * selectedValue?.main_unit_quantity, itemCount, discountPrecent, taxPrecent);
    if (itemSelected?.id && store?.id) getQuantity(itemSelected?.id, store?.id, selectedValue?.id!, selectedValue.main_unit_quantity, itemSelected.product_type)
  };

  const onDateChange = (newValue: Date | null) => {
    setExpireDate(newValue!.toISOString().split('.')[0] + "Z")
  };




  const calculateItemTotal = (fPrice: any, fCount: any, discount: any, ftaxPrecent: any) => {
    let mItemTotal = 0;

    let mPrice = parseFloat(fPrice) || 0
    let mCount = parseFloat(fCount) || 0

    mItemTotal = mPrice * mCount * (1 - discount / 100) * (1 + ftaxPrecent / 100);
    // console.log(mItemTotal)

    setItemTotal(mItemTotal);
    // console.log('calculateItemTotal')
  };


  useEffect(() => {
    if (itemSelected?.id && store?.id && unitSelected?.main_unit_quantity) getQuantity(itemSelected?.id, store?.id, unitSelected?.id!, unitSelected?.main_unit_quantity, itemSelected.product_type)
  }, [itemSelected, store, currentItems])

  useEffect(() => {
    loadExpenseToPrice()
  }, [])

  const getItemsProductCount = (productId: number, storeId: number) => {
    return currentItems.reduce((acc, item) => acc + ((item.product_id === productId && item.store_id === storeId) ? item.main_unit_quantity : 0), 0)
  }

  const getQuantity = async (productId: number, storeId: number, unitId: number, unitQuantity: number, productType: number) => {
    // setApiCallStatus({ error: "", "status": "loading" }
    if(productType === 2) {
      setProductQty(trans.product_type.service)
      return
    }
    if(productType === 3) {
      setProductQty(trans.product_type.product)
      return
    }
    setProductQty('')
    try {
      const response = await api.get(
        `/product/qty/${productId}/${storeId}/${unitId}`,
        {}
      )

      if (response.status === 200) {
        const currentQty = invoiceType === 1 || invoiceType === 6 ? -1 * getItemsProductCount(productId, storeId) : getItemsProductCount(productId, storeId)
        const qty = ((response.data === '' ? 0 : response.data) + currentQty) / unitQuantity
        setProductQty(qty.toString())
      }
    } catch (err: any) {
      if (err.code === 'ERR_NETWORK') {
        const currentQty = invoiceType === 1 || invoiceType === 6 ? -1 * getItemsProductCount(productId, storeId) : getItemsProductCount(productId, storeId)
        const qty = currentQty / unitQuantity
        setProductQty(qty.toString())
      }else if (err.response.status === 404) {
        const currentQty = invoiceType === 1 || invoiceType === 6 ? -1 * getItemsProductCount(productId, storeId) : getItemsProductCount(productId, storeId)
        const qty = currentQty / unitQuantity
        setProductQty(qty.toString())
      }
    }
  };


  const onAutoCompleteChange = (event: React.SyntheticEvent<Element, Event>, value: ProductCatalog | null, reason: AutocompleteChangeReason) => {

    setItemSelected(value);


    if (value !== null) {
     
      setItemCount(1)
      setProductIdValue(value.id);
      //usercode
      // setProductUserCodeValue(value.user_defined_code)
      calculateItemTotal(value.pricing[priceList].price, 1, discountPrecent, taxPrecent);
      setitemPrice(value.pricing[priceList].price)
      setUnitSelected(value.product_units[0])
      var date = new Date();
      date.setDate(date.getDate() + parseInt(value.default_expiry_days.toString()));
      setExpireDate(date.toISOString().split('.')[0] + "Z")

    } else {
      setProductIdValue(null);
      setProductUserCodeValue(null);
    }

  };



  const onProductIdChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setProductIdValue(parseFloat(event.target.value));
    //usercode
    // setProductUserCodeValue(event.target.value);
  };

  const onItemCountChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setItemCount(parseFloat(event.target.value));
    calculateItemTotal(itemPrice, event.target.value, discountPrecent, taxPrecent);
  };

  const onItemPriceChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (saleLessThanSalesPrice) {
      setitemPrice(parseFloat(event.target.value))
      calculateItemTotal(event.target.value, itemCount, discountPrecent, taxPrecent);
    } else {
      if (parseFloat(event.target.value) >= (itemSelected?.pricing[priceList].price! * unitSelected?.main_unit_quantity!)) {
        setitemPrice(parseFloat(event.target.value))
        calculateItemTotal(event.target.value, itemCount, discountPrecent, taxPrecent);
      }
    }
  };

  const onDiscountPrecentChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDiscountPrecent(discountMode === InvoiceDiscountModeEnum.PerInvoice ? 0 : parseFloat(event.target.value))
    calculateItemTotal(itemPrice, itemCount, event.target.value, taxPrecent);
  };

  const onTaxPrecentChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTaxPrecent(taxMode === InvoiceTaxModeEnum.PerInvoice ? 0 : parseFloat(event.target.value))
    calculateItemTotal(itemPrice, itemCount, discountPrecent, event.target.value);
  };


  const onCodekeyPress = (e: any) => {
    if (e.keyCode === 13) {
      const foundItem = productCatalogs!.filter((item) => codeMode === 1 ? item.id == e.target.value:  item.user_defined_code == e.target.value);
      // console.log(foundItem)
      if (foundItem !== undefined && foundItem.length > 0) {
        // setItemCount(1)
        // console.log('before select')


        setItemSelected(foundItem[0]);
        setUnitSelected(foundItem[0].product_units[0])
        setItemCount(1)
        // setitemPrice(parseFloat((foundItem[0].pricing[priceList].price * (1 + expenseToPrice)).toFixed(2)));
        // calculateItemTotal(parseFloat((foundItem[0].pricing[priceList].price * (1 + expenseToPrice)).toFixed(2)), 1, discountPrecent, taxPrecent);
        setitemPrice(foundItem[0].pricing[priceList].price);
        calculateItemTotal(foundItem[0].pricing[priceList].price, 1, discountPrecent, taxPrecent);
        if (codeMode === 1) {
          setProductIdValue(foundItem[0].id)
        } else {
          setProductUserCodeValue(foundItem[0].user_defined_code)
        }

        var date = new Date();
        date.setDate(date.getDate() + parseInt(foundItem[0].default_expiry_days.toString()));
        setExpireDate(date.toISOString().split('.')[0] + "Z")

        // Next item if click enter
        countRef.current!.focus();
     
      } else {
        setItemSelected(null);
      }    
    }
  };

  

  const onDescriptionPress = (e: any) => {
    // console.log('onDescriptionPress')
    if (e.keyCode === 13) {
      priceRef.current?.focus()
    }

  }

  const onDescriptionFocus = (e: any) => {
    descriptionRef.current?.select()
    // console.log('onDescriptionFocus')
  }

  const onCodeFocus = (e: any) => {
    // codeRef.current?.select()
    setTimeout(function () {
      e.target.select();
    }, 0);
  }

  const onCountFocus = (e: any) => {
    // sizeCodeRef.current?.select()
    setTimeout(function () {
      e.target.select();
    }, 0);
  }


  const onPricePress = (e: any) => {
    if (e.keyCode === 13) {
      if(discountMode === InvoiceDiscountModeEnum.PerInvoice){
        addCurrentLine()
      }else {
        discountRef.current?.focus()
      }
    }
    // console.log('onPricePress')
  }

  const onDiscountPress = (e: any) => {
    if (e.keyCode === 13) {
      addCurrentLine()
    }
  }

  const onCountkeyPress = (e: any) => {
    if (e.keyCode === 13) {
      priceRef.current?.focus()
    }
  };


  const onPriceFocus = (e: any) => {
    // priceRef.current?.select()
    setTimeout(function () {
      e.target.select();
    }, 0);

    // e.target.select();
    // console.log('onPriceFocus')
  }

  const addCurrentLine = () => {
    if (itemSelected !== null && itemCount > 0 && itemSelected.id > 0) {
     
      if (codeMode === 1) {
        if (productIdValue?.toString().trim() === '' || productIdValue === undefined || productIdValue === null) return
      } else {
        if (productUserCodeValue?.toString().trim() === '' || productUserCodeValue === undefined || productUserCodeValue === null) return
      }
      if (itemPrice.toString().trim() === '' || itemPrice === undefined || itemPrice === null) return
      if (itemCount.toString().trim() === '' || itemCount === undefined || itemCount === null) return
      if (discountPrecent.toString().trim() === '' || discountPrecent === undefined || discountPrecent === null) return
      // if (withholdingtax.toString().trim() === '' || withholdingtax === undefined || withholdingtax === null) return
      if (taxPrecent.toString().trim() === '' || taxPrecent === undefined || taxPrecent === null) return

      calculateItemTotal(itemPrice, itemCount, discountPrecent, taxPrecent)
      let invoiceItem = {} as InvoiceItem;
      invoiceItem.product_name = itemSelected.product_name ?? '';
      // invoiceItem.product_id = productIdValue
      invoiceItem.product_id = itemSelected.id;
      invoiceItem.product_type = itemSelected.product_type;
      invoiceItem.unit_name = unitSelected?.unit_name ?? '';
      invoiceItem.unit_id = unitSelected?.id ?? 0;
      invoiceItem.product_components = [...itemSelected.components];
      invoiceItem.quantity = itemCount;
      invoiceItem.main_unit_quantity = itemCount * (unitSelected?.main_unit_quantity ?? 1);
      invoiceItem.unit_price = itemPrice;
      invoiceItem.main_unit_price = itemPrice / (unitSelected?.main_unit_quantity ?? 1);
      invoiceItem.discount_display = discountPrecent;
      invoiceItem.tax_precent = taxPrecent;
      invoiceItem.tax = Math.round(((itemPrice * itemCount * (1 - discountPrecent / 100)) * taxPrecent / 100 + Number.EPSILON) * 100) / 100;
      invoiceItem.subtotal = Math.round(((itemPrice * itemCount) + Number.EPSILON) * 100) / 100
      invoiceItem.discount = Math.round(((itemPrice * itemCount * discountPrecent / 100) + Number.EPSILON) * 100) / 100
      invoiceItem.salesTotal = Math.round(((itemPrice * itemCount) * (1 - discountPrecent / 100) + Number.EPSILON) * 100) / 100; // to salestotal - discount
      invoiceItem.total = Math.round((itemTotal + Number.EPSILON) * 100) / 100; // to salestotal - discount + tax
      invoiceItem.einv_code = itemSelected.einv_code ?? '';
      invoiceItem.store_name = store?.name ?? ''
      invoiceItem.store_id = store?.id ?? 0
      invoiceItem.item_discount_mode = 0 //temp value
      invoiceItem.has_expire_date = itemSelected.has_expire_date
      invoiceItem.expire_date = new Date(expireDate).toISOString().split('.')[0] + "Z"

      dispatch(addNewInvoiceItem(invoiceItem))
      // document.getElementById("item_code").focus();
      codeRef.current!.focus();
      // descriptionRef.current!.focus();
      // colorCodeRef.current!.focus();
      // setProductIdValue(null)
      // document.getElementById("item_count").select();

    } else {
      codeRef.current!.focus();
      // descriptionRef.current!.focus();
      // colorCodeRef.current!.focus();
      // document.getElementById("item_code").focus();
    }


  }



  return (
    <Container maxWidth={false} sx={{margin:0, padding:0}}>
      {!productCatalogs && <LinearProgress />}
      {productCatalogs && (
        <>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>

          </Box>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>







            <Box sx={{ flexGrow: 1 }}>
              <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                <TextField
                  sx={{ flexGrow: 1, margin: "4px" }}
                  id="item_code"
                  label={trans.invoice_item.code}
                  inputRef={codeRef}
                  onKeyDown={onCodekeyPress}
                  onChange={onProductIdChanged}
                  onFocus={onCodeFocus}
                  value={codeMode === 1 ? productIdValue || '' : productUserCodeValue || ''}
                  size="small"
                />
                <Box sx={{ marginX: "4px", flexGrow:1 }}>
                  {`${trans.invoice_item.quantity}: ${productQty}`}
                </Box>
              </Box>
              <Box >
                <Autocomplete
                  sx={{ flexGrow: 1, margin: "4px" }}
                  {...defaultProps}
                  id="Products"
                  autoHighlight
                  limitTags={10}
                  onChange={onAutoCompleteChange}
                  getOptionLabel={(option) => {
                    return option.product_name || ""
                  }}
                  value={itemSelected}
                  isOptionEqualToValue={(option, value) => {
                    // console.log(value)
                    if (value !== null) {
                      return option!.id === value?.id;
                    } else {
                      return false;
                    }
                  }}
                  // isOptionEqualToValue={(option, value) =>
                  //   option.iso === value.iso
                  // }
                  renderInput={(params) => (
                    <TextField {...params} label={trans.invoice_item.product} size="small" inputRef={descriptionRef} onKeyDown={onDescriptionPress} onFocus={onDescriptionFocus} />

                  )}
                />
              </Box>

            </Box>






            {itemSelected?.product_units?.length! > 0 && unitSelected ?
              <Box>
                <FormControl  >
                  <InputLabel htmlFor="product_unit">{trans.invoice_item.unit}</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="product_unit"
                    // defaultValue={unitSelected?.id}
                    value={unitSelected?.id!}
                    name="product_unit"

                    label="Type"
                    onChange={onUnitChanged}

                    size='small'
                    sx={{ width: 120, margin: "4px" }}
                  >
                    {itemSelected?.product_units.map(unit => (<MenuItem value={unit.id} key={unit.id}>{unit.unit_name}</MenuItem>))}
                    {/* {itemSelected?.product_units.map(unit => (<option value={unit.id} key={unit.id}>{unit.unit_name}</option>))} */}
                  </Select>
                </FormControl>
               
                <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                  <Box>{`pcs: ${unitSelected.main_unit_quantity}`}</Box>
                </Box>
                
              </Box> : <></>
            }



            <TextField
              sx={{ width: 100, margin: "4px" }}
              id="item_count"
              label={trans.invoice_item.count}
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={onItemCountChanged}
              onKeyDown={onCountkeyPress}
              onFocus={onCountFocus}
              inputRef={countRef}
              value={itemCount}
              size="small"
            />


            <Box>
              <Box>
                <TextField
                  sx={{ width: 120, margin: "4px" }}
                  id="item_price"
                  label={trans.invoice_item.price}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  // value={itemSelected === null? 0 : itemPrice}
                  value={itemPrice}
                  onChange={onItemPriceChanged}
                  onKeyDown={onPricePress}
                  onFocus={onPriceFocus}
                  inputRef={priceRef}
                  type="number"
                  size="small"
                />
              </Box>
              {/* <TextField
                sx={{ width: 120, margin: "4px" }}
                id="suggested_price"
                label={trans.invoice_item.suggested_price}
                InputLabelProps={{
                  shrink: true,
                }}
                // value={itemSelected === null? 0 : itemPrice}
                value={parseFloat((itemPrice * (1 + expenseToPrice)).toFixed(2))}
                type="number"
                size="small"
              /> */}
            </Box>



            {discountMode === InvoiceDiscountModeEnum.PerItem ?
              <TextField
                sx={{ width: 120, margin: "4px" }}
                id="item_discount"
                label={trans.invoice_item.discount_precent}
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{ inputProps: { min: 0, max: 100 } }}
                onChange={onDiscountPrecentChanged}
                onKeyDown={onDiscountPress}
                value={discountPrecent}
                onFocus={event => {
                  event.target.select()
                }}
                inputRef={discountRef}
                size="small"
              />
              : null}

            {taxMode === InvoiceTaxModeEnum.PerItem ?
              <TextField
                sx={{ width: 120, margin: "4px" }}
                id="tax_precent"
                label={trans.invoice_item.tax_precent}
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{ inputProps: { min: 0, max: 100 } }}
                onChange={onTaxPrecentChanged}
                // onKeyDown={onCountkeyPress}
                // inputRef={countRef}
                value={taxPrecent}
                onFocus={event => {
                  event.target.select()
                }}
                size="small"
              /> : null}



            {/* <TextField
              sx={{ width: 80, margin: "4px" }}
              id="withholdingtax"
              label={trans.invoice_item.withholdingtax}
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{ inputProps: { min: 0, max: 100 } }}
              onChange={onWithholdingtaxChanged}
              // onKeyDown={onCountkeyPress}
              // inputRef={countRef}
              value={withholdingtax}
              onFocus={event => {
                event.target.select()
              }}
              size="small"
            /> */}




            <TextField
              sx={{ width: 120, margin: "4px" }}
              id="item_total"
              label={trans.invoice_item.total}
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              value={itemTotal.toFixed(2)}
              size="small"
            />
            {itemSelected?.has_expire_date ?
              <LocalizationProvider dateAdapter={AdapterDateFns} >
                <DateField
                  label={trans.invoice_item.expire_date}
                  format="dd/MM/yyyy"
                  value={new Date(expireDate)}
                  onChange={onDateChange}
                  // slotProps={{ textField: { variant: 'standard' } }}
                  sx={{ marginX: "6px" }}
                />
              </LocalizationProvider> :
              <></>}

            {(productIdValue || productUserCodeValue) && <IconButton aria-label="delete" onClick={addCurrentLine} sx={{ width: 45, color: "#1976d2" }}>
              <AddBoxIcon />
            </IconButton>}

          </Box>

        </>)}
    </Container>
  );
}

