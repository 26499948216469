import { Box, Card, CardActionArea, CardContent, CardMedia, Grid, Typography } from '@mui/material'
import React from 'react'
import { useAppSelector } from '../../../app/hooks'
import { selectTranslations } from '../../../features/i18n/i18nSlice'
import LocalGroceryStoreIcon from '@mui/icons-material/LocalGroceryStore';


export default function BarDashboardCard({ itemColor, itemName, onClick, children }: { itemColor: string, itemName: string, onClick: Function, children: React.ReactNode }) {

    const trans = useAppSelector(selectTranslations)


    return (
        <Grid item xs={12} sm={6} md={4} lg={3} xl={3} >
            <Card sx={{ display: 'flex', justifyContent: 'flex-start', margin: 2, minWidth: 220, backgroundColor: itemColor }}>
            <CardActionArea  onClick={() => onClick()} sx={{display: 'flex', justifyContent: 'flex-start',}} >
                {children}
                <Box sx={{ display: 'flex', flexDirection: 'column'}}>
                    <CardContent sx={{ flex: '1 0 auto' }}>
                        <Typography gutterBottom variant="h5" component="div" align='center'>
                            {itemName}
                        </Typography>

                    </CardContent>
                </Box>

</CardActionArea>

            </Card>

        </Grid>
    )
}
