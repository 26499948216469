import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';
// import { Route, Routes } from 'react-router-dom';
import {
  BrowserRouter,
  Routes,
  Route,
  createBrowserRouter,
  RouterProvider
} from "react-router-dom";
import Home from './pages/home';
import Page1 from './pages/page1';
import Page2 from './pages/page2';
import ProtectedRoute from './pages/ProtectedRoute';

// import { QueryClient, QueryClientProvider } from 'react-query'
import Drafts from './pages/Drafts/Drafts';
import Draft from './components/draft_invoice02/Draft';
import DraftPrint from './pages/DraftPrint';
import SignIn from './features/login/login';
import CustomerList from './features/drawer/CustomerList';
import CustomerRep01 from './features/customer/CustomerRep01';
import CustomerForm from './features/customer/customer';
import AddCustomer from './features/customer/addCustomer';
import AddProduct from './features/product/addProduct';
import ProductRep01 from './features/product/ProductRep01';
import ProductForm from './features/product/product';
import Invoices from './pages/Invoices/Invoices';
// import InvoiceDraft from './components/draft_invoice03/Draft';
import InvoiceDraft from './features/invoice/Draft';
import AddCustomerDocument from './features/customer/AddCustomerDocument';
import CustomerStatment from './features/customer/CustomerStatment';
import CustomerDocumentForm from './features/customer/CustomerDocumentForm';
import DraftPage from './pages/DraftPage';
import InvoiceDashboard from './pages/dashboards/invoice/InvoiceDashboard';
import CustomerDashboard from './pages/dashboards/customer/CustomerDashboard';
import ProductDashboard from './pages/dashboards/product/ProductDashboard';
import StockDashboard from './pages/dashboards/stock/StockDashboard';
import FinanceDashboard from './pages/dashboards/finance/FinanceDashboard';
import AddExpensesDocument from './features/finance/AddExpensesDocument';
import ExpensesDocumentForm from './features/finance/ExpensesDocumentForm';
import ExpenseRep01 from './features/finance/ExpenseRep01';
import StockRep01 from './features/product/StockRep01';
import ProductAllTrans from './features/product/ProductAllTrans';
import BrokerRep01 from './features/customer/BrokerRep01';
import AddBroker from './features/customer/addBroker';
import BrokerForm from './features/customer/BrokerForm';
import SalesPersonRep01 from './features/customer/SalesPersonRep01';
import AddSalesPerson from './features/customer/addSalesPerson';
import SalesPersonForm from './features/customer/SalesPersonForm';
import InvoicesWithSearch from './pages/Invoices/InvoicesWithSearch';
import MyAccountDashboard from './pages/dashboards/myaccount/MyAccountDashboard';
import UsersDashboard from './pages/dashboards/users/UsersDashboard';
import RolesList from './features/users/RolesList';
import RoleForm from './features/users/RoleForm';
import UsersList from './features/user/UsersList';
import UserForm from './features/user/UserForm';
import ProfitLos from './pages/charts/statisticscharts/ProfitLos';
import Chart1 from './pages/charts/apexch1/Chart1';
import Chart2 from './pages/charts/apexch1/Chart2';
import ReportsDashboard from './pages/dashboards/reports/ReportsDashboard';
import FinanceMonthlyRep from './pages/reports/FinanceMonthlyRep';
import FinanceMonthlyRepProfit from './pages/reports/FinanceMonthlyRepProfit';
import StockRep03 from './features/product/StockRep03';
import StockRep04 from './features/product/StockRep04';
import BrokerRep02 from './features/customer/BrokerRep02';
import SalesPersonRep02 from './features/customer/SalesPersonRep02';
import CustomerRep02 from './features/customer/CustomerRep02';
import ProductRep04 from './features/product/ProductRep04_v2';
import StockRep001 from './features/product/StockRep001';
import ProductCategoryForm from './features/product/ProductCategoryForm';
import AddProductCategory from './features/product/AddProductCategory';
import ProductCategoryList from './features/product/ProductCategoryList';
import AddColor from './features/product/AddColor';
import ColorsList from './features/product/ColorsList';
import ColorForm from './features/product/ColorForm';
import AddSize from './features/product/AddSize';
import SizesList from './features/product/SizesList';
import SizeForm from './features/product/SizeForm';
import AddStore from './features/product/AddStore';
import StoreList from './features/product/StoreList';
import StoreForm from './features/product/StoreForm';
import AddProductWithCS from './features/product/AddProductWithCS';
import ProductWithCS from './features/product/ProductWithCS';
import DraftAvailableStock from './components/draft_invoice02/DraftAvailableStock';
import InvoiceCustomersRep01 from './pages/Invoices/InvoiceCustomersRep01';
import InvoiceCustomersRep02 from './pages/Invoices/InvoiceCustomersRep02';
import StockRep002 from './features/product/StockRep002';
import StockRep005 from './features/product/StockRep005';
import AddExpensesDocumentMultiple from './features/finance/AddExpensesDocumentMultiple';
import ExpensesDocumentMultipleForm from './features/finance/ExpensesDocumentMultipleForm';
import AddExpenseType from './features/finance/AddExpenseType';
import SetExpenseToPrice from './features/finance/SetExpenseToPrice';
import StockRep006 from './features/product/StockRep006';
import CustomerRep03 from './features/customer/CustomerRep03';
import CustomerRep04 from './features/customer/CustomerRep04';
import ProductCatRep01 from './features/product/ProductCatRep01';
import ProductCatRep02 from './features/product/ProductCatRep02';
import ProductRep05 from './features/product/ProductRep05';
import ExpenseTypeList from './features/finance/ExpenseTypeList';
import ExpenseTypeForm from './features/finance/ExpenseTypeForm';
import CustomerRep05 from './features/customer/CustomerRep05';
import StockRep003 from './features/product/StockRep003';
import StockRep004 from './features/product/StockRep004';
import ExpenseRep001 from './features/finance/ExpenseRep001';
import ProductStoreTrans from './features/product/ProductStoreTrans';
import ProductAllTrans01 from './features/product/ProductAllTrans01';
import StockExpireAfterDays from './features/product/StockExpireAfterDays';
import StockWithoutSalesForDays from './features/product/StockWithoutSalesForDays';
import BrokerRep002 from './features/customer/BrokerRep002';
import SalesPersonRep002 from './features/customer/SalesPersonRep002';
import StockRep009 from './features/product/StockRep009';
import StockRep010 from './features/product/StockRep010';
import StockRep010Gen from './features/product/StockRep010Gen';
import StockRep011 from './features/product/StockRep011';
import CustomerStatmentDetailed from './features/customer/CustomerStatmentDetailed';
import ProductAllTransWithDateRange from './features/product/ProductAllTransWithDateRange';
import UsersLogs from './features/users/UsersLogs';
import FinanceRep003 from './features/finance/FinanceRep003';
import InvoicesWithSearchAG from './pages/Invoices/InvoicesWithSearchAG';
import InvoicesWithSearchAGPg from './pages/Invoices/InvoicesWithSearchAGPg';
import FinanceMonthlyRepProfitAg from './pages/reports/FinanceMonthlyRepProfitAg';
import SettingsDashboard from './pages/dashboards/settings/SettingsDashboard';
import POSList from './features/pos/POSList_v1';
import POSForm from './features/pos/POSForm';
import EmployeesReport from './features/commission/EmployeesReport';
import Employee from './features/commission/Ecmployee';
import CommissionCatReport from './features/commission/CommissionCatReport';
import CommissionAmountsReport from './features/commission/CommissionAmountsReport';
import { useAppDispatch, useAppSelector } from './app/hooks';
import { setOffline, setOnline } from './features/connection/connectionSlice';
import { currentNewLoginTrial, isAuthenticated, isOnline, setAppOffline, tryLoginRequest } from './features/login/loginSlice';
import { timeout } from 'd3';

// const queryClient = new QueryClient()



const router = createBrowserRouter([
  {
    path: "/",
    element: <ProtectedRoute />,
    children: [
      {
        index: true,
        element: <InvoiceDashboard />
      },
      { path: "page1", element: <Page1 /> },
      { path: "page2", element: <Page2 /> },
      { path: "drafts", element: <Drafts /> },
      { path: "draft/stock", element: <DraftAvailableStock /> },
      { path: "draft/:invoiceType/:draftId", element: <DraftPage /> },
      { path: "invoices", element: <InvoicesWithSearchAGPg /> },
      { path: "invoice/:invoiceId", element: <InvoiceDraft /> },

      { path: "invoice/customers", element: <InvoiceCustomersRep01 /> },
      { path: "invoice/customers/sales", element: <InvoiceCustomersRep02 /> },

      { path: "customers", element: <CustomerDashboard /> },
      { path: "customers/rep01", element: <CustomerRep01 /> },
      { path: "customers/rep02", element: <CustomerRep02 /> },
      { path: "customers/rep03", element: <CustomerRep03 /> },
      { path: "customers/rep04", element: <CustomerRep04 /> },
      { path: "customers/rep05", element: <CustomerRep05 /> },
      { path: "customer/statmentdetail/:accountId/:startDate/:endDate", element: <CustomerStatmentDetailed /> },
      { path: "customer/statment/:accountId", element: <CustomerStatment /> },
      { path: "customer/newdoc/:documentType", element: <AddCustomerDocument /> },
      { path: "customer/document/:documentId", element: <CustomerDocumentForm /> },
      { path: "customer/:customerId", element: <CustomerForm /> },
      { path: "customer/add", element: <AddCustomer /> },

      { path: "reports", element: <ReportsDashboard /> },
      { path: "finance/rep01", element: <FinanceMonthlyRep /> },
      { path: "finance/rep02", element: <FinanceMonthlyRepProfitAg /> },
      { path: "finance/rep03/:accountId/:month/:year", element: <FinanceRep003 /> },





      { path: "users", element: <UsersDashboard /> },
      { path: "users/roles", element: <RolesList /> },
      { path: "users/role/:roleId", element: <RoleForm /> },
      { path: "users/userList", element: <UsersList /> },
      { path: "users/user/:userId", element: <UserForm /> },

      { path: "employee/all", element: <EmployeesReport /> },
      { path: "employee/:employeeId", element: <Employee /> },

      { path: "commissioncat/all", element: <CommissionCatReport /> },
      { path: "commissioncat/:categoryId", element: <CommissionAmountsReport /> },

      { path: "userlogs/:pageNumber", element: <UsersLogs /> },

      { path: "settings", element: <SettingsDashboard /> },
      { path: "settings/pos", element: <POSList /> },

      { path: "broker/rep01", element: <BrokerRep01 /> },
      { path: "broker/rep02", element: <BrokerRep002 /> },
      { path: "broker/add", element: <AddBroker /> },
      { path: "broker/:brokerId", element: <BrokerForm /> },


      { path: "productcategory/add", element: <AddProductCategory /> },
      { path: "productcategory/all", element: <ProductCategoryList /> },
      { path: "productcategory/rep01", element: <ProductCatRep01 /> },
      { path: "productcategory/rep02", element: <ProductCatRep02 /> },
      { path: "productcategory/:itemId", element: <ProductCategoryForm /> },


      { path: "colors/add", element: <AddColor /> },
      { path: "colors/all", element: <ColorsList /> },
      { path: "colors/:itemId", element: <ColorForm /> },


      { path: "sizes/add", element: <AddSize /> },
      { path: "sizes/all", element: <SizesList /> },
      { path: "sizes/:itemId", element: <SizeForm /> },

      { path: "store/add", element: <AddStore /> },
      { path: "store/all", element: <StoreList /> },
      { path: "store/:itemId", element: <StoreForm /> },

      { path: "salesperson/rep01", element: <SalesPersonRep01 /> },
      { path: "salesperson/rep02", element: <SalesPersonRep002 /> },
      { path: "salesperson/add", element: <AddSalesPerson /> },
      { path: "salesperson/:salesPersonId", element: <SalesPersonForm /> },


      { path: "pos/:posId/:posName", element: <POSForm /> },

      { path: "products", element: <ProductDashboard /> },
      { path: "products/rep01", element: <ProductRep04 /> },
      { path: "products/rep02", element: <ProductRep05 /> },
      { path: "product/add", element: <AddProductWithCS /> },
      { path: "product/transwithrange/:productId/:startDate/:endDate", element: <ProductAllTransWithDateRange /> },
      { path: "product/trans/:productId", element: <ProductAllTrans01 /> },
      { path: "product/storetrans/:productId/:storeId", element: <ProductStoreTrans /> },
      { path: "product/:productId", element: <ProductWithCS /> },
      { path: "stock", element: <StockDashboard /> },
      { path: "finance", element: <FinanceDashboard /> },

      { path: "expense/add", element: <AddExpensesDocumentMultiple /> },
      { path: "expense/:documentId", element: <ExpensesDocumentMultipleForm /> },
      { path: "expenses", element: <ExpenseRep001 /> },

      { path: "expensetype/add", element: <AddExpenseType /> },
      { path: "expensetype/all", element: <ExpenseTypeList /> },
      { path: "expensetype/:itemId", element: <ExpenseTypeForm /> },

      { path: "expense/expenseperprice", element: <SetExpenseToPrice /> },

      { path: "stock/rep01", element: <StockRep002 /> },
      { path: "stock/rep03", element: <StockRep003 /> },
      { path: "stock/rep04", element: <StockRep004 /> },
      { path: "stock/rep05", element: <StockRep005 /> },
      { path: "stock/rep06", element: <StockRep006 /> },
      { path: "stock/rep07/:numberOfDays", element: <StockExpireAfterDays /> },
      { path: "stock/rep08/:numberOfDays", element: <StockWithoutSalesForDays /> },
      { path: "stock/rep09", element: <StockRep009 /> },
      { path: "/stock/product/:productId", element: <StockRep010Gen /> },
      { path: "stock/rep11", element: <StockRep011 /> },

      { path: "myaccount", element: <MyAccountDashboard /> },
      { path: "profitloss", element: <ProfitLos /> },
      { path: "charttest", element: <Chart2 /> },
    ]
  },
  { path: "login", element: <SignIn /> }
]);

function App() {

  const dispatch = useAppDispatch();
  // const [intervalId, setIntervalId] = useState<NodeJS.Timeout|null>(null)
  const newLoginTrial = useAppSelector(currentNewLoginTrial);
  const authenticated = useAppSelector(isAuthenticated);
  const online = useAppSelector(isOnline)
  console.log("newLoginTrial is: " + newLoginTrial.toString)


  const login = () => { 
    dispatch(tryLoginRequest())
  };

  useEffect(() => {
    if (authenticated && !online) {
      console.log("1")

      const timeoutId = setTimeout(() => {
        console.log("2")
        if(!online){
          console.log("3")
          login()
        }else{
          console.log("4")
          clearTimeout(timeoutId);
        }
      }, 10000)


      // setIntervalId(newInterval)


      console.log("new login")
      return () => {
        console.log("finished")
  
        clearTimeout(timeoutId!)
      };
    }
  }, [newLoginTrial, dispatch]);

  // const dispatch = useDispatch<AppDispatch>(); 
  
  useEffect(() => {


    // const checkConnection = async () => {
    //   if (!navigator.onLine) {
    //     console.log(navigator.onLine)
    //     console.log('You are offline');
    //   } else {
    //     console.log(navigator.onLine)
    //     console.log('You are online');
    //   }
    // };
    // checkConnection();

    const handleOnline = () => dispatch(tryLoginRequest());
    const handleOffline = () => dispatch(setAppOffline());
    // const handleOnline = () => console.log("online"); 
    // const handleOffline = () => console.log("offline"); 
    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);
    console.log("started")

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  return (
    <RouterProvider router={router} />
  );
  // return (
  //   // <QueryClientProvider contextSharing={true} client={queryClient}>

  //   <Routes>
  //     <Route path="/" element={<ProtectedRoute />} >
  //       <Route path="/" element={<Home/>} />
  //       <Route path="/page1" element={<Page1/>} />
  //       <Route path="/page2" element={<Page2/>} />
  //       <Route path="/drafts" element={<Drafts/>} />
  //       <Route path="/draft/:draftId" element={<Draft/>} />
  //       <Route path="/invoices" element={<Invoices/>} />
  //       <Route path="/invoice/:invoiceId" element={<InvoiceDraft/>} />
  //       <Route path="/customers" element={<CustomerRep01/>} />
  //       <Route path="/customer/:customerId" element={<CustomerForm/>} />
  //       <Route path="/customer/add" element={<AddCustomer/>} />
  //       <Route path="/products" element={<ProductRep01/>} />
  //       <Route path="/product/add" element={<AddProduct/>} />
  //       <Route path="/product/:productId" element={<ProductForm/>} />
  //     </Route>

  //     <Route path="/login" element={<SignIn />} />
  //   </Routes>
  //   // </QueryClientProvider>

  // );
}

export default App;
export { router };