import { createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../../app/store';
import api from '../services/api'
import { DraftMode, InvoiceDiscountMode, InvoiceDiscountModeEnum, InvoiceTaxMode, InvoiceTaxModeEnum } from '../../models/Invoice';
import { Invoice02, InvoiceType } from '../../models/Invoice02';
import { InvoiceItem, InvoiceItemWithHash } from '../../models/InvoiceItem';
import { nanoid } from 'nanoid';
import { SelectChangeEvent } from '@mui/material';
import { Broker, Customer, SalesPerson } from '../../models/Customer';
import { db } from '../../db';
import { StoreHouse } from '../../models/StoreHouse';




export interface InvoiceState {
  // data for all invoices
  storeList: StoreHouse[];
  discountModeList: InvoiceDiscountMode[];
  taxModeList: InvoiceTaxMode[];

  mode: DraftMode;
  id: number | undefined;
  draftCount: number;
  invoice: Invoice02 | undefined;

  changesNotSaved: boolean;
  orderQtyTotal: Number;
  selectionModel: string[];
  invoiceTransferStore: number;
  broker: Broker | undefined;
  salesPerson: SalesPerson | undefined;


  error: string;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  navigateAction: '' | 'navigate back';
  busySatusCount: number;

}



const initialState: InvoiceState = {
  storeList: [],
  discountModeList: [],
  taxModeList: [],

  mode: DraftMode.DraftSaving,
  id: undefined,
  draftCount: 0,
  invoice: undefined,

  changesNotSaved: false,
  orderQtyTotal: 0,
  selectionModel: [],
  invoiceTransferStore: 1,
  broker: undefined,
  salesPerson: undefined,

  error: "",
  status: 'idle',
  navigateAction: '',
  busySatusCount: 0,
};



const calculateTotals = (state: InvoiceState, invoiceItems: InvoiceItem[]) => {
  console.log("calculateTotals")

  let subtotal = 0
  let discount_percentage = 0
  let discount = 0
  let salesTotal = 0
  let tax_percentage = 0
  let tax = 0
  let freight = 0
  let total = 0
  let qtyTotal = 0

  invoiceItems.filter(item => !item.cancelled).forEach(item => {

    subtotal += item.subtotal
    discount += item.discount
    salesTotal += item.salesTotal
    tax += item.tax
    total += item.total
    qtyTotal += item.quantity

  })


  if (state.invoice?.invoice_discount_mode === InvoiceDiscountModeEnum.PerInvoice) {
    discount = state.invoice.discount_percentage * subtotal / 100
    salesTotal = subtotal - discount
    discount_percentage = state.invoice.discount_percentage
  } else {
    discount_percentage = 100 * discount / subtotal
  }
  if (state.invoice?.invoice_tax_mode === InvoiceTaxModeEnum.PerInvoice) {
    tax = salesTotal * state.invoice.tax_percentage / 100
    tax_percentage = state.invoice.tax_percentage

  } else {
    tax_percentage = 100 * tax / salesTotal
  }

  if (state.invoice?.invoice_discount_mode === InvoiceDiscountModeEnum.PerInvoice || state.invoice?.invoice_tax_mode === InvoiceTaxModeEnum.PerInvoice) {
    total = subtotal - discount + tax
  }
  total += freight


  state.invoice = ({
    ...state.invoice,
    invoice_items: invoiceItems,
    subtotal: subtotal,
    discount: discount,
    discount_percentage: discount_percentage,
    salesTotal: salesTotal,
    tax: tax,
    tax_percentage: tax_percentage,
    freight: freight,
    total: total
  } as Invoice02)
  state.orderQtyTotal = qtyTotal;
  state.changesNotSaved = true;
};


const loadTotals = (state: InvoiceState, invoiceItems: InvoiceItem[]) => {
  let subtotal = 0
  let discount = 0
  let salesTotal = 0
  let tax = 0
  let total = 0
  let qtyTotal = 0
  invoiceItems.filter(item => !item.cancelled).forEach(item => {
    subtotal += item.subtotal
    salesTotal += item.salesTotal
    discount += item.discount
    tax += item.tax
    total += item.total
    qtyTotal += item.quantity
  })

  state.orderQtyTotal = qtyTotal;
}



export const invoiceSlice = createSlice({
  name: 'invoice',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {

    addLoadInvoice: (state, action: PayloadAction<number>) => {
      console.log("addLoadInvoice")
      state.id = action.payload;
      state.invoice = undefined;

      // state.id = undefined;
      state.draftCount = 0;
      state.changesNotSaved = false;
      state.orderQtyTotal = 0;
      state.selectionModel = [];

      state.status = 'loading';
      state.busySatusCount = state.busySatusCount + 1;
    },
    loadInvoiceSucceeded: (state, action: PayloadAction<any>) => {
      console.log("loadInvoiceSucceeded")
      state.invoice = { ...action.payload["invoice"], invoice_items: action.payload["invoice"]["invoice_items"].map((item: InvoiceItem) => ({ ...item, itemHash: nanoid() })) } as Invoice02
      state.changesNotSaved = false
      state.status = 'succeeded';
      state.busySatusCount = state.busySatusCount > 0 ? state.busySatusCount - 1 : 0
    },

    addCancelInvoice: (state) => {
      console.log("addCancelInvoice")
      state.status = 'loading';
      state.busySatusCount = state.busySatusCount + 1
    },
    cancelInvioceSucceeded: (state) => {
      console.log("cancelInvioceSucceeded")
      state.id = undefined
      state.invoice = undefined
      state.status = 'succeeded';
      state.navigateAction =  'navigate back'
      state.busySatusCount = state.busySatusCount > 0 ? state.busySatusCount - 1 : 0
    },

    addUpdateInvoice: (state) => {
      console.log("addUpdateInvoice")
      state.status = 'loading';
      state.busySatusCount = state.busySatusCount + 1
    },


    addNewInvoiceItem: (state, action: PayloadAction<InvoiceItem>) => {
      console.log("addNewInvoiceItem")
      const newInvoiceItems: InvoiceItemWithHash[] = [...state.invoice?.invoice_items as InvoiceItemWithHash[], { ...action.payload, itemHash: nanoid() } as InvoiceItemWithHash]
      calculateTotals(state, newInvoiceItems)
    },

    onDeleteBillItems: (state) => {
      console.log("onDeleteBillItems")
      const newInvoiceItems: InvoiceItemWithHash[] = state.invoice?.invoice_items?.map(item => ({ ...item, cancelled: item.cancelled ? true : state.selectionModel.includes((item as InvoiceItemWithHash).itemHash) })) as InvoiceItemWithHash[]
      calculateTotals(state, newInvoiceItems)
      state.selectionModel = [];
    },

    onTaxPercentageChange: (state, action: PayloadAction<React.ChangeEvent<HTMLInputElement>>) => {
      console.log("onTaxPercentageChange")
      state.invoice = {
        ...state.invoice,
        tax_percentage: parseFloat(action.payload.target.value),
        tax: (state.invoice?.subtotal! - state.invoice?.discount!) * parseFloat(action.payload.target.value) / 100,
        total: state.invoice?.subtotal! - state.invoice?.discount! + ((state.invoice?.subtotal! - state.invoice?.discount!) * parseFloat(action.payload.target.value) / 100) + state.invoice?.freight!
      } as Invoice02
      state.changesNotSaved = true;
    },

    onTaxChange: (state, action: PayloadAction<React.ChangeEvent<HTMLInputElement>>) => {
      console.log("onTaxChange")
      state.invoice = {
        ...state.invoice,
        tax_percentage: (parseFloat(action.payload.target.value) * 100) / (state.invoice?.subtotal! - state.invoice?.discount!),
        tax: parseFloat(action.payload.target.value),
        total: state.invoice?.subtotal! - state.invoice?.discount! + parseFloat(action.payload.target.value) + state.invoice?.freight!
      } as Invoice02
      state.changesNotSaved = true;
    },

    onFreightChange: (state, action: PayloadAction<React.ChangeEvent<HTMLInputElement>>) => {
      console.log("onFreightChange")
      state.invoice = {
        ...state.invoice,
        freight: parseFloat(action.payload.target.value),
        total: state.invoice?.subtotal! - state.invoice?.discount! + state.invoice?.tax! + parseFloat(action.payload.target.value)
      } as Invoice02
      state.changesNotSaved = true;
    },
    onDiscountPercentageChange: (state, action: PayloadAction<React.ChangeEvent<HTMLInputElement>>) => {
      console.log("onDiscountPercentageChange")
      state.invoice = {
        ...state.invoice,
        discount_percentage: parseFloat(action.payload.target.value),
        discount: state.invoice?.subtotal! * parseFloat(action.payload.target.value) / 100,
        tax: (state.invoice?.subtotal! - state.invoice?.subtotal! * parseFloat(action.payload.target.value) / 100) * state.invoice?.tax_percentage! / 100,
        // tax_percentage: parseFloat(event.target.value),
        total: state.invoice?.subtotal! * (1 - parseFloat(action.payload.target.value) / 100) * (1 + state.invoice?.tax_percentage! / 100) + state.invoice?.freight!
      } as Invoice02
      state.changesNotSaved = true;
    },

    onDiscountChange: (state, action: PayloadAction<React.ChangeEvent<HTMLInputElement>>) => {
      console.log("onDiscountChange")
      state.invoice = {
        ...state.invoice,
        discount_percentage: (parseFloat(action.payload.target.value) * 100) / state.invoice?.subtotal!,
        discount: parseFloat(action.payload.target.value),
        tax: (state.invoice?.subtotal! - parseFloat(action.payload.target.value)) * state.invoice?.tax_percentage! / 100,
        // tax_percentage: parseFloat(event.target.value),
        total: (state.invoice?.subtotal! - parseFloat(action.payload.target.value)) * (1 + state.invoice?.tax_percentage! / 100) + state.invoice?.freight!
      } as Invoice02
      state.changesNotSaved = true;
    },

    onDateChange: (state, action: PayloadAction<Date | null>) => {
      console.log("onDateChange")
      state.invoice = {
        ...state.invoice,
        invoice_date: action.payload!.toISOString().split('.')[0] + "Z"
      } as Invoice02
      state.changesNotSaved = true;
    },

    onInputValueChange: (state, action: PayloadAction<React.ChangeEvent<HTMLInputElement>>) => {
      console.log("onInputValueChange")
      state.invoice = {
        ...state.invoice,
        [action.payload.target.name]: action.payload.target.value
      } as Invoice02
      state.changesNotSaved = true;
    },

    onRowSelectionChanged: (state, action: PayloadAction<string[]>) => {
      console.log("onRowSelectionChanged")
      state.selectionModel = action.payload
    },

    onSelectValueChange: (state, action: PayloadAction<SelectChangeEvent<number>>) => {
      console.log("onSelectValueChange")
      state.invoice = {
        ...state.invoice,
        [action.payload.target.name]: action.payload.target.value
      } as Invoice02
      state.changesNotSaved = true;
    },

    onTransferStoreChanged: (state, action: PayloadAction<any>) => {
      console.log("onTransferStoreChanged")
      state.invoiceTransferStore = action.payload.target.value
    },

    onStoreChanged: (state, action: PayloadAction<SelectChangeEvent<number>>) => {
      console.log("onStoreChanged")
      const firstValue = state.storeList?.find(store => (store.id !== action.payload.target.value))!.id
      state.invoiceTransferStore = firstValue
      state.invoice = {
        ...state.invoice,
        store: action.payload.target.value
      } as Invoice02
      state.changesNotSaved = true;
    },

    onBrokerChanged: (state, action: PayloadAction<Broker>) => {
      console.log("onBrokerChanged")
      state.invoice = {
        ...state.invoice,
        sales_person_id: action.payload.id
      } as Invoice02
      state.changesNotSaved = true;
    },

    onSalesPersonChanged: (state, action: PayloadAction<SalesPerson>) => {
      console.log("onSalesPersonChanged")
      state.invoice = {
        ...state.invoice,
        sales_person_id: action.payload.id
      } as Invoice02
      state.changesNotSaved = true;
    },

    onSetBrokerObject: (state, action: PayloadAction<Broker>) => {
      console.log("onSetBrokerObject")
      state.broker = action.payload
    },

    onSetSalesPersonObject: (state, action: PayloadAction<SalesPerson>) => {
      console.log("onSetSalesPersonObject")
      state.salesPerson = action.payload
    },
    onSetStoreList: (state, action: PayloadAction<StoreHouse[]>) => {
      console.log("onSetStoreList")
      state.storeList = action.payload
    },
    onSetDiscountModeList: (state, action: PayloadAction<InvoiceDiscountMode[]>) => {
      console.log("onSetDiscountModeList")
      state.discountModeList = action.payload
    },
    onSetTaxModeList: (state, action: PayloadAction<InvoiceTaxMode[]>) => {
      console.log("onSetTaxModeList")
      state.taxModeList = action.payload
    },

    onRowsChange: (state, action: PayloadAction<{ rows: InvoiceItemWithHash[], id: number, field: string, newValue: any }>) => {
      console.log("onRowsChange")
      const { rows, id, field, newValue } = action.payload;

      if (field === "unit_price") {
        const item_new_price = parseFloat(newValue.toString())
        const existingRow = (state.invoice?.invoice_items as InvoiceItemWithHash[]).find(row => row.id === id);
        const item_hash = existingRow?.itemHash



        calculateTotals(state,
          state.invoice?.invoice_items?.map((item) => {

            if ((item as InvoiceItemWithHash).itemHash === item_hash) {

              return {
                ...item,
                unit_price: item_new_price,
                main_unit_price: item_new_price,
                subtotal: Math.round(((item_new_price * item.quantity) + Number.EPSILON) * 100) / 100,
                tax: Math.round(((item_new_price * item.quantity * (1 - item.discount_display / 100)) * item.tax_precent / 100 + Number.EPSILON) * 100) / 100,
                discount: Math.round(((item_new_price * item.quantity * item.discount_display / 100) + Number.EPSILON) * 100) / 100,
                salesTotal: Math.round(((item_new_price * item.quantity) * (1 - item.discount_display / 100) + Number.EPSILON) * 100) / 100, // to salestotal - discount
                total: Math.round(item_new_price * item.quantity * (1 - item.discount_display / 100) * (1 + item.tax_precent / 100)),
                update_info: !('update_info' in item) ? 'price_changed,' : (item.update_info.includes('price_changed') ? item.update_info : item.update_info + 'price_changed,'),
              }

            } else {
              return item
            }

          }) as InvoiceItemWithHash[])
      }



      if (state.invoice?.invoice_type === 2 || state.invoice?.invoice_type === 3 || state.invoice?.invoice_type === 5 || state.invoice?.invoice_type === 7) {

        if (field === "quantity") {
          const item_new_quantity = parseFloat(newValue.toString())
          const existingRow = (state.invoice?.invoice_items as InvoiceItemWithHash[]).find(row => row.id === id);
          const item_hash = existingRow?.itemHash

          calculateTotals(state,
            state.invoice?.invoice_items?.map((item) => {

              if ((item as InvoiceItemWithHash).itemHash === item_hash) {

                return {
                  ...item,
                  quantity: item_new_quantity,
                  main_unit_quantity: item_new_quantity,
                  subtotal: Math.round(((item_new_quantity * item.main_unit_price) + Number.EPSILON) * 100) / 100,
                  tax: Math.round(((item_new_quantity * item.main_unit_price * (1 - item.discount_display / 100)) * item.tax_precent / 100 + Number.EPSILON) * 100) / 100,
                  discount: Math.round(((item_new_quantity * item.main_unit_price * item.discount_display / 100) + Number.EPSILON) * 100) / 100,
                  salesTotal: Math.round(((item_new_quantity * item.main_unit_price) * (1 - item.discount_display / 100) + Number.EPSILON) * 100) / 100, // to salestotal - discount
                  total: Math.round(item_new_quantity * item.main_unit_price * (1 - item.discount_display / 100) * (1 + item.tax_precent / 100)),
                  update_info: !('update_info' in item) ? 'quantity_changed,' : (item.update_info.includes('quantity_changed') ? item.update_info : item.update_info + 'quantity_changed,'),
                }

              } else {
                return item
              }

            }) as InvoiceItemWithHash[])
        }
      }


      if (field === "discount_display") {
        const item_new_discount = parseFloat(newValue.toString())
        const existingRow = (state.invoice?.invoice_items as InvoiceItemWithHash[]).find(row => row.id === id);
        const item_hash = existingRow?.itemHash

        calculateTotals(state,
          state.invoice?.invoice_items?.map((item) => {

            if ((item as InvoiceItemWithHash).itemHash === item_hash) {

              return {
                ...item,
                discount_display: item_new_discount,
                tax: Math.round(((item.main_unit_price * item.quantity * (1 - item_new_discount / 100)) * item.tax_precent / 100 + Number.EPSILON) * 100) / 100,
                discount: Math.round(((item.main_unit_price * item.quantity * item_new_discount / 100) + Number.EPSILON) * 100) / 100,
                salesTotal: Math.round(((item.main_unit_price * item.quantity) * (1 - item_new_discount / 100) + Number.EPSILON) * 100) / 100, // to salestotal - discount
                total: Math.round(item.main_unit_price * item.quantity * (1 - item_new_discount / 100) * (1 + item.tax_precent / 100)),
                update_info: !('update_info' in item) ? 'discount_changed,' : (item.update_info.includes('discount_changed') ? item.update_info : item.update_info + 'discount_changed,'),
              }

            } else {
              return item
            }

          }) as InvoiceItemWithHash[])
      }



    },

    onNewCustomerSelected: (state, action: PayloadAction<Customer>) => {
      console.log("onNewCustomerSelected")
      state.invoice = {
        ...state.invoice,
        customer_id: action.payload.id,
        customer_name: action.payload.fullname,
        customer_account_id: action.payload.account_id,
        customer_type: action.payload.customer_type,
      } as Invoice02
      state.changesNotSaved = true;
    },
    onloadTotals: (state) => {
      console.log("onloadTotals")
      if (state.invoice !== undefined) loadTotals(state, state.invoice?.invoice_items!)
    },



    loadFailure: (state, action: PayloadAction<string>) => {

      state.error = action.payload;
      state.status = 'failed';
      state.busySatusCount = state.busySatusCount > 0 ? state.busySatusCount - 1 : 0
    },



  },
});

export const { addLoadInvoice, loadInvoiceSucceeded, addCancelInvoice, cancelInvioceSucceeded, addUpdateInvoice, addNewInvoiceItem, onDeleteBillItems, onTaxPercentageChange, onSetBrokerObject, onTaxChange, onFreightChange, onDiscountPercentageChange, onDiscountChange, onDateChange, onInputValueChange, onRowSelectionChanged, onSelectValueChange, onTransferStoreChanged, onStoreChanged, onBrokerChanged, onSalesPersonChanged, onSetSalesPersonObject, onSetStoreList, onSetDiscountModeList, onSetTaxModeList, onRowsChange, onNewCustomerSelected, onloadTotals, loadFailure } = invoiceSlice.actions;
export const currentInvoiceId = (state: RootState) => state.invoiceDraft.id;
export const invoiceData = (state: RootState) => state.invoiceDraft.invoice;
export const selectionModel = (state: RootState) => state.invoiceDraft.selectionModel;
export const stores = (state: RootState) => state.invoiceDraft.storeList;
export const discountModes = (state: RootState) => state.invoiceDraft.discountModeList;
export const taxModes = (state: RootState) => state.invoiceDraft.taxModeList;
export const transferStoreData = (state: RootState) => state.invoiceDraft.invoiceTransferStore;
export const unSavedChanges = (state: RootState) => state.invoiceDraft.changesNotSaved;
export const broker = (state: RootState) => state.invoiceDraft.broker;
export const salesPerson = (state: RootState) => state.invoiceDraft.salesPerson;
export const navigateAction = (state: RootState) => state.invoiceDraft.navigateAction;


export const invoiceCallStatus = (state: RootState) => state.invoiceDraft.status;
export const invoiceCallBusyCount = (state: RootState) => state.invoiceDraft.busySatusCount


export const getBrokerObject = (): AppThunk => async (dispatch, getState) => {
  console.log("getBrokerObject")
  const invoice = invoiceData(getState());
  const brokerObject = await db.broker.get({ id: invoice?.broker_id })

  dispatch(onSetBrokerObject(brokerObject!));
};

export const getSalesPersonObject = (): AppThunk => async (dispatch, getState) => {
  console.log("getSalesPersonObject")
  const invoice = invoiceData(getState());
  if (invoice !== undefined) {
    const salesPersonObject = await db.salesPerson.get({ id: invoice?.sales_person_id })
    dispatch(onSetSalesPersonObject(salesPersonObject!));
  }
};


export const loadInvoice = (invoiceId: number): AppThunk => async (dispatch, getState) => {

  console.log("loadInvoice")
  const storeList = stores(getState());
  const discountModeList = discountModes(getState());
  const taxModeList = taxModes(getState());
  dispatch(addLoadInvoice(invoiceId));
  try {

    const response = await api.get(
      `/invoice/detailed/${invoiceId}`,
      { headers: { "Content-Type": "application/json" } }
    )

    if (response.status === 200) {


      if (storeList.length === 0) {
        const storeItems = await db.storeHouse.toArray()
        dispatch(onSetStoreList(storeItems))
      }
      if (discountModeList.length === 0) {
        const discountModItems = await db.invoiceDiscountMode.toArray()
        dispatch(onSetDiscountModeList(discountModItems))
      }
      if (discountModeList.length === 0) {
        const taxModItems = await db.invoiceTaxMode.toArray()
        dispatch(onSetTaxModeList(taxModItems))
      }

      for (let i = 0; i < response.data["invoice"]["invoice_items"].length; i++) {
        if (response.data["invoice"]["invoice_items"][i].product_type === 3) {
          const product = await db.productCatalog.get(response.data["invoice"]["invoice_items"][i].product_id)
          response.data["invoice"]["invoice_items"][i].product_components = product?.components
        }
      }

      dispatch(loadInvoiceSucceeded(response.data));
    } else {
      dispatch(loadFailure(response.statusText));
    }

  } catch (err: any) {
    dispatch(loadFailure(err.message));
  }
};


export const cancelInvoice = (): AppThunk => async (dispatch, getState) => {
  console.log("cancelInvoice")
  const invoiceId = currentInvoiceId(getState());
  dispatch(addCancelInvoice());
  try {

    const response = await api.delete(
      `/invoice/${invoiceId}`,
      { headers: { "Content-Type": "application/json" } }
    )

    if (response.status === 200) {
      dispatch(cancelInvioceSucceeded())
    } else {
      dispatch(loadFailure(response.statusText));
    }

  } catch (err: any) {
    if (err.response.data.msg === "Stock used" || err.response.data.msg === "Not enough quantity") {
      dispatch(loadFailure("Can not Cancel, " + err.response.data.msg));
    } else {
      dispatch(loadFailure("Can not Cancel"));
    }

  }
};

export const updateInvoiceData = (): AppThunk => async (dispatch, getState) => {
  console.log("updateInvoiceData")
  const invoice = invoiceData(getState());
  dispatch(addUpdateInvoice());
  try {

    const response = await api.put(
      '/invoice',
      invoice,
      { headers: { "Content-Type": "application/json" } }
    )

    if (response.status === 200) {
      console.log("updateInvoiceDataSucceeded")
      dispatch(loadInvoiceSucceeded(response.data));
    } else {
      dispatch(loadFailure(response.statusText));
    }

  } catch (err: any) {
    if (err.response.data.msg === "Stock used" || err.response.data.msg === "Not enough quantity") {
      dispatch(loadFailure("Can not update, " + err.response.data.msg));
    } else {
      dispatch(loadFailure("Can not update"));
    }

  }
};



export const AdjustInvoiceStock = (): AppThunk => async (dispatch, getState) => {
  console.log("updateInvoiceData")
  const invoice = invoiceData(getState());
  dispatch(addUpdateInvoice());
  try {

    const response = await api.put(
      '/invoice/adjuststock',
      invoice,
      { headers: { "Content-Type": "application/json" } }
    )

    if (response.status === 200) {
      console.log("updateInvoiceDataSucceeded")
      dispatch(loadInvoiceSucceeded(response.data));
    } else {
      dispatch(loadFailure(response.statusText));
    }

  } catch (err: any) {
    if (err.response.data.msg === "Stock used" || err.response.data.msg === "Not enough quantity") {
      dispatch(loadFailure("Can not update, " + err.response.data.msg));
    } else {
      dispatch(loadFailure("Can not update"));
    }

  }
};



// export const cashInvoiceOffline = (cashInvoice: any): AppThunk => async (dispatch, getState) => {


//   let foundInvoice:any = await db.encInvoice!.get(cashInvoice?.draft_uid) ?? null

//   if(foundInvoice === null || foundInvoice === undefined){
//     const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(cashInvoice), "asdflkower@kS990#$sdfsdf").toString(); 


//     // const bytes = CryptoJS.AES.decrypt(encryptedData, "asdflkower@kS990#$sdfsdf"); 
//     // const obj = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

//     // return { id: invoice.id, encryptedData };

//     db.transaction('rw', db.encInvoice, async () => {
//       await db.encInvoice.put({id: cashInvoice?.draft_uid, data: encryptedData})
//       }).then(() => {
//       console.log("Transaction committed");
//       return true
//     }).catch(err => {
//       console.error(err.stack);
//       return false
//     });
//   }
//   return false


// };




export default invoiceSlice.reducer;
