import React, { useContext, useEffect, createContext, useMemo, useState, useCallback } from 'react'
import { LinearProgress, Paper, Button, Box, Container, Typography, Divider, AppBar, Toolbar, TextField, Input } from '@mui/material';
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { selectDirection, selectTranslations } from "../i18n/i18nSlice";

import CloudSyncIcon from '@mui/icons-material/CloudSync';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useNavigate, Link } from "react-router-dom";


import DataGrid, { Column, SortColumn, HeaderRendererProps, useFocusRef, headerRenderer as HeaderRenderer } from 'react-data-grid';
import { DraggableHeaderRenderer } from './DraggableHeaderRenderer'
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useDrag, useDrop } from 'react-dnd';



import { Order } from '../../models/Order';
import { css as MyCss } from '@linaria/core';
import { selectPaletteMode } from '../appconfig/appConfigSlice';
import { db } from '../../db';
import api from '../services/api';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import SaveIcon from '@mui/icons-material/Save';

import * as XLSX from 'xlsx';

interface ApiStatus {
  error: string;
  status: 'idle' | 'loading' | 'succeeded' | 'failed'
}


//*1-add model
interface Row {
  id: number;
  product_name: string;
  category_name: string;
  international_code: string;
}


//*2- add filter
interface Filter extends Omit<Row, 'id'> {
  enabled: boolean;
}
const FilterContext = createContext<Filter | undefined>(undefined);


export default function ProductRep05() {

  const navigate = useNavigate();
  const paletteMode = useAppSelector(selectPaletteMode);

  const dispatch = useAppDispatch()

  const langDirection = useAppSelector(selectDirection);
  const trans = useAppSelector(selectTranslations);
  const [rows, setRows] = useState<Row[]>([])
  const [sortColumns, setSortColumns] = useState<readonly SortColumn[]>([]);

  const [apiCallStatus, setApiCallStatus] = React.useState<ApiStatus>({ error: "", status: "idle" })
  const isLoading = () => apiCallStatus.status === "loading";
  const isSucceeded = () => apiCallStatus.status === "succeeded";
  const isIdle = () => apiCallStatus.status === "idle";
  const isFailed = () => apiCallStatus.status === "failed";
  const [file, setFile] = useState<File | null>(null);


  


  //*4- define get report

  const getReport = async () => {
    setApiCallStatus({ error: "", "status": "loading" })
    try {
      const response = await api.get(
        '/product/rep05',
        { headers: { "Content-Type": "application/json" } }
      )

      if (response.status === 200) {

        setRows(response.data["product"])

        setApiCallStatus({ error: "", "status": "succeeded" })
      } else {
        setApiCallStatus({ error: response.statusText, "status": "failed" })
      }

    } catch (err: any) {
      setApiCallStatus({ error: err.message, "status": "failed" })
    }
  };

  const classes = {
    filterColumnClassName: 'filter-cell',
  }


  const filterColumnClassName = 'filter-cell';
  const filterContainerClassname = `
    .${filterColumnClassName} {
      line-height: 35px;
      padding: 0;
      > div {
        padding-block: 0;
        padding-inline: 8px;
        &:first-child {
          border-block-end: 1px solid var(--rdg-border-color);
        }
      }
    }
  `;
  const filterClassname = `
    inline-size: 100%;
    padding: 4px;
    font-size: 14px;
  `;





  function inputStopPropagation(event: React.KeyboardEvent<HTMLInputElement>) {
    if (['ArrowLeft', 'ArrowRight'].includes(event.key)) {
      event.stopPropagation();
    }
  }

  //*5- set default values
  const [filters, setFilters] = useState<Filter>({
    product_name: '',
    category_name: '',
    international_code: '',
    enabled: true
  });



  //*6- set fitlers and ordering
  const filteredRows = useMemo((): readonly Row[] => {

    if (sortColumns.length === 0) {
      let sortedRows: Row[] = rows.filter((r) => {
        return (
          (filters.product_name ? r.product_name.toLowerCase().includes(filters.product_name.toLowerCase()) : true) &&
        (filters.category_name ? r.category_name.toLowerCase().includes(filters.category_name.toLowerCase()) : true) && 
        (filters.international_code ? r.international_code.toLowerCase().includes(filters.international_code.toLowerCase()) : true)

        );
      });
      return sortedRows
    }
    const { columnKey, direction } = sortColumns[0];

    let sortedRows: Row[] = rows.filter((r) => {
      return (
        (filters.product_name ? r.product_name.toLowerCase().includes(filters.product_name.toLowerCase()) : true) &&
        (filters.category_name ? r.category_name.toLowerCase().includes(filters.category_name.toLowerCase()) : true) && 
        (filters.international_code ? r.international_code.toLowerCase().includes(filters.international_code.toLowerCase()) : true)
      );
    });



    switch (columnKey) {
      case 'product_name':
        sortedRows = sortedRows.sort((a, b) => a[columnKey].localeCompare(b[columnKey]));
        break;
      case 'category_name':
        sortedRows = sortedRows.sort((a, b) => a[columnKey].localeCompare(b[columnKey]));
        break;
      case 'international_code':
        sortedRows = sortedRows.sort((a, b) => a[columnKey].localeCompare(b[columnKey]));
        break;
      default:
        sortedRows = (sortedRows as any[]).sort((a, b) => a[columnKey] - b[columnKey]);
    }
    return direction === 'DESC' ? sortedRows.reverse() : sortedRows;
  }, [rows, filters, sortColumns]);



  const [columnsChanged, setColumnsChanged] = useState(false);

  const filteredColumns = useMemo((): readonly any[] => {

    function HeaderRenderer(props: HeaderRendererProps<Row>) {
      return <DraggableHeaderRenderer {...props} onColumnsReorder={handleColumnsReorder} />;
    }

    // const handleColumnsReorder = (sourceKey: string, targetKey: string) => {
    //   const sourceColumnIndex = columns.findIndex((c) => c.key === sourceKey);
    //   const targetColumnIndex = columns.findIndex((c) => c.key === targetKey);
    //   const reorderedColumns = [...columns];

    //   reorderedColumns.splice(
    //     targetColumnIndex,
    //     0,
    //     reorderedColumns.splice(sourceColumnIndex, 1)[0]
    //   );

    //   setColumns(reorderedColumns);
    // }
    function handleColumnsReorder(sourceKey: string, targetKey: string) {
      const sourceColumnIndex = columns.findIndex((c) => c.key === sourceKey);
      const targetColumnIndex = columns.findIndex((c) => c.key === targetKey);
      const reorderedColumns = [...columns];

      reorderedColumns.splice(
        targetColumnIndex,
        0,
        reorderedColumns.splice(sourceColumnIndex, 1)[0]
      );

      setColumns(reorderedColumns);
      setColumnsChanged(!columnsChanged)
    }

    var additinalColumns: string[] = []
    if (rows.length > 0) {
      additinalColumns = Object.keys(rows[0]).filter(item => !['id', 'product_name', 'category_name', 'international_code'].includes(item))
    }
    const addedClm = additinalColumns.map(clm => ({ key: clm, name: clm, resizable: true, sortable: true, width: 'max-content' }))


    //*7- set colums
    const fc = [
      { key: 'id', name: 'id', resizable: true, sortable: true, width: 20 },
      { key: 'open', name: trans.nsi_table.open, resizable: true, sortable: true, width: 20, formatter({ row }: { row: any }) { return <Button style={{ width: 32, minWidth: 32 }} onClick={() => onOpenClick(row.id!)}>{trans.nsi_table.open}</Button> } },
      {
        key: 'product_name', name: trans.product_rep1.product_name, resizable: true, sortable: true, width: 300,
        headerCellClass: filterColumnClassName,
        headerRenderer: (p: HeaderRendererProps<Row>) => (

          <FilterRenderer<Row, unknown, HTMLInputElement> {...p} onColumnsReorder={handleColumnsReorder}>

            {({ filters, ...rest }) => (
              <>
                <HeaderRenderer {...p}></HeaderRenderer>
                <input
                  {...rest}
                  className={filterClassname!}
                  // variant='outlined'
                  style={{ inlineSize: "100%", padding: "4px", fontSize: "14px" }}
                  value={filters.product_name}
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      product_name: e.target.value
                    })
                  }
                  onKeyDown={inputStopPropagation}
                />
              </>
            )}
          </FilterRenderer>
        )
      },
      {
        key: 'category_name', name: trans.product_rep1.category_name, resizable: true, sortable: true, width: 300,
        headerCellClass: filterColumnClassName,
        headerRenderer: (p: HeaderRendererProps<Row>) => (

          <FilterRenderer<Row, unknown, HTMLInputElement> {...p} onColumnsReorder={handleColumnsReorder}>

            {({ filters, ...rest }) => (
              <>
                <HeaderRenderer {...p}></HeaderRenderer>
                <input
                  {...rest}
                  className={filterClassname!}
                  // variant='outlined'
                  style={{ inlineSize: "100%", padding: "4px", fontSize: "14px" }}
                  value={filters.category_name}
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      category_name: e.target.value
                    })
                  }
                  onKeyDown={inputStopPropagation}
                />
              </>
            )}
          </FilterRenderer>
        )
      },
      {
        key: 'international_code', name: 'international_code', resizable: true, sortable: true, width: 300,
        headerCellClass: filterColumnClassName,
        headerRenderer: (p: HeaderRendererProps<Row>) => (

          <FilterRenderer<Row, unknown, HTMLInputElement> {...p} onColumnsReorder={handleColumnsReorder}>

            {({ filters, ...rest }) => (
              <>
                <HeaderRenderer {...p}></HeaderRenderer>
                <input
                  {...rest}
                  className={filterClassname!}
                  // variant='outlined'
                  style={{ inlineSize: "100%", padding: "4px", fontSize: "14px" }}
                  value={filters.international_code}
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      international_code: e.target.value
                    })
                  }
                  onKeyDown={inputStopPropagation}
                />
              </>
            )}
          </FilterRenderer>
        )
      },
      ...addedClm
    ]

    return fc
  }, [columnsChanged, rows])


  const [columns, setColumns] = useState(filteredColumns);


  //*8- set on click open
  const onOpenClick = (id: number) => {
    navigate("/product/" + id.toString());
  }

  useEffect(() => {
    getReport()
  }, [])


  const onSortColumnsChange = useCallback((sortColumns: SortColumn[]) => {
    setSortColumns(sortColumns.slice(-1));
  }, []);


  const draggableColumns = useMemo((): readonly Column<Row>[] => {
    function HeaderRenderer(props: HeaderRendererProps<Row>) {
      return <DraggableHeaderRenderer {...props} onColumnsReorder={handleColumnsReorder} />;
    }

    function handleColumnsReorder(sourceKey: string, targetKey: string) {
      const sourceColumnIndex = columns.findIndex((c) => c.key === sourceKey);
      const targetColumnIndex = columns.findIndex((c) => c.key === targetKey);
      const reorderedColumns = [...columns];

      reorderedColumns.splice(
        targetColumnIndex,
        0,
        reorderedColumns.splice(sourceColumnIndex, 1)[0]
      );

      setColumns(reorderedColumns);
    }

    return columns.map((c) => {
      if (c.key === 'id') return c;
      return { ...c, headerRenderer: HeaderRenderer };
    });
  }, [columns]);




  //*3-add download mapping
  const getPrintedData = () => {
    return filteredRows.map(item => {
      const { id, product_name, category_name, international_code, ...rest } = item
      return ({
        id: item.id,
        [trans.product_rep1.product_name]: item["product_name"],
        [trans.product_rep1.category_name]: item["category_name"],
        international_code: item["international_code"],
        ...rest
      })
    })
  }

  const downloadExcel = (data: any) => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "DataSheet1.xlsx");
  };

  const readExcelFile = () => {
    if (file !== null) {
      // Create a new XSSFWorkbook object.
      const workbook = XLSX.readFile(file.name);

      // Get the first sheet.
      const sheet = workbook.Sheets[0];

      // Get the rows and columns.
      const rows = sheet.getPhysicalNumberOfRows();
      const columns = sheet.getPhysicalNumberOfColumns();

      // Create an array to store the data.
      const dataArray = [];

      // Loop through the rows.
      for (var i = 0; i < rows; i++) {
        const row = sheet.getRow(i);

        // Create an array to store the values in the row.
        const rowArray = [];

        // Loop through the columns.
        for (var j = 0; j < columns; j++) {
          const cell = row.getCell(j);

          // Get the value of the cell.
          const value = cell.getRawValue();

          // Add the value to the row array.
          rowArray.push(value);
        }

        // Add the row array to the data array.
        dataArray.push(rowArray);
      }

      // Return the data array.
      return dataArray;
    }
  }



  const readExcelFile1 = (file: File) => {
    const reader = new FileReader();
    reader.onload = function () {
      const data = reader.result;
      const workbook = XLSX.read(data, { type: "array" });
      const sheet = workbook.Sheets[workbook.SheetNames[0]];
      const rows = sheet.getPhysicalNumberOfRows();
      const columns = sheet.getPhysicalNumberOfColumns();
      const dataArray = [];
      for (var i = 0; i < rows; i++) {
        const row = sheet.getRow(i);
        const rowArray = [];
        for (var j = 0; j < columns; j++) {
          const cell = row.getCell(j);
          const value = cell.getRawValue();
          rowArray.push(value);
        }
        dataArray.push(rowArray);
      }
      const jsonData = JSON.stringify(dataArray);
      return jsonData;
    };
    reader.readAsArrayBuffer(file);
  };


  const readExcelFile2 = (file: File, mode: number) => {
    if (file) {

      const reader = new FileReader();
      reader.onload = () => {
        const data = reader.result;
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = XLSX.utils.sheet_to_json(worksheet);
        if(mode === 1){
          mapToPriceList(json)
        }
        if(mode === 2){
          mapToPriceListUsingIntCode(json)
        }
      };
      reader.readAsArrayBuffer(file);
    }
  };


  const mapToPriceList = async (products: any[]) => {
    const priceList = await db.priceList.toArray()
    // const newList = priceList.forEach( item => {
    //   const newitems = products.map(prod => ({product_catalog: prod.id, product_price_list: item.id, price: prod[item.list_name]}))
    //   return newitems
    //   })
    const newList = priceList.map(item => (
      products.map(prod => ({ product_catalog: prod.id, product_price_list: item.id, price: prod[item.list_name] }))

    ))
    const allList = newList.flat(1)
    await updatePriceListById(allList)
    // console.log(allList);
  }


  
  const mapToPriceListUsingIntCode = async (products: any[]) => {
    const priceList = await db.priceList.toArray()
    
    const newList = priceList.map(item => (
      products.map(prod => ({ international_code: prod.international_code, product_price_list: item.id, price: prod[item.list_name] }))

    ))
    const allList = newList.flat(1).filter(item => item.international_code !== 'NaN' && item.international_code !== '' && item.international_code !== undefined)
    await updatePriceListByIntCode(allList)
    // console.log(allList);
  }


  
  const updatePriceListById = async (price_list: any[]) => {
    setApiCallStatus({ error: "", "status": "loading" })
    try {
      const response = await api.put(
        '/product/pricingbyid',
        {
          list: price_list
        },
        { headers: { "Content-Type": "application/json" } }
      )

      if (response.status === 200) {
       
        setApiCallStatus({ error: "", "status": "succeeded" })
      } else {
        alert(response.data['msg'])
        setApiCallStatus({ error: response.statusText, "status": "failed" })
      }

    } catch (err: any) {
      alert(err.response.data['msg'])
      setApiCallStatus({ error: err.message, "status": "failed" })
    }
  };


  
  const updatePriceListByIntCode = async (price_list: any[]) => {
    setApiCallStatus({ error: "", "status": "loading" })
    try {
      const response = await api.put(
        '/product/pricingbyintcode',
        {
          list: price_list
        },
        { headers: { "Content-Type": "application/json" } }
      )

      if (response.status === 200) {
       
        setApiCallStatus({ error: "", "status": "succeeded" })
      } else {
        alert(response.data['msg'])
        setApiCallStatus({ error: response.statusText, "status": "failed" })
      }

    } catch (err: any) {
      alert(err.response.data['msg'])
      setApiCallStatus({ error: err.message, "status": "failed" })
    }
  };


  // const handleFileChange = (event: React.ChangeEvent<HTMLInputElement> | undefined ) => {

  const handleFileChange = (event: any) => {
    setFile(event.target?.files[0]);
  };

  const readFile = async () => {
    if (!file) {
      return;
    }

    const reader = new FileReader();

    reader.onload = function () {
      const data = reader.result;

      // Do something with the data.
    };

    reader.readAsArrayBuffer(file);
  };



  return (
    <Container maxWidth={false} sx={{margin:0, padding:0}}>
      {isLoading() && <LinearProgress />}
      {isSucceeded() && (
        <>
          <Box sx={{ display: 'flex', flexDirection: 'column', height: `calc(100vh - 128px)` }}>
            <Box>
              {rows.length != 0 ?
                <>
                  <Paper elevation={3} sx={{ marginBottom: 2, padding: 2, direction: langDirection }}>
                    <Button variant="contained" startIcon={<FileDownloadIcon sx={{ marginRight: 0, marginLeft: 1 }} />} sx={{ marginX: 1 }} onClick={() => downloadExcel(getPrintedData())}>
                      Download
                    </Button>
                  </Paper>


                  <Paper elevation={3} sx={{ marginBottom: 2, padding: 2, direction: langDirection }}>
                    <Box>

                      <Button variant="contained" startIcon={<SaveIcon sx={{ marginRight: 0, marginLeft: 1 }} />} sx={{ marginX: 1 }} onClick={() => readExcelFile2(file!, 1)}>
                        Update With App ID
                      </Button>
                      <Input type="file" name="file1" onChange={handleFileChange} />
                    </Box>

                    <Box sx={{ marginTop: 1 }}>
                      <Button variant="contained" startIcon={<SaveIcon sx={{ marginRight: 0, marginLeft: 1 }} />} sx={{ marginX: 1 }} onClick={() => readExcelFile2(file!, 2)}>
                        Update With International Code
                      </Button>
                      <Input type="file" name="file2" onChange={handleFileChange} />
                    </Box>
                  </Paper>
                </>

                : null}

              <AppBar position="static" sx={{ backgroundColor: "#283593" }}>
                <Toolbar component="nav" variant="dense" sx={{ justifyContent: 'space-between', overflowX: 'auto' }}  >
                  <Typography component="h2" variant="h5">
                    {trans.product_rep1.title}
                  </Typography>
                </Toolbar>
              </AppBar>
            </Box>

            <DndProvider backend={HTML5Backend}>
              <FilterContext.Provider value={filters}>
                <DataGrid
                  className={`${paletteMode === 'light' ? 'rdg-light' : 'rdg-dark'} ${filters.enabled ? filterContainerClassname : undefined}`}
                  columns={filteredColumns}
                  rows={filteredRows}
                  headerRowHeight={filters.enabled ? 70 : undefined}
                  direction={langDirection === "rtl" ? "rtl" : "ltr"}

                  style={{ flexGrow: 1 }}

                  sortColumns={sortColumns}
                  onSortColumnsChange={onSortColumnsChange}
                />
              </FilterContext.Provider>
            </DndProvider>
          </Box>
        </>
      )}
    </Container>
  )
}



interface DraggableHeaderRendererProps<R, SR> extends HeaderRendererProps<R, SR> {
  onColumnsReorder: (sourceKey: string, targetKey: string) => void;
}


function FilterRenderer<R, SR, T extends HTMLOrSVGElement>({
  onColumnsReorder,
  isCellSelected,
  column,
  children,
  ...props
}: DraggableHeaderRendererProps<R, SR> & {
  children: (args: {
    ref: React.RefObject<T>;
    tabIndex: number;
    filters: Filter;
  }) => React.ReactElement;
}) {
  const filters = useContext(FilterContext)!;
  const { ref, tabIndex } = useFocusRef<T>(isCellSelected);
  const [{ isDragging }, drag] = useDrag({
    type: 'COLUMN_DRAG',
    item: { key: column.key },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  });

  const [{ isOver }, drop] = useDrop({
    accept: 'COLUMN_DRAG',
    drop({ key }: { key: string }) {
      onColumnsReorder(key, column.key);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  });

  return (
    <>
      <div
        ref={(ref) => {
          drag(ref);
          drop(ref);
        }}
        style={{
          opacity: isDragging ? 0.5 : 1,
          backgroundColor: isOver ? '#ececec' : undefined,
          cursor: 'move'
        }}
      >
        {filters!.enabled && <div>{children({ ref, tabIndex, filters })}</div>}
      </div>
    </>
  );
}


