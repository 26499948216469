import React, { useMemo, useState } from 'react'

import { Autocomplete, AutocompleteChangeReason, Button, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide, TextField } from '@mui/material'
import { useNavigate } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { selectDirection, selectTranslations } from "../../features/i18n/i18nSlice";
import { db } from '../../db';
import { Customer } from '../../models/Customer';



export const useSelectCustomerDialog = (onCustomerSelected: Function) => {
  const [openDialog, setOpenDialog] = useState(false);
  const trans = useAppSelector(selectTranslations);
  const [dialogReturn, setDialogReturn] = useState('');
  const [customers, setCustomers] = React.useState<Customer[]>([]);
  const [selectedCustomerId, setSelectedCustomerId] = React.useState<number>();
  const [selectedCustomer, setSelectedCustomer] = React.useState<Customer>();


  const customersDefaultProps = {
    options: customers,
    getOptionLabel: (option: Customer) => option.fullname,
};

const onCustomerAutoCompleteChange = (event: React.SyntheticEvent<Element, Event>, value: Customer | null, reason: AutocompleteChangeReason) => {
  setSelectedCustomer(value!)
  setSelectedCustomerId(value?.id!)
};





  const onDialogClickYes = () => {
    setOpenDialog(false);
    onCustomerSelected(selectedCustomer)
  };


  const onDialogClickNo = () => {
    setOpenDialog(false);

  };

  const onDialogOpen = async (currentCustomerId: number) => {

    const customerList = await db.customer.toArray()
    setCustomers(customerList)
    const selectedCustomerObject = await db.customer.get({id: currentCustomerId})
    setSelectedCustomerId(currentCustomerId)
    setSelectedCustomer(selectedCustomerObject)
    setDialogReturn('')
    setOpenDialog(true);
  };


  const SelectDialog = useMemo(() => {
    return () => (
      <>
      <DialogTitle>{trans.new_invoice.dialog.change_customer_title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          {trans.new_invoice.dialog.change_customer_message}
        </DialogContentText>
        <Autocomplete
                sx={{ flexGrow: 1, margin: "4px" }}
                {...customersDefaultProps}
                // options={customers}
                id="customerselect"
                autoHighlight
                limitTags={10}
                onChange={onCustomerAutoCompleteChange}
                getOptionLabel={(option) => {
                  return option.fullname || ""
                }}
                value={customers.find(customer => customer.id === selectedCustomerId) ?? null}
                isOptionEqualToValue={(option, value) => {
                  // console.log(value)
                  if (value !== null) {
                    return option?.id === value?.id;
                  } else {
                    return false;
                  }
                }}
                // isOptionEqualToValue={(option, value) =>
                //   option.iso === value.iso
                // }
                renderInput={(params) => (
                  <TextField {...params} label={trans.new_invoice.invoice_customer} size="small" />
                  
                  )}
                  />
      </DialogContent>
      <DialogActions>
        <Button onClick={onDialogClickNo}>{trans.new_invoice.dialog.no_button}</Button>
        <Button onClick={onDialogClickYes}>{trans.new_invoice.dialog.yes_button}</Button>
      </DialogActions>
      </>
   )
  }, [openDialog, onDialogClickNo, onDialogClickYes])


  return {
    openDialog,
    setOpenDialog,
    dialogReturn,
    SelectDialog,
    onDialogOpen: (currentCustomerId: number) => onDialogOpen(currentCustomerId)
  };
};