import { InvoiceLine } from "./InvoiceLine";

export interface Invoice {
  id: number;
  issuer: { [key: string]: any };
  receiver: { [key: string]: any };
  documentType: string | null;
  documentTypeVersion: string | null;
  dateTimeIssued: string; //Date | null;
  taxpayerActivityCode: string | null;
  internalID: string | null;
  purchaseOrderReference: string | null;
  purchaseOrderDescription: string | null;
  salesOrderReference: string | null;
  salesOrderDescription: string | null;
  proformaInvoiceNumber: string | null;
  payment: { [key: string]: any };
  delivery: { [key: string]: any };
  invoiceLines: InvoiceLine[];
  totalDiscountAmount: number;
  totalSalesAmount: number;
  netAmount: number;
  taxTotals: { [key: string]: any };
  totalAmount: number;
  extraDiscountAmount: number;
  totalItemsDiscountAmount: number;
  // signatures: [];
  externalId: number;
  signed: boolean;
  invoice_type: string;
  sent: boolean;
  cancelled: boolean;

}


export enum InvoiceDiscountModeEnum {
  PerInvoice = 1  ,
  PerItem = 2
}
export interface InvoiceDiscountMode {
  id: number;
  discount_mode_name: string;
}


export enum InvoiceTaxModeEnum {
  PerInvoice = 1  ,
  PerItem = 2
}

export interface InvoiceTaxMode {
  id: number;
  tax_mode_name: string;
}

export enum DraftMode {
  DraftSaving = 1,
  WithouDrafts = 2,
}