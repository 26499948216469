import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { selectDirection, selectTranslations } from "../i18n/i18nSlice";
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { customerCallStatus, customerData, initialBalance, setInitialBalance, setCustomerData, customerTypeList, addCustomerData, resetCustomerData, getCustomerTypeList } from './customerSlice'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux';
import { height } from '@mui/system';
import LinearProgress from '@mui/material/LinearProgress';
import SaveIcon from '@mui/icons-material/Save';
import { AppBar, Divider, FormControl, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, Toolbar } from '@mui/material';




type Props = {}
const theme = createTheme();

export default function AddCustomer() {

  const navigate = useNavigate();

  const [customerId, setCustomerId] = React.useState(0)
  const [newForm, setNewForm] = React.useState<boolean>(true);

  const [changesNotSaved, setChangesNotSaved] = React.useState<boolean>(false);

  // const isOnline = navigator.onLine
  const dispatch = useAppDispatch();
  const apiCallStatus = useAppSelector(customerCallStatus);
  const customerObject = useAppSelector(customerData);
  const customerTypeListData = useAppSelector(customerTypeList);
  const initialBalanceValue = useAppSelector(initialBalance);
  const isLoading = (status: any) => status === "loading";
  const isSucceeded = (status: any) => status === "succeeded";
  const isIdle = (status: any) => status === "idle";
  const trans = useAppSelector(selectTranslations);




  const onInputValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setCustomerData({ ...customerObject, [event.target.name]: event.target.value }))
    setChangesNotSaved(true)
  }
  const onInitialBalanceValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setInitialBalance(parseFloat(event.target.value)))
    setChangesNotSaved(true)
  }

  const onSelectChange = (event: SelectChangeEvent<number>, child: React.ReactNode) => {
    dispatch(setCustomerData({ ...customerObject, [event.target.name]: event.target.value }))
    setChangesNotSaved(true)
  };

  const onAdd = () => {
    dispatch(addCustomerData())
  }


  React.useEffect(() => {
    dispatch(resetCustomerData())
    dispatch(getCustomerTypeList())
  }, [])

  React.useEffect(() => {
    if (customerObject.id! > 0 && !newForm) {
      setCustomerId(customerObject.id!)
    } 
      
  }, [customerObject.id])


  React.useEffect(() => {
    if(newForm){
      setNewForm(false)
    }else{
      if (isSucceeded(apiCallStatus) && customerId > 0) {
        navigate("/customer/" + customerObject.id!.toString());
      }
    }
    
  }, [apiCallStatus, customerId])




  return (
    <ThemeProvider theme={theme}>
      <Container>
      <AppBar position="static" sx={{ backgroundColor: "#283593" }}>
        <Toolbar component="nav" variant="dense" sx={{ justifyContent: 'space-between', overflowX: 'auto' }}  >
          <Typography component="h2" variant="h5">
          {trans.customer_form.add_title}
          </Typography>
        </Toolbar>
      </AppBar>

        
        {(isLoading(apiCallStatus)) && <LinearProgress />}
        {(isSucceeded(apiCallStatus) || isIdle(apiCallStatus)) && (
          <>
            <Grid container>
              <Grid item sm={12} md={6} lg={6} padding={2}>
                <Box sx={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                  <TextField id="customer_name" name="fullname" label={trans.customer_form.fullname} variant="standard" size='small' onChange={onInputValueChange} value={customerObject.fullname} required />
                  <TextField id="mobile" name="mobile" label={trans.customer_form.mobile} variant="standard" size='small' onChange={onInputValueChange} value={customerObject.mobile} />
                  <TextField id="phone" name="phone" label={trans.customer_form.phone} variant="standard" size='small' onChange={onInputValueChange} value={customerObject.phone} />
                  <TextField id="email" name="email" type='email' label={trans.customer_form.email} variant="standard" size='small' onChange={onInputValueChange} value={customerObject.email} />
                  <TextField id="address" name="address" label={trans.customer_form.address} variant="standard" size='small' onChange={onInputValueChange} value={customerObject.address} />
                  <TextField id="initial_balance" name="initial_balance" type='number' label={trans.customer_form.initial_balance} variant="standard" size='small' onChange={onInitialBalanceValueChange} value={initialBalanceValue} />
                  { customerTypeListData.length > 0 ?
                  <FormControl variant="standard" fullWidth>
                    <InputLabel htmlFor="customer_type" >{trans.customer_form.type}</InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="customer_type"
                      // defaultValue={'P'}
                      value={customerObject.customer_type!}
                      name="customer_type"

                      label="Type"
                      // onChange={handleChange}
                      onChange={onSelectChange}

                      size='small'
                      sx={{ width: "100%" }}
                    >
                      {customerTypeListData.map(customerType => (<MenuItem value={customerType.id} key={customerType.id}>{customerType.customer_type_name}</MenuItem>))}
                    </Select>

                  </FormControl>: null}
                </Box>
              </Grid>
              <Grid item sm={12} md={6} lg={6} padding={2}>
                <Box sx={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                </Box>
              </Grid>
            </Grid>

            <Paper elevation={1} sx={{ marginY: 2 }}>
              <Box sx={{ display: 'flex', flexWrap: 'wrap', padding: 2, justifyContent: 'space-around' }}>
                <Button variant="contained" startIcon={<SaveIcon sx={{ marginRight: 0, marginLeft: 1 }} />} onClick={onAdd} sx={{ margin: '2px' }} size="small" disabled={!changesNotSaved}>
                  {trans.customer_form.add_button}
                </Button>
              </Box>
            </Paper>

          </>)}
      </Container>
    </ThemeProvider>
  );
}