import React from 'react'
import './PrintForm.css';
import { Box, Container, Typography } from '@mui/material';
import { Invoice02 } from '../../models/Invoice02';

import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { selectDirection, selectTranslations } from "../../features/i18n/i18nSlice";


const pageStyle = `
  @page {
    size: 80mm 50mm;
  }

  @media all {
    .pagebreak {
      display: none;
    }
  }

  @media print {
    .pagebreak {
      page-break-before: always;
    }
  }
`;

type Props = {
  invoice: Invoice02;
};

export default function PrintForm({ invoice }: Props) {
  const trans = useAppSelector(selectTranslations);

  const marginTop = "10px"
  const marginRight = "5px"
  const marginBottom = "0px"
  const marginLeft = "5px"
  const getPageMargins = () => {
    return `@page { margin: ${marginTop} ${marginRight} ${marginBottom} ${marginLeft} !important; }`;
  };

  return (
    <>
      <style>{getPageMargins()}</style>
      <Container maxWidth={false} sx={{margin:0, padding:0}}>

        {/* header */}
        <Box sx={{ backgroundColor: '#f3f3f3', display: 'flex', flexDirection: 'row', }}>
          <Box sx={{ flexGrow: 1 }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>

              <Box >
                <Typography variant='h5'>
                  {"مركز الفادي للدهانات"}
                </Typography>
              </Box>

              <Box>

                <Typography variant='h5'>
                  {"El Fady Center For Paints"}
                </Typography>
              </Box>

              <Box>

                <Typography variant='subtitle2'>
                  {"01000041872 - 0842204658"}
                </Typography>
              </Box>


            </Box>
          </Box>
          <Box>
            <img src="/elfady.png" height={120} style={{ margin: 5 }} />
          </Box>
        </Box>


        <Box sx={{ marginTop: 6, display: 'flex', flexDirection: 'row', }}>
          <Typography variant='body1'>
            {trans.new_invoice.invoice_customer}: {invoice.customer_name}
          </Typography>
        </Box>

        <Box sx={{ marginTop: 3, backgroundColor: '#f3f3f3', display: 'flex', flexDirection: 'row', }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant='body1'>
              {invoice.invoice_date.split('T')[0]}
            </Typography>
          </Box>
          <Box>
            <Typography variant='body1'>
              No: {invoice.id}
            </Typography>
          </Box>
        </Box>

        <Box sx={{marginTop: 2}}>
          <table style={{ border: "1px solid rgb(0, 0, 0)", borderCollapse: "collapse", width: '100%'}}>
            <thead>
              <tr>
                <th style={{ border: "1px solid rgb(0, 0, 0)", borderCollapse: "collapse" }}>{trans.new_invoice.table.product}</th>
                <th style={{ border: "1px solid rgb(0, 0, 0)", borderCollapse: "collapse" }}>{trans.new_invoice.table.count}</th>
                <th style={{ border: "1px solid rgb(0, 0, 0)", borderCollapse: "collapse" }}>{trans.new_invoice.table.unit}</th>
                <th style={{ border: "1px solid rgb(0, 0, 0)", borderCollapse: "collapse" }}>{trans.new_invoice.table.price}</th>
                <th style={{ border: "1px solid rgb(0, 0, 0)", borderCollapse: "collapse" }}>{trans.new_invoice.table.total}</th>
              </tr>
            </thead>
            <tbody style={{ border: "1px solid rgb(0, 0, 0)" }}>
              {invoice.invoice_items.map((row, i) => (
                <tr key={i}>
                  <td style={{ border: "1px solid rgb(0, 0, 0)", borderCollapse: "collapse" }}>{row.product_name}</td>
                  <td style={{ border: "1px solid rgb(0, 0, 0)", borderCollapse: "collapse" }}>{row.quantity}</td>
                  <td style={{ border: "1px solid rgb(0, 0, 0)", borderCollapse: "collapse" }}>{row.unit_name}</td>
                  <td style={{ border: "1px solid rgb(0, 0, 0)", borderCollapse: "collapse" }}>{row.unit_price}</td>
                  <td style={{ border: "1px solid rgb(0, 0, 0)", borderCollapse: "collapse" }}>{row.total}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Box>

        <Box sx={{ marginTop: 2 }}>
          <table >
            <tbody style={{ border: "1px solid rgb(0, 0, 0)", borderCollapse: "collapse" }}>
              {invoice.salesTotal > 0 ?
                <tr key={trans.new_invoice.totals.salesTotal}>
                  <td >{trans.new_invoice.totals.salesTotal}</td>
                  <td >{invoice.salesTotal.toFixed(2)}</td>
                </tr> : <></>
              }

              {invoice.discount > 0 ?
                <tr key={trans.new_invoice.totals.discount}>
                  <td >{trans.new_invoice.totals.discount}</td>
                  <td >{invoice.discount.toFixed(2)}</td>
                </tr> : <></>
              }


              {invoice.tax > 0 ?
                <tr key={trans.new_invoice.totals.tax}>
                  <td >{trans.new_invoice.totals.tax}</td>
                  <td >{invoice.tax.toFixed(2)}</td>
                </tr> : <></>
              }


              {invoice.salesTotal > 0 ?
                <tr key={trans.new_invoice.totals.total} style={{ borderTop: "1px solid rgb(0, 0, 0)", borderCollapse: "collapse" }}>
                  <td style={{ borderTop: "1px solid rgb(0, 0, 0)", borderCollapse: "collapse" }}>{trans.new_invoice.totals.total}</td>
                  <td style={{ borderTop: "1px solid rgb(0, 0, 0)", borderCollapse: "collapse" }}>{invoice.total.toFixed(2)}</td>
                </tr> : <></>
              }
            </tbody>
          </table>
        </Box>


      </Container>
    </>
  )
}
