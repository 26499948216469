import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { selectDirection, selectTranslations } from "../i18n/i18nSlice";
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { productCallStatus, productData, setProductData, addProductData, resetProductData, productUnits, addproductUnits, setproductUnits, productPricing, getPriceList, priceList, setproductPricing, getProductTypeList, productTypeList, getProductCategoryList, productCategoryList } from './productSlice'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux';
import { height } from '@mui/system';
import LinearProgress from '@mui/material/LinearProgress';
import SaveIcon from '@mui/icons-material/Save';
import { Divider, FormControl, InputLabel, MenuItem, Paper, Select, SelectChangeEvent } from '@mui/material';
import ProductUnits from './ProductUnits';
import ProductPricing from './ProductPricing';
import { ProductUnit, PriceItem, Colors, Sizes } from '../../models/ProductCatalog';
import { db } from '../../db';





const theme = createTheme();

export default function AddProductWithCS() {

  const navigate = useNavigate();

  const [productId, setProductId] = React.useState(0)
  const [colorList, setColorList] = React.useState<Colors[]>([])
  const [sizeList, setSizeList] = React.useState<Sizes[]>([])

  const [expireDetermined, setExpireDetermined] = React.useState(true)

  const [changesNotSaved, setChangesNotSaved] = React.useState<boolean>(false);

  // const isOnline = navigator.onLine
  const dispatch = useAppDispatch();
  const apiCallStatus = useAppSelector(productCallStatus);
  const productObject = useAppSelector(productData);
  const productUnitsData = useAppSelector(productUnits)
  const productPricingData = useAppSelector(productPricing);
  const priceListData = useAppSelector(priceList);
  const productTypeListData = useAppSelector(productTypeList);
  const productCategoryListData = useAppSelector(productCategoryList);
  const isLoading = (status: any) => status === "loading";
  const isSucceeded = (status: any) => status === "succeeded";
  const isIdle = (status: any) => status === "idle";
  const isFailed = (status: any) => status === "failed";
  const trans = useAppSelector(selectTranslations);




  const loadLookups = async () => {
    const colors = await db.colors.toArray()
    setColorList(colors)
    const sizes = await db.sizes.toArray()
    setSizeList(sizes)
  };

  const generatePriceListItems: () => { [key: string]: PriceItem } = () => {

    // return priceListData.map(item => ({...item, price: 0}))

    if (Object.keys(productPricingData).length > 0) return productPricingData;

    var result = priceListData.reduce<{ [key: string]: PriceItem }>((map, obj) => {
      let { id, ...nitem } = obj
      map[obj.id] = { ...nitem, price: 0, id: 0 };
      return map;
    }, {});
    return result
  }




  const onInputValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setProductData({ ...productObject, [event.target.name]: event.target.value }))
    setChangesNotSaved(true)
  }

  const onCheckChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setProductData({ ...productObject, [event.target.name]: event.target.checked }))
    setChangesNotSaved(true)
    if(event.target.name === 'has_expire_date'){
      setExpireDetermined(false)
    }
  };

  // const handleChange = (event: { target: { value: string } }) => {
  const onSelectChange = (event: SelectChangeEvent<number>, child: React.ReactNode) => {
    dispatch(setProductData({ ...productObject, [event.target.name]: event.target.value }))
    setChangesNotSaved(true)
  };

  const onAdd = () => {
    dispatch(addProductData())
  }


  const addNewUnit = (productUnit: ProductUnit) => {
    dispatch(addproductUnits(productUnit))
  }

  const setNewProductUnits = (productUnits: ProductUnit[]) => {
    dispatch(setproductUnits(productUnits))
  }

  const updatePricing = (pricing: { [key: string]: PriceItem }) => {
    dispatch(setproductPricing(pricing))
  }


  React.useEffect(() => {
    loadLookups()
    setProductId(0)
    dispatch(resetProductData())
    dispatch(getPriceList())
    dispatch(getProductTypeList())
    dispatch(getProductCategoryList())
  }, [])

  React.useEffect(() => {
    if (productObject.id! > 0) setProductId(productObject.id!)
  }, [productObject.id])


  React.useEffect(() => {
    if (productObject.id! > 0) setProductId(productObject.id!)
  }, [productObject.id])

  React.useEffect(() => {
    if (isSucceeded(apiCallStatus) && productId > 0 && productObject.id > 0) {
      navigate("/product/" + productObject.id!.toString());
    }
  }, [apiCallStatus, productId])




  return (
      <Container>
        <Typography variant='h5'>{trans.product_form.add_title}</Typography>
        <Divider />
        {(isLoading(apiCallStatus)) && <LinearProgress />}
        {(isSucceeded(apiCallStatus) || isIdle(apiCallStatus) || isFailed(apiCallStatus)) && (
          <>
            <Grid container>
              <Grid item sm={12} md={6} lg={6} padding={2}>
                <Box sx={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', justifyContent: 'space-between' }}>

                  <TextField id="product_name" name="product_name" label={trans.product_form.product_name} variant="standard" size="small" onChange={onInputValueChange} value={productObject.product_name} required />
                  <TextField id="international_code" name="international_code" label={trans.product_form.international_code} variant="standard" size="small" onChange={onInputValueChange} value={productObject.international_code} required />
                  <TextField id="user_defined_code" name="user_defined_code" label={trans.product_form.user_defined_code} variant="standard" size="small" onChange={onInputValueChange} value={productObject.user_defined_code} required />
                  {/* <TextField id="product_type" name="product_type" label={trans.product_form.product_type} variant="standard" size="small" onChange={onInputValueChange} value={productObject.product_type} required /> */}
                  {/* <TextField id="product_category" name="product_category" label={trans.product_form.product_category} variant="standard" size="small" onChange={onInputValueChange} value={productObject.product_category} required /> */}


                  {productCategoryListData.length > 0 ?
                  <FormControl variant="standard" fullWidth>
                    <InputLabel id="demo-simple-select-standard-label" >{trans.product_form.product_category}</InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="product_category"
                      // defaultValue={'P'}
                      value={productObject.product_category!}
                      name="product_category"

                      label="Type"
                      onChange={onSelectChange}

                      size='small'
                      sx={{ width: "100%" }}
                    >
                      {productCategoryListData.map(productCategory => (<MenuItem value={productCategory.id} key={productCategory.id}>{productCategory.category_name}</MenuItem>))}
                    </Select>
                  </FormControl>
                  : <></>}

                {productTypeListData.length > 0 ?
                  <FormControl variant="standard" fullWidth>
                    <InputLabel id="demo-simple-select-standard-label" >{trans.product_form.product_type}</InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="product_type"
                      // defaultValue={'P'}
                      value={productObject.product_type!}
                      name="product_type"

                      label="Type"
                      // onChange={handleChange}
                      onChange={onSelectChange}

                      size='small'
                      sx={{ width: "100%" }}
                    >
                      {productTypeListData.map(productType => (<MenuItem value={productType.id} key={productType.id}>{productType.type_name}</MenuItem>))}
                    </Select>

                  </FormControl>
                  : <></>}



                {colorList.length ?
                  <FormControl variant="standard" fullWidth>
                    <InputLabel id="demo-simple-select-standard-label" >{trans.product_form.product_color}</InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="color_id"
                      // defaultValue={'P'}
                      value={productObject.color_id!}
                      name="color_id"

                      label="Type"
                      onChange={onSelectChange}

                      size='small'
                      sx={{ width: "100%" }}
                    >
                      {colorList.map(item => (<MenuItem value={item.id} key={item.id}>{item.color_name}</MenuItem>))}
                    </Select>
                  </FormControl>

                  : <></>}
                {sizeList.length > 0 ?
                  <FormControl variant="standard" fullWidth>
                    <InputLabel id="demo-simple-select-standard-label" >{trans.product_form.product_size}</InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="size_id"
                      // defaultValue={'P'}
                      value={productObject.size_id!}
                      name="size_id"

                      label="Type"
                      onChange={onSelectChange}

                      size='small'
                      sx={{ width: "100%" }}
                    >
                      {sizeList.map(item => (<MenuItem value={item.id} key={item.id}>{item.size_name}</MenuItem>))}
                    </Select>
                  </FormControl>
                  : <></>}



                </Box>
              </Grid>
              <Grid item sm={12} md={6} lg={6} padding={2}>
                <Box sx={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                  {/* <TextField id="is_medicine" name="is_medicine" label={trans.product_form.is_medicine} variant="standard" size="small" onChange={onInputValueChange} value={productObject.is_medicine} required /> */}
                  <FormControlLabel label={trans.product_form.is_medicine} control={<Checkbox name="is_medicine" checked={productObject.is_medicine} onChange={onCheckChanged} tabIndex={-1} disableRipple />} />
                  <FormControlLabel label={trans.product_form.is_freez} control={<Checkbox name="is_freez" checked={productObject.is_freez} onChange={onCheckChanged} tabIndex={-1} disableRipple />} />
                  {/* <TextField id="is_freez" name="is_freez" label={trans.product_form.is_freez} variant="standard" size="small" onChange={onInputValueChange} value={productObject.is_freez} required /> */}
                  <FormControlLabel label={trans.product_form.is_returnable} control={<Checkbox name="is_returnable" checked={productObject.is_returnable} onChange={onCheckChanged} tabIndex={-1} disableRipple />} />
                  {/* <TextField id="is_returnable" name="is_returnable" label={trans.product_form.is_returnable} variant="standard" size="small" onChange={onInputValueChange} value={productObject.is_returnable} required /> */}
                  <FormControlLabel label={trans.product_form.has_expire_date} control={<Checkbox name="has_expire_date" checked={productObject.has_expire_date} indeterminate={expireDetermined} onChange={onCheckChanged} tabIndex={-1} disableRipple required />} />

                  <TextField id="default_expiry_days" name="default_expiry_days" type="number" label={trans.product_form.default_expiry_days} variant="standard" size="small" onChange={onInputValueChange} value={productObject.default_expiry_days} required />
                  <TextField id="min_quantity" name="min_quantity" type='number' label={trans.product_form.min_quantity} variant="standard" size='small' onChange={onInputValueChange} value={productObject.min_quantity} />
                  <TextField id="max_quantity" name="max_quantity" type='number' label={trans.product_form.max_quantity} variant="standard" size='small' onChange={onInputValueChange} value={productObject.max_quantity} />
                  <TextField id="einv_code" name="einv_code" label={trans.product_form.einv_code} variant="standard" size="small" onChange={onInputValueChange} value={productObject.einv_code} required />

                </Box>
              </Grid>
            </Grid>
            <Typography variant='h6'>{trans.product_form.product_price_list}</Typography>
            <Divider />
            <ProductPricing priceList={generatePriceListItems()} updatePricing={updatePricing} />
            <Typography variant='h6'>{trans.product_form.product_units}</Typography>
            <Divider />
            <ProductUnits prodctUnits={productUnitsData} addproductUnits={addNewUnit} setNewProductUnits={setNewProductUnits} />

            <Paper elevation={1} sx={{ marginY: 2 }}>
              <Box sx={{ display: 'flex', flexWrap: 'wrap', padding: 2, justifyContent: 'space-around' }}>
                <Button variant="contained" startIcon={<SaveIcon sx={{ marginRight: 0, marginLeft: 1 }} />} onClick={onAdd} sx={{ margin: '2px' }} size="small" disabled={!changesNotSaved || expireDetermined}>
                  {trans.product_form.add_button}
                </Button>
              </Box>
            </Paper>

          </>)}
      </Container>
  );
}