import { useState, useEffect } from 'react';
import { db } from '../../db'
import { Order } from '../../models/Order';
import { Invoice02 } from '../../models/Invoice02';


const useDraftList = (): Invoice02[] => {

  const [draftList, setDraftList] = useState<Invoice02[]>([])



  const getList = async () => {
    //
    // Query Dexie's API
    //
    const list = await db.invoiceDraft.toArray();

    // Return result
    setDraftList(list)
  }

  useEffect(() => {
    // if(syncDone) getList()
    getList()
  }, [])

  return draftList
}

export default useDraftList
