import { AppBar, Box, Card, CardActionArea, CardContent, Container, Dialog, Grid, LinearProgress, Paper, Toolbar, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useAppSelector, useAppDispatch } from '../../../app/hooks'
import { selectDirection, selectTranslations } from '../../../features/i18n/i18nSlice'
import DashboardCard from './DashboardCard';
import { useNavigate } from 'react-router-dom';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import DvrIcon from '@mui/icons-material/Dvr';
import { refreshDB, actionCallStatus, currentPos } from '../../../features/login/loginSlice';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { POS } from '../../../models/POS';
import { useYesNoDialog } from './useYesNoDialog';




// import { selectDirection, selectTranslations } from "../../features/i18n/i18nSlice";
// import { useAppSelector, useAppDispatch } from "../../app/hooks";

const theme = createTheme();

export default function SettingsDashboard() {

    const trans = useAppSelector(selectTranslations)
    const langDirection = useAppSelector(selectDirection);
    const pos = useAppSelector(currentPos);

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const apiCallStatus = useAppSelector(actionCallStatus);
    const isLoading = (status: any) => status === "loading";
    const isSucceeded = (status: any) => status === "succeeded";
    const isIdle = (status: any) => status === "idle";
    const isFailed = (status: any) => status === "failed";

    const { YesNoDialog, onDialogOpen } = useYesNoDialog()

    // Reset password  Dialog

    //   const {
    //     openDialog: showResetPasswordDialog,
    //     setOpenDialog: setShowResetPasswordDialog,
    //     SelectDialog: ResetPasswordDialog,
    //     onDialogOpen: onResetPasswordDialogOpen } = useResetPasswordDialog()


    return (
        <ThemeProvider theme={theme}>
            <Container>


                {(isLoading(apiCallStatus)) && <LinearProgress />}
                {(isSucceeded(apiCallStatus) || isIdle(apiCallStatus)) && (
                    <>
                        <Paper>

                            <Grid container>
                                <DashboardCard itemColor="#c0ca33" itemName={trans.dashboards.privilages_list} onClick={() => navigate("/users/roles")} >
                                    <img src="privilage.png" height={60} style={{ margin: 5 }} />
                                    {/* <CategoryIcon sx={{ fontSize: 80, color: 'whitesmoke' }} ></CategoryIcon> */}
                                </DashboardCard>
                                {(pos === null) && <DashboardCard itemColor="#40c4ff" itemName={trans.dashboards.assign_pos} onClick={() => navigate("/settings/pos")} >
                                    <DvrIcon sx={{ fontSize: 70, color: 'whitesmoke' }} ></DvrIcon>
                                </DashboardCard>}

                                {(pos !== null) && <DashboardCard itemColor="#ef9a9a" itemName={trans.dashboards.unassign_pos} onClick={ () => onDialogOpen()} >
                                    <DvrIcon sx={{ fontSize: 70, color: 'whitesmoke' }} ></DvrIcon>
                                </DashboardCard>}

                                <DashboardCard itemColor="#2196f3" itemName={trans.dashboards.users_logs} onClick={() => onDialogOpen} >
                                    <PointOfSaleIcon sx={{ fontSize: 70, color: 'whitesmoke' }} ></PointOfSaleIcon>
                                </DashboardCard>
                            </Grid>
                        </Paper>
                    </>
                )}


                <YesNoDialog />
            </Container>
        </ThemeProvider>

    )
}
