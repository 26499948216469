import React, { useState, useEffect } from "react"
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import {hideVerification} from './loginSlice'

export default function CountDown() {
  const dispatch = useAppDispatch();
  const timer = React.useRef<number | undefined>(undefined);

    const secondsToTime = (secs: number) => {
        let hours = Math.floor(secs / (60 * 60));
    
        let divisor_for_minutes = secs % (60 * 60);
        let minutes = Math.floor(divisor_for_minutes / 60);
    
        let divisor_for_seconds = divisor_for_minutes % 60;
        let seconds = Math.ceil(divisor_for_seconds);
    
        let obj = {
          "h": hours,
          "m": minutes,
          "s": seconds
        };
        return obj;
      }


    const [currentSeconds, setCurrentSeconds] = useState(120)
    const [currentTime, setCurrentTime] = useState(secondsToTime(120))
    
      const countDown = () => {
        // Remove one second, set state so a re-render happens.
        let seconds = currentSeconds - 1;
        if(currentSeconds > 0){
        setCurrentSeconds(seconds)
        setCurrentTime(secondsToTime(seconds))
        }
        if(currentSeconds===0){
          dispatch(hideVerification())
        }
        // Check if we're at zero.
        // if (seconds == 0) { 
        //   clearInterval(this.timer);
        // }
      }

      const startTimer = () => {
        if (currentSeconds >= 0) {
          timer.current = window.setInterval(countDown, 1000);
        }
      }

      const pad = (num: number, size: number) => {
        let numStr = num.toString();
        while (numStr.length < size) numStr = "0" + numStr;
        return num;
    }

      useEffect(() => {
        startTimer()
        
        return () => window.clearInterval(timer.current);
        // if (currentSeconds === 0){
        //     return () => clearInterval(interval);
        // }
        
      }, [currentSeconds]);
    
  return (
    <>{currentSeconds>=0?<div>{pad(currentTime.m,2)}:{pad(currentTime.s,2)}</div>:null}</>
  )
}
