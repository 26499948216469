import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid2 from '@mui/material/Grid2';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import DeleteIcon from '@mui/icons-material/Delete';

import { selectDirection, selectTranslations } from "../i18n/i18nSlice";
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux';
import { height } from '@mui/system';
import LinearProgress from '@mui/material/LinearProgress';
import { Dialog, Divider, FormControl, IconButton, InputLabel, MenuItem, Paper, Select, SelectChangeEvent } from '@mui/material';
import { ProductUnit, PriceItem, Colors, Sizes } from '../../models/ProductCatalog';



import SaveIcon from '@mui/icons-material/Save';
import { useParams } from 'react-router-dom';
import CustomizedMenus, { StyledMenuItemData } from '../../components/StyledMenu';
import DateRangeDialog from '../customer/DateRangeDialog';
import { useCallback } from 'react';
import { nanoid } from 'nanoid';
import { commissionCallBusyCount, commissionCallStatus, employeeData, getEmployee, setEmployeeData } from './commissionSlice';





const theme = createTheme();

export default function Employee() {
  const navigate = useNavigate();
  const params = useParams();
  const { employeeId } = params

  const [changesNotSaved, setChangesNotSaved] = React.useState<boolean>(false);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  // console.log(selectionModel)

  const dispatch = useAppDispatch();
  const apiCallStatus = useAppSelector(commissionCallStatus);
  const apiStatusBusyCount = useAppSelector(commissionCallBusyCount);
  const employeeObject = useAppSelector(employeeData);

  const isLoading = React.useMemo(() => (apiCallStatus === "loading" || apiStatusBusyCount > 0), [apiCallStatus, apiStatusBusyCount]);
  const isSucceeded = React.useMemo(() => (apiCallStatus === "succeeded" && apiStatusBusyCount === 0), [apiCallStatus, apiStatusBusyCount]);
  const isIdle = React.useMemo(() => (apiCallStatus === "idle" && apiStatusBusyCount === 0), [apiCallStatus, apiStatusBusyCount]);
  const isFailed = React.useMemo(() => (apiCallStatus === "failed" && apiStatusBusyCount === 0), [apiCallStatus, apiStatusBusyCount]);

  const trans = useAppSelector(selectTranslations);
  const langDirection = useAppSelector(selectDirection);


  const loadLookups = async () => {
    // const colors = await db.colors.toArray()
    // setColorList(colors)
    // const sizes = await db.sizes.toArray()
    // setSizeList(sizes)
    // const storeHouse = await db.storeHouse.toArray()
    // setStoreHouseList(storeHouse)
  };



  const onInputValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setEmployeeData({ ...employeeObject, [event.target.name]: event.target.value }))
    setChangesNotSaved(true)
  }

  const onUpdate = () => {
    // dispatch(updateProductData())
    // if (isSucceeded) setChangesNotSaved(false)
  }


  React.useEffect(() => {
    loadLookups()
    dispatch(getEmployee(parseInt(employeeId!)))
  }, [])






  return (
    <Container>
      <Typography variant='h5'>{trans.employee_form.title}</Typography>
      <Divider />
      {(isLoading) && <LinearProgress />}
      {(isSucceeded || isIdle || isFailed) && (
        <>
          <Grid2 container>
            <Grid2 size={{ sm: 12, md: 6, lg: 6 }} padding={2} >
              <Box sx={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', justifyContent: 'space-between' }}>

                <TextField id="fullname" name="fullname" label={trans.employee_form.fullname} variant="standard" size="small" onChange={onInputValueChange} value={employeeObject.fullname} required />
                <TextField id="mobile" name="mobile" label={trans.employee_form.mobile} variant="standard" size="small" onChange={onInputValueChange} value={employeeObject.mobile} required />
                <TextField id="phone" name="phone" label={trans.employee_form.phone} variant="standard" size="small" onChange={onInputValueChange} value={employeeObject.phone} required />
                <TextField id="email" name="email" label={trans.employee_form.email} variant="standard" size="small" onChange={onInputValueChange} value={employeeObject.email} required />
                <TextField id="address" name="address" label={trans.employee_form.address} variant="standard" size="small" onChange={onInputValueChange} value={employeeObject.address} required />
                <TextField id="salary" name="salary" label={trans.employee_form.salary} variant="standard" size="small" onChange={onInputValueChange} value={employeeObject.salary} required />
                <TextField id="balance" name="balance" label={trans.employee_form.balance} variant="standard" size="small" value={employeeObject.balance} required />
                <TextField id="comment" name="comment" label={trans.employee_form.comment} variant="standard" size="small" onChange={onInputValueChange} value={employeeObject.comment} required />

              </Box>
            </Grid2>

          </Grid2>
        </>)}


    </Container>
  );
}