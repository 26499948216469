import { AppBar, Box, Card, CardActionArea, CardContent, Container, Dialog, Grid2, LinearProgress, Paper, Toolbar, Typography } from '@mui/material'
import React from 'react'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { selectDirection, selectTranslations } from '../../../features/i18n/i18nSlice'
import InvoiceDashboardCard from './InvoiceDashboardCard';
import { useNavigate } from 'react-router-dom';


import StockDashboardCard from './StockDashboardCard';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import PersonIcon from '@mui/icons-material/Person';
import TaskIcon from '@mui/icons-material/Task';
import { currentPos, currentShift, isOnline, isUseShift, openShift, closeShift, actionCallStatus, permCashierOnly } from '../../../features/login/loginSlice';
import BarDashboardCard from './BarDashboardCard';
import SummarizeIcon from '@mui/icons-material/Summarize';
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import WatchOffIcon from '@mui/icons-material/WatchOff';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import { useGetNumValueDialog } from './useGetNumValueDialog';


// import { selectDirection, selectTranslations } from "../../features/i18n/i18nSlice";
// import { useAppSelector, useAppDispatch } from "../../app/hooks";



export default function InvoiceDashboard() {

    const apiCallStatus = useAppSelector(actionCallStatus);
    const isLoading = (status: any) => status === "loading";
    const isSucceeded = (status: any) => status === "succeeded";
    const isIdle = (status: any) => status === "idle";
    const isFailed = (status: any) => status === "failed";

    const langDirection = useAppSelector(selectDirection);
    const trans = useAppSelector(selectTranslations)
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const onlineMode = useAppSelector(isOnline);
    const pos = useAppSelector(currentPos);

    const useShift = useAppSelector(isUseShift);
    const shift = useAppSelector(currentShift);
    const isCashierOnly = useAppSelector(permCashierOnly)
    console.log(isCashierOnly)

    // Broker select Dialog
    const [initValue, setInitValue] = React.useState<number>(0);
    const onInitValueSelected = (value: number) => {
        setInitValue(value)
        dispatch(openShift(value))
    }
    const {
        openDialog: showNumValueDialog,
        setOpenDialog: setShowNumValueDialog,
        dialogReturn: dialogNumValueReturn,
        SelectDialog: SelectNumValueDialog,
        onDialogOpen: onNumValueDialogrDialogOpen } = useGetNumValueDialog(onInitValueSelected)

    const onOpenShiftClicked = () => {
        onNumValueDialogrDialogOpen(initValue)

    }





    return (
        <>
            {(isLoading(apiCallStatus)) && <LinearProgress />}
            {(isSucceeded(apiCallStatus) || isIdle(apiCallStatus) || isFailed(apiCallStatus)) && (

                <>
                    {(((pos !== null) && (!useShift || (useShift && shift !== null)))) &&
                        <Paper>

                            <Toolbar     >
                                <Typography component="h5" variant="h5" >
                                    {trans.dashboards.create_invoice}
                                </Typography>
                            </Toolbar>

                            <Grid2 container>

                                {/* <Container sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: '', backgroundColor:'blue'}} maxWidth={false}> */}
                                <InvoiceDashboardCard itemColor={trans.new_invoice.type.title_color1} itemName={trans.new_invoice.type.title_name1} onClick={() => navigate("/draft/1/0")} />

                                {!isCashierOnly ? <InvoiceDashboardCard itemColor={trans.new_invoice.type.title_color2} itemName={trans.new_invoice.type.title_name2} onClick={() => navigate("/draft/2/0")} /> : <></>}
                                <InvoiceDashboardCard itemColor={trans.new_invoice.type.title_color3} itemName={trans.new_invoice.type.title_name3} onClick={() => navigate("/draft/3/0")} />
                                {!isCashierOnly ?
                                    <>
                                        <InvoiceDashboardCard itemColor={trans.new_invoice.type.title_color6} itemName={trans.new_invoice.type.title_name6} onClick={() => navigate("/draft/6/0")} />
                                        {onlineMode &&
                                            <>
                                                <InvoiceDashboardCard itemColor={trans.new_invoice.type.title_color4} itemName={trans.new_invoice.type.title_name4} onClick={() => navigate("/draft/4/0")} />
                                                <InvoiceDashboardCard itemColor={trans.new_invoice.type.title_color7} itemName={trans.new_invoice.type.title_name7} onClick={() => navigate("/draft/7/0")} />
                                                <InvoiceDashboardCard itemColor={trans.new_invoice.type.title_color8} itemName={trans.new_invoice.type.title_name8} onClick={() => navigate("/draft/8/0")} />
                                            </>
                                        }
                                    </>
                                    : <></>
                                }
                                {/* <InvoiceDashboardCard itemColor={trans.new_invoice.type.title_color4} itemName={trans.new_invoice.type.title_name8} />
                    <InvoiceDashboardCard itemColor={trans.new_invoice.type.title_color4} itemName={trans.new_invoice.type.title_name8} />
                    <InvoiceDashboardCard itemColor={trans.new_invoice.type.title_color4} itemName={trans.new_invoice.type.title_name8} />
                    <InvoiceDashboardCard itemColor={trans.new_invoice.type.title_color4} itemName={trans.new_invoice.type.title_name8} /> */}


                                {/* <InvoiceDashboardCard itemColor={trans.new_invoice.type.title_color8} itemName={trans.new_invoice.type.title_draft_name8} /> */}




                                {/* </Container> */}
                            </Grid2>


                        </Paper>
                    }


                    <Paper sx={{ marginY: 3 }}>
                        <Grid2 container>

                            <StockDashboardCard itemColor="#64dd17" itemName={trans.drawer_sentinvoice_button} onClick={() => navigate("/invoices")} >
                                <TaskIcon sx={{ fontSize: 70, color: 'whitesmoke' }} ></TaskIcon>
                            </StockDashboardCard>
                            <StockDashboardCard itemColor="#757575" itemName={trans.drawer_drafts_button} onClick={() => navigate("/drafts")} >
                                <TextSnippetIcon sx={{ fontSize: 70, color: 'whitesmoke' }} ></TextSnippetIcon>
                            </StockDashboardCard>
                            
                            {!isCashierOnly && onlineMode ?
                                <>
                                    <StockDashboardCard itemColor="#69f0ae" itemName={trans.dashboards.invoice_customers} onClick={() => navigate("/invoice/customers")} >
                                        <PersonIcon sx={{ fontSize: 70, color: 'whitesmoke' }} ></PersonIcon>
                                    </StockDashboardCard>
                                    <StockDashboardCard itemColor="#69f0ae" itemName={trans.dashboards.invoice_customers_sales} onClick={() => navigate("/invoice/customers/sales")} >
                                        <img src="brokers.png" height={60} style={{ margin: 5 }} />
                                    </StockDashboardCard>
                                </> : <></>
                            }

                        </Grid2>
                    </Paper>
                    {onlineMode &&
                        <>
                            {!isCashierOnly ?
                                <Paper sx={{ marginY: 3 }}>
                                    <Grid2 container>
                                        <BarDashboardCard itemColor="#90a4ae" itemName={trans.dashboards.categories_sales} onClick={() => navigate("/productcategory/rep01")} >
                                            <SummarizeIcon sx={{ fontSize: 70, color: 'whitesmoke' }} ></SummarizeIcon>
                                        </BarDashboardCard>
                                        <BarDashboardCard itemColor="#90a4ae" itemName={trans.dashboards.categories_purchases} onClick={() => navigate("/productcategory/rep02")} >
                                            <SummarizeIcon sx={{ fontSize: 70, color: 'whitesmoke' }} ></SummarizeIcon>
                                        </BarDashboardCard>
                                    </Grid2>
                                </Paper>
                                : <></>}
                        </>

                    }



                    {(pos !== null && useShift) &&
                        <Paper>

                            <Toolbar     >
                                <Typography component="h5" variant="h5" >
                                    {trans.dashboards.shift_dashboard}
                                </Typography>
                            </Toolbar>

                            <Grid2 container>
                                {(shift === null) &&
                                    <BarDashboardCard itemColor="#4caf50" itemName={trans.dashboards.open_shift} onClick={() => onOpenShiftClicked()} >
                                        <MoreTimeIcon sx={{ fontSize: 70, color: 'whitesmoke' }} ></MoreTimeIcon>
                                    </BarDashboardCard>
                                }

                                {onlineMode &&
                                    <>
                                        {(shift !== null) &&
                                            <BarDashboardCard itemColor="#e53935" itemName={trans.dashboards.close_shift} onClick={() => dispatch(closeShift())} >
                                                <WatchOffIcon sx={{ fontSize: 70, color: 'whitesmoke' }} ></WatchOffIcon>
                                            </BarDashboardCard>
                                        }
                                        {!isCashierOnly ?
                                            <BarDashboardCard itemColor="#9575cd" itemName={trans.dashboards.shifts_data} onClick={() => navigate("/productcategory/rep01")} >
                                                <WorkHistoryIcon sx={{ fontSize: 70, color: 'whitesmoke' }} ></WorkHistoryIcon>
                                            </BarDashboardCard>
                                            : <></>}
                                    </>
                                }

                            </Grid2>


                        </Paper>
                    }


                    <Dialog
                        open={showNumValueDialog}
                        keepMounted
                        onClose={() => setShowNumValueDialog(false)}
                        aria-describedby="alert-dialog-slide-description"
                        sx={{ direction: langDirection }}
                    >

                        <SelectNumValueDialog />
                    </Dialog>
                </>
            )}
        </>
    )
}
