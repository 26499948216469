import { Box, Card, CardActionArea, CardContent, Grid, Typography } from '@mui/material'
import React from 'react'
import { useAppSelector } from '../../../app/hooks'
import { selectTranslations } from '../../../features/i18n/i18nSlice'
import LocalGroceryStoreIcon from '@mui/icons-material/LocalGroceryStore';


export default function InvoiceDashboardCard({ itemColor, itemName, onClick }: { itemColor: string, itemName: string, onClick: Function }) {

    const trans = useAppSelector(selectTranslations)
    

    return (
<Grid item xs={12} sm={6} md={4} lg={3} xl={3} >
        <Card sx={{ minWidth: 200, maxHeight:200, backgroundColor: itemColor, margin:2 }}>
            <CardActionArea  onClick={() => onClick()} >
                <Box display="flex"
                    justifyContent="center"
                    alignItems="center">
                    <LocalGroceryStoreIcon sx={{ fontSize: 70, color: 'whitesmoke' }} ></LocalGroceryStoreIcon>
                </Box>
            
            <CardContent>

                <Typography gutterBottom variant="h6" component="div" align='center' color='white'>
                    {itemName}
                </Typography>

            </CardContent>
            </CardActionArea>
        </Card>

        </Grid>
    )
}
