import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../../app/store';
import api from '../services/api'
// import getBrowserFingerprint from 'get-browser-fingerprint';
import { ClientJS } from 'clientjs';

import { db } from "../../db";
import Product from '../../components/product/Product';
import { PriceItem, PriceList, ProductCatalog, ProductCategory, ProductType, ProductUnit } from '../../models/ProductCatalog';
import { StoreHouse } from '../../models/StoreHouse';
import { nanoid } from 'nanoid';




export interface ProductState {
  product: ProductCatalog;
  productsReport: any[];
  priceList: PriceList[];
  productTypeList: ProductType[];
  productCategoryList: ProductCategory[];
  productTrans: any[];
  draftStock: any[];
  error: string;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  busySatusCount:number;
}



const initialState: ProductState = {
  product: {
    id: 0,
    international_code: '',
    user_defined_code: '',
    product_name: '',
    product_type: 1,
    product_category: 1,
    has_expire_date: false,
    is_medicine: false,
    is_freez: false,
    is_returnable: true,
    default_expiry_days: 365,
    color_id:0,
    size_id:1,
    einv_code: '',
    min_quantity: 1,
    max_quantity: 1,
    created_on: new Date().toLocaleString(),
    updated_on: new Date().toLocaleString(),
    update_timestamp: 0,
    pricing: {},
    product_units: [{
      id: undefined,
      product_catalog: undefined,
      unit_name: 'piece',
      is_main: true,
      is_sales_default: true,
      is_purchase_default: true,
      main_unit_quantity: 1,
    }],
    components: [],
  },
  
  productsReport: [],
  priceList: [],
  productTypeList: [],
  productCategoryList: [],
  productTrans: [],
  draftStock:[],
  error: "",
  status: 'idle',
  busySatusCount:0,
};



export const productSlice = createSlice({
  name: 'product',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {

    resetProductData: (state) => {
      state.product = initialState.product
      state.status = 'idle';
      state.busySatusCount = state.busySatusCount > 0? state.busySatusCount -1 : 0
    },

    addRequest: (state, action: PayloadAction<number>) => {
      state.product.id = action.payload;
      state.status = 'loading';
      state.busySatusCount = state.busySatusCount + 1
    },

    getProductSucceeded: (state, action: PayloadAction<ProductCatalog>) => {
      state.product = action.payload
      state.status = 'succeeded';
      state.busySatusCount = state.busySatusCount > 0? state.busySatusCount -1 : 0
    },

    updateProductSucceeded: (state, action: PayloadAction<ProductCatalog>) => {
      state.product = action.payload
      state.status = 'succeeded';
      state.busySatusCount = state.busySatusCount > 0? state.busySatusCount -1 : 0
    },

    getPriceListSucceeded: (state, action: PayloadAction<PriceList[]>) => {
      state.priceList = action.payload
      state.status = 'succeeded';
      state.busySatusCount = state.busySatusCount > 0? state.busySatusCount -1 : 0
    },

    getProductTypeListSucceeded: (state, action: PayloadAction<ProductType[]>) => {
      state.productTypeList = action.payload
      state.status = 'succeeded';
      state.busySatusCount = state.busySatusCount > 0? state.busySatusCount -1 : 0
    },

    getProductCategoryListSucceeded: (state, action: PayloadAction<ProductCategory[]>) => {
      state.productCategoryList = action.payload
      state.status = 'succeeded';
      state.busySatusCount = state.busySatusCount > 0? state.busySatusCount -1 : 0
    },

    loadFailure: (state, action: PayloadAction<string>) => {

      state.error = action.payload;
      state.status = 'failed';
      state.busySatusCount = state.busySatusCount > 0? state.busySatusCount -1 : 0
    },

    getAllProductSucceeded: (state, action: PayloadAction<any[]>) => {
      state.productsReport = action.payload;
      state.status = 'succeeded';
      state.busySatusCount = state.busySatusCount > 0? state.busySatusCount -1 : 0
    },

    setProductData: (state, action: PayloadAction<ProductCatalog>) => {
      state.product = action.payload
    },
    setproductUnits: (state, action: PayloadAction<ProductUnit[]>) => {
      state.product.product_units = action.payload
    },
    setproductPricing: (state, action: PayloadAction<{ [key: string]: PriceItem }>) => {
      state.product.pricing = action.payload
    },
    setproductComponents: (state, action: PayloadAction<any[]>) => {
      state.product.components = action.payload
    },

    setDraftStock: (state, action: PayloadAction<any[]>) => {
      state.draftStock = action.payload
    },

    clearProductTrans: (state) => {
      state.productTrans = []
    },

    getProductTransSucceeded: (state, action: PayloadAction<any[]>) => {
      state.productTrans = action.payload;
      state.status = 'succeeded';
      state.busySatusCount = state.busySatusCount > 0? state.busySatusCount -1 : 0
    },
    addproductUnits: (state, action: PayloadAction<ProductUnit>) => {
      let pieceId = 1
      state.product.product_units.forEach(item => {
        var pieceName = item.unit_name
        pieceName = pieceName.replace("piece", "")
        var isPieceNum = Number(pieceName)
        if (isPieceNum) {
          pieceId = Number(pieceName) + 1
        }

      })
      state.product.product_units.push({
        id: 0,
        product_catalog: undefined,
        unit_name: 'piece' + pieceId,
        is_main: false,
        is_sales_default: false,
        is_purchase_default: false,
        main_unit_quantity: 1,
      })
    },

  },
});

export const { addRequest, getProductSucceeded, updateProductSucceeded, loadFailure, getAllProductSucceeded, setProductData, resetProductData, setproductUnits, addproductUnits, setproductComponents, getPriceListSucceeded, setproductPricing, getProductTypeListSucceeded, getProductCategoryListSucceeded, clearProductTrans, getProductTransSucceeded, setDraftStock } = productSlice.actions;
export const productData = (state: RootState) => state.product.product;
export const productTrans = (state: RootState) => state.product.productTrans;
export const productUnits = (state: RootState) => state.product.product.product_units;
export const productPricing = (state: RootState) => state.product.product.pricing;
export const priceList = (state: RootState) => state.product.priceList;
export const productTypeList = (state: RootState) => state.product.productTypeList;
export const productCategoryList = (state: RootState) => state.product.productCategoryList;
export const productsReport = (state: RootState) => state.product.productsReport;
// export const productCallStatus = (state: RootState) => ({status: state.product.status, busyCount: state.product.busySatusCount});
export const productCallStatus = (state: RootState) => state.product.status;
export const productCallBusyCount = (state: RootState) => state.product.busySatusCount

export const draftStock = (state: RootState) => state.product.draftStock;


export const getProduct = (productId: number): AppThunk => async (dispatch, getState) => {
  dispatch(addRequest(productId));
  try {

    const response = await api.get(
      `/product/${productId}`,
      { headers: { "Content-Type": "application/json" } }
    )
    // console.log(data);
    // console.log(response);

    if (response.status === 200) {
      dispatch(getProductSucceeded({...response.data['product'], components: response.data['product'].components.map((item:any) => ({...item, cancelled: false, itemHash: nanoid()}))}));
    } else {
      dispatch(loadFailure(response.statusText));
    }

  } catch (err: any) {
    // throw new Error(err);
    dispatch(loadFailure(err.message));
  }
};



export const updateProductData = (): AppThunk => async (dispatch, getState) => {
  const product = productData(getState());

  dispatch(addRequest(product.id!));
  try {



    const response = await api.put(
      '/product',
      product,
      { headers: { "Content-Type": "application/json" } }
    )
    // console.log(data);
    // console.log(response);

    if (response.status === 200) {
      updateProductInDB({...response.data['product'], components: response.data['product'].components.map((item:any)=> ({id: item.component_id, quantity: item.main_unit_quantity, unite_id: item.unite_id}))})
      // dispatch(updateProductSucceeded(response.data['product']));
      dispatch(updateProductSucceeded({...response.data['product'], components: response.data['product'].components.map((item:any) => ({...item, cancelled: false, itemHash: nanoid()}))}));
    } else {
      dispatch(loadFailure(response.statusText));
    }

  } catch (err: any) {
    // throw new Error(err);
    dispatch(loadFailure(err.message));
  }
};


export const updateProductInDB = (product: ProductCatalog) => {
  db.transaction('rw', db.productCatalog, async () => {
    db.productCatalog.put(product)
  }).then(() => {
    console.log("Transaction committed");
  }).catch(err => {
    console.error(err.stack);
  });
};


export const addProductData = (): AppThunk => async (dispatch, getState) => {
  dispatch(addRequest(0));
  const product = productData(getState());

  try {


    const response = await api.post(
      '/product',
      product,
      { headers: { "Content-Type": "application/json" } }
    )
    // console.log(data);
    // console.log(response);

    if (response.status === 200) {
      dispatch(getProductSucceeded(response.data['product']));
    } else {
      dispatch(loadFailure(response.statusText));
    }

  } catch (err: any) {
    // throw new Error(err);
    dispatch(loadFailure(err.message));
  }
};


// export const getAllProduct = (): AppThunk => async (dispatch, getState) => {
//   dispatch(addRequest(0));
//   try {

//     const response = await api.get(
//       '/product/all',
//       { headers: { "Content-Type": "application/json" } }
//     )

//     if (response.status === 200) {
//       dispatch(getAllProductSucceeded(response.data['products']));
//     } else {
//       dispatch(loadFailure(response.statusText));
//     }

//   } catch (err: any) {
//     // throw new Error(err);
//     dispatch(loadFailure(err.message));
//   }
// };


export const getAllProduct = (): AppThunk => async (dispatch) => {
  dispatch(addRequest(0));
  const productList = await db.productCatalog.toArray()

  if (productList) {
    dispatch(getAllProductSucceeded(productList));
  }
  else {
    dispatch(loadFailure("Product Category List not exist"));

  }
};


export const getPriceList = (): AppThunk => async (dispatch, getState) => {
  dispatch(addRequest(0));

  const priceList = await db.priceList.toArray()

  if (priceList) {
    dispatch(getPriceListSucceeded(priceList));
  }
  else {
    dispatch(loadFailure("Price List not exist"));

  }
}

export const getProductTypeList = (): AppThunk => async (dispatch) => {
  dispatch(addRequest(0));

  const typeList = await db.productType.toArray()

  if (typeList) {
    dispatch(getProductTypeListSucceeded(typeList));
  }
  else {
    dispatch(loadFailure("Product Type List not exist"));

  }
}


export const getProductCategoryList = (): AppThunk => async (dispatch) => {
  dispatch(addRequest(0));

  const categoryList = await db.productCategory.toArray()

  if (categoryList) {
    dispatch(getProductCategoryListSucceeded(categoryList));
  }
  else {
    dispatch(loadFailure("Product Category List not exist"));

  }
}



export const getProductStockTransactions = (productId: number): AppThunk => async (dispatch, getState) => {
  dispatch(addRequest(0));
  dispatch(clearProductTrans());
  try {

    const response = await api.get(
      `/product/trans/${productId}`,
      { headers: { "Content-Type": "application/json" } }
    )
    // console.log(data);
    // console.log(response);

    if (response.status === 200) {
      dispatch(getProductTransSucceeded(response.data['transactions']));
    } else {
      dispatch(loadFailure(response.statusText));
    }

  } catch (err: any) {
    // throw new Error(err);
    dispatch(loadFailure(err.message));
  }
};



export default productSlice.reducer;
