import {createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import type { Property } from "csstype";

import {
    defaultLang,
    supportedLangs,
    defaultDirection,
  } from "../../config/i18nConfig";



export interface i18nState {
    lang: string;
    supportedLangs: {[key: string]: string};
    direction: Property.Direction;
    translations: {[key: string]: any}
  }

  // const defaultDirection: string = (defaultLang === "ar" ? "rtl": "ltr");
  const initialState: i18nState = {
    lang: defaultLang, // "en" when app loads
    supportedLangs: { ...supportedLangs },
    direction: defaultDirection,
    // We'll keep our translations in Redux to start.
    // Later on we'll move them to their own files so 
    // that they're easier to manage. 
    translations: {
      en: {
        appbar_search_placeholder: "Search...",
        drawer_invoice_button: "Not Sent Invoices",
        drawer_sentinvoice_button: "Sent Invoices",
        drawer_newinvoice_button: "New Invoice",
        drawer_drafts_button: "Drafts",
        drawer_invoices_button: "Invoices",
        drawer_invoice_totals_button: "Invoice Totals Report",
        drawer_invoice_details_button: "Invoice Details Report",
        drawer_invoice_menu: "Invoices",
        drawer_invoice_new_sale: "Sales",
        drawer_invoice_new_purchase: "Purchase",
        drawer_invoice_new_sale_return: "Sales return",
        drawer_invoice_new_purchase_return: "Purchase return",
        drawer_invoice_new_adjust_remove: "Adjust remove",
        drawer_invoice_new_adjust_add: "Adjust add",
        drawer_invoice_new_transfer: "Transfer",
        drawer_customers_menu: "Customers",
        drawer_customers_List: "Customer List",
        drawer_customers_add: "Add",
        drawer_products_menu: "Products",
        drawer_stock_List: "Stock",
        drawer_finance_List: "Finance",
        drawer_profit_List:"Profit",
        drawer_my_account:"My Account",
        drawer_users:"Users",
        drawer_settings:"ٍSettings",
        search_button: "Search",
        search_from: "From",
        search_to: "To",
        ratings: "Ratings",
        product_type: {
          ware: "Ware",
          service: "Service",
          product: "Product"
        },
        get_num_dialog:{
          title: "Value",
        },
        date_range_dialog:{
          title: "Select Report Range",
          from: "From",
          to: "To",
        },
        change_password_dialog:{
          title: "Change Password",
          old_password: "Old Password",
          new_password: "New Password",
          confirm_new_password: "Confirm New Password",
        },
        dashboards:{
          create_invoice: "Create Invoice",
          shift_dashboard: "Shifts",
          open_shift: "Open Shift",
          close_shift: "Close Shift",
          shifts_data: "Shifts Data",
          stock_report: "Stock Report",
          items_with_stock_report: "Items With Stock",
          add_expense: "Add Expense",
          expenses_report: "Expenses Report",
          sync_data: "Refresh Data",
          change_password: "Change Password",
          privilages_list: "Privilages List",
          users_data: "Users Data",
          users_logs: "Users Logs",
          finance_indicators: "Finance Indicators",
          finance_indicators_profit: "Finance Indicators",
          products_add: "Add",
          products_List: "Product List",
          products_edit_pricing: "Edit Pricing",
          products_stock_less_than_min: "Products Less Than Min",
          products_stock_greater_than_max: "Products Greater Than Max",
          brokers_sales: "Brokers Sales",
          sales_persons_sales: "Sales Persons Sales",
          customers_sales: "Customers Sales",
          vendors_purchases: "Vendors Purchases",
          customers_profits: "Customers Profits",
          add_product_category: "Add Product Category",
          add_color: "Add Color",
          add_size: "Add Size",
          add_store: "Add Store",
          product_category_list: "Product Category List",
          color_list: "Color List",
          size_list: "Size List",
          store_list: "Store List",
          invoice_customers: "Invoice Customers",
          invoice_customers_sales: "Customers Sales",
          stock_average_cost: "Stock Average Cost",
          stock_category_cost: "Stock Category Cost",
          stock_transfer: "Stock Transfer",
          add_expense_type: "Add Expense Type",
          expense_type_list: "Expense Type List",
          set_expense_per_price: "Set Expense Per Price",
          categories_sales: "Categories Sales",
          categories_purchases: "Categories Purchases",
          stock_expired_days: "Stock Expired By Days",
          stock_without_transaction: "Stock Without Transaction",
          number_of_days: "Number Of Days",
          number_of_days_greater_than: "Number Of Days Greater Than",
          number_of_days_less_than: "Number Of Days Less Than",
          branch_name: "Branch: ",
          change_branch_title: "Change Branch",
          can_not_update_dueto_draft: "Can not apply action due to existance of drafts not inserted?",
          assign_pos: "Assign POS",
          unassign_pos: "Unassign POS",
        },
        customers_dashboards:{
          customers_section: "Customers",
          customers_list: "Customers List",
          customers_add: "Add",
          brokers_section: "Brokers",
          brokers_list: "Brokers List",
          brokers_add: "Add",
          sales_persons_section: "Sales Persons",
          sales_persons_list: "Sales Persons List",
          sales_persons_add: "Add",
          customers_with_balance: "Customers Balance",
        },
        customer_form:{
          title: "Customer Data",
          add_title: "Add Customer",
          update_button:"Update",
          add_button:"Add",
          id:"id",
          fullname:"Name",
          mobile: "Mobile",
          phone: "Phone",
          email: "Email",
          address: "Address",
          initial_balance: "Initial Balance",
          type: "Type",
          pay_button: "Pay",
          collect_button: "Collect",
          sales_discount_button: "Sales Discount",
          purchase_discount_button: "Purchase Discount",
          statment_button: "Statment",
          statment_detailed_button: "Detailed Statment",
        },
        lookup_form:{
          product_category_title: "Product Category",
          product_category_add_title: "Add Product Category",
          color_title: "Colors",
          color_add_title: "Add Color",
          size_title: "Sizes",
          size_add_title: "Add Size",
          store_title: "Stores",
          store_add_title: "Add Store",
          update_button:"Update",
          add_button:"Add",
          id:"id",
          category_name:"Name",
          color_name:"Name",
          size_name:"Name",
          name:"Name",

          expense_title: "Expense Types",
          account_name: "Expense Name",
        },
        broker_form:{
          title: "Broker Data",
          add_title: "Add Broker",
          update_button:"Update",
          add_button:"Add",
          id:"id",
          broker_name:"Name",
          phone: "Phone",
          email: "Email",
          comment: "Comment",
          year: "Year",
          month: "Month",
          total: "Total",
        },
        sales_person_form:{
          title: "Sales Person Data",
          add_title: "Add Sales Person",
          update_button:"Update",
          add_button:"Add",
          id:"id",
          sales_person_name:"Name",
          phone: "Phone",
          email: "Email",
          comment: "Comment",
          year: "Year",
          month: "Month",
          total: "Total",
        },
        document_form:{
          title_pay: "Collection Entry From: ",
          title_collect: "Add Collection Entry From: ",
          title_sales_discount: "Sales Discount to customer: ",
          title_purchase_discount: "Purchase discount from: ",
          title_expense: "Expenses",
          title_add_expense_type: "Add Expnese type",
          accounting_months: "Accounting Months",
          title_expense_type: "Type",
          finance_store: "Finance Store",
          amount: "Amount",
          date: "Date",
          note: "Note",
          description: "Description",
          submit_button: "Add",
          update_button: "Update",
          cancel_button: "Cancel",

          title_expense_per_price: "Expense Amount Per Price",
          expense: "Expense",
          sales_cost: "Sales Cost",
          expense_per_price: "Expense Per Price",
          cancel_dialog_title: "Cancel Expenses",
          cancel_dialog_message: "Are you sure, you want cancel expenses?",
          total: "Total",
          cancel_message: "This action will cancel document, are you agree?",
          cancel_title: "Cancel Document",
        },
        role_form: {
          title: "Privilages of Role:"
        },
        user_form:{
          title: "Data of User:",
          username: "Username",
          mobile_phone: "Mobile Phone",
          email: "Email",
          first_name: "First Name",
          last_name: "Last Name",
          role: "Role",
          update_button: "Update",
          reset_password: "Reset Password",
        },

        pos_form:{
          title: "Assign POS:",
          pos_name: "POS Name",
          point_name: "Point Name",
          reg_code: "Registration Code",
          assign_button: "Assign",
        },
        
        
        product_form:{
          title: "Product Data",
          add_title: "Add Product",

          id: 'id',
          international_code: 'International Code',
          user_defined_code: 'User Defined Code',
          product_name: 'Name',
          product_type: 'Type',
          product_category: 'Category',
          has_expire_date: 'Has Expire Date',
          is_medicine: 'Is Medicine',
          is_freez: 'Is Freez',
          is_returnable: 'Is Retunable',
          default_expiry_days: 'Default Expire Days',
          einv_code: 'Tax Code',
          product_color: "Color",
          product_size: "Size",
          min_quantity: "Minimum Quantity",
          max_quantity: "Maximun Quantity",
          pricing: 'Pricing',
          product_units: 'Units',
          product_components: 'Product Components',
          units:{
            add_button: "Add Unit",
            unit_name:"Unit",
            main_unit_quantity: "Quantity",
            is_main: "Main Unit",
            is_sales_default: "Sales",
            is_purchase_default:"Purchase"
          },
          product_price_list: "Pricing",
          price_list:{
            list_name:"Price List",
            price: "Price",
            default_list: "Default List",
          },

          update_button:"Update",
          add_button:"Add",
          open_all_trans_button: "Poduct Transactions",
          open_store_trans_button: "Poduct Stores Transactions",
          stock_in_all_branches: "Stock In All Branches",
        },
        customer_rep1: {
          title: "Customers and Vendors Report:",
          fullname: "Full Name",
          mobile: 'Mobile',
          phone: 'Phone',
          email: 'Email',
          address: 'Address',
          customer_type_name: 'Type',
          account: "Account",
          balance: "Balance",
          total: "Total",
        },
        commission_dashboard: {
          employees: "Employes",
          commission_categories: "Commission Categories",
        },
        employee_form:{
          title: "Employee Data",
          add_title: "Add Employee",
          id: 'id',
          fullname: 'Full Name',
          mobile: "Mobile",
          phone: "Phone",
          email: "Email",
          address: "Address",
          salary: "Salary",
          balance: "Balance",
          comment: "Comment",
          commission_category: "Commission Category", 
        },
        expense_rep1: {
          title: "Expenses Report:",
          document_date: "Date",
          description: "Note",
          amount: "Amount",
          accounting_months: "Accounting Months",
          comment: "Comment",  
          account_name: "Expense Item",
          document_type_name: "Document Type",
          transactions: "Transactions",
        },
        all_employee_report: {
          id: "id",
          fullname: "Name",
          balance: "Balance",
        },
        commission_categories_report: {
          id: "id",
          commission_category: "Category",
        },
        commission_amounts_report: {
          product_id: "id",
          product_name: "Product",
          commission: "Commission",
        },
        roles_list: {
          title: "Users Roles:",
          role_name: "Role Name",
        },
        users_list:{
          title: "Users:",
          username: "Username",
          mobile_phone: "Mobile Phone",
          email: "Email",
          first_name: "First Name",
          last_name: "Last Name",
        },
        users_logs: {
          log_date: "Date",
          log_action_type_name: "Action",
          log_object_type_name: "Type",
          object_id: "Item Id",
          username: "Username",
        },
        stock_rep1: {
          title: "Stock:",
          title_draft_available_stock: "Available Stock",
          title_stock_transfer: "Stock Transfer",
          user_defined_code: "Code",
          product_name: "Product",
          store_name: "Store",
          branch_name: "Branch",
          category_name: "Category",
          main_unit_quantity: "Quantity",
          title_min_quantity: "Minimum Quantity Report:",
          min_quantity: "Minimum Quantity",
          title_max_quantity: "Maximum Quantity Report:",
          max_quantity: "Maximum Quantity",
          available_stock: "Available Stock",
          purchase_price: "Purchase Price",
          average_cost_price: "Average Cost Price",
          total_cost: "Total Cost",
          total_cost_by_purchase_price:"Total Cost with Purchase Price",
          total: "Total",
          expire_date: "Expire Date",
          remaining_days: "Remaining Days To Expire",
          latest_sales_date: "Last Transaction Date",
          without_sales_days: "Without Sales Days",
          invoice_date: "Invoice Date",
          from_store: "From Store",
          to_store: "To Store",

        },
        customer_statment: {
          title: "Statment: ",
          title_detailed: "Detailed Statment",
          id: "id",
          document_date: "Date",
          document_type: "Type",
          credit: "Credit",
          debit: "Debit",
          balance: "Balance",
          comment: "Comment",
          item_name: "Item Name",
          quantity: "ََQuantity",
          unit_price: "Price",
          total: "Total",
          previouse_balance: "Previouse Balance",
        },
        product_rep1: {
          id: "id",
          title: "Products Report:",
          product_name: "Product Name",
          product_category_name: "Category",
          category_name: "Category",
          international_code: "International Code",
          user_defined_code: "User Code",
        },
        invoice_customer_rep1: {
          title: "Customers Report",
          title_sales: "Customers Sales",
          customer_phone: "Phone",
          customer_name: "Name",
          customer_company: "Company",
          customer_address: "Address",
          customer_field: "Field",
          year: "Year",
          month: "Month",
          total: "Total",
        },
        invoice_rep1: {
          id: "id",
          draft_id: "id",
          title: "Invoices:",
          title_drafts: "Drafts:",
          invoice_type: "Type",
          invoice_type_name: "Type",
          customer_name: "Customer",
          invoice_date: "Date",
          subtotal: "Sub Total",
          discount: "Discount",
          salesTotal: "Sales Total",
          tax: "Tax",
          additions: "Service",
          freight: "Freight",
          total: "Total",
          broker_name: "Broker",
          sales_person_name: "Sales Person",
          invoice_comment: "Note",
          invcustomer_name: "Invoice Customer",
          invcustomer_phone: "Invoice Customer Phone",
        },
        product_trans: {
          id: "id",
          title: "Product Transactions: ",
          invoice_date: "Date",
          invoice_type: "Type",
          store_name: "Store",
          main_unit_price: "Price",
          in: "In",
          out: "Out",
          stock: "Stock",
          fullname: "Customer",
          invoice_comment: "Notes",
        },
        finance_eports:{
          profit: "Profit",
        },
        nsi_table: {
          title: "Invoices Report",
          open: "open",
          customer: "Customer",
          date_time_issued: "Date",
          total_sales_amount: "Total Amount",
          net_amount: "Net Amount",
          tax_totals: "Tax Total",
          total_amount: "Total",
          signed: "signed",
          send: 'send',
          invoice_type: 'type',
        },
        invoice:{
          invoiceLine:{
            description: "Item Name",
            quantity: "Quantity",
            unitvalue: "Unit Price",
            salestotal: "Sales Total",
            discount_rate: "Discount Rate",
            discount_value: "Discount Value",
            net_total: "Total After Discount", 
            tax_rate: "Tax Rate",
            tax_value: "Tax Value",
            total: "Net Total"
          },
          dateTimeIssued: "Date",
          dialog:{
            yes_button: "Yes",
            no_button: "No",
            update_title: "Are you sure, you save invoice?",
            update_message: "This action will overwrite the invoice data",
            cancel_title: "Are you sure, you cancel invoice?",
            cancel_message: "This action will cancel invoice from electonic invoice",
          },
          update_button: "Update",
          cancel_button: "Cancel",
          print_button: "Print",
        },
        invoice_item:{
          code: 'Code',
          color: 'Color',
          size: 'Size',
          product: 'Product',
          unit: 'unit',
          price: 'Price',
          count: 'Count',
          discount_precent: 'Discount',
          tax_precent: 'Tax',
          withholdingtax: 'Withholding Tax',
          total: 'Total',
          store: 'Store',
          expire_date: "Expire Date",
          transfer_store: 'Transfer To Store',
          quantity: "Quantity",
          suggested_price:"Suggested",
          comment1: "note",
        },
        new_invoice:{
          invoice_discount_mode: "Discount Mode",
          invoice_tax_mode: "Tax Mode",
          invoice_comment: "comment",
          invoice_date: "Date",
          cash_invoice: "Cash Invoice",
          use_tax: "Use Tax",
          invoice_customer: "Customer",
          invoice_broker: "Broker",
          invoice_sales_person: "Sales Person",
          delete_button: "Delete",
          cancel_button: "Cancel",
          add_button: "Add",
          stock_button: "Stock",
          uavailable_stock_button: "Unavailable Stock",
          update_button: "Update",
          draw_button: "Draw Stock",
          print_button: "Print",
          new_draft_button: "New Draft",
          invcustomer_phone: "Customer Phone",
          invcustomer_name: "Customer Name",
          invcustomer_company: "Company",
          invcustomer_address: "Address",
          invcustomer_field: "Field",
          data_not_saved_msg: "Changes you made may not be saved, Are you sure you want to leave?",
          tax_customer:{
            type: "Type",
            id: "Id",
            name: "Name",
            country: "Country",
            gov: "Governerate",
            city: "City",
            street: "Street",
            building_no: "Building No",
            zip_code: "Zip Code",
            floor: "Floor",
            room: "Room",
            landmark: "Landmark",
            additional: "Additional Information",
          },
          type:{
            title_name1: "Sales Order",
            title_draft_name1: "Sales Order Draft",
            title_color1: "#1565c0",
            title_name2: "Purchase Order",
            title_draft_name2: "Purchase Order Draft",
            title_color2: "#1b5e20",
            title_name3: "Sales return",
            title_draft_name3: "Sales return Draft",
            title_color3: "#42a5f5",
            title_name4: "Adjust remove",
            title_draft_name4: "Adjust remove Draft",
            title_color4: "#c62828",
            title_name6: "Purchase return",
            title_draft_name6: "Purchase return Draft",
            title_color6: "#4caf50",
            title_name7: "Adjust add",
            title_draft_name7: "Adjust add Draft",
            title_color7: "#e65100",
            title_name8: "Transfer stock",
            title_color8: "##FFB400",
          },
          accordion1:{
            title: "Customer Data",
          },
          accordion2:{
            title: "Invoice Data",
            date: "Date",
            branch: "Branch",
            tax_precent: "Tax Precent",
          },
          table:{
          code: 'Code',
          product: 'Product',
          unit: 'Unit',
          price: 'Price',
          count: 'Count',
          discount: 'Discount',
          tax: 'Tax',
          total: 'Total',
          expire_date: 'Expire Date',
          store: "Store",
          comment1: "Comment"
          },
          totals:{
            salesTotal: "Total",
            discount_percentage: "Precent",
            discount: "Discount",
            tax_percentage: "Precent",
            tax: "Tax",
            freight: "Freight",
            total: "Net Total",
          },
          payment_amount_pay: "Payment Paied",
          payment_amount_collect: "Payment Collected",
          customer: {
            customer_type: "Type",
            customer_id: "Id",
            customer_name: "Name",
            customer_country: "Country",
            customer_gov: "Governerate",
            customer_city: "City",
            customer_street: "Street",
            customer_building_no: "Building No",
            customer_zip_code: "Zip Code",
            customer_floor: "Floor",
            customer_room: "Room",
            customer_landmark: "Landmark",
            customer_additional: "Additional Information",
          },
          dialog:{
            yes_button: "Yes",
            no_button: "No",
            delete_title: "Are you sure, you want delete this Draft?",
            delete_message: "Deleting this Draft will remove it permanently",
            cancel_title: "Are you sure, you want cancel this Invoice?",
            cancel_message: "Deleting this Invoice will cancel it permanently",
            change_customer_title: "Are you sure, you want change Customer?",
            change_customer_message: "This action will change invoice customer",
          },
        }

      },
      ar: {
        appbar_search_placeholder: "بحث...",
        drawer_invoice_button: "فواتير لم يتم ارسالها",
        drawer_sentinvoice_button: "فواتير تم ارسالها",
        drawer_newinvoice_button: "فاتوة جديدة",
        drawer_drafts_button: "مسودات",
        drawer_invoices_button: "الفواتير",
        drawer_invoice_totals_button: "تقرير اجمالي الفواتير",
        drawer_invoice_details_button: "تقرير تفصيلي للفواتير",
        drawer_invoice_menu: "الفواتير",
        drawer_invoice_new_sale: "بيع",
        drawer_invoice_new_purchase: "شراء",
        drawer_invoice_new_sale_return: "مرتجع مبيعات",
        drawer_invoice_new_purchase_return: "مرتجع مشتريات",
        drawer_invoice_new_adjust_remove: "تسوية خصم",
        drawer_invoice_new_adjust_add: "تسوية اضافة",
        drawer_invoice_new_transfer: "تحويل مخزون",
        drawer_customers_menu: "العملاء",
        drawer_customers_List: "بيان بالعملاء",
        drawer_customers_add: "إضافة",
        drawer_products_menu: "الاصناف",
        drawer_stock_List: "المخزون",
        drawer_finance_List: "ماليات",
        drawer_profit_List:"تقارير",
        drawer_my_account:"حسابي",
        drawer_users:"المستخدمين",
        drawer_settings:"الاعدادات",
        search_button: "بحث",
        search_from: "من",
        search_to: "إلي",
        ratings: "Bewertungen",
        product_type: {
          ware: "سلعة",
          service: "خدمة",
          product: "منتج"
        },
        get_num_dialog:{
          title: "القيمة",
        },
        date_range_dialog:{
          title: "الفترة الزمنية للتقرير",
          from: "من",
          to: "إلي",
        },
        change_password_dialog:{
          title: "تغيير كلمة العبور",
          old_password: "كلمة العبور القديمة",
          new_password: "كلمة العبور الجديدة",
          confirm_new_password: "تأكيد كلمة العبور الجديدة",
        },
        dashboards:{
          create_invoice: "انشاء فاتورة",
          shift_dashboard: "الوردية",
          open_shift: "فتح وردية",
          close_shift: "اغلاق الوردية",
          shifts_data: "بيان الورديات",
          stock_report: "مخزون الاصناف",
          items_with_stock_report: "اصناف لها مخزون",
          add_expense: "اضافة مصروفات",
          expenses_report: "تقرير المصروفات",
          sync_data: "تحديث البيانات",
          change_password: "تغيير كلمة العبور",
          privilages_list: "قائمة الصلاحيات",
          users_data: "بيانات المستخدمين",
          users_logs: "عمليات المستخدمين",
          finance_indicators: "القوائم المالية",
          finance_indicators_profit: "القوائم المالية والارباح",
          products_add: "إضافة",
          products_List: "قائمة الاصناف",
          products_edit_pricing: "تحديث الاسعار",
          products_stock_less_than_min: "مخزون اقل من الحد الادنى",
          products_stock_greater_than_max: "مخزون اكبر من الحد الاقصى ",
          brokers_sales: "مبيعات الوسطاء",
          sales_persons_sales: "مبيعات البائعين",
          customers_sales: "مبيعات العملاء",
          vendors_purchases: "مشتريات الموردين",
          customers_profits: "ارباح العملاء الشهرية",
          add_product_category: "اضافة فئة",
          add_color: "اضافة لون",
          add_size: "اضافة حجم",
          add_store: "اضافة مخزن",
          product_category_list: "قائمة الفئات",
          color_list: "قائمة الالوان",
          size_list: "قائمة الاحجام",
          store_list: "قائمة المخازن",
          invoice_customers: "عملاء الفواتير",
          invoice_customers_sales: "مبيعات العملاء",
          stock_average_cost: "متوسط سعر المخزون",
          stock_category_cost: "متوسط سعر الفئات",
          stock_transfer: "حركات المخزون",
          add_expense_type: "اضافة بند مصروفات",
          expense_type_list: "بنود المصروفات",
          set_expense_per_price: "تحميل المصاريف بالسعر",
          categories_sales: "مبيعات الفئات",
          categories_purchases: "مشتريات الفئات",
          stock_expired_days: "المدة المتبقية لانتهاء الصلاحية",
          stock_without_transaction: "الراكد",
          number_of_days: "عدد الايام",
          number_of_days_greater_than: "عدد الايام اكبر من",
          number_of_days_less_than: "عدد الايام اقل من",
          branch_name: "الفرع: ",
          change_branch_title: "تغيير الفرع",
          can_not_update_dueto_draft: "لا يمكن تنفيذ العملية لوجود مسودات لم يتم إدخالها",
          assign_pos: "تعيين نقطة بيع",
          unassign_pos: "حذف نقطة البيع",
        },
        customers_dashboards:{
          customers_section: "العملاء",
          customers_list: "تقرير العملاء",
          customers_add: "اضافة",
          brokers_section: "الوسطاء",
          brokers_list: "تقرير الوسطاء",
          brokers_add: "اضافة",
          sales_persons_section: "البائعين",
          sales_persons_list: "تقرير البائعين",
          sales_persons_add: "اضافة",
          customers_with_balance: "حسابات العملاء",
        },
        customer_form:{
          title: "بيانات عميل",
          add_title: "إضافة عميل",
          update_button:"تحديث",
          add_button:"اضافة",
          id:"id",
          fullname:"الاسم",
          mobile: "المحمول",
          phone: "التليفون",
          email: "البريد الالكتروني",
          address: "العنوان",
          initial_balance: "الرصيد",
          type: "النوع",
          pay_button: "دفع",
          collect_button: "تحصيل",
          sales_discount_button: "خصم مبيعات نقدي",
          purchase_discount_button: "خصم مشتريات نقدي",
          statment_button: "كشف حساب",
          statment_detailed_button: "كشف حساب تفصيلي",
        },
        lookup_form:{
          product_category_title: "الفئات",
          product_category_add_title: "اضافة فئة",
          color_title: "الالوان",
          color_add_title: "اضافة لون",
          size_title: "الاحجام",
          size_add_title: "اضافة حجم",
          store_title: "المخازن",
          store_add_title: "اضافة مخزن",
          update_button:"تحديث",
          add_button:"اضافة",
          id:"id",
          category_name:"الاسم",
          color_code: "الكود",
          color_name:"الاسم",
          size_code: "الكود",
          size_name:"الاسم",
          name:"الاسم",

          expense_title: "بنود المصروفات",
          account_name: "اسم البند",
        },
        broker_form:{
          title: "بيانات الوسيط",
          add_title: "اضافة وسيط",
          update_button:"تحديث",
          add_button:"اضافة",
          id:"id",
          broker_name:"الاسم",
          phone: "تليفون",
          email: "بريد الكتروني",
          comment: "ملاحظات",
          year: "السنة",
          month: "الشهر",
          total: "الاجمالي",
        },
        sales_person_form:{
          title: "بيانات بائع",
          add_title: "اضافة بائع",
          update_button:"تحديث",
          add_button:"اضافة",
          id:"id",
          sales_person_name:"الاسم",
          phone: "تليفون",
          email: "بريد الكتروني",
          comment: "ملاحظات",
          year: "السنة",
          month: "الشهر",
          total: "الاجمالي",
        },
        document_form:{
          title_pay: "دفع إلي: ",
          title_collect:  "تحصيل من: ",
          title_sales_discount: "خصم نقدي علي المبيعات للعميل: ",
          title_purchase_discount: "خصم نقدي علي المشتريات من المورد: ",
          title_add_expense_type: "اضافة بند مصروفات",
          title_expense: "مصروفات",
          title_expense_type: "البند",
          finance_store: "صندوق مالي",
          accounting_months: "اشهر المحاسبة",
          amount: "المبلغ",
          date: "التاريخ",
          note: "ملاحظات",
          description: "ملاحظات",
          submit_button: "ادخال",
          update_button: "تحديث",
          cancel_button: "الغاء",

          title_expense_per_price: "تحميل المصروفات بالاسعار",
          expense: "المصروفات",
          sales_cost: "تكلفة المبيعات",
          expense_per_price: "المصروفات لكل وحدة",
          cancel_dialog_title: "الغاء قائمة المصروفات",
          cancel_dialog_message: "هل انت متأكد, تريد الغاء قائمة المصروفات؟",
          total: "الاجمالي",
          cancel_message: "هذه العملية سوف تؤدي إلي الغاء هذا البيان",
          cancel_title: "الغاء البيان",
        },
        role_form: {
          title: "صلاحيات دور:"
        },
        user_form:{
          title: "بيانات المستخدم:",
          username: "اسم المستخدم",
          mobile_phone: "رقم التليفون",
          email: "البريد الالكتروني",
          first_name: "الاسم الاول",
          last_name: "الاسم الاخير",
          role: "قائمة الصلاحيات",
          update_button: "تحديث",
          reset_password: "تغيير كلمة العبور",
        },

        pos_form:{
          title: "تفعيل نقطة بيع:",
          pos_name: "اسم النقطة",
          point_name: "اسم الجهاز",
          reg_code: "كود التفعيل",
          assign_button: "تفعيل",
        },
      

        product_form:{
          title: "بيانات منتج",
          add_title: "إضافة منتج",

          id: 'id',
          international_code: 'الكود الدولي',
          user_defined_code: 'كود المستخدم',
          product_name: 'الاسم',
          product_type: 'النوع',
          product_category: 'التصنيف',
          has_expire_date: 'يستخدم تاريخ صلاحية',
          is_medicine: 'دواء',
          is_freez: 'يحتاج تبريد',
          is_returnable: 'يقبل مرتجع',
          default_expiry_days: 'عدد ايام الصلاحية',
          einv_code: 'كود الضرائب',
          product_color: "اللون",
          product_size: "الحجم",
          min_quantity: "الحد الادني للمخزون",
          max_quantity: "الحد الاقصى للمخزون",
          pricing: 'التسعير',
          product_units: 'الوحدات',
          product_components: 'مكونات المنتج',
          units:{
            add_button: "اضافة وحدة",
            unit_name:"الوحدة",
            main_unit_quantity: "الكمية",
            is_main: "وحدة اساسية",
            is_sales_default: "بيع",
            is_purchase_default:"شراء"
          },
          product_price_list: "التسعير",
          price_list:{
            list_name:"قائمة الاسعار",
            price: "السعر",
            default_list: "القائمة الافتراضية",
          },

          update_button:"تحديث",
          add_button:"اضافة",
          open_all_trans_button: "حركات الصنف",
          open_store_trans_button: "حركات الصنف بالمخازن",
          stock_in_all_branches: "المخزون بجميع الفروع",
        },
        customer_rep1: {
          title: "بيان بالعملاء و الموردين",
          fullname: "الاسم",
          mobile: 'تليفون محمول',
          phone: 'تليفون',
          email: 'بريد الكتروني',
          address: 'العنوان',
          customer_type_name: 'النوع',
          account: "الرصيد",
          balance: "الرصيد",
          total: "الاجمالي",
        },
        commission_dashboard: {
          employees: "الموظفين",
          commission_categories: "نماذج العمولة",
        },
        employee_form:{
          title: "بيانات الموظف",
          add_title: "اضافة موظف",
          id: 'id',
          fullname: 'الاسم',
          mobile: "الموبايل",
          phone: "التليفون",
          email: "الاميل",
          address: "العنوان",
          salary: "المرتب",
          balance: "الرصيد",
          comment: "ملاحظات",
          commission_category: "قائمة العمولة", 
        },
        expense_rep1: {
          title: "تقرير المصروفات:",
          document_date: "التاريخ",
          description: "ملاحظات",
          amount: "المبلغ",
          accounting_months: "اشهرة المحاسبة",
          comment: "ملاحظات",
          account_name: "بند المصروفات",  
          document_type_name: "العملية",
          transactions: "العمليات",
        },
        all_employee_report: {
          id: "id",
          fullname: "الاسم",
          balance: "الحساب",
        },
        commission_categories_report: {
          id: "id",
          commission_category: "النموذج",
        },
        commission_amounts_report: {
          product_id: "id",
          product_name: "المنتج",
          commission: "العمولة",
        },
        roles_list: {
          title: "ادوار المستخدمين:",
          role_name: "اسم الدور",
        },
        users_list:{
          title: "المستخدمين",
          username: "اسم المستخدم",
          mobile_phone: "رقم التليفون",
          email: "البريد الالكتروني",
          first_name: "الاسم الاول",
          last_name: "الاسم الاخير",
        },
        users_logs: {
          log_date: "التاريخ",
          log_action_type_name: "العملية",
          log_object_type_name: "البيان",
          object_id: "رقم البيان",
          username: "المستخدم",
        },
        stock_rep1: {
          title: "المخزون:",
          title_draft_available_stock: "المخزون المتاح للاصناف",
          title_stock_transfer: "حركات المخزون",
          user_defined_code: "الكود",
          product_name: "المنتج",
          store_name: "المخزن",
          branch_name: "الفرع",
          category_name: "الفئة",
          main_unit_quantity: "الكمية",
          title_min_quantity: "تقرير الاصناف اقل من الحد الادني",
          min_quantity: "الحد الادنى",
          title_max_quantity: "تقرير الانصناف اكبر من الحد الاقصى",
          max_quantity: "الحد الاقصى",
          available_stock: "المخزون المتاح",
          purchase_price: "سعر الشراء",
          average_cost_price: "متوسط سعر التكلفة",
          total_cost: "اجمالي التكلفة",
          total_cost_by_purchase_price:"احمالي التكلفة بسعر الشراء",
          total: "الاجمالي",
          expire_date: "تاريخ انتهاء الصلاحية",
          remaining_days: "عدد الايام المتبقي لانتهاء الصلاحية",
          latest_sales_date: "تاريخ اخر عملية",
          without_sales_days: "عدد ايام الركود",
          invoice_date: "تاريخ العملية",
          from_store: "من مخزن",
          to_store: "الي مخزن",
        },
        customer_statment: {
          title: "كشف حساب: ",
          title_detailed: "كشف حساب تفصيلي: ",
          id: "id",
          document_date: "التاريخ",
          document_type: "العملية",
          credit: "دائن",
          debit: "مدين",
          balance: "الرصيد",
          comment: "ملاحظات",
          item_name: "الصنف",
          quantity: "الكمية",
          unit_price: "السعر",
          total: "الاجمالي",
          previouse_balance: "رصيد سابق",
        },
        product_rep1: {
          id: "id",
          title: "تقرير الاصناف: ",
          product_name: "اسم المنتج",
          product_category_name: "الفئة",
          category_name: "الفئة",
          international_code: "الكود الدولي",
          user_defined_code: "كود المستخدم",
        },
        invoice_customer_rep1: {
          title: "تقرير العملاء",
          title_sales: "مبيعات العملاء",
          customer_phone: "التليفون",
          customer_name: "الاسم",
          customer_company: "الشركة",
          customer_address: "العنوان",
          customer_field: "المجال",
          year: "السنة",
          month: "الشهر",
          total: "الاجمالي",
        },
        invoice_rep1: {
          id: "id",
          draft_id: "id",
          title: "بيان بالفواتير:",
          title_drafts: "المسودات:",
          invoice_type: "النوع",
          invoice_type_name: "النوع",
          customer_name: "العميل",
          invoice_date: "التاريخ",
          subtotal: "ج قبل الخصم",
          discount: "الخصم",
          salesTotal: "ج بعد الخصم",
          tax: "الضريبة",
          additions: "خدمة",
          freight: "نقل",
          total: "الاجمالي",
          broker_name: "الوسيط",
          sales_person_name: "البائع",
          invoice_comment: "ملاحظات",
          invcustomer_name: "عميل الفاتورة",
          invcustomer_phone: "تليفون العميل",
        },
        finance_eports:{
          profit: "الارباح",
        },
        product_trans: {
          id: "id",
          title: "حركات الصنف: ",
          invoice_date: "التاريخ",
          invoice_type: "العملية",
          store_name: "المخزن",
          main_unit_price: "السعر",
          in: "اضافة",
          out: "صرف",
          stock: "الرصيد",
          fullname: "العميل",
          invoice_comment: "ملاحظات",
        },
        nsi_table: {
          title: "بيان بالفواتير",
          open: "فتح",
          customer: "العميل",
          date_time_issued: "التاريخ",
          total_sales_amount: "الاجمالي قبل الخصم",
          net_amount: "الاجمالي بعد الخصم",
          tax_totals: "الضريبة",
          total_amount: "الاجمالي",
          signed: "الختم",
          send: 'ارسال',
          invoice_type: 'النوع',
        },
        invoice:{
          invoiceLine:{
            description: "الصنف",
            quantity: "الكمية",
            unitvalue: "السعر",
            salestotal: "الاجمالي",
            discount_rate: "نسبة الخصم",
            discount_value: "قيمة الخصم",
            net_total: "الاجمالي بعد الخصم", 
            tax_rate: "نسبة الضريبة",
            tax_value: "قيمة الضريبة",
            total: "صافي الاجمالي"
          },
          dateTimeIssued: "التاريخ",
          dialog:{
            yes_button: "نعم",
            no_button: "لا",
            update_title: "هل تريد حفظ التعديلات التي تمت؟",
            update_message: "هذة العملية ستؤدي الي تغيير بيانات الفاتورة",
            cancel_title: "هل تريد الغاء القاتورة؟",
            cancel_message: "هذة العملية سوف تؤدي إلي إلغاء الفاتورة من منظومة القاتورة الالكترونية",
          },
          update_button: "تحديث",
          cancel_button: "الغاء",
          print_button: "طباعة",
        },
        invoice_item:{
          code: 'الكود',
          color: 'اللون',
          size: 'الحجم',
          product: 'المنتج',
          unit: 'الوحدة',
          price: 'السعر',
          count: 'العدد',
          discount_precent: 'نسبة الخصم',
          tax_precent: 'نسبة الضريبة',
          withholdingtax: 'ضريبة خصم',
          total: 'الاجمالي',
          store: 'الفرع',
          expire_date: "انتهاء الصلاحية",
          transfer_store: 'تحويل الي مخزن',
          quantity: "الكمية",
          suggested_price:"مقترح",
          comment1: "ملاحظات",
        },
        new_invoice:{
          invoice_discount_mode: "نظام الخصم",
          invoice_tax_mode: "نظام الضريبة",
          invoice_comment: "ملاحظات",
          invoice_date: "التاريخ",
          cash_invoice: "نقدي",
          use_tax: "اضافة ضريبة",
          invoice_customer: "العميل",
          invoice_broker: "الوسيط",
          invoice_sales_person: "البائع",
          delete_button: "حذف",
          cancel_button: "الغاء",
          add_button: "اضافة",
          stock_button: "المخزون",
          uavailable_stock_button: "رصيد غير كافي",
          update_button: "تحديث",
          draw_button: "تسوية مخزون",
          print_button: "طباعة",
          new_draft_button: "فتح مسودة جديدة",
          invcustomer_phone: "تليفون العميل",
          invcustomer_name: "اسم العميل",
          invcustomer_company: "الشركة",
          invcustomer_address: "العنوان",
          invcustomer_field: "المجال",
          data_not_saved_msg: "يوجد تغيير لم يتم حفظه, هل تريد الخروج؟",
          tax_customer:{
            type: "النوع",
            id: "رقم البطاقة/الضريبي",
            name: "الاسم",
            country: "البلد",
            gov: "المحافظة",
            city: "المدينة",
            street: "الشارع",
            building_no: "رقم المبني",
            zip_code: "الرقم البريدي",
            floor: "الدور",
            room: "الغرقة",
            landmark: "معلم",
            additional: "بيانات اضافية",
          },
          type:{
            title_name1: "فاتورة بيع",
            title_draft_name1: "مسودة فاتورة بيع",
            title_color1: "#00acc1",
            title_name2: "فاتورة شراء",
            title_draft_name2: "مسودة فاتورة شراء",
            title_color2: "#4caf50",
            title_name3: "فاتورة مرتجع بيع",
            title_draft_name3: "مسودة فاتورة مرتجع بيع",
            title_color3: "#00d3ee",
            title_name4: "خصم من المخزون",
            title_draft_name4: "مسودة تسوية خصم من المخزون",
            title_color4: "#f55a4e",
            title_name6: "فاتورة مرتجع شراء",
            title_draft_name6: "مسودة فاتورة مرتجع شراء",
            title_color6: "#5cb860",
            title_name7: "اضافة الي المخزون",
            title_draft_name7: "مسودة تسوية اضافة من المخزون",
            title_color7: "#ffa21a",
            title_name8: "تحويل مخزون",
            title_draft_name8: "مسودة تحويل مخزون",
            title_color8: "#9c27b0",
          },
          accordion1:{
            title: "بيانات العميل",
          },
          accordion2:{
            title: "بيانات الفاتورة",
            date: "التاريخ",
            branch: "الفرع",
            tax_precent: "نسبة الضريبة",
          },
          table:{
            code: 'الكود',
            product: 'المنتج',
            unit: 'الوحدة',
            price: 'السعر',
            count: 'العدد',
            discount: 'الخصم',
            tax: 'الضريبة',
            total: 'الاجمالي',
            expire_date: 'انتهاء الصلاحية',
            store: "الفرع",
            comment1: "ملاحظات"
          },
          totals:{
            salesTotal: "الاجمالي",
            discount_percentage: "النسبة",
            discount: "الخصم",
            tax_percentage: "النسبة",
            tax: "الضريبة",
            freight: "توصيل",
            total: "الصافي",
          },
          payment_amount_pay: "مبلغ مدفوع",
          payment_amount_collect: "مبلغ محصل",
          customer: {
            customer_type: "النوع",
            customer_id: "رقم البطاقة/الضريبي",
            customer_name: "الاسم",
            customer_country: "البلد",
            customer_gov: "المحافظة",
            customer_city: "المدينة",
            customer_street: "الشارع",
            customer_building_no: "رقم المبني",
            customer_zip_code: "الرقم البريدي",
            customer_floor: "الدور",
            customer_room: "الغرقة",
            customer_landmark: "معلم",
            customer_additional: "بيانات اضافية",
          },
          dialog:{
            yes_button: "نعم",
            no_button: "لا",
            delete_title: "هل تريد حذف هذة المسودة؟",
            delete_message: "هذة العملية سوف تؤدي الي حذف نهائي للمسودة ولا يمكن استرجاعها",
            cancel_title: "هل تريد الغاء هذة الفاتورة؟",
            cancel_message: "هذة العملية سوف تؤدي الي الغاء نهائي للفاتورة ولا يمكن استرجاعها",
            change_customer_title: "هل تريد تغيير العميل?",
            change_customer_message: "هذه العملية سوف يؤدي إلي تغيير العميل وتغيير بحساب العميل السابق و الحالي",
          },
        }
      },
      fr: {
        appbar_search_placeholder: "Search...",
        drawer_invoice_button: "Amélioration continue",
        drawer_sentinvoice_button: "Sent Invoices",
        drawer_newinvoice_button: "New Invoice",
        drawer_drafts_button: "Drafts",
        drawer_invoices_button: "Invoices",
        drawer_invoice_totals_button: "Invoice Totals Report",
        drawer_invoice_details_button: "Invoice Details Report",
        drawer_invoice_menu: "Invoices",
        drawer_invoice_new_sale: "Sales",
        drawer_invoice_new_purchase: "Purchase",
        drawer_invoice_new_sale_return: "Sales return",
        drawer_invoice_new_purchase_return: "Purchase return",
        drawer_invoice_new_adjust_remove: "Adjust remove",
        drawer_invoice_new_adjust_add: "Adjust add",
        drawer_invoice_new_transfer: "Transfer",
        drawer_customers_menu: "Customers",
        drawer_customers_List: "Customer List",
        drawer_customers_add: "Add",
        drawer_products_menu: "Products",
        drawer_stock_List: "Stock",
        drawer_finance_List: "Finance",
        drawer_profit_List:"Profit",
        drawer_my_account:"My Account",
        drawer_users:"Users",
        drawer_settings:"ٍSettings",
        search_button: "Search",
        search_from: "From",
        search_to: "To",
        ratings: "Évaluations",
        product_type: {
          ware: "Ware",
          service: "Service",
          product: "Product"
        },
        get_num_dialog:{
          title: "Value",
        },
        date_range_dialog:{
          title: "Select Report Range",
          from: "From",
          to: "To",
        },
        change_password_dialog:{
          title: "Change Password",
          old_password: "Old Password",
          new_password: "New Password",
          confirm_new_password: "Confirm New Password",
        },
        dashboards:{
          create_invoice: "Create Invoice",
          shift_dashboard: "Shifts",
          open_shift: "Open Shift",
          close_shift: "Close Shift",
          shifts_data: "Shifts Data",
          stock_report: "Stock Report",
          items_with_stock_report: "Items With Stock",
          add_expense: "Add Expense",
          expenses_report: "Expenses Report",
          sync_data: "Refresh Data",
          change_password: "Change Password",
          privilages_list: "Privilages List",
          users_data: "Users Data",
          users_logs: "Users Logs",
          finance_indicators: "Finance Indicators",
          finance_indicators_profit: "Finance Indicators",
          products_add: "Add",
          products_List: "Product List",
          products_edit_pricing: "Edit Pricing",
          products_stock_less_than_min: "Products Less Than Min",
          products_stock_greater_than_max: "Products Greater Than Max",
          brokers_sales: "Brokers Sales",
          sales_persons_sales: "Sales Persons Sales",
          customers_sales: "Customers Sales",
          vendors_purchases: "Vendors Purchases",
          customers_profits: "Customers Profits",
          add_product_category: "Add Product Category",
          add_color: "Add Color",
          add_size: "Add Size",
          add_store: "Add Store",
          product_category_list: "Product Category List",
          color_list: "Color List",
          size_list: "Size List",
          store_list: "Store List",
          invoice_customers: "Invoice Customers",
          invoice_customers_sales: "Customers Sales",
          stock_average_cost: "Stock Average Cost",
          stock_category_cost: "Stock Category Cost",
          stock_transfer: "Stock Transfer",
          add_expense_type: "Add Expense Type",
          expense_type_list: "Expense Type List",
          set_expense_per_price: "Set Expense Per Price",
          categories_sales: "Categories Sales",
          categories_purchases: "Categories Purchases",
          stock_expired_days: "Stock Expired By Days",
          stock_without_transaction: "Stock Without Transaction",
          number_of_days: "Number Of Days",
          number_of_days_greater_than: "Number Of Days Greater Than",
          number_of_days_less_than: "Number Of Days Less Than",
          branch_name: "Branch: ",
          change_branch_title: "Change Branch",
          can_not_update_dueto_draft: "Can not apply action due to existance of drafts not inserted?",
          assign_pos: "Assign POS",
          unassign_pos: "Unassign POS",
        },
        
        customers_dashboards:{
          customers_section: "Customers",
          customers_list: "Customers List",
          customers_add: "Add",
          brokers_section: "Brokers",
          brokers_list: "Brokers List",
          brokers_add: "Add",
          sales_persons_section: "Sales Persons",
          sales_persons_list: "Sales Persons List",
          sales_persons_add: "Add",
          customers_with_balance: "Customers Balance",
          
        },
        customer_form:{
          title: "Customer Data",
          add_title: "Add Customer",
          update_button:"Update",
          add_button:"Add",
          id:"id",
          fullname:"Name",
          mobile: "Mobile",
          phone: "Phone",
          email: "Email",
          address: "Address",
          initial_balance: "Initial Balance",
          type: "Type",
          pay_button: "Pay",
          collect_button: "Collect",
          sales_discount_button: "Sales Discount",
          purchase_discount_button: "Purchase Discount",
          statment_button: "Statment",
          statment_detailed_button: "Detailed Statment",
        },
        lookup_form:{
          product_category_title: "Product Category",
          product_category_add_title: "Add Product Category",
          color_title: "Colors",
          color_add_title: "Add Color",
          size_title: "Sizes",
          size_add_title: "Add Size",
          store_title: "Stores",
          store_add_title: "Add Store",
          update_button:"Update",
          add_button:"Add",
          id:"id",
          category_name:"Name",
          color_name:"Name",
          size_name:"Name",
          name:"Name",

          expense_title: "Expense Types",
          account_name: "Expense Name",
        },
        broker_form:{
          title: "Broker Data",
          add_title: "Add Broker",
          update_button:"Update",
          add_button:"Add",
          id:"id",
          broker_name:"Name",
          phone: "Phone",
          email: "Email",
          comment: "Comment",
          year: "Year",
          month: "Month",
          total: "Total",
        },
        sales_person_form:{
          title: "Sales Person Data",
          add_title: "Add Sales Person",
          update_button:"Update",
          add_button:"Add",
          id:"id",
          sales_person_name:"Name",
          phone: "Phone",
          email: "Email",
          comment: "Comment",
          year: "Year",
          month: "Month",
          total: "Total",
        },
        document_form:{
          title_pay: "Collection Entry From: ",
          title_collect: "Add Collection Entry From: ",
          title_sales_discount: "Sales Discount to customer: ",
          title_purchase_discount: "Purchase discount from: ",
          title_expense: "Expenses",
          title_add_expense_type: "Add Expnese type",
          title_expense_type: "Type",
          finance_store: "Finance Store",
          accounting_months: "Accounting Months",
          amount: "Amount",
          date: "Date",
          note: "Note",
          description: "Description",
          submit_button: "Add",
          update_button: "Update",
          cancel_button: "Cancel",

          title_expense_per_price: "Expense Amount Per Price",
          expense: "Expense",
          sales_cost: "Sales Cost",
          expense_per_price: "Expense Per Price",
          cancel_dialog_title: "Cancel Expenses",
          cancel_dialog_message: "Are you sure, you want cancel expenses?",
          total: "Total",
          cancel_message: "This action will cancel document, are you agree?",
          cancel_title: "Cancel Document",
        },
        role_form: {
          title: "Privilages of Role:"
        },
        user_form:{
          title: "Data of User:",
          username: "Username",
          mobile_phone: "Mobile Phone",
          email: "Email",
          first_name: "First Name",
          last_name: "Last Name",
          role: "Role",
          update_button: "Update",
          reset_password: "Reset Password",
        },

        pos_form:{
          title: "Assign POS:",
          pos_name: "POS Name",
          point_name: "Point Name",
          reg_code: "Registration Code",
          assign_button: "Assign",
        },
        
        product_form:{
          title: "Product Data",
          add_title: "Add Product",

          id: 'id',
          international_code: 'International Code',
          user_defined_code: 'User Defined Code',
          product_name: 'Name',
          product_type: 'Type',
          product_category: 'Category',
          has_expire_date: 'Has Expire Date',
          is_medicine: 'Is Medicine',
          is_freez: 'Is Freez',
          is_returnable: 'Is Retunable',
          default_expiry_days: 'Default Expire Days',
          einv_code: 'Tax Code',
          product_color: "Color",
          product_size: "Size",
          min_quantity: "Minimum Quantity",
          max_quantity: "Maximun Quantity",
          pricing: 'Pricing',
          product_units: 'Units',
          product_components: 'Product Components',
          units:{
            add_button: "Add Unit",
            unit_name:"Unit",
            main_unit_quantity: "Quantity",
            is_main: "Main Unit",
            is_sales_default: "Sales",
            is_purchase_default:"Purchase"
          },
          product_price_list: "Pricing",
          price_list:{
            list_name:"Price List",
            price: "Price",
            default_list: "Default List",
          },

          update_button:"Update",
          add_button:"Add",
          open_all_trans_button: "Poduct Transactions",
          open_store_trans_button: "Poduct Stores Transactions",
          stock_in_all_branches: "Stock In All Branches",
        },
        customer_rep1: {
          title: "Customers and Vendors Report:",
          fullname: "Full Name",
          mobile: 'Mobile',
          phone: 'Phone',
          email: 'Email',
          address: 'Address',
          customer_type_name: 'Type',
          account: "Account",
          balance: "Balance",
          total: "Total",
        },
        commission_dashboard: {
          employees: "Employes",
          commission_categories: "Commission Categories",
        },
        employee_form:{
          title: "Employee Data",
          add_title: "Add Employee",
          id: 'id',
          fullname: 'Full Name',
          mobile: "Mobile",
          phone: "Phone",
          email: "Email",
          address: "Address",
          salary: "Salary",
          balance: "Balance",
          comment: "Comment",
          commission_category: "Commission Category", 
        },
        expense_rep1: {
          title: "Expenses Report:",
          document_date: "Date",
          description: "Note",
          amount: "Amount",
          accounting_months: "Accounting Months",
          comment: "Comment",  
          account_name: "Expense Item",
          document_type_name: "Document Type",
          transactions: "Transactions",
        },
        all_employee_report: {
          id: "id",
          fullname: "Name",
          balance: "Balance",
        },
        commission_categories_report: {
          id: "id",
          commission_category: "Category",
        },
        commission_amounts_report: {
          product_id: "id",
          product_name: "Product",
          commission: "Commission",
        },
        roles_list: {
          title: "Users Roles:",
          role_name: "Role Name",
        },
        users_list:{
          title: "Users:",
          username: "Username",
          mobile_phone: "Mobile Phone",
          email: "Email",
          first_name: "First Name",
          last_name: "Last Name",
        },
        users_logs: {
          log_date: "Date",
          log_action_type_name: "Action",
          log_object_type_name: "Type",
          object_id: "Item Id",
          username: "Username",
        },
        stock_rep1: {
          title: "Stock:",
          title_draft_available_stock: "Available Stock",
          title_stock_transfer: "Stock Transfer",
          user_defined_code: "Code",
          product_name: "Product",
          store_name: "Store",
          branch_name: "Branch",
          category_name: "Category",
          main_unit_quantity: "Quantity",
          title_min_quantity: "Minimum Quantity Report:",
          min_quantity: "Minimum Quantity",
          title_max_quantity: "Maximum Quantity Report:",
          max_quantity: "Maximum Quantity",
          available_stock: "Available Stock",
          purchase_price: "Purchase Price",
          average_cost_price: "Average Cost Price",
          total_cost: "Total Cost",
          total_cost_by_purchase_price:"Total Cost with Purchase Price",
          total: "Total",
          expire_date: "Expire Date",
          remaining_days: "Remaining Days To Expire",
          latest_sales_date: "Last Transaction Date",
          without_sales_days: "Without Sales Days",
          invoice_date: "Invoice Date",
          from_store: "From Store",
          to_store: "To Store",
        },
        customer_statment: {
          title: "Statment: ",
          title_detailed: "Detailed Statment",
          id: "id",
          document_date: "Date",
          document_type: "Type",
          credit: "Credit",
          debit: "Debit",
          balance: "Balance",
          comment: "Comment",
          item_name: "Item Name",
          quantity: "ََQuantity",
          unit_price: "Price",
          total: "Total",
          previouse_balance: "Previouse Balance",
        },
        product_rep1: {
          id: "id",
          title: "Products Report:",
          product_name: "Product Name",
          product_category_name: "Category",
          category_name: "Category",
          international_code: "International Code",
          user_defined_code: "User Code",
        },
        invoice_customer_rep1: {
          title: "Customers Report",
          title_sales: "Customers Sales",
          customer_phone: "Phone",
          customer_name: "Name",
          customer_company: "Company",
          customer_address: "Address",
          customer_field: "Field",
          year: "Year",
          month: "Month",
          total: "Total",
        },
        invoice_rep1: {
          id: "id",
          draft_id: "id",
          title: "Invoices:",
          title_drafts: "Drafts:",
          invoice_type: "Type",
          invoice_type_name: "Type",
          customer_name: "Customer",
          invoice_date: "Date",
          subtotal: "Sub Total",
          discount: "Discount",
          salesTotal: "Sales Total",
          tax: "Tax",
          additions: "Service",
          freight: "Freight",
          total: "Total",
          broker_name: "Broker",
          sales_person_name: "Sales Person",
          invoice_comment: "Note",
          invcustomer_name: "Invoice Customer",
          invcustomer_phone: "Invoice Customer Phone",
        },
        product_trans: {
          id: "id",
          title: "Product Transactions: ",
          invoice_date: "Date",
          invoice_type: "Type",
          store_name: "Store",
          main_unit_price: "Price",
          in: "In",
          out: "Out",
          stock: "Stock",
          fullname: "Customer",
          invoice_comment: "Notes",
        },
        finance_eports:{
          profit: "Profit",
        },
        nsi_table: {
          title: "Invoices Report",
          open: "open",
          customer: "Customer",
          date_time_issued: "Date",
          total_sales_amount: "Total Amount",
          net_amount: "Net Amount",
          tax_totals: "Tax Total",
          total_amount: "Total",
          signed: "signed",
          send: 'send',
          invoice_type: 'type',
        },
        invoice:{
          invoiceLine:{
            description: "Item Name",
            quantity: "Quantity",
            unitvalue: "Unit Price",
            salestotal: "Sales Total",
            discount_rate: "Discount Rate",
            discount_value: "Discount Value",
            net_total: "Total After Discount", 
            tax_rate: "Tax Rate",
            tax_value: "Tax Value",
            total: "Net Total"
          },
          dateTimeIssued: "Date",
          dialog:{
            yes_button: "Yes",
            no_button: "No",
            update_title: "Are you sure, you save invoice?",
            update_message: "This action will overwrite the invoice data",
            cancel_title: "Are you sure, you cancel invoice?",
            cancel_message: "This action will cancel invoice from electonic invoice",
          },
          update_button: "Update",
          cancel_button: "Cancel",
          print_button: "Print",
        },
        invoice_item:{
          code: 'Code',
          color: 'Color',
          size: 'Size',
          product: 'Product',
          unit: 'unit',
          price: 'Price',
          count: 'Count',
          discount_precent: 'Discount',
          tax_precent: 'Tax',
          withholdingtax: 'Withholding Tax',
          total: 'Total',
          store: 'Store',
          expire_date: "Expire Date",
          transfer_store: 'Transfer To Store',
          quantity: "Quantity",
          suggested_price:"Suggested",
          comment1: "note",
        },
        new_invoice:{
          invoice_discount_mode: "Discount Mode",
          invoice_tax_mode: "Tax Mode",
          invoice_comment: "comment",
          invoice_date: "Date",
          cash_invoice: "Cash Invoice",
          use_tax: "Use Tax",
          invoice_customer: "Customer",
          invoice_broker: "Broker",
          invoice_sales_person: "Sales Person",
          delete_button: "Delete",
          cancel_button: "Cancel",
          add_button: "Add",
          stock_button: "Stock",
          uavailable_stock_button: "Unavailable Stock",
          update_button: "Update",
          draw_button: "Draw Stock",
          print_button: "Print",
          new_draft_button: "New Draft",
          invcustomer_phone: "Customer Phone",
          invcustomer_name: "Customer Name",
          invcustomer_company: "Company",
          invcustomer_address: "Address",
          invcustomer_field: "Field",
          data_not_saved_msg: "Changes you made may not be saved, Are you sure you want to leave?",
          tax_customer:{
            type: "Type",
            id: "Id",
            name: "Name",
            country: "Country",
            gov: "Governerate",
            city: "City",
            street: "Street",
            building_no: "Building No",
            zip_code: "Zip Code",
            floor: "Floor",
            room: "Room",
            landmark: "Landmark",
            additional: "Additional Information",
          },
          type:{
            title_name1: "Sales Order",
            title_draft_name1: "Sales Order Draft",
            title_color1: "#1565c0",
            title_name2: "Purchase Order",
            title_draft_name2: "Purchase Order Draft",
            title_color2: "#1b5e20",
            title_name3: "Sales return",
            title_draft_name3: "Sales return Draft",
            title_color3: "#42a5f5",
            title_name4: "Adjust remove",
            title_draft_name4: "Adjust remove Draft",
            title_color4: "#c62828",
            title_name6: "Purchase return",
            title_draft_name6: "Purchase return Draft",
            title_color6: "#4caf50",
            title_name7: "Adjust add",
            title_draft_name7: "Adjust add Draft",
            title_color7: "#e65100",
            title_name8: "Transfer stock",
            title_color8: "##FFB400",
          },
          accordion1:{
            title: "Customer Data",
          },
          accordion2:{
            title: "Invoice Data",
            date: "Date",
            branch: "Branch",
            tax_precent: "Tax Precent",
          },
          table:{
          code: 'Code',
          product: 'Product',
          unit: 'Unit',
          price: 'Price',
          count: 'Count',
          discount: 'Discount',
          tax: 'Tax',
          total: 'Total',
          expire_date: 'Expire Date',
          store: "Store",
          comment1: "Comment",
          },
          totals:{
            salesTotal: "Total",
            discount_percentage: "Precent",
            discount: "Discount",
            tax_percentage: "Precent",
            tax: "Tax",
            freight: "Freight",
            total: "Net Total",
          },
          payment_amount_pay: "Payment Paied",
          payment_amount_collect: "Payment Collected",
          customer: {
            customer_type: "Type",
            customer_id: "Id",
            customer_name: "Name",
            customer_country: "Country",
            customer_gov: "Governerate",
            customer_city: "City",
            customer_street: "Street",
            customer_building_no: "Building No",
            customer_zip_code: "Zip Code",
            customer_floor: "Floor",
            customer_room: "Room",
            customer_landmark: "Landmark",
            customer_additional: "Additional Information",
          },
          dialog:{
            yes_button: "Yes",
            no_button: "No",
            delete_title: "Are you sure, you want delete this Draft?",
            delete_message: "Deleting this Draft will remove it permanently",
            cancel_title: "Are you sure, you want cancel this Invoice?",
            cancel_message: "Deleting this Invoice will cancel it permanently",
            change_customer_title: "Are you sure, you want change Customer?",
            change_customer_message: "This action will change invoice customer",
          },
        }
      },
    },
  };


// const initialState: LanguageState = {
//     lang: 'en',
//     direction: 'ltr',
//     textAlign: 'left'
// }


export const i18nSlice = createSlice({
  name: 'i18n',
  initialState,
  reducers: {
    setLang: (state: any, action: PayloadAction<string>) => {
      state.lang = action.payload;
      if(action.payload === "ar"){
        state.direction = 'rtl';
      }else{
        state.direction = 'ltr';
      }
    },
  },
});

export const { setLang } = i18nSlice.actions;
export const selectTranslations  = (state: RootState) => state.i18n.translations[state.i18n.lang];
export const selectDirection = (state: RootState) => state.i18n.direction;

  
export default i18nSlice.reducer;
  
//https://phrase.com/blog/posts/roll-your-own-i18n-solution-react-redux/