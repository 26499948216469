import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { selectDirection, selectTranslations } from "../../features/i18n/i18nSlice";
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { getCustomer, customerCallStatus, customerData, setCustomerData, updateCustomerData } from './customerSlice'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux';
import { height } from '@mui/system';
import LinearProgress from '@mui/material/LinearProgress';
import { useParams } from 'react-router-dom';
import SaveIcon from '@mui/icons-material/Save';
import { AppBar, Divider, Paper, Toolbar } from '@mui/material';
import api from '../services/api';
import { Broker, SalesPerson } from '../../models/Customer';
import { reSyncData } from '../login/loginSlice';



interface ApiStatus {
  error: string;
  status: 'idle' | 'loading' | 'succeeded' | 'failed'
}

type Props = {}
const theme = createTheme();

export default function SalesPersonForm() {
  const params = useParams();
  const { salesPersonId } = params
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const [salesPerson, setSalesPerson] = React.useState<SalesPerson>()
  const [changesNotSaved, setChangesNotSaved] = React.useState<boolean>(false);

  const [apiCallStatus, setApiCallStatus] = React.useState<ApiStatus>({ error: "", status: "idle" })
  const isLoading = () => apiCallStatus.status === "loading";
  const isSucceeded = () => apiCallStatus.status === "succeeded";
  const isIdle = () => apiCallStatus.status === "idle";
  const isFailed = () => apiCallStatus.status === "failed";

  const trans = useAppSelector(selectTranslations);




  const onInputValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSalesPerson({...salesPerson, [event.target.name]: event.target.value} as SalesPerson)
    setChangesNotSaved(true)
  }

  const onUpdate = () => {
    updateSalesPerson()
    if (isSucceeded()) setChangesNotSaved(false)
  }




  const getSalesPerson = async () => {
    setApiCallStatus({ error: "", "status": "loading" })
    try {
      const response = await api.get(
        `/salesperson/${salesPersonId}`,
        { headers: { "Content-Type": "application/json" } }
      )

      if (response.status === 200) {

        setSalesPerson(response.data["salesPerson"])
        setApiCallStatus({ error: "", "status": "succeeded" })
      } else {
        setApiCallStatus({ error: response.statusText, "status": "failed" })
      }

    } catch (err: any) {
      setApiCallStatus({ error: err.message, "status": "failed" })
    }
  };

  const updateSalesPerson = async () => {
    setApiCallStatus({ error: "", "status": "loading" })
    try {
      const response = await api.put(
        '/salesperson',
        salesPerson,
        { headers: { "Content-Type": "application/json" } }
      )

      if (response.status === 200) {
        dispatch(reSyncData())
        setSalesPerson(response.data["salesPerson"])
        setChangesNotSaved(false)
        setApiCallStatus({ error: "", "status": "succeeded" })
      } else {
        alert(response.data['msg'])
        setApiCallStatus({ error: response.statusText, "status": "failed" })
      }

    } catch (err: any) {
      alert(err.response.data['msg'])
      setApiCallStatus({ error: err.message, "status": "failed" })
    }
  };



  React.useEffect(() => {
    getSalesPerson()
  }, []);



  return (
      <Container>
      <AppBar position="static" sx={{backgroundColor:"#283593"}}>
      {/* <Toolbar sx={{ borderBottom: 1, borderColor: 'divider' }} variant="dense" > */}
      <Toolbar component="nav" variant="dense" sx={{ justifyContent: 'space-between', overflowX: 'auto' }}  >
        {/* <Typography variant='h4'>{trans.sales_person_form.title}</Typography> */}
        {/* <Typography component="h2" variant="h5" color="primary" gutterBottom> */}
        <Typography component="h2" variant="h5">
          {trans.sales_person_form.title}
        </Typography>
        </Toolbar>
        </AppBar>
        {/* <Divider /> */}
        {isLoading() && <LinearProgress />}
        {(isSucceeded() || isIdle() || isFailed())&& (
          <>
            <Grid container>
              <Grid item sm={12} md={6} lg={6} padding={2}>
                <Box sx={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                  <TextField id="id" name="id"  label={trans.sales_person_form.id} variant="standard" size='small'  value={salesPersonId} />
                  <TextField id="sales_person_name" name="sales_person_name" label={trans.sales_person_form.sales_person_name} variant="standard" size='small' onChange={onInputValueChange} value={salesPerson?.sales_person_name} required />
                  <TextField id="phone" name="phone" label={trans.sales_person_form.phone} variant="standard" size='small' onChange={onInputValueChange} value={salesPerson?.phone} />
                  <TextField id="email" name="email" type='email' label={trans.sales_person_form.email} variant="standard" size='small' onChange={onInputValueChange} value={salesPerson?.email} />
                  <TextField id="comment" name="comment" label={trans.sales_person_form.comment} variant="standard" size='small' onChange={onInputValueChange} value={salesPerson?.comment} />
                </Box>
              </Grid>
            </Grid>

            <Paper elevation={1} sx={{ marginY: 2 }}>
              <Box sx={{ display: 'flex', flexWrap: 'wrap', padding: 2, justifyContent: 'space-around' }}>
                <Button variant="contained" startIcon={<SaveIcon sx={{ marginRight: 0, marginLeft: 1 }} />} onClick={onUpdate} sx={{ margin: '2px' }} size="small" disabled={!changesNotSaved}>
                  {trans.sales_person_form.update_button}
                </Button>
              </Box>
            </Paper>

          </>)}
      </Container>
  );
}