import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../../app/store';
import api from '../services/api'
// import getBrowserFingerprint from 'get-browser-fingerprint';
import { ClientJS } from 'clientjs';

import { db, recreateDB } from "../../db";
import Product from '../../components/product/Product';
import { Colors, PriceList, ProductCatalog, ProductCategory, ProductType, Sizes } from '../../models/ProductCatalog';
import { Broker, Customer, CustomerType, SalesPerson } from '../../models/Customer';
import { StoreHouse } from '../../models/StoreHouse';
import { useLiveQuery } from 'dexie-react-hooks';
import { InvoiceDiscountMode, InvoiceTaxMode } from '../../models/Invoice';
import { AppConfig, AppConfiguration } from '../../models/AppConfig';
import { stat } from 'fs';
import { Buffer } from "buffer";
import { POS } from '../../models/POS';
import { Shift } from '../../models/shift';
import { nanoid } from 'nanoid';

var bcrypt = require('bcryptjs');
var CryptoJS = require("crypto-js");
interface Permission {
  permission_id: number;
}

export interface LoginState {
  login: boolean;
  verify: boolean;
  firstLogin: boolean;
  username: string | null;
  tempparam: string | null;
  accessToken: string | null;
  refreshToken: string | null;
  branch_id: number;
  permissions: Permission[];
  branches: [];
  draftCount: number;
  blocked: boolean;
  error: string;
  online: boolean;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  envUrl: string;
  pos: POS | null;
  useShift: boolean;
  shift: Shift | null
  newLoginTrial: number;
}

const initialState: LoginState = {
  login: false,
  verify: false,
  firstLogin: false,
  username: null,
  tempparam: null,
  accessToken: null,
  refreshToken: null,
  branch_id: 0,
  permissions: [],
  branches: [],
  draftCount: 0,
  blocked: true,
  error: "",
  online: false,
  status: 'idle',
  envUrl: "",
  pos: null,
  useShift: false,
  shift: null,
  newLoginTrial: 0,
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
// export const incrementAsync = createAsyncThunk(
//   'counter/fetchCount',
//   async (amount: number) => {
//     const response = await fetchCount(amount);
//     // The value we return becomes the `fulfilled` action payload
//     return response.data;
//   }
// );

export const loginSlice = createSlice({
  name: 'login',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    logout: (state: any) => {

      state.login = false
      state.verify = false
      state.firstLogin = false
      state.username = null
      state.accessToken = null
      state.refreshToken = null
      state.branch_id = 0
      state.permissions = []
      state.branches = []
      state.draftCount = 0
      state.blocked = true
      state.error = ""
      state.online = false
      state.status = 'idle'
      state.envUrl = ""
      // state = initialState
    },
    addRequest: (state: any, action: PayloadAction<{ username: string }>) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.username = action.payload.username;
      state.status = 'loading';
    },
    addRequesting: (state: any) => {
      state.status = 'loading';
    },
    actionScceeded: (state: any) => {
      state.status = 'succeeded';
    },
    initSyncCompleted: (state: any, action: any) => {
      state.login = true;
      state.useShift = action.payload.useShift
      state.status = 'succeeded';
    },
    loginCompleted: (state: any) => {
      state.login = true;
      state.status = 'succeeded';
    },
    actionFailed: (state: any, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.status = 'failed';
    },
    newSyncFailed: (state: any, action: PayloadAction<string>) => {
      state.login = true; // although failure it will loging and go offline after first request
      state.error = action.payload;
      state.status = 'succeeded';
      state.newLoginTrial += 1
    },
    loginScceeded: (state: any, action: any) => {
      // state.login = true;
      state.verify = false;
      state.accessToken = action.payload.access_token;
      state.refreshToken = action.payload.refresh_token;
      state.permissions = action.payload.permissions
      state.branch_id = action.payload.branch_id
      state.role = action.payload.role;
      state.error = "";
      state.online = true;
      state.envUrl = action.payload.env_url;
      state.pos = action.payload.pos
      state.useShift = action.payload.useShift
      state.shift = action.payload.shift


      // state.status = 'succeeded';
    },
    tryLoginScceeded: (state: any, action: any) => {
      // state.login = true;
      state.verify = false;
      state.accessToken = action.payload.access_token;
      state.refreshToken = action.payload.refresh_token;
      state.permissions = action.payload.permissions
      state.branch_id = action.payload.branch_id
      state.role = action.payload.role;
      state.error = "";
      state.online = true;
      state.envUrl = action.payload.env_url;
      state.pos = action.payload.pos
      state.useShift = action.payload.useShift
      state.shift = action.payload.shift
      state.tempparam = null
      // state.status = 'succeeded';
    },
    tryReLoginScceeded: (state: any) => {
      // state.login = true;
      state.online = true;
    },
    tryLoginFailure: (state: any) => {
      state.newLoginTrial += 1
      console.log("newLoginTrial to: " + state.newLoginTrial.toString())

    },
    loginOfflineScceeded: (state: any, action: any) => {
      state.login = true;
      state.verify = false;
      state.error = "";
      state.online = false;
      state.status = 'succeeded';
      state.pos = action.payload.pos
      state.useShift = action.payload.useShift
      state.shift = action.payload.shift
      state.tempparam = action.payload.tempparam
      state.newLoginTrial += 1

    },
    // Use the PayloadAction type to declare the contents of `action.payload`
    loginFailure: (state: any, action: PayloadAction<string>) => {

      state.login = false;
      state.verify = false;
      state.accessToken = null;
      state.refreshToken = null;
      state.branch_id = 0;
      state.admin = false;
      state.error = action.payload;
      state.status = 'failed';
    },
    posAssigned: (state: any, action: any) => {
      state.pos = action.payload
      state.status = 'succeeded';
    },
    posUnassigned: (state: any) => {
      state.pos = null
      state.status = 'succeeded';
    },
    shiftOpned: (state: any, action: any) => {
      state.shift = action.payload
      state.status = 'succeeded';
    },
    setUseShift: (state: any, action: any) => {
      state.useShift = action.payload.useShift
    },
    shiftClosed: (state: any) => {
      state.shift = null
      state.status = 'succeeded';
    },
    needVerification: (state: any, action: PayloadAction<string>) => {

      state.login = false;
      state.verify = true;
      state.accessToken = null;
      state.refreshToken = null;
      state.branch_id = 0;
      state.admin = false;
      state.error = action.payload;
      state.status = 'failed';
    },
    hideVerification: (state: any) => {
      state.verify = false;
    },
    updateToken: (state: any, action: any) => {
      state.accessToken = action.payload;
    },

    setDraftCount: (state: any, action: PayloadAction<number>) => {
      state.draftCount = action.payload;
    },
    setAppOffline: (state: any) => {
      state.online = false;
      state.newLoginTrial += 1
    },

  },
  // // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // // including actions generated by createAsyncThunk or in other slices.
  // extraReducers: (builder) => {
  //   builder
  //     .addCase(incrementAsync.pending, (state) => {
  //       state.status = 'loading';
  //     })
  //     .addCase(incrementAsync.fulfilled, (state, action) => {
  //       state.status = 'idle';
  //       state.value += action.payload;
  //     })
  //     .addCase(incrementAsync.rejected, (state) => {
  //       state.status = 'failed';
  //     });
  // },
});

export const { addRequest, addRequesting, actionScceeded, initSyncCompleted, loginCompleted, actionFailed, newSyncFailed, loginScceeded, tryLoginScceeded, tryReLoginScceeded, tryLoginFailure, loginOfflineScceeded, loginFailure, posAssigned, posUnassigned, shiftOpned, shiftClosed, setUseShift, needVerification, hideVerification, updateToken, setDraftCount, setAppOffline, logout } = loginSlice.actions;
export const isAuthenticated = (state: RootState) => state.login.login;
export const useVerfication = (state: RootState) => state.login.verify;
export const actionCallStatus = (state: RootState) => state.login.status;

export const selectDraftsCount = (state: RootState) => state.login.draftCount;
export const isOnline = (state: RootState) => state.login.online;
export const branchId = (state: RootState) => state.login.branch_id;
export const currentPos = (state: RootState) => state.login.pos;
export const isUseShift = (state: RootState) => state.login.useShift;
export const currentShift = (state: RootState) => state.login.shift;
export const tempParam = (state: RootState) => state.login.tempparam;
export const currentUsername = (state: RootState) => state.login.username;
export const currentNewLoginTrial = (state: RootState) => state.login.newLoginTrial;

const checkPermission = (state: RootState, permissionId: number, actionPermission: boolean = true) => state.login.permissions.find(p => (actionPermission && (p.permission_id === 1 || p.permission_id === 2 || p.permission_id === permissionId) || p.permission_id === permissionId)) ? true : false;

export const permSupperAdmin = (state: RootState) => checkPermission(state, 1)
export const permAdmin = (state: RootState) => checkPermission(state, 2)
export const permReadInvoices = (state: RootState) => checkPermission(state, 3)
export const permAddInvoice = (state: RootState) => checkPermission(state, 4)
export const permUpdateInvoices = (state: RootState) => checkPermission(state, 5)
export const permReadProducts = (state: RootState) => checkPermission(state, 6)
export const permAddProduct = (state: RootState) => checkPermission(state, 7)
export const permUpdateProducts = (state: RootState) => checkPermission(state, 8)
export const permReadStock = (state: RootState) => checkPermission(state, 9)
export const permReadExpenses = (state: RootState) => checkPermission(state, 10)
export const permAddExpenses = (state: RootState) => checkPermission(state, 11)
export const permUpdateExpenses = (state: RootState) => checkPermission(state, 12)
export const permReadCustomers = (state: RootState) => checkPermission(state, 13)
export const permAddCustomer = (state: RootState) => checkPermission(state, 14)
export const permUpdateCustomers = (state: RootState) => checkPermission(state, 15)
export const permReadUsers = (state: RootState) => checkPermission(state, 16)
export const permUpdateUsers = (state: RootState) => checkPermission(state, 17)
export const permReadFinanceReports = (state: RootState) => checkPermission(state, 18)
export const permSaleLessThanSalesPrice = (state: RootState) => checkPermission(state, 19)
export const permCashierOnly = (state: RootState) => checkPermission(state, 20, false)

// export const permSupperAdmin = (state: RootState) => state.login.permissions.find(p => (p.permission_id === 1 || p.permission_id === 2 || p.permission_id === 1)) ? true : false;
// export const permAdmin = (state: RootState) => state.login.permissions.find(p => (p.permission_id === 1 || p.permission_id === 2 || p.permission_id === 2)) ? true : false;
// export const permReadInvoices = (state: RootState) => state.login.permissions.find(p => (p.permission_id === 1 || p.permission_id === 2 || p.permission_id === 3)) ? true : false;
// export const permAddInvoices = (state: RootState) => state.login.permissions.find(p => (p.permission_id === 1 || p.permission_id === 2 || p.permission_id === 4)) ? true : false;
// export const permUpdateInvoices = (state: RootState) => state.login.permissions.find(p => (p.permission_id === 1 || p.permission_id === 2 || p.permission_id === 5)) ? true : false;
// export const permReadProducts = (state: RootState) => state.login.permissions.find(p => (p.permission_id === 1 || p.permission_id === 2 || p.permission_id === 6)) ? true : false;
// export const permReadStock = (state: RootState) => state.login.permissions.find(p => (p.permission_id === 1 || p.permission_id === 2 || p.permission_id === 9)) ? true : false;
// export const permReadExpenses = (state: RootState) => state.login.permissions.find(p => (p.permission_id === 1 || p.permission_id === 2 || p.permission_id === 10)) ? true : false;
// export const permReadCustomers = (state: RootState) => state.login.permissions.find(p => (p.permission_id === 1 || p.permission_id === 2 || p.permission_id === 13)) ? true : false;
// export const permReadUsers = (state: RootState) => state.login.permissions.find(p => (p.permission_id === 1 || p.permission_id === 2 || p.permission_id === 16)) ? true : false;
// export const permReadFinanceReports = (state: RootState) => state.login.permissions.find(p => (p.permission_id === 1 || p.permission_id === 2 || p.permission_id === 18)) ? true : false;

// // The function below is called a selector and allows us to select a value from
// // the state. Selectors can also be defined inline where they're used instead of
// // in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
// export const selectCount = (state: RootState) => state.counter.value;

// // We can also write thunks by hand, which may contain both sync and async logic.
// // Here's an example of conditionally dispatching actions based on current state.
// export const incrementIfOdd =
//   (amount: number): AppThunk =>
//   (dispatch, getState) => {
//     const currentValue = selectCount(getState());
//     if (currentValue % 2 === 1) {
//       dispatch(incrementByAmount(amount));
//     }
//   };


export const refreshDB = (): AppThunk => async (dispatch, getState) => {
  await db.RemoveAll()
  dispatch(logout())
  // await recreateDB(db)
  // dispatch(getSyncDataRequest())
}


export const getSyncDataRequest = (username: string, password: string, branchId: number, branchName: string): AppThunk => async (dispatch, getState) => {
  dispatch(addRequesting());
  try {

    const response = await api.get(
      "/initsyncdata",
      { headers: { "Content-Type": "application/json" } }
    )
    // console.log(data);
    // console.log(response);

    if (response.status === 200) {
      let useShift: boolean = false;

      db.transaction('rw', db.syncData, db.productCatalog, db.customer, db.storeHouse, async () => {

        //
        // Transaction Scope
        //

        let r = await db.syncData.bulkAdd(response.data['sync_data']['sync_table'].map((item: any) => item.id === 8 ? { ...item, last_update_timestamp: -1 } : item)) // for initial sync the invoice update flag should be -1 to cash all invoices again
        await db.productCatalog.bulkAdd(response.data['sync_data']['product_catalog'])
        await db.customer.bulkAdd(response.data['sync_data']['customer'])
        await db.storeHouse.bulkAdd(response.data['sync_data']['store'])

        // await db.branch.bulkAdd(response.data['sync_data']['branches'])

        // const friend = await db.friends.get({name: "David"});
        // ++friend.age;
        // await db.friends.put(friend);

      }).then(() => {

        //npm i --save-dev @types/clientjs
        // Transaction Complete
        //

        console.log("Transaction committed");

      }).catch(err => {

        //
        // Transaction Failed
        //

        console.error(err.stack);
      });




      db.transaction('rw', db.priceList, db.productType, db.productCategory, db.invoiceDiscountMode, async () => {
        let r = await db.priceList.bulkAdd(response.data['sync_data']['price_list'])
        await db.productType.bulkAdd(response.data['sync_data']['product_type'])
        await db.productCategory.bulkAdd(response.data['sync_data']['product_category'])
        await db.invoiceDiscountMode.bulkAdd(response.data['sync_data']['invoice_discount_mode'])


      }).then(() => {
        console.log("Transaction committed");
      }).catch(err => {
        console.error(err.stack);
      });



      db.transaction('rw', db.invoiceTaxMode, db.customerType, db.broker, db.salesPerson, async () => {
        let r = await db.invoiceTaxMode.bulkAdd(response.data['sync_data']['invoice_tax_mode'])
        await db.customerType.bulkAdd(response.data['sync_data']['customer_type'])
        await db.broker.bulkAdd(response.data['sync_data']['brokers'])
        await db.salesPerson.bulkAdd(response.data['sync_data']['sales_person'])


      }).then(() => {
        console.log("Transaction committed");
      }).catch(err => {
        console.error(err.stack);
      });



      db.transaction('rw', db.colors, db.sizes, db.appConfiguration, async () => {
        let r = await db.colors.bulkAdd(response.data['sync_data']['colors'])
        await db.sizes.bulkAdd(response.data['sync_data']['sizes'])
        await db.appConfiguration.bulkAdd(response.data['sync_data']['app_configuration'])

      }).then(async () => {
        const appConfItem1 = await db.appConfiguration.get(1)
        if (appConfItem1) useShift = appConfItem1!.configuration_value === "1"

        console.log("Transaction committed");
      }).catch(err => {
        console.error(err.stack);
      });

      await addInitConfigs(username, password, branchId, branchName)

      dispatch(initSyncCompleted<any>({ useShift }));
    } else {
      dispatch(actionFailed(response.statusText));
    }

  } catch (err: any) {
    dispatch(actionFailed(err.message));
  }
};

const addInitConfigs = async (username: string, password: string, branchId: number, branchName: string) => {
  var salt = bcrypt.genSaltSync(10);
  var usernameHash = bcrypt.hashSync(username, salt);
  var passwordHash = bcrypt.hashSync(password, salt);

  await db.appConfig.put({ id: 1, config_name: 'config1', config_value: usernameHash } as AppConfig)
  await db.appConfig.add({ id: 2, config_name: 'config2', config_value: passwordHash } as AppConfig)
  await db.appConfig.add({ id: 3, config_name: 'branch_id', config_value: branchId.toString() } as AppConfig)
  await db.appConfig.add({ id: 4, config_name: 'branch_name', config_value: branchName } as AppConfig)
}




export const getNewSyncRequest = (ut: number): AppThunk => async (dispatch, getState) => {
  dispatch(addRequesting());
  try {

    const response = await api.get(
      "/syncdata",
      {
        params: { ut },
        headers: { "Content-Type": "application/json" }
      }
    )
    // console.log(data);
    // console.log(response);

    if (response.status === 200) {

      db.transaction('rw', db.syncData, db.productCatalog, db.customer, db.storeHouse, async () => {

        // response.data['sync_data']['branches']?.forEach(element => {
        //   db.branch.put(element)
        // });

        response.data['sync_data']['sync_table']?.forEach((element: any) => {
          db.syncData.put(element)
        });

        response.data['sync_data']['product_catalog']?.forEach((element: ProductCatalog) => {
          db.productCatalog.put(element)
        });

        response.data['sync_data']['customer']?.forEach((element: Customer) => {
          db.customer.put(element)
        });

        response.data['sync_data']['store']?.forEach((element: StoreHouse) => {
          db.storeHouse.put(element)
        });




      }).then(() => {
        console.log("Transaction committed");
      }).catch(err => {
        console.error(err.stack);
      });




      db.transaction('rw', db.priceList, db.productType, db.productCategory, db.invoiceDiscountMode, async () => {

        response.data['sync_data']['price_list']?.forEach((element: PriceList) => {
          db.priceList.put(element)
        });
        response.data['sync_data']['product_type']?.forEach((element: ProductType) => {
          db.productType.put(element)
        });
        response.data['sync_data']['product_category']?.forEach((element: ProductCategory) => {
          db.productCategory.put(element)
        });
        response.data['sync_data']['invoice_discount_mode']?.forEach((element: InvoiceDiscountMode) => {
          db.invoiceDiscountMode.put(element)
        });


      }).then(() => {
        console.log("Transaction committed");
      }).catch(err => {
        console.error(err.stack);
      });


      db.transaction('rw', db.invoiceTaxMode, db.customerType, db.broker, db.salesPerson, async () => {
        response.data['sync_data']['invoice_tax_mode']?.forEach((element: InvoiceTaxMode) => {
          db.invoiceTaxMode.put(element)
        });
        response.data['sync_data']['customer_type']?.forEach((element: CustomerType) => {
          db.customerType.put(element)
        });
        response.data['sync_data']['brokers']?.forEach((element: Broker) => {
          db.broker.put(element)
        });
        response.data['sync_data']['sales_person']?.forEach((element: SalesPerson) => {
          db.salesPerson.put(element)
        });

      }).then(() => {
        console.log("Transaction committed");
      }).catch(err => {
        console.error(err.stack);
      });


      db.transaction('rw', db.colors, db.sizes, db.appConfiguration, async () => {
        let useShift: boolean = false;

        response.data['sync_data']['colors']?.forEach((element: Colors) => {
          db.colors.put(element)
        });
        response.data['sync_data']['sizes']?.forEach((element: Sizes) => {
          db.sizes.put(element)
        });
        response.data['sync_data']['app_configuration']?.forEach((element: AppConfiguration) => {
          db.appConfiguration.put(element)
        });


        const appConfItem1 = await db.appConfiguration.get(1)
        if (appConfItem1) useShift = appConfItem1!.configuration_value === "1"
        dispatch(setUseShift<any>({ useShift }));

      }).then(() => {
        console.log("Transaction committed");
      }).catch(err => {
        console.error(err.stack);
      });



      // dispatch(actionScceeded());
      dispatch(loginCompleted());
    } else {
      dispatch(newSyncFailed(response.statusText));
    }





  } catch (err: any) {
    dispatch(newSyncFailed(err.message));
  }




};



export const getNewSyncRequestBackground = (ut: number): AppThunk => async (dispatch, getState) => {
  // dispatch(addRequesting());
  try {

    const response = await api.get(
      "/syncdata",
      {
        params: { ut },
        headers: { "Content-Type": "application/json" }
      }
    )
    // console.log(data);
    // console.log(response);

    if (response.status === 200) {

      db.transaction('rw', db.syncData, db.productCatalog, db.customer, db.storeHouse, async () => {

        // response.data['sync_data']['branches']?.forEach(element => {
        //   db.branch.put(element)
        // });

        response.data['sync_data']['sync_table']?.forEach((element: any) => {
          db.syncData.put(element)
        });

        response.data['sync_data']['product_catalog']?.forEach((element: ProductCatalog) => {
          db.productCatalog.put(element)
        });

        response.data['sync_data']['customer']?.forEach((element: Customer) => {
          db.customer.put(element)
        });

        response.data['sync_data']['store']?.forEach((element: StoreHouse) => {
          db.storeHouse.put(element)
        });




      }).then(() => {
        console.log("Transaction committed");
      }).catch(err => {
        console.error(err.stack);
      });




      db.transaction('rw', db.priceList, db.productType, db.productCategory, db.invoiceDiscountMode, async () => {

        response.data['sync_data']['price_list']?.forEach((element: PriceList) => {
          db.priceList.put(element)
        });
        response.data['sync_data']['product_type']?.forEach((element: ProductType) => {
          db.productType.put(element)
        });
        response.data['sync_data']['product_category']?.forEach((element: ProductCategory) => {
          db.productCategory.put(element)
        });
        response.data['sync_data']['invoice_discount_mode']?.forEach((element: InvoiceDiscountMode) => {
          db.invoiceDiscountMode.put(element)
        });


      }).then(() => {
        console.log("Transaction committed");
      }).catch(err => {
        console.error(err.stack);
      });


      db.transaction('rw', db.invoiceTaxMode, db.customerType, db.broker, db.salesPerson, async () => {
        response.data['sync_data']['invoice_tax_mode']?.forEach((element: InvoiceTaxMode) => {
          db.invoiceTaxMode.put(element)
        });
        response.data['sync_data']['customer_type']?.forEach((element: CustomerType) => {
          db.customerType.put(element)
        });
        response.data['sync_data']['brokers']?.forEach((element: Broker) => {
          db.broker.put(element)
        });
        response.data['sync_data']['sales_person']?.forEach((element: SalesPerson) => {
          db.salesPerson.put(element)
        });

      }).then(() => {
        console.log("Transaction committed");
      }).catch(err => {
        console.error(err.stack);
      });


      db.transaction('rw', db.colors, db.sizes, db.appConfiguration, async () => {
        let useShift: boolean = false;

        response.data['sync_data']['colors']?.forEach((element: Colors) => {
          db.colors.put(element)
        });
        response.data['sync_data']['sizes']?.forEach((element: Sizes) => {
          db.sizes.put(element)
        });
        response.data['sync_data']['app_configuration']?.forEach((element: AppConfiguration) => {
          db.appConfiguration.put(element)
        });


        const appConfItem1 = await db.appConfiguration.get(1)
        if (appConfItem1) useShift = appConfItem1!.configuration_value === "1"
        dispatch(setUseShift<any>({ useShift }));

      }).then(() => {
        console.log("Transaction committed");
      }).catch(err => {
        console.error(err.stack);
      });



      // dispatch(actionScceeded());
      dispatch(loginCompleted());
    } else {
      // dispatch(actionFailed(response.statusText));
    }





  } catch (err: any) {
    // dispatch(actionFailed(err.message));
  }




};


export const tryLoginRequest = (): AppThunk => async (dispatch, getState) => {

  const state: RootState = getState();
  const authenticated = state.login.login; //isAuthenticated(getState());
  const online = state.login.online; //isOnline(getState())
  const accessToken = state.login.accessToken;

  if (authenticated && !online && accessToken !== null) {
    try {
      const response = await api.get(
        "user/conn",
        { headers: { "Content-Type": "application/json" } }
      )

      if (response.status === 200) {
        dispatch(tryReLoginScceeded());
        dispatch(syncOfflineInvoices());
      } else {
        dispatch(tryLoginFailure());
      }

    } catch (err: any) {
      if (err.code === 'ERR_NETWORK') {

      }
      else if (err.response.status === 405) {

      } else {

      }
      dispatch(tryLoginFailure());
    }

  } else if (authenticated && !online) {

    // dispatch(addRequest({ username }));
    const username = state.login.username; //currentUsername(getState());
    const passwordPlain = state.login.tempparam; //tempParam(getState());
    const pos = state.login.pos; //currentPos(getState());
    const shift = state.login.shift; //currentShift(getState());
    try {

      // const fingerprint = getBrowserFingerprint();


      // Create a new ClientJS object
      const client = new ClientJS();
      // Get the client's fingerprint id
      const fp = client.getFingerprint();

      const hash = CryptoJS.MD5(passwordPlain)
      const hashBase64 = hash.toString(CryptoJS.enc.Base64);
      const salt = bcrypt.genSaltSync(10);
      const passHash = bcrypt.hashSync(hashBase64, salt);
      const password = Buffer.from(passHash, 'binary').toString('base64');



      const payload = { username, password, fp }

      // const syncData = useLiveQuery(
      //   () => db.syncData.toArray()
      // );



      let useShift: boolean = false;
      const appConfItem1 = await db.appConfiguration.get(1)
      if (appConfItem1) useShift = appConfItem1!.configuration_value === "1"


      const response = await api.post(
        "/user/login",
        payload,
        { headers: { "Content-Type": "application/json" } }
      )
      // console.log(data);
      // console.log(response);

      if (response.status === 200) {

        const syncData = await db.syncData.toArray();
        if (syncData!.length !== 0) {
          const syncItem = await db.syncData.get(1)
          dispatch(getNewSyncRequestBackground(syncItem!.last_update_timestamp))
        }
        dispatch(syncOfflineInvoices());
        dispatch(tryLoginScceeded({ ...response.data, pos, useShift, shift }));
      } else {
        dispatch(tryLoginFailure());
      }

    } catch (err: any) {
      // throw new Error(err);
      if (err.code === 'ERR_NETWORK') {

      }
      else if (err.response.status === 405) {

        // alert(err.response.statusText)
      } else {

      }
      dispatch(tryLoginFailure());
    }
  }
};

export const loginRequest = (username: string, passwordPlain: string, vfcode: string | null): AppThunk => async (dispatch, getState) => {
  dispatch(addRequest({ username }));
  try {

    // const fingerprint = getBrowserFingerprint();


    // Create a new ClientJS object
    const client = new ClientJS();
    // Get the client's fingerprint id
    const fp = client.getFingerprint();

    const hash = CryptoJS.MD5(passwordPlain)
    const hashBase64 = hash.toString(CryptoJS.enc.Base64);
    const salt = bcrypt.genSaltSync(10);
    const passHash = bcrypt.hashSync(hashBase64, salt);
    const password = Buffer.from(passHash, 'binary').toString('base64');



    const payload = vfcode ? { username, password, fp, vfcode } : { username, password, fp }

    // const syncData = useLiveQuery(
    //   () => db.syncData.toArray()
    // );

    let pos: POS | null = null;
    const posInfoCount = await db.posInfo.count()
    if (posInfoCount > 0) {
      const posEnc = await db.posInfo.get(1)
      pos = JSON.parse(CryptoJS.AES.decrypt(posEnc?.info, 'helloHeremydear123').toString(CryptoJS.enc.Utf8));
    }

    let useShift: boolean = false;
    const appConfItem1 = await db.appConfiguration.get(1)
    if (appConfItem1) useShift = appConfItem1!.configuration_value === "1"

    let shift: Shift | null = null;
    if (useShift) {
      const shiftCount = await db.shift.count()
      if (shiftCount > 0) {
        shift = await db.shift!.get(1) ?? null
      }
    }

    const response = await api.post(
      "/user/login",
      payload,
      { headers: { "Content-Type": "application/json" } }
    )
    // console.log(data);
    // console.log(response);

    if (response.status === 200) {

      const syncData = await db.syncData.toArray();
      if (syncData!.length === 0) {
        dispatch(getSyncDataRequest(username, passwordPlain, response.data["branch_id"], response.data["branch_name"]))
      } else {
        const syncItem = await db.syncData.get(1)
        dispatch(getNewSyncRequest(syncItem!.last_update_timestamp))
      }
      dispatch(syncOfflineInvoices());
      dispatch(loginScceeded({ ...response.data, pos, useShift, shift }));
    } else if (response.status === 405) {
      dispatch(needVerification(response.statusText));
    } else {
      dispatch(loginFailure(response.statusText));
      alert(response.statusText)
    }

  } catch (err: any) {
    // throw new Error(err);
    if ((err.code === 'ERR_NETWORK' || err.code === 'ERR_BAD_REQUEST') && err.response.status !== 405) {
      dispatch(loginFailure(err.message));
      dispatch(loginOfflineRequest(username, passwordPlain))
    }
    else if (err.response.status === 405) {
      dispatch(needVerification(err.response.statusText));
      // alert(err.response.statusText)
    } else {
      dispatch(loginFailure(err.message));
      alert(err.message)
    }

  }
};


export const loginOfflineRequest = (username: string, password: string): AppThunk => async (dispatch, getState) => {
  dispatch(addRequest({ username }));
  const usernameHash = await db.appConfig.get({ config_name: "config1" })
  const passwordHash = await db.appConfig.get({ config_name: "config2" })

  const correct_username: boolean = bcrypt.compareSync(username, usernameHash?.config_value);
  const correct_password: boolean = bcrypt.compareSync(password, passwordHash?.config_value);

  if (correct_username && correct_password) {
    let pos: POS | null = null;
    const posInfoCount = await db.posInfo.count()
    if (posInfoCount > 0) {
      const posEnc = await db.posInfo.get(1)
      pos = JSON.parse(CryptoJS.AES.decrypt(posEnc?.info, 'helloHeremydear123').toString(CryptoJS.enc.Utf8));
    }

    let useShift: boolean = false;
    const appConfItem1 = await db.appConfiguration.get(1)
    if (appConfItem1) useShift = appConfItem1!.configuration_value === "1"

    let shift: Shift | null = null;
    if (useShift) {
      const shiftCount = await db.shift.count()
      if (shiftCount > 0) {
        shift = await db.shift!.get(1) ?? null
      }
    }
    dispatch(loginOfflineScceeded<any>({ pos, useShift, shift, tempparam: password }));
  } else {
    alert("Wrong username or password")
    dispatch(loginFailure("Wrong Username or Password"));
  }

};



export const assignPOSRequest = (posId: number, pointName: string, regCode: string): AppThunk => async (dispatch, getState) => {

  dispatch(addRequesting());
  const randomWordArray = CryptoJS.lib.WordArray.random(20);
  // Convert the word array to a hexadecimal string
  const assignCode = randomWordArray.toString(CryptoJS.enc.Hex).substring(0, 20);

  try {
    const response = await api.put(
      '/pos/assign',
      {
        "pos_id": posId,
        "point_name": pointName,
        "reg_code": regCode,
        "assign_code": assignCode

      }
      ,
      { headers: { "Content-Type": "application/json" } }
    )

    if (response.status === 200) {
      // setDocumentData(response.data["document"])


      //cach pos in device
      const posInfo = CryptoJS.AES.encrypt(JSON.stringify({
        "id": posId,
        "pos_name": "",
        "point_name": pointName,
        "assigned": true,
        "assign_code": assignCode
      }), 'helloHeremydear123').toString();

      db.transaction('rw', db.posInfo, async () => {
        db.posInfo.clear()
        db.posInfo.put({
          "id": 1,
          "info": posInfo
        })
      }).then(() => {
        console.log("Transaction committed");
      }).catch(err => {
        console.error(err.stack);
      });


      dispatch(posAssigned<any>({
        "id": posId,
        "pos_name": "",
        "point_name": pointName,
        "assigned": true,
        "assign_code": assignCode
      }))
    } else {
      alert(response.data['msg'])
      dispatch(actionFailed(response.statusText));
    }

  } catch (err: any) {
    alert(err.response.data['msg'])
    dispatch(actionFailed(err.message));
  }
};


export const unAssignPOSRequest = (): AppThunk => async (dispatch, getState) => {
  dispatch(addRequesting());

  try {
    const pos = currentPos(getState());
    const response = await api.put(
      '/pos/unassign',
      {
        "pos_id": pos!.id,
      }
      ,
      { headers: { "Content-Type": "application/json" } }
    )

    if (response.status === 200) {
      // setDocumentData(response.data["document"])


      db.transaction('rw', db.posInfo, async () => {
        db.posInfo.clear()
      }).then(() => {
        console.log("Transaction committed");
      }).catch(err => {
        console.error(err.stack);
      });
      dispatch(posUnassigned())
    } else {
      alert(response.data['msg'])
      dispatch(actionFailed(response.statusText));
    }

  } catch (err: any) {
    alert(err.response.data['msg'])
    dispatch(actionFailed(err.message));
  }
};



export const openShift = (startCash: number): AppThunk => async (dispatch, getState) => {

  dispatch(addRequesting());


  let pos: POS | null = null;
  const posInfoCount = await db.posInfo.count()
  if (pos === null) dispatch(actionFailed("no POS exist"));

  if (posInfoCount > 0) {
    const posEnc = await db.posInfo.get(1)
    pos = JSON.parse(CryptoJS.AES.decrypt(posEnc?.info, 'helloHeremydear123').toString(CryptoJS.enc.Utf8));
  }

  const shiftCount = await db.shift.count()
  if (shiftCount === 0) {
    let shift: Shift | null = null;
    const pos = currentPos(getState());

    shift = {
      shift_id: 1,
      id: null,
      shift_uid: nanoid(),
      start_time: (new Date()).toISOString().split('.')[0] + "Z",
      end_time: "",
      start_cash: startCash,
      end_cash: 0,
      closed: false,
      pos_id: pos?.id!,
      pos_assign_code: pos?.assign_code!,
      branch_id: 0,
      shift_user: 0
    }

    await db.shift.put(shift)



    //add in to server and get id to assign
    try {
      const response = await api.post(
        '/shift/open',
        shift
        ,
        { headers: { "Content-Type": "application/json" } }
      )

      if (response.status === 200) {

        db.transaction('rw', db.shift, async () => {

          shift!.id = response.data["shift_id"]
          db.shift.put({ ...shift!, id: response.data["shift_id"] })
        }).then(() => {
          console.log("Transaction committed");
        }).catch(err => {
          console.error(err.stack);
        });


        dispatch(shiftOpned<any>({ ...shift!, id: response.data["shift_id"] }));
      } else {
        alert(response.data['msg'])
        dispatch(actionFailed(response.statusText));
      }

    } catch (err: any) {
      db.transaction('rw', db.shift, async () => {
        db.shift.clear()
      }).then(() => {
        console.log("Transaction committed");
      }).catch(err => {
        console.error(err.stack);
      });

      alert(err.response.data['msg'])
      dispatch(actionFailed(err.message));
    }



  } else {
    dispatch(actionFailed("there exist opened shift"));
  }
};


export const closeShift = (): AppThunk => async (dispatch, getState) => {

  dispatch(addRequesting());

  const shiftCount = await db.shift.count()
  if (shiftCount > 0) {

    const shift = currentShift(getState());
    //add in to server and get id to assign
    try {
      const response = await api.put(
        '/shift/close',
        shift
        ,
        { headers: { "Content-Type": "application/json" } }
      )

      if (response.status === 200) {

        db.transaction('rw', db.shift, async () => {
          db.shift.put({
            ...shift!
          })
        }).then(() => {


          db.transaction('rw', db.shift, async () => {
            db.shift.clear()
          }).then(() => {
            console.log("Transaction committed");
          }).catch(err => {
            console.error(err.stack);
          });



          console.log("Transaction committed");
        }).catch(err => {
          console.error(err.stack);
        });


        dispatch(shiftClosed());
      } else {
        alert(response.data['msg'])
        dispatch(actionFailed(response.statusText));
      }

    } catch (err: any) {
      alert(err.response.data['msg'])
      dispatch(actionFailed(err.message));
    }



  } else {
    dispatch(actionFailed("there is no shift to close"));
  }
};



export const reSyncData = (): AppThunk => async (dispatch) => {

  const syncData = await db.syncData.toArray();
  if (syncData!.length === 0) {
    dispatch(logout())
  } else {
    const syncItem = await db.syncData.get(1)
    dispatch(getNewSyncRequest(syncItem!.last_update_timestamp))
  }

};


export const updateDraftCount = (): AppThunk => async (dispatch) => {

  const draftsCount = await db.invoiceDraft.count()

  if (draftsCount !== undefined) {
    dispatch(setDraftCount(draftsCount));
  }

}



export const changeBranch = (branchId: number): AppThunk => async (dispatch) => {

  dispatch(addRequesting());
  try {



    const response = await api.put(
      '/user/changebranch',
      {
        branch_id: branchId
      },
      { headers: { "Content-Type": "application/json" } }
    )

    if (response.status === 200) {
      dispatch(refreshDB())
      dispatch(actionScceeded());
    } else {
      dispatch(actionFailed(response.statusText));
    }

  } catch (err: any) {
    // throw new Error(err);
    dispatch(actionFailed(err.message));
  }
};


export const syncOfflineInvoices = (): AppThunk => async (dispatch, getState) => {
  const allInvoices = await db.encInvoice.toArray()


  if (allInvoices!.length > 0) {
    allInvoices.forEach(async (invoice) => {
      const bytes = CryptoJS.AES.decrypt(invoice.data, "asdflkower@kS990#$sdfsdf");
      const inv = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      try {

        const response = await api.post(
          '/invoice',
          inv,
          { headers: { "Content-Type": "application/json" } }
        )

        if (response.status === 200) {
          db.transaction('rw', db.encInvoice, async () => {
            await db.encInvoice.delete(inv?.draft_uid!)
          }).then(() => {
            console.log("Transaction committed");
          }).catch(err => {
            console.log(err.stack)
          });
        }

      } catch (err: any) {

        if (err.status === 409) {
          db.transaction('rw', db.encInvoice, async () => {
            await db.encInvoice.delete(inv?.draft_uid!)
          }).then(() => {
            console.log("Transaction committed");
          }).catch(err => {
            // if(err.message === "invoice exist")
            console.log(err.stack)
            // console.error(err.stack);
          });
        } else {
          console.log(err.stack)
        }
      }
    })
  }

};



// export const { addTodo, getTodo } = todoSlide.actions;
// export const showTodo = (state) => state.todo.data;
// export default todoSlide.reducer;


export default loginSlice.reducer;
