import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { selectDirection, selectTranslations } from "../i18n/i18nSlice";
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { useLocation, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux';
import { height } from '@mui/system';
import LinearProgress from '@mui/material/LinearProgress';
import { useParams } from 'react-router-dom';
import SaveIcon from '@mui/icons-material/Save';
import PasswordIcon from '@mui/icons-material/Password';

import { AppBar, Dialog, Divider, FormControl, IconButton, InputLabel, List, ListItem, ListItemButton, ListItemIcon, ListItemText, MenuItem, Paper, Select, SelectChangeEvent, Toolbar } from '@mui/material';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateField } from '@mui/x-date-pickers';
import api from '../services/api';
import ResetPasswordDialog from './ResetPasswordDialog';
import { db } from '../../db';
import { assignPOSRequest, currentPos } from '../login/loginSlice';
import { set } from 'date-fns';

var CryptoJS = require("crypto-js");
interface ApiStatus {
  error: string;
  status: 'idle' | 'loading' | 'succeeded' | 'failed'
}



type Props = {}
const theme = createTheme();
//{customerAccountId, customerType}: {customerAccountId: number, customerType: number}

export default function POSForm() {
  const params = useParams();
  const { posId, posName } = params
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const [apiCallStatus, setApiCallStatus] = React.useState<ApiStatus>({ error: "", status: "idle" })
  const isLoading = () => apiCallStatus.status === "loading";
  const isSucceeded = () => apiCallStatus.status === "succeeded";
  const isIdle = () => apiCallStatus.status === "idle";
  const isFailed = () => apiCallStatus.status === "failed";
  const trans = useAppSelector(selectTranslations);
  const langDirection = useAppSelector(selectDirection);

  const pos = useAppSelector(currentPos)
  const [posChanged, setPosChanged] = React.useState<boolean>(false);

  
  const [pointName, setPointName] = React.useState<string>("");
  const [regCode, setRegCode] = React.useState<string>("");
  
  const [changesNotSaved, setChangesNotSaved] = React.useState<boolean>(false);

  const [checked, setChecked] = React.useState([0]);
  const [showResetPasswordDialog, setShowResetPasswordDialog] = React.useState(false);

  const onResePasswordClicked = () => {
    setShowResetPasswordDialog(true)
  }


 

  const onPointNameValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPointName(event.target.value)
    setChangesNotSaved(true)
  }
  const onRegCodeValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRegCode(event.target.value)
    setChangesNotSaved(true)
  }


  const onAssign = () => {
    dispatch(assignPOSRequest(parseInt(posId!), pointName, regCode))  
    setPosChanged(true)
}

React.useEffect(() => {
  if (pos == null && posChanged) {
    navigate('/settings');
  }
}, [pos, posChanged]);


  // const assignPos = async () => {
  //   setApiCallStatus({ error: "", "status": "loading" })

  //   const randomWordArray = CryptoJS.lib.WordArray.random(20);
  //   // Convert the word array to a hexadecimal string
  //   const assignCode = randomWordArray.toString(CryptoJS.enc.Hex).substring(0, 20);

  //   try {
  //     const response = await api.put(
  //       '/pos/assign',
  //       {
  //         "pos_id": parseInt(posId!),
  //         "point_name": pointName,
  //         "reg_code": regCode,
  //         "assign_code": assignCode

  //       }
  //       ,
  //       { headers: { "Content-Type": "application/json" } }
  //     )

  //     if (response.status === 200) {
  //       // setDocumentData(response.data["document"])


  //       //cach pos in device
  //       const posInfo = CryptoJS.AES.encrypt(JSON.stringify({
  //         "id": parseInt(posId!),
  //         "pos_name":"",
  //         "point_name": pointName,
  //         "assigned": true,
  //         "assign_code": assignCode
  //       }), 'helloHeremydear123').toString();

  //       db.transaction('rw', db.posInfo, async () => {
  //         db.posInfo.clear()
  //         db.posInfo.put( {
  //           "id": 1,
  //           "info":posInfo
  //         })
  //       }).then(() => {
  //         console.log("Transaction committed");
  //       }).catch(err => {
  //         console.error(err.stack);
  //       });

        
  //       setChangesNotSaved(false)
  //       setApiCallStatus({ error: "", "status": "succeeded" })
  //     } else {
  //       alert(response.data['msg'])
  //       setApiCallStatus({ error: response.statusText, "status": "failed" })
  //     }

  //   } catch (err: any) {
  //     alert(err.response.data['msg'])
  //     setApiCallStatus({ error: err.message, "status": "failed" })
  //   }
  // };


  return (
    <Container>
  <AppBar position="static" sx={{ backgroundColor: "#283593" }}>
        <Toolbar component="nav" variant="dense" sx={{ justifyContent: 'space-between', overflowX: 'auto' }}  >
          <Typography component="h2" variant="h5">
            {`${trans.pos_form.title}` }
          </Typography>
        </Toolbar>
      </AppBar>
      {(isLoading()) && <LinearProgress />}
      {(isSucceeded() || isIdle() || isFailed()) && (
        <>
          <Grid container>
              <Box sx={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                <TextField id="pos_name" name="pos_name"  label={trans.pos_form.pos_name} variant="standard" size='small'  value={posName} inputProps={{readOnly: true}} />
                <TextField id="point_name" name="point_name" label={trans.pos_form.point_name} variant="standard" size='small' onChange={onPointNameValueChange} value={pointName} />
                <TextField id="reg_code" name="reg_code" label={trans.pos_form.reg_code} variant="standard" size='small' onChange={onRegCodeValueChange} value={regCode} />
                  
              </Box>

          </Grid>

          <Paper elevation={1} sx={{ marginY: 2 }}>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', padding: 2, justifyContent: 'space-around' }}>
              <Button variant="contained" startIcon={<SaveIcon sx={{ marginRight: 0, marginLeft: 1 }} />} onClick={() => onAssign()} sx={{ margin: '2px' }} size="small" disabled={!changesNotSaved}>
                {trans.pos_form.assign_button}
              </Button>
            </Box>
          </Paper>

        </>)}


    </Container>
);
}