import React, { useMemo, useState } from 'react'

import { Autocomplete, AutocompleteChangeReason, Button, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide, TextField } from '@mui/material'
import { useNavigate } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { selectDirection, selectTranslations } from "../../features/i18n/i18nSlice";
import { db } from '../../db';
import { Broker } from '../../models/Customer';



export const useSelectBrokerDialog = (onBrokerSelected: Function) => {
  const [openDialog, setOpenDialog] = useState(false);
  const trans = useAppSelector(selectTranslations);
  const [dialogReturn, setDialogReturn] = useState('');
  const [brokers, setBrokers] = React.useState<Broker[]>([]);
  const [selectedBrokerId, setSelectedBrokerId] = React.useState<number>();
  const [selectedBroker, setSelectedBroker] = React.useState<Broker>();


  const brokersDefaultProps = {
    options: brokers,
    getOptionLabel: (option: Broker) => option.broker_name,
};

const onBrokerAutoCompleteChange = (event: React.SyntheticEvent<Element, Event>, value: Broker | null, reason: AutocompleteChangeReason) => {
  setSelectedBroker(value!)
  setSelectedBrokerId(value?.id!)
};





  const onDialogClickYes = () => {
    setOpenDialog(false);
    onBrokerSelected(selectedBroker)
  };


  const onDialogClickNo = () => {
    setOpenDialog(false);

  };

  const onDialogOpen = async (currentBrokerId: number) => {

    const brokerList = await db.broker.toArray()
    setBrokers(brokerList)
    const selectedBrokerObject = await db.broker.get({id: currentBrokerId})
    setSelectedBrokerId(currentBrokerId)
    setSelectedBroker(selectedBrokerObject)
    setDialogReturn('')
    setOpenDialog(true);
  };


  const SelectDialog = useMemo(() => {
    return () => (
      <>
      <DialogTitle>{trans.new_invoice.dialog.delete_title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          {trans.new_invoice.dialog.delete_message}
        </DialogContentText>
        <Autocomplete
                sx={{ flexGrow: 1, margin: "4px" }}
                {...brokersDefaultProps}
                // options={brokers}
                id="brokerselect"
                autoHighlight
                limitTags={10}
                onChange={onBrokerAutoCompleteChange}
                getOptionLabel={(option) => {
                  return option.broker_name || ""
                }}
                value={brokers.find(broker => broker.id === selectedBrokerId) ?? null}
                isOptionEqualToValue={(option, value) => {
                  // console.log(value)
                  if (value !== null) {
                    return option?.id === value?.id;
                  } else {
                    return false;
                  }
                }}
                // isOptionEqualToValue={(option, value) =>
                //   option.iso === value.iso
                // }
                renderInput={(params) => (
                  <TextField {...params} label={trans.new_invoice.invoice_broker} size="small" />
                  
                  )}
                  />
      </DialogContent>
      <DialogActions>
        <Button onClick={onDialogClickNo}>{trans.new_invoice.dialog.no_button}</Button>
        <Button onClick={onDialogClickYes}>{trans.new_invoice.dialog.yes_button}</Button>
      </DialogActions>
      </>
   )
  }, [openDialog, onDialogClickNo, onDialogClickYes])


  return {
    openDialog,
    setOpenDialog,
    dialogReturn,
    SelectDialog,
    onDialogOpen: (currentBrokerId: number) => onDialogOpen(currentBrokerId)
  };
};