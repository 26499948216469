import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../../app/store';
import api from '../services/api'
import { Employee } from '../../models/Emplyee';



export interface CommissionState {
  employee: Employee;
  employeesReport: any[];
  commissionCatReport: any[];
  // commissionCatAmountsReport: {commission: number, product_id: number, product_name: string}[];
  commissionCatAmountsReport: any[];
  currnetCategoryId: number;
  error: string;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  busySatusCount:number;
}



const initialState: CommissionState = {
  employee: {
    id: 0,
    user_id: 0,
    commission_category_id: 0,
    fullname: '',
    mobile: '',
    phone: '',
    email: '',
    address: '',

    salary: 0,
    balance: 0,

    account_id: 0,
    branch_id: 0,
    comment: '',
    cancelled: false,
  },
  
  employeesReport: [],
  commissionCatReport: [],
  commissionCatAmountsReport: [],
  currnetCategoryId: 0,
  error: "",
  status: 'idle',
  busySatusCount:0,
};



export const commissionSlice = createSlice({
  name: 'commission',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {

    resetCommissionData: (state) => {
      state.employee = initialState.employee
      state.status = 'idle';
      state.busySatusCount = state.busySatusCount > 0? state.busySatusCount -1 : 0
    },

    addGetEmployeeRequest: (state, action: PayloadAction<number>) => {
      state.employee.id = action.payload;
      state.status = 'loading';
      state.busySatusCount = state.busySatusCount + 1
    },

    getEmployeeSucceeded: (state, action: PayloadAction<Employee>) => {
      state.employee = action.payload
      state.status = 'succeeded';
      state.busySatusCount = state.busySatusCount > 0? state.busySatusCount -1 : 0
    },

    addUpdateEmployeeRequest: (state, action: PayloadAction<number>) => {
      state.employee.id = action.payload;
      state.status = 'loading';
      state.busySatusCount = state.busySatusCount + 1
    },

    updateEmployeeSucceeded: (state, action: PayloadAction<Employee>) => {
      state.employee = action.payload
      state.status = 'succeeded';
      state.busySatusCount = state.busySatusCount > 0? state.busySatusCount -1 : 0
    },
    addCreateEmployeeRequest: (state) => {
      state.employee.id = 0;
      state.status = 'loading';
      state.busySatusCount = state.busySatusCount + 1
    },

    createEmployeeSucceeded: (state, action: PayloadAction<Employee>) => {
      state.employee = action.payload
      state.status = 'succeeded';
      state.busySatusCount = state.busySatusCount > 0? state.busySatusCount -1 : 0
    },
    addGetAllEmployeesRequest: (state) => {
      state.employee.id = 0;
      state.status = 'loading';
      state.busySatusCount = state.busySatusCount + 1
    },
    getAllEmployeesSucceeded: (state, action: PayloadAction<[]>) => {
      state.employeesReport = action.payload
      state.status = 'succeeded';
      state.busySatusCount = state.busySatusCount > 0? state.busySatusCount -1 : 0
    },
    addGetAllCommissionRequest: (state) => {
      state.status = 'loading';
      state.busySatusCount = state.busySatusCount + 1
    },
    getAllCommissionSucceeded: (state, action: PayloadAction<[]>) => {
      state.commissionCatReport = action.payload
      state.status = 'succeeded';
      state.busySatusCount = state.busySatusCount > 0? state.busySatusCount -1 : 0
    },
    addGetCommissionAmounts: (state, action: PayloadAction<number>) => {
      state.currnetCategoryId = action.payload;
      state.status = 'loading';
      state.busySatusCount = state.busySatusCount + 1
    },
    getCommissionAmountsSucceeded: (state, action: PayloadAction<[]>) => {
      state.commissionCatAmountsReport = action.payload
      state.status = 'succeeded';
      state.busySatusCount = state.busySatusCount > 0? state.busySatusCount -1 : 0
    },
    changeCommissionAmounts: (state, action: PayloadAction<{ id: number, field: string, value: any }>) => {
      // state.commissionCatAmountsReport = [...state.commissionCatAmountsReport.filter( (item:any) => item.product_id !== action.payload.id ), action.payload]

      const { id, field, value } = action.payload; 

      // const existingRow = state.commissionCatAmountsReport.find(row => row.product_id === id); 
      // if (existingRow) { 
      //   const newRows = deepCopy(state.commissionCatAmountsReport);
      //   newRows[field] = value; 
      //   newRows.isChanged = true;
      //   state.commissionCatAmountsReport = newRows
      // }


      
      const existingRow = state.commissionCatAmountsReport.find(row => row.product_id === id); 
      if (existingRow) { 
        (existingRow as any)[field] = value; 
        (existingRow as any).isChanged = true;
      }

      // const rowIndex = state.commissionCatAmountsReport.findIndex(row => row.product_id === id); 
      // if (rowIndex >= 0) { 
      //   state.commissionCatAmountsReport[rowIndex][field] = value; 
      //   state.commissionCatAmountsReport[rowIndex].isChanged = true;
      // }
      // const newRow = [...state.commissionCatAmountsReport.map( (item:any) => item.product_id !== action.payload.id? item : {...item, [field]: value, isChanged: true} )]
      // state.commissionCatAmountsReport = [...state.commissionCatAmountsReport.map( (item:any) => item.product_id !== action.payload.id? item : {...item, [field]: value, isChanged: true} )]
      // console.log(state.commissionCatAmountsReport.map( (item:any) => item.product_id === id? {...item, [field]: value, isChanged: true}: item ))

      // state.commissionCatAmountsReport = action.payload
      state.status = 'succeeded';
      state.busySatusCount = state.busySatusCount > 0? state.busySatusCount -1 : 0
    },
    addUpdateComissionRequest: (state) => {
      state.status = 'loading';
      state.busySatusCount = state.busySatusCount + 1
    },
    updateCommissionAmountsSucceeded: (state, action: PayloadAction<[]>) => {
      state.commissionCatAmountsReport = action.payload
      state.status = 'succeeded';
      state.busySatusCount = state.busySatusCount > 0? state.busySatusCount -1 : 0
    },

    
    
    loadFailure: (state, action: PayloadAction<string>) => {

      state.error = action.payload;
      state.status = 'failed';
      state.busySatusCount = state.busySatusCount > 0? state.busySatusCount -1 : 0
    },

    setEmployeeData: (state, action: PayloadAction<Employee>) => {
      state.employee = action.payload
    },

    

  },
});

export const { addGetEmployeeRequest, getEmployeeSucceeded, addUpdateEmployeeRequest, updateEmployeeSucceeded, addCreateEmployeeRequest, createEmployeeSucceeded, addGetAllEmployeesRequest, getAllEmployeesSucceeded, addGetAllCommissionRequest, getAllCommissionSucceeded, addGetCommissionAmounts, getCommissionAmountsSucceeded, changeCommissionAmounts, addUpdateComissionRequest, updateCommissionAmountsSucceeded, loadFailure, setEmployeeData } = commissionSlice.actions;
export const employeeData = (state: RootState) => state.commission.employee;
export const employeesReport = (state: RootState) => state.commission.employeesReport;
export const commissionCallStatus = (state: RootState) => state.commission.status;
export const commissionCallBusyCount = (state: RootState) => state.commission.busySatusCount
export const commissionCatReportData = (state: RootState) => state.commission.commissionCatReport;
export const commissionCatAmountsReportData = (state: RootState) => state.commission.commissionCatAmountsReport;


export const getEmployee = (employeeId: number): AppThunk => async (dispatch, getState) => {
  dispatch(addGetEmployeeRequest(employeeId));
  try {

    const response = await api.get(
      `/employ/${employeeId}`,
      { headers: { "Content-Type": "application/json" } }
    )
    // console.log(data);
    // console.log(response);

    if (response.status === 200) {
      dispatch(getEmployeeSucceeded(response.data['employee']));
    } else {
      dispatch(loadFailure(response.statusText));
    }

  } catch (err: any) {
    // throw new Error(err);
    dispatch(loadFailure(err.message));
  }
};



export const updateEmployee = (): AppThunk => async (dispatch, getState) => {
  const employee = employeeData(getState());

  dispatch(addUpdateEmployeeRequest(employee.id!));
  try {



    const response = await api.put(
      '/employee',
      employee,
      { headers: { "Content-Type": "application/json" } }
    )
    // console.log(data);
    // console.log(response);

    if (response.status === 200) {
      dispatch(updateEmployeeSucceeded(response.data['employee']));
    } else {
      dispatch(loadFailure(response.statusText));
    }

  } catch (err: any) {
    // throw new Error(err);
    dispatch(loadFailure(err.message));
  }
};


export const createEmployee = (): AppThunk => async (dispatch, getState) => {
  dispatch(addCreateEmployeeRequest());
  const employee = employeeData(getState());

  try {
    const response = await api.post(
      '/employee',
      employee,
      { headers: { "Content-Type": "application/json" } }
    )
    // console.log(data);
    // console.log(response);

    if (response.status === 200) {
      dispatch(createEmployeeSucceeded(response.data['employee']));
    } else {
      dispatch(loadFailure(response.statusText));
    }

  } catch (err: any) {
    // throw new Error(err);
    dispatch(loadFailure(err.message));
  }
};



export const getAllEmployees = (): AppThunk => async (dispatch) => {
  dispatch(addGetAllEmployeesRequest());
  try {

    const response = await api.get(
      `/employ/all/`,
      { headers: { "Content-Type": "application/json" } }
    )
    // console.log(data);
    // console.log(response);

    if (response.status === 200) {
      dispatch(getAllEmployeesSucceeded(response.data['employees']));
    } else {
      dispatch(loadFailure(response.statusText));
    }

  } catch (err: any) {
    // throw new Error(err);
    dispatch(loadFailure(err.message));
  }
};

export const getAllCommissionCat = (): AppThunk => async (dispatch) => {
  dispatch(addGetAllCommissionRequest());
  try {

    const response = await api.get(
      `/commissioncat/all/`,
      { headers: { "Content-Type": "application/json" } }
    )
    // console.log(data);
    // console.log(response);

    if (response.status === 200) {
      dispatch(getAllCommissionSucceeded(response.data['categories']));
    } else {
      dispatch(loadFailure(response.statusText));
    }

  } catch (err: any) {
    // throw new Error(err);
    dispatch(loadFailure(err.message));
  }
};


export const getCommissionAmounts = (categoryId: number): AppThunk => async (dispatch, getState) => {
  dispatch(addGetCommissionAmounts(categoryId));
  try {

    const response = await api.get(
      `/commissioncat/${categoryId}`,
      { headers: { "Content-Type": "application/json" } }
    )
    // console.log(data);
    // console.log(response);

    if (response.status === 200) {
      dispatch(getCommissionAmountsSucceeded(response.data['commissions']));
    } else {
      dispatch(loadFailure(response.statusText));
    }

  } catch (err: any) {
    // throw new Error(err);
    dispatch(loadFailure(err.message));
  }
};



export const updateCommissionAmounts = (categoryId: number): AppThunk => async (dispatch, getState) => {
  const comissions = commissionCatAmountsReportData(getState());

  dispatch(addUpdateComissionRequest());
  try {



    const response = await api.put(
      '/commissioncat',
      {id: categoryId, comissions: [...comissions.filter((item:any) => 'isChanged' in item).map((found: any) => ({commission: (found.commission/100), product_id: found.product_id}))]},
      // {id: categoryId, comissions: [...comissions.filter((item:any) => 'isChanged' in item)]},
      { headers: { "Content-Type": "application/json" } }
    )
    // console.log(data);
    // console.log(response);

    if (response.status === 200) {
      dispatch(getCommissionAmountsSucceeded(response.data['commissions']));
    } else {
      dispatch(loadFailure(response.statusText));
    }

  } catch (err: any) {
    // throw new Error(err);
    dispatch(loadFailure(err.message));
  }
};



export default commissionSlice.reducer;
