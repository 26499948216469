import React, { useMemo, useState } from 'react'

import { Autocomplete, AutocompleteChangeReason, Button, DialogActions, DialogContent, DialogContentText, DialogTitle, LinearProgress, Slide, TextField, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom';
import { selectDirection, selectTranslations } from '../../features/i18n/i18nSlice';
import { useAppSelector } from '../../app/hooks'
import api from '../../features/services/api';

var CryptoJS = require("crypto-js");

interface ApiStatus {
  error: string;
  status: 'idle' | 'loading' | 'succeeded' | 'failed'
}



export default function ResetPasswordDialog({openDialog, setOpenDialog, userId}:{openDialog:boolean , setOpenDialog: Function, userId: number}) {
  // const [openDialog, setOpenDialog] = useState(false);
  const trans = useAppSelector(selectTranslations);
  const langDirection = useAppSelector(selectDirection);

  const [dialogReturn, setDialogReturn] = useState('');

  const [apiCallStatus, setApiCallStatus] = React.useState<ApiStatus>({ error: "", status: "idle" })
  const isLoading = () => apiCallStatus.status === "loading";
  const isSucceeded = () => apiCallStatus.status === "succeeded";
  const isIdle = () => apiCallStatus.status === "idle";
  const isFailed = () => apiCallStatus.status === "failed";


  const [oldPssword, setOldPssword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');



  const onOldPsswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()
    setOldPssword(event.target.value)
  }

  const onNewPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewPassword(event.target.value)
  }

  const onConfirmNewPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmNewPassword(event.target.value)
  }

  const isValidNewPassword = (): boolean => {
    var regularExpression = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/;
    const isOk = regularExpression.test(newPassword)
    const isValid = confirmNewPassword === newPassword && regularExpression.test(newPassword)
    return isValid
  }

  const onDialogClickYes = async () => {
    // setOpenDialog(false);
    if(isValidNewPassword()){
      await resetPassword()
    }
  };


  const onDialogClickNo = () => {
    setOpenDialog(false);

  };

  const onDialogOpen = async () => {
    // setDialogReturn('')
    setOldPssword('')
    setNewPassword('')
    setConfirmNewPassword('')
    setOpenDialog(true);
    
  };



  const resetPassword = async () => {
    setApiCallStatus({ error: "", "status": "loading" })

    const hashNewPass = CryptoJS.MD5(newPassword)
    const hashNewPassBase64 = hashNewPass.toString(CryptoJS.enc.Base64);

    try {
      const response = await api.put(
        '/users/resetpasswordbyadmin',
        {
          password: hashNewPassBase64,
          user_id: userId,
        },
        { headers: { "Content-Type": "application/json" } }
      )

      if (response.status === 200) {
        setOpenDialog(false);
      } else {
        alert(response.data['msg'])
        setApiCallStatus({ error: response.statusText, "status": "failed" })
      }

    } catch (err: any) {
      alert(err.response.data['msg'])
      setApiCallStatus({ error: err.message, "status": "failed" })
    }
  };

return (
      <>
        <DialogTitle>{trans.change_password_dialog.title}</DialogTitle>
        {isLoading() && <LinearProgress />}
        {(isSucceeded() || isIdle() || isFailed()) && (
          <>
            <DialogContent>
             
              <TextField
              key = "new_password"
                margin="normal"
                required
                fullWidth
                name="new_password"
                label={trans.change_password_dialog.new_password}
                type="password"
                id="new_password"
                autoComplete="current-password"
                value={newPassword}
                onChange={onNewPasswordChange}
              />

              <TextField
              key = "confirm_new_password"
                margin="normal"
                required
                fullWidth
                name="confirm_new_password"
                label={trans.change_password_dialog.confirm_new_password}
                type="password"
                id="confirm_new_password"
                autoComplete="current-password"
                value={confirmNewPassword}
                onChange={onConfirmNewPasswordChange}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={onDialogClickNo} >{trans.new_invoice.dialog.no_button}</Button>
              <Button onClick={onDialogClickYes} disabled={!isValidNewPassword()}>{trans.new_invoice.dialog.yes_button}</Button>
            </DialogActions>
          </>)}
      </>
    )


  // return {
  //   openDialog,
  //   setOpenDialog,
  //   dialogReturn,
  //   SelectDialog,
  //   onDialogOpen: () => onDialogOpen()
  // };
};