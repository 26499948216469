import React from 'react'
// import Draft  from '../components/draft_invoice02/Draft';
import Draft02  from '../components/draft_invoice021/Draft02';
import Draft03  from '../components/draft_invoice022/Draft03';
import Draft  from '../components/draft_invoice06/Draft';
// import Draft  from '../components/draft_invoice02/Draft';


import { useParams } from 'react-router-dom';
import DraftCoffee from '../components/draft_invoice05/DraftCoffee';

export default function DraftPage() {

  const params = useParams();
  const { invoiceType, draftId } = params

  const invType = invoiceType ? parseInt(invoiceType) : 0

  const getDraftType = (invType: number) => {
    if(invType === 1 || invType === 2 || invType === 3 || invType === 6){
      return <Draft></Draft>
    }

    if( invType === 7 || invType === 5){
      // return <Draft02></Draft02>
      return <Draft></Draft>
    }

    if(invType === 4 || invType === 8 ){
      // return <Draft03></Draft03>
      return <Draft></Draft>
    }
  }

  return(
  <>
  {getDraftType(invType)}
  </>
  )
   
}
