import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { selectDirection, selectTranslations } from "../i18n/i18nSlice";
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { useLocation, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux';
import { height } from '@mui/system';
import LinearProgress from '@mui/material/LinearProgress';
import { useParams } from 'react-router-dom';
import SaveIcon from '@mui/icons-material/Save';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import { AppBar, Divider, FormControl, IconButton, InputLabel, List, ListItem, ListItemButton, ListItemIcon, ListItemText, MenuItem, Paper, Select, SelectChangeEvent, Toolbar } from '@mui/material';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateField } from '@mui/x-date-pickers';
import api from '../services/api';


interface ApiStatus {
  error: string;
  status: 'idle' | 'loading' | 'succeeded' | 'failed'
}

interface Permission {
  id: number;
  permission_name: string;
  guaranteed: boolean;
}

type Props = {}
const theme = createTheme();
//{customerAccountId, customerType}: {customerAccountId: number, customerType: number}
export default function RoleForm() {
  const params = useParams();
  const { roleId } = params
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const [apiCallStatus, setApiCallStatus] = React.useState<ApiStatus>({ error: "", status: "idle" })
  const isLoading = () => apiCallStatus.status === "loading";
  const isSucceeded = () => apiCallStatus.status === "succeeded";
  const isIdle = () => apiCallStatus.status === "idle";
  const isFailed = () => apiCallStatus.status === "failed";
  const trans = useAppSelector(selectTranslations);

  // const [amount, setAmount] = React.useState(0)
  // const [note, setNote] = React.useState('')
  // const [docDate, setDocDate] = React.useState(Date());
  // const [customerFullname, setCustomerFullname] = React.useState(0)

  // const [documentType, setDocumentType] = React.useState(0)
  // const [customerAccountId, setCustomerAccountId] = React.useState(0)

  const [rolePermissions, setRolePermissions] = React.useState<Permission[]>([])
  const [roleName, setRoleName] = React.useState<string>('')
  
  const [changesNotSaved, setChangesNotSaved] = React.useState<boolean>(false);

  const [checked, setChecked] = React.useState([0]);


  const onCheckChanged = (event: React.ChangeEvent<HTMLInputElement>, modifiedId: number) => {
    // console.log(event.target.checked)

    const newList = rolePermissions.map( item => {
        if (item.id === modifiedId) {

            return { ...item, guaranteed: event.target.checked} as Permission
        }
        return item;
    })
    setRolePermissions(newList)
    setChangesNotSaved(true)
};

 



  const onUpdate = () => {

    updateRole()
  }


  const getRole = async () => {
    setApiCallStatus({ error: "", "status": "loading" })
    try {
      const response = await api.get(
        `/users/roles/${roleId}`,
        { headers: { "Content-Type": "application/json" } }
      )

      if (response.status === 200) {

        setRolePermissions(response.data["role"])
        setRoleName(response.data['role_name'])

        // setAmount(response.data["document"].amount)
        // setNote(response.data["document"].note)
        // setDocDate(response.data["document"].date)
        // setCustomerFullname(response.data["document"].fullname)
        // setDocumentType(response.data["document"].document_type)
        // setCustomerAccountId(response.data["document"].customer_account_id)

        setApiCallStatus({ error: "", "status": "succeeded" })
      } else {
        setApiCallStatus({ error: response.statusText, "status": "failed" })
      }

    } catch (err: any) {
      setApiCallStatus({ error: err.message, "status": "failed" })
    }
  };

  const updateRole = async () => {
    setApiCallStatus({ error: "", "status": "loading" })
    try {
      const response = await api.put(
        '/users/role',
        {
          rold_id: parseInt(roleId!),
          role_permissions: rolePermissions.filter(item => item.guaranteed === true)
        }
        ,
        { headers: { "Content-Type": "application/json" } }
      )

      if (response.status === 200) {
        // setDocumentData(response.data["document"])
        setChangesNotSaved(false)
        setApiCallStatus({ error: "", "status": "succeeded" })
      } else {
        alert(response.data['msg'])
        setApiCallStatus({ error: response.statusText, "status": "failed" })
      }

    } catch (err: any) {
      alert(err.response.data['msg'])
      setApiCallStatus({ error: err.message, "status": "failed" })
    }
  };



  React.useEffect(() => {
    getRole()
  }, [])

  return (
    <Container>

      <AppBar position="static" sx={{ backgroundColor: "#283593" }}>
        <Toolbar component="nav" variant="dense" sx={{ justifyContent: 'space-between', overflowX: 'auto' }}  >
          <Typography component="h2" variant="h5">
            {`${trans.role_form.title} ${roleName}` }
          </Typography>
        </Toolbar>
      </AppBar>
      {(isLoading()) && <LinearProgress />}
      {(isSucceeded() || isIdle() || isFailed()) && (
        <>
        <Paper elevation={1} sx={{ marginY: 2 }}>
          <Grid container>

          <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
      {rolePermissions.map((item) => {
        return (
          <ListItem
          key={item["id"]}
         
          disablePadding
        >
          
            <ListItemButton role={undefined}  dense>
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={item.guaranteed}
                  tabIndex={-1}
                  disableRipple
                  // inputProps={{ 'aria-labelledby': item.id }}
                  onChange={(event) => onCheckChanged(event, item.id)}
                />
              </ListItemIcon>
              <ListItemText id={item.id.toString()} primary={item.permission_name} />
            </ListItemButton>
          </ListItem>
        );
      })}
    </List>

    
            

          </Grid>
          </Paper>

          <Paper elevation={1} sx={{ marginY: 2 }}>
              <Box sx={{ display: 'flex', flexWrap: 'wrap', padding: 2, justifyContent: 'space-around' }}>
                <Button variant="contained" startIcon={<SaveIcon sx={{ marginRight: 0, marginLeft: 1 }} />} onClick={onUpdate} sx={{ margin: '2px' }} size="small" disabled={!changesNotSaved}>
                  {trans.customer_form.update_button}
                </Button>
              </Box>
            </Paper>

          

        </>)}
    </Container>
  );
}