import React, { useMemo, useState } from 'react'

import { Autocomplete, AutocompleteChangeReason, Button, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide, TextField } from '@mui/material'
import { useNavigate } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { selectDirection, selectTranslations } from "../../features/i18n/i18nSlice";
import { db } from '../../db';
import { SalesPerson } from '../../models/Customer';



export const useSelectSalesPersonDialog = (onSalesPersonSelected: Function) => {
  const [openDialog, setOpenDialog] = useState(false);
  const trans = useAppSelector(selectTranslations);
  const [dialogReturn, setDialogReturn] = useState('');
  const [salesPersons, setSalesPersons] = React.useState<SalesPerson[]>([]);
  const [selectedSalesPersonId, setSelectedSalesPersonId] = React.useState<number>();
  const [selectedSalesPerson, setSelectedSalesPerson] = React.useState<SalesPerson>();


  const salesPersonsDefaultProps = {
    options: salesPersons,
    getOptionLabel: (option: SalesPerson) => option.sales_person_name,
};

const onSalesPersonAutoCompleteChange = (event: React.SyntheticEvent<Element, Event>, value: SalesPerson | null, reason: AutocompleteChangeReason) => {
  setSelectedSalesPerson(value!)
  setSelectedSalesPersonId(value?.id!)
};





  const onDialogClickYes = () => {
    setOpenDialog(false);
    onSalesPersonSelected(selectedSalesPerson)
  };


  const onDialogClickNo = () => {
    setOpenDialog(false);

  };

  const onDialogOpen = async (currentSalesPersonId: number) => {

    const salesPersonList = await db.salesPerson.toArray()
    setSalesPersons(salesPersonList)
    const selectedSalesPersonObject = await db.salesPerson.get({id: currentSalesPersonId})
    setSelectedSalesPersonId(currentSalesPersonId)
    setSelectedSalesPerson(selectedSalesPersonObject)
    setDialogReturn('')
    setOpenDialog(true);
  };


  const SelectDialog = useMemo(() => {
    return () => (
      <>
      <DialogTitle>{trans.new_invoice.dialog.delete_title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          {trans.new_invoice.dialog.delete_message}
        </DialogContentText>
        <Autocomplete
                sx={{ flexGrow: 1, margin: "4px" }}
                {...salesPersonsDefaultProps}
                // options={salesPersons}
                id="salesPersonselect"
                autoHighlight
                limitTags={10}
                onChange={onSalesPersonAutoCompleteChange}
                getOptionLabel={(option) => {
                  return option.sales_person_name || ""
                }}
                value={salesPersons.find(salesPerson => salesPerson.id === selectedSalesPersonId) ?? null}
                isOptionEqualToValue={(option, value) => {
                  // console.log(value)
                  if (value !== null) {
                    return option?.id === value?.id;
                  } else {
                    return false;
                  }
                }}
                // isOptionEqualToValue={(option, value) =>
                //   option.iso === value.iso
                // }
                renderInput={(params) => (
                  <TextField {...params} label={trans.new_invoice.invoice_salesPerson} size="small" />
                  
                  )}
                  />
      </DialogContent>
      <DialogActions>
        <Button onClick={onDialogClickNo}>{trans.new_invoice.dialog.no_button}</Button>
        <Button onClick={onDialogClickYes}>{trans.new_invoice.dialog.yes_button}</Button>
      </DialogActions>
      </>
   )
  }, [openDialog, onDialogClickNo, onDialogClickYes])


  return {
    openDialog,
    setOpenDialog,
    dialogReturn,
    SelectDialog,
    onDialogOpen: (currentSalesPersonId: number) => onDialogOpen(currentSalesPersonId)
  };
};