import { Broker, SalesPerson } from "./Customer";
import { InvoiceItem } from "./InvoiceItem";


export enum InvoiceType {
  Sale = 1,
  Purchase = 2,
  SalesReturn = 3,
  AdjustRemove = 4,
  InitialStock = 5,
  PurchaseReturn = 6,
  AdjustAdd = 7,
  TransferStock = 8,
  StokOut = 9
}
// "Sale"
// "Purchase"
// "Sales return"
// "adjust remove"
// "initial stock"
// "Purchase return"
// "adjust add"
// "transfer stock"
// "stok out"

export interface Invoice02 {
  draft_id: number | undefined;
  id: number;
  draft_uid: string | null;
  invoice_type: number;
  cash_invoice: boolean;
  payment_method: number;
  customer_name: string;
  customer_id: number;
  customer_type: number;
  customer_account_id: number;
  invoice_date: string;
  invoice_discount_mode: number;
  discount_percentage: number;

  invoice_tax_mode: number;
  tax_percentage: number;

  subtotal: number;
  discount: number;
  salesTotal: number;
  tax: number;
  total: number;
  additions: number;
  freight: number;

  payment_amount: number;

  store: number;
  invoice_comment: string;
  broker_id: number;
  sales_person_id: number;

  invcustomer_id: number;
  invcustomer_phone: string;
  invcustomer_name: string;
  invcustomer_company: string;
  invcustomer_address: string;
  invcustomer_field: string;

  einv_customer: Record<string, unknown>; // JSON data type

  insert_date: string;
  update_date: number;
  cancelled: boolean;
  invoice_items: InvoiceItem[];
}

export interface EncInvoice {
  id: string;
  data: string;
}