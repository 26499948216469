import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid2 from '@mui/material/Grid2';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { selectDirection, selectTranslations } from "../i18n/i18nSlice";
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { addCollectedAmount, customerCallStatus, customerData, setCustomerData, updateCustomerData } from './customerSlice'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux';
import { height } from '@mui/system';
import LinearProgress from '@mui/material/LinearProgress';
import { useParams } from 'react-router-dom';
import SaveIcon from '@mui/icons-material/Save';
import { AppBar, Autocomplete, AutocompleteChangeReason, Divider, Paper, Toolbar } from '@mui/material';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateField } from '@mui/x-date-pickers';
import api from '../services/api';
import { finaceStoreList, getFinaceStoreList } from '../finance/financeSlice';



interface ApiStatus {
  error: string;
  status: 'idle' | 'loading' | 'succeeded' | 'failed'
}

interface DocumentData {
  id: number;
  document_date: string;
  description: string;
  document_type: string;
  document_type_id: number;
  customer_account_id: number;
  customer_type_id: number;
  extended_docuemnt_id: number;
  other_account_id: number;
  amount: number;
  comment: string;
  fullname: string;
}

interface FinanceStore {
  id: number;
  account_name: string;
}



type Props = {}
const theme = createTheme();
//{customerAccountId, customerType}: {customerAccountId: number, customerType: number}
export default function AddCustomerDocument() {
  const params = useParams();
  const { documentType } = params

  const financeStoreRef = React.useRef<HTMLInputElement>(null);
  const amountRef = React.useRef<HTMLInputElement>(null);

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const customerObject = useAppSelector(customerData);
  const [apiCallStatus, setApiCallStatus] = React.useState<ApiStatus>({ error: "", status: "idle" })
  const isLoading = () => apiCallStatus.status === "loading";
  const isSucceeded = () => apiCallStatus.status === "succeeded";
  const isIdle = () => apiCallStatus.status === "idle";
  const isFailed = () => apiCallStatus.status === "failed";

  const [storeAccountId, setStoreAccountId] = React.useState<number>(0)

  const trans = useAppSelector(selectTranslations);
  const finaceStores: FinanceStore[] = useAppSelector(finaceStoreList);


  const [documentData, setDocumentDataData] = React.useState<DocumentData>({
    id: 0,
    document_date: new Date().toISOString().split('.')[0] + "Z",
    description: customerObject?.fullname!,
    document_type: '',
    document_type_id: documentType ? parseInt(documentType) : 0,
    customer_account_id: 0,
    customer_type_id: 0,
    extended_docuemnt_id: 0,
    other_account_id:0,
    amount: 0,
    comment: '',
    fullname: '',
  })

  // const [amount, setAmount] = React.useState(0)
  // const [note, setNote] = React.useState('')
  // const [docDate, setDocDate] = React.useState(Date());





  const onAmountValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const amountValue: number = Number(event.target.value)
    setDocumentDataData({ ...documentData!, amount: amountValue })
  }

  const onNoteValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDocumentDataData({ ...documentData!, comment: event.target.value })
  }

  const onDateChange = (newValue: Date | null) => {
    // setDocDate(newValue!.toISOString().split('.')[0] + "Z")
    setDocumentDataData({ ...documentData!, document_date: newValue!.toISOString().split('.')[0] + "Z" })
  };


  const addDocument = async (document: DocumentData) => {
    setApiCallStatus({ error: "", "status": "loading" })
    try {
      const response = await api.post(
        '/customer/document',
        document,
        { headers: { "Content-Type": "application/json" } }
      )

      if (response.status === 200) {

        navigate(-1)
        setApiCallStatus({ error: "", "status": "succeeded" })
      } else {
        setApiCallStatus({ error: response.statusText, "status": "failed" })
      }

    } catch (err: any) {
      setApiCallStatus({ error: err.message, "status": "failed" })
    }
  };

  const getDocumentTitle = () => {

    if(documentType === '11') {
      return trans.document_form.title_collect + customerObject.fullname
    }else if(documentType === '12') {
      return trans.document_form.title_pay + customerObject.fullname
    }else if(documentType === '13') {
      return trans.document_form.title_sales_discount + customerObject.fullname
    }else if(documentType === '14') {
      return trans.document_form.title_purchase_discount + customerObject.fullname
    }else{
      return ""
    }

  }

  const onSubmit = () => {

    setDocumentDataData({
      ...documentData!,
      customer_account_id: customerObject.account_id,
      customer_type_id: customerObject.customer_type,
      document_type_id: documentType ? parseInt(documentType) : 0,
      other_account_id: storeAccountId,
    })
    
    addDocument({
      ...documentData!,
      customer_account_id: customerObject.account_id,
      customer_type_id: customerObject.customer_type,
      document_type_id: documentType ? parseInt(documentType) : 0,
      other_account_id: storeAccountId,
    })
    // if (isSucceeded()) navigate(-1)
  }


  const financeStoreDefaultProps = {
    options: finaceStores,
    getOptionLabel: (option: FinanceStore) => option.account_name,
  };

  const onFinanceStoreAutoCompleteChange = (event: React.SyntheticEvent<Element, Event>, value: any | null, reason: AutocompleteChangeReason) => {
      if (value !== null) {
        setStoreAccountId(value.id)  
      }
    };
    const onFinanceStorePress = (e: any) => {
      if (e.keyCode === 13) {
        amountRef.current?.focus()
      }
    }
    const onFinanceStoreFocus = (e: any) => {
      financeStoreRef.current?.select()
    }
  


  React.useEffect(() => {
      dispatch(getFinaceStoreList())
    }, []);

  return (
      <Container>

        <AppBar position="static" sx={{ backgroundColor: "#283593" }}>
          <Toolbar component="nav" variant="dense" sx={{ justifyContent: 'space-between', overflowX: 'auto' }}  >
            <Typography component="h2" variant="h5">
              {getDocumentTitle()}
            </Typography>
          </Toolbar>
        </AppBar>
        {isLoading() && <LinearProgress />}
        {(isSucceeded() || isIdle() || isFailed()) && (
          <>
            <Grid2 container>
            <Grid2 size={{ sm: 12, md: 6, lg:6 }}  padding={2}>
                <Box sx={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', justifyContent: 'space-between' }}>

            {finaceStores.length > 0 ?


                <Autocomplete
                  sx={{ flexGrow: 1, margin: "4px" }}
                  {...financeStoreDefaultProps}
                  // options={customers}
                  id="expense_type"
                  autoHighlight
                  limitTags={10}
                  onChange={onFinanceStoreAutoCompleteChange}
                  getOptionLabel={(option) => {
                    return option.account_name || ""
                  }}
                  value={finaceStores.find(store => store.id === storeAccountId) ?? null}
                  isOptionEqualToValue={(option, value) => {
                    // console.log(value)
                    if (value !== null) {
                      return option?.id === value?.id;
                    } else {
                      return false;
                    }
                  }}
                  // isOptionEqualToValue={(option, value) =>
                  //   option.iso === value.iso
                  // }
                  renderInput={(params) => (
                    <TextField {...params} label={trans.document_form.finance_store} size="small" inputRef={financeStoreRef} onKeyDown={onFinanceStorePress} onFocus={onFinanceStoreFocus} />

                  )}
                /> : null}


                  <TextField id="amount" name="amount" type='number' label={trans.document_form.amount} variant="standard" size='small' onChange={onAmountValueChange} value={documentData?.amount} inputRef={amountRef} />
                  <TextField id="note" name="note" label={trans.document_form.note} variant="standard" size='small' onChange={onNoteValueChange} value={documentData?.comment} />
                  <LocalizationProvider dateAdapter={AdapterDateFns} >
                    <DateField
                      label={trans.document_form.date}
                      format="dd/MM/yyyy"
                      value={documentData ? new Date(documentData?.document_date) : new Date()}
                      onChange={onDateChange}
                      // slotProps={{ textField: { variant: 'standard' } }}
                      sx={{ marginX: "6px" }}
                    />
                  </LocalizationProvider>
                </Box>
              </Grid2>

            </Grid2>

            <Paper elevation={1} sx={{ marginY: 2 }}>
              <Box sx={{ display: 'flex', flexWrap: 'wrap', padding: 2, justifyContent: 'space-around' }}>
                <Button variant="contained" startIcon={<SaveIcon sx={{ marginRight: 0, marginLeft: 1 }} />} onClick={onSubmit} sx={{ margin: '2px' }} size="small" disabled={documentData?.amount===0 || storeAccountId ===0}>
                  {trans.document_form.submit_button}
                </Button>
              </Box>
            </Paper>

          </>)}
      </Container>
  );
}