import React, { useEffect, useMemo, useState, useCallback, useRef } from 'react';
import { LinearProgress, Paper, Button, Box, Container, Typography, AppBar, Toolbar } from '@mui/material';


import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { selectDirection, selectTranslations } from "../i18n/i18nSlice";

import { selectPaletteMode } from '../appconfig/appConfigSlice';
import { AgGridReact } from 'ag-grid-react'; // React Grid Logic
import "ag-grid-community/styles/ag-grid.css"; // Core CSS
import "ag-grid-community/styles/ag-theme-quartz.css"; // Theme
// import "ag-grid-community/styles/ag-theme-quartz-dark.css"; // Theme
// import "./styles.css"
import { ColDef, SizeColumnsToContentStrategy, GridReadyEvent, ValueFormatterFunc, IRowNode, RowStyle } from 'ag-grid-community';




// interface Row extends Record<string, any>{}

interface Props {
  columnsInfo: Record<string, any>[];
  rows: Record<string, any>[];
  transRep: string;
  // getReportPagination: (setRowsFun: React.Dispatch<React.SetStateAction<Row[]>>, setApiCallStatusFun: React.Dispatch<React.SetStateAction<ApiStatus>>, pageNumber:number, setPageNumber:Function, setPagesCount: Function) => Promise<void>; 
  onOpenClick?: (id: number) => void;
  openParameterName?: string;
  filterModel?: any;
  saveFilterModel?: (filterModel:any)=> void

}




// const useRepoAGPg = ({columnsInfo, transRep, getReportPagination, onOpenClick, openParameterName, filterModel, saveFilterModel}: Props) => {
  // export default function RepoAGPg (columnsInfo: Record<string, any>[], rows: Record<string, any>[], transRep: string, onOpenClick?: (id: number) => void, openParameterName?: string, filterModel?: any, saveFilterModel?: (filterModel:any)=> void) {
    export default function RepoAGPg ({columnsInfo, rows, transRep, onOpenClick, openParameterName, filterModel, saveFilterModel}: Props) {
  

  const gridRef = useRef<AgGridReact>(null);

  
  const paletteMode = useAppSelector(selectPaletteMode);



  const langDirection = useAppSelector(selectDirection);
  const trans = useAppSelector(selectTranslations);

  // set background colour on every row, this is probably bad, should be using CSS classes
  const rowStyle = { background: '' };

  // set background colour on even rows again, this looks bad, should be using CSS classes
  const getRowStyle = (params: any): RowStyle => {
    // if (params.node.rowIndex % 2 === 0) {
    //     return { background: 'red' };
    // }
    // return { border: '1px solid #bdc1c6' };

    if(!params.node.isRowPinned()){
      return { borderBottom: '1px solid #bdc1c6' };
    }else{
      return { borderBottom: '1px solid #bdc1c6', background: paletteMode === 'light' ?'#e8eaf6':'#283593' };
    }
  };

  // function formatNumber (number: number):ValueFormatterFunc {
  //   // this puts commas into the number eg 1000 goes to 1,000,
  //   // i pulled this from stack overflow, i have no idea how it works
  //   return Math.floor(number)
  //     .toString()
  //     .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  // }

  function formatNumber (params: { value: number }): string {
    // this puts commas into the number eg 1000 goes to 1,000,
    // i pulled this from stack overflow, i have no idea how it works
    return (params.value?.toString() || '')
      // .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
      // .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
      // .replace(/\B(?=(\d{3})+(?!\d))/g, '$1,');
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  }

  function customValueFormatter(params: { value: any }): string {
    // You can perform any custom formatting logic here
    const formattedValue = `$${params.value.toFixed(2)}`; // Example: Format as currency
  
    return formattedValue;
  }
  
  

  const openColumn: ColDef[] = (typeof onOpenClick !== 'undefined' && typeof openParameterName !== 'undefined') ?
    [{
      field: "open",
      headerName: trans.nsi_table.open,
      pinned: langDirection === "rtl" ? 'right' : 'left',
      floatingFilter: false,
      cellRenderer: typeof onOpenClick !== 'undefined' ? (params: any) => {
        // put the value in bold
        return !params.node.isRowPinned() && <Button style={{ width: 32, minWidth: 32 }} onClick={() => onOpenClick(params.data[openParameterName])}>{trans.nsi_table.open}</Button>;
      } : null
    },
    ] : []

  // Column Definitions: Defines & controls grid columns.
  const [colDefs, setColDefs] = useState<ColDef[]>(
    [
      ...openColumn,
      ...columnsInfo.filter((column: Record<string, any>) => {
        if (column.field in trans[transRep]) {
          return true
        } else {
          return false
        }
      }
      ).map((column: Record<string, any>) =>(
        {
          field: column.field,
          headerName: trans[transRep][column.field],
          floatingFilter: true,
          // filter: k==='invoice_type'?'بيع': true,
          filter: column.type === 'number'? 'agNumberColumnFilter': true ,
          filterParams: {
            buttons: ['reset'],
          },
          ...(column.type === 'number') && {valueFormatter: formatNumber,},
          ...column.hasOwnProperty('pinned') && column.pinned === true && {pinned: langDirection === "rtl" ? 'right' as const : 'left' as const}
        } 
      ))
    ]
  );

  const defaultColDef = useMemo(() => ({
    floatingFilter: true,
    cellStyle: { borderRight: '1px solid #bdc1c6' },
  }), [])


  const autoSizeStrategy: SizeColumnsToContentStrategy = {
    type: 'fitCellContents'
  };

  function generatePinnedBottomData(){
    // generate a row-data with null values
    let result: Record<string, any>  = {};

    gridRef.current!.api.getAllGridColumns().forEach(item => {
        result[item.getId()] = null;
    });
    return calculatePinnedBottomData(result);
}


function calculatePinnedBottomData(target: any){
  //console.log(target);
  //**list of columns fo aggregation**
  let columnsWithAggregation = columnsInfo.filter(column => column.hasOwnProperty('summary')).map(column => column.field)
  console.log(columnsWithAggregation)
  columnsWithAggregation.forEach(element => {
    // console.log('element', element);
    gridRef.current!.api.forEachNodeAfterFilter((rowNode: IRowNode<any>, index: number) => {
        //if(rowNode.index < 10){
          //console.log(rowNode);
        //}
          if (rowNode.data[element])
              target[element] += Number(parseFloat(rowNode.data[element]).toFixed(2));
      });
      if (target[element])
          target[element] = `${target[element].toFixed(2)}`;
  })
  //console.log(target);
  return target;
}


  const onGridReady = useCallback((event: GridReadyEvent) => {
    if(typeof filterModel !== 'undefined') event.api.setFilterModel(filterModel);

    setTimeout(()=>{
      let pinnedBottomData = generatePinnedBottomData();
      // event.api.setPinnedBottomRowData([pinnedBottomData]);
      gridRef.current!.api.updateGridOptions({pinnedBottomRowData:[pinnedBottomData]});
    }, 500)

  }, []);


  
  
  


  return (
            <div className={`${paletteMode === 'light' ? 'ag-theme-quartz' : 'ag-theme-quartz-dark'}`} style={{ height: "100%", width: "100%" }}>
              <AgGridReact
                ref={gridRef}
                rowData={rows}
                columnDefs={colDefs}
                defaultColDef={defaultColDef}
                enableRtl={langDirection === "rtl" ? true : false}
                // enableRangeSelection={true}
                enableCellTextSelection={true}
                // rowStyle={rowStyle}
                getRowStyle={getRowStyle}
                autoSizeStrategy={autoSizeStrategy}
                onGridReady={onGridReady}
                // pinnedBottomRowData = {[...generatePinnedBottomData()]}
                onFilterChanged={() => {

                  saveFilterModel?.(gridRef.current!.api.getFilterModel())
                  // dispatch(setInvoicesAGFilter(gridRef.current!.api.getFilterModel()))
                  let pinnedBottomData = generatePinnedBottomData();
                  // gridRef.current!.api.setPinnedBottomRowData([pinnedBottomData]);
                  gridRef.current!.api.updateGridOptions({pinnedBottomRowData:[pinnedBottomData]});
                }}
              />
            </div>
  )


}
