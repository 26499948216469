import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { selectDirection, selectTranslations } from "../i18n/i18nSlice";
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { getExpensesTypeList, expensesTypeList, financeCallStatus } from './financeSlice'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux';
import { height } from '@mui/system';
import LinearProgress from '@mui/material/LinearProgress';
import { useParams } from 'react-router-dom';
import SaveIcon from '@mui/icons-material/Save';
import { AppBar, Autocomplete, AutocompleteChangeReason, Divider, FormControl, IconButton, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, Toolbar } from '@mui/material';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateField } from '@mui/x-date-pickers';
import api from '../services/api';
import { RowsChangeData, SelectColumn, textEditor } from 'react-data-grid';
import ExpenseDatagride from './ExpenseDatagride';
import AddBoxIcon from '@mui/icons-material/AddBox';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';


import { nanoid } from 'nanoid';
import { useYesNoDialog } from './useYesNoDialog';
import ExpenseDatagrideEditable from './ExpenseDatagrideEditable';



interface ApiStatus {
  error: string;
  status: 'idle' | 'loading' | 'succeeded' | 'failed'
}


interface ExpenseData {
  itemHash: string;
  finance_account_id: number;
  finance_account_name: string;
  amount: number;
  description: string;
}

interface DocumentData {
  id: number;
  document_date: string;
  description: string;
  document_type: string;
  document_type_id: number;
  // account_type_id: number;
  extended_docuemnt_id: number;
  accounting_months: number;
  comment: string;
  items: ExpenseData[];
}

interface SummaryRow {
  id: string;
  amount: number;
}


interface ExpenseType {
  id: number;
  expense_name: string;
}



type Props = {}
const theme = createTheme();
//{customerAccountId, customerType}: {customerAccountId: number, customerType: number}
export default function ExpensesDocumentMultipleForm() {
  const params = useParams();
  const { documentId } = params

  const expenseTypeRef = React.useRef<HTMLInputElement>(null);
  const amountRef = React.useRef<HTMLInputElement>(null);
  const descriptionRef = React.useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const financeApiCallStatus = useAppSelector(financeCallStatus);
  // const expensesType: ExpenseType[] = useAppSelector(expensesTypeList);
  const [apiCallStatus, setApiCallStatus] = React.useState<ApiStatus>({ error: "", status: "idle" })
  const isLoading = () => apiCallStatus.status === "loading";
  const isSucceeded = () => apiCallStatus.status === "succeeded";
  const isIdle = () => apiCallStatus.status === "idle";
  const isFailed = () => apiCallStatus.status === "failed";
  const trans = useAppSelector(selectTranslations);
  const langDirection = useAppSelector(selectDirection);
  const [documentData, setDocumentData] = React.useState<DocumentData>()
  const [changesNotSaved, setChangesNotSaved] = React.useState<boolean>(false);


  const [expensesType, setExpensesType] = React.useState<ExpenseType[]>([])
  // const [expenseList, setExpenseList] = React.useState<ExpenseData[]>([])
  // const [selectedFinanceAccountId, setSelectedFinanceAccountId] = React.useState<number>()
  const [selectionModel, setSelectionModel] = React.useState<ReadonlySet<string>>(() => new Set());

  const [amountValue, setAmountValue] = React.useState<number>(0)
  const [descriptionValue, setDescriptionValue] = React.useState<string>('')
  const [expenseId, setExpenseId] = React.useState<number>(0)
  const [expenseName, setExpenseName] = React.useState<string>('')
  const [total, setTotal] = React.useState<number>(0)

  // const [amount, setAmount] = React.useState(0)
  // const [note, setNote] = React.useState('')
  // const [docDate, setDocDate] = React.useState(Date());



  const onCancel = async () => {
    await cancelDocument()
    if (isSucceeded()) navigate(-1)
  }

  const { YesNoDialog, onDialogOpen } = useYesNoDialog(onCancel)


  const onRowSelectionChanged = (selections: ReadonlySet<string>) => {
    setSelectionModel(selections)
  }

  const onRowsChange = (rows: ExpenseData[], data: RowsChangeData<ExpenseData, SummaryRow>) => {

    if (data.column.key === "amount") {
      const item_new_amount = parseFloat(rows[data.indexes[0]].amount.toString())
      const item_hash = rows[data.indexes[0]].itemHash

      const updated_items = documentData?.items.map((item) => {

        if ((item as ExpenseData).itemHash === item_hash) {

          return {
            ...item,
            amount: item_new_amount,
            update_info: "row_changed",
          }

        } else {
          return item
        }

      })

      setDocumentData({ ...documentData!, items: updated_items! })

      const totalValue = updated_items?.reduce((acc, ritem) => acc + parseFloat(ritem.amount.toString()), 0)
      setTotal(totalValue!)
      setChangesNotSaved(true)
    }

    if (data.column.key === "description") {
      const item_new_description = rows[data.indexes[0]].description
      const item_hash = rows[data.indexes[0]].itemHash

      const updated_items = documentData?.items.map((item) => {

        if ((item as ExpenseData).itemHash === item_hash) {

          return {
            ...item,
            description: item_new_description,
            update_info: "row_changed",
          }

        } else {
          return item
        }

      })

      setDocumentData({ ...documentData!, items: updated_items! })
      setChangesNotSaved(true)
    }

  }

  const formatGrideColumn = () => {
    return [
      // { field: 'id', headerName: 'ID', width: 90 },
      SelectColumn,
      {
        key: 'finance_account_name',
        name: trans.document_form.title_expense_type,
        width: 390,
        // editable: true,
        // headerCellClass: "draf-header",
        sortable: false,
        summaryFormatter({ row }: { row: any; }): JSX.Element {
          return <strong style={{ direction: langDirection }}>Rows: {row.totalCount}</strong>;
        }
      },
      {
        key: 'amount',
        name: trans.document_form.amount,
        type: 'number',
        width: 140,
        // editable: true,
        sortable: false,
        summaryFormatter({ row }: { row: any; }): JSX.Element {
          return <>{row.qtyTotal}</>;
        },
        editable: true,
        editor: textEditor,
        formatter: ({ row }: { row: any; }) => (row.update_info?.includes("row_changed") ? <div style={{ background: '#ffe082', margin: 0, padding: 0 }}>{row.amount}</div> : <>{row.amount}</>),
      },
      {
        key: 'description',
        name: trans.document_form.description,
        type: 'number',
        width: '100%',
        // editable: true,
        sortable: false,
        summaryFormatter({ row }: { row: any; }): JSX.Element {
          return <>{row.qtyTotal}</>;
        },
        editable: true,
        editor: textEditor,
        formatter: ({ row }: { row: any; }) => (row.update_info?.includes("row_changed") ? <div style={{ background: '#ffe082', margin: 0, padding: 0 }}>{row.description}</div> : <>{row.description}</>),
      },
    ]

  }

  const expenseDefaultProps = {
    options: expensesType,
    getOptionLabel: (option: ExpenseType) => option.expense_name,
  };

  const onDeleteBillItems = () => {



    setDocumentData({ ...documentData!, items: documentData!.items.filter(item => !selectionModel.has(item.itemHash)) })
    // setExpenseList(expenseList.filter(item => !selectionModel.has(item.itemHash)))

    const totalValue = documentData!.items.filter(item => !selectionModel.has(item.itemHash)).reduce((acc, ritem) => acc + parseFloat(ritem.amount.toString()), 0)
    setTotal(totalValue)

    setSelectionModel(new Set());
    setChangesNotSaved(true)
  }


  const addCurrentLine = () => {

    if (amountValue > 0) {

      const newList = [...documentData!.items!, { finance_account_id: expenseId, finance_account_name: expenseName, amount: amountValue, description: descriptionValue, itemHash: nanoid() } as ExpenseData]
      setDocumentData({ ...documentData!, items: newList })
      // setExpenseList(newList)
      setAmountValue(0)
      setDescriptionValue('')
      const totalValue = newList.reduce((acc, ritem) => acc + parseFloat(ritem.amount.toString()), 0)
      setTotal(totalValue)
      expenseTypeRef.current!.focus();
      setChangesNotSaved(true)
    }
  }


  const onExpenseAutoCompleteChange = (event: React.SyntheticEvent<Element, Event>, value: any | null, reason: AutocompleteChangeReason) => {

    if (value !== null) {

      setExpenseId(value.id)
      setExpenseName(value.expense_name)

      //   const newList = [...expenseList! , {...selectedExpense} as ExpenseData ]
      // setExpenseList(newList)

    }
  };




  const onAmountValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const amountValue: number = Number(event.target.value)
    setAmountValue(amountValue)
  }
  const onDescriptionValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDescriptionValue(event.target.value)
  }

  const onNoteValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDocumentData({ ...documentData!, comment: event.target.value })
    setChangesNotSaved(true)
  }

  const onSelectChange = (event: SelectChangeEvent<number>, child: React.ReactNode) => {
    // setSelectedFinanceAccountId(parseInt(event.target.value as string))
    // const expenseObject = expensesType?.find(item => item .finance_account_id = parseInt(event.target.value as string))
    // setSelectedExpense(expenseObject)
  };

  const onSelectAccountingMonthsChange = (event: SelectChangeEvent<number>, child: React.ReactNode) => {
    setDocumentData({ ...documentData!, accounting_months: parseInt(event.target.value as string) })
  };


  const onDateChange = (newValue: Date | null) => {
    // setDocDate(newValue!.toISOString().split('.')[0] + "Z")
    setDocumentData({ ...documentData!, document_date: newValue!.toISOString().split('.')[0] + "Z" })
    setChangesNotSaved(true)
  };

  const onExpenseTypePress = (e: any) => {
    if (e.keyCode === 13) {
      amountRef.current?.focus()
    }
  }
  const onExpenseTypeFocus = (e: any) => {
    expenseTypeRef.current?.select()
  }

  const onAmountPress = (e: any) => {
    if (e.keyCode === 13) {
      descriptionRef.current?.focus()
    }
  }

  const onAmountFocus = (e: any) => {
    // priceRef.current?.select()
    setTimeout(function () {
      e.target.select();
    }, 0);

    // e.target.select();
    // console.log('onPriceFocus')
  }

  const onDescriptionPress = (e: any) => {
    if (e.keyCode === 13) {
      addCurrentLine()
    }
  }

  const onDescriptionFocus = (e: any) => {
    // priceRef.current?.select()
    setTimeout(function () {
      e.target.select();
    }, 0);

    // e.target.select();
    // console.log('onPriceFocus')
  }



  const getExpneseType = async () => {
    setApiCallStatus({ error: "", "status": "loading" })
    try {
      const response = await api.get(
        '/expense/type',
        { headers: { "Content-Type": "application/json" } }
      )

      if (response.status === 200) {

        setExpensesType(response.data["types"])

        // setAmount(response.data["document"].amount)
        // setNote(response.data["document"].note)
        // setDocDate(response.data["document"].date)
        // setCustomerFullname(response.data["document"].fullname)
        // setDocumentType(response.data["document"].document_type)
        // setCustomerAccountId(response.data["document"].customer_account_id)

        setApiCallStatus({ error: "", "status": "succeeded" })
      } else {
        setApiCallStatus({ error: response.statusText, "status": "failed" })
      }

    } catch (err: any) {
      setApiCallStatus({ error: err.message, "status": "failed" })
    }
  };


  const getDocument = async () => {
    setApiCallStatus({ error: "", "status": "loading" })
    try {
      const response = await api.get(
        `/expense/${documentId}`,
        { headers: { "Content-Type": "application/json" } }
      )

      if (response.status === 200) {

        setDocumentData({ ...response.data["document"], items: (response.data["document"]["items"] as any[]).map(item => ({ ...item, itemHash: nanoid() })) })
        const totalValue = (response.data["document"]["items"] as any[]).reduce((acc, ritem) => acc + parseFloat(ritem.amount), 0)
        setTotal(totalValue)
        // setAmount(response.data["document"].amount)
        // setNote(response.data["document"].note)
        // setDocDate(response.data["document"].date)
        // setCustomerFullname(response.data["document"].fullname)
        // setDocumentType(response.data["document"].document_type)
        // setCustomerAccountId(response.data["document"].customer_account_id)

        setApiCallStatus({ error: "", "status": "succeeded" })
      } else {
        setApiCallStatus({ error: response.statusText, "status": "failed" })
      }

    } catch (err: any) {
      setApiCallStatus({ error: err.message, "status": "failed" })
    }
  };

  const updateDocument = async () => {
    setApiCallStatus({ error: "", "status": "loading" })
    try {
      const response = await api.put(
        '/expense/multiple',
        documentData,
        { headers: { "Content-Type": "application/json" } }
      )

      if (response.status === 200) {
        setDocumentData({ ...response.data["document"], itemHash: nanoid() })
        setChangesNotSaved(false)
        setApiCallStatus({ error: "", "status": "succeeded" })
      } else {
        alert(response.data['msg'])
        setApiCallStatus({ error: response.statusText, "status": "failed" })
      }

    } catch (err: any) {
      alert(err.response.data['msg'])
      setApiCallStatus({ error: err.message, "status": "failed" })
    }
  };



  const cancelDocument = async () => {
    setApiCallStatus({ error: "", "status": "loading" })
    try {
      const response = await api.delete(
        `/customer/document/${documentData?.id}`,
        { headers: { "Content-Type": "application/json" } }
      )

      if (response.status === 200) {

        setApiCallStatus({ error: "", "status": "succeeded" })
        navigate(-1)
      } else {
        setApiCallStatus({ error: response.statusText, "status": "failed" })
      }

    } catch (err: any) {
      setApiCallStatus({ error: err.message, "status": "failed" })
    }
  };




  const onUpdate = () => {

    updateDocument()
  }



  React.useEffect(() => {
    getExpneseType()
    getDocument()
  }, []);

  return (
    <Container>

      {(isLoading()) && <LinearProgress />}
      {(isSucceeded() || isIdle() || isFailed()) && (
        <>
          <AppBar position="static" sx={{ backgroundColor: "#283593" }}>
            <Toolbar component="nav" variant="dense" sx={{ justifyContent: 'space-between', overflowX: 'auto' }}  >
              <Typography component="h2" variant="h5">
                {trans.document_form.title_expense}
              </Typography>
            </Toolbar>
          </AppBar>



          <Box sx={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', justifyContent: 'space-between' }}>
            {documentData ?
              <>
                <TextField id="note" name="note" label={trans.document_form.note} variant="standard" size='small' onChange={onNoteValueChange} value={documentData?.comment} />
                <LocalizationProvider dateAdapter={AdapterDateFns} >
                  <DateField
                    label={trans.document_form.date}
                    format="dd/MM/yyyy"
                    value={documentData ? new Date(documentData?.document_date) : new Date()}
                    onChange={onDateChange}
                    // slotProps={{ textField: { variant: 'standard' } }}
                    sx={{ marginX: "6px" }}
                  />
                </LocalizationProvider>
              </> : <></>
            }

          </Box>


          <Paper sx={{ marginY: 3 }}>

            <Box sx={{ display: 'flex', flexDirection: 'row', }}>
              {expensesType && expensesType!.length > 0 ?


                <Autocomplete
                  sx={{ flexGrow: 1, margin: "4px" }}
                  {...expenseDefaultProps}
                  // options={customers}
                  id="expense_type"
                  autoHighlight
                  limitTags={10}
                  onChange={onExpenseAutoCompleteChange}
                  getOptionLabel={(option) => {
                    return option.expense_name || ""
                  }}
                  value={expensesType!.find(expense => expense.id === expenseId) ?? null}
                  isOptionEqualToValue={(option, value) => {
                    // console.log(value)
                    if (value !== null) {
                      return option?.id === value?.id;
                    } else {
                      return false;
                    }
                  }}
                  // isOptionEqualToValue={(option, value) =>
                  //   option.iso === value.iso
                  // }
                  renderInput={(params) => (
                    <TextField {...params} label={trans.document_form.title_expense_type} size="small" inputRef={expenseTypeRef} onKeyDown={onExpenseTypePress} onFocus={onExpenseTypeFocus} />

                  )}
                /> : null}

              <TextField id="amount" name="amount" type='number' label={trans.document_form.amount} variant="standard" size='small'
                onChange={onAmountValueChange}
                onKeyDown={onAmountPress}
                onFocus={onAmountFocus}
                value={amountValue} inputRef={amountRef} />

              <TextField id="description" name="description" label={trans.document_form.description} variant="standard" size='small'
                onChange={onDescriptionValueChange}
                onKeyDown={onDescriptionPress}
                onFocus={onDescriptionFocus}
                value={descriptionValue} inputRef={descriptionRef} />

              {expensesType && <IconButton aria-label="delete" onClick={addCurrentLine} sx={{ width: 45, color: "#1976d2" }}>
                <AddBoxIcon />
              </IconButton>}
            </Box>
          </Paper>

          <Paper elevation={1} >

            {(selectionModel.size > 0) && <IconButton aria-label="delete" sx={{ marginX: 2, color: "#ff0000" }}
              onClick={onDeleteBillItems}
            >
              <DeleteIcon fontSize="small" />
            </IconButton>}
            <ExpenseDatagrideEditable rows={documentData ? documentData.items : []} columns={formatGrideColumn()} onRowSelectionChanged={onRowSelectionChanged} selectedRows={selectionModel} direction={langDirection === "rtl" ? "rtl" : "ltr"} onRowsChange={onRowsChange} />

          </Paper>

          <Paper elevation={1} sx={{ marginY: 2 }}>
            <TextField id="order_total" label={trans.document_form.total} type="number" variant="standard" size='small' value={total} />
          </Paper>

          <Paper elevation={1} sx={{ marginY: 2 }}>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', padding: 2, justifyContent: 'flex-start' }}>
              <Button variant="contained" startIcon={<SaveIcon sx={{ marginRight: 0, marginLeft: 1 }} />} onClick={onUpdate} sx={{ margin: '2px' }} size="small" disabled={!changesNotSaved}>
                {trans.document_form.update_button}
              </Button>
              <Button variant="contained" startIcon={<DeleteForeverIcon sx={{ marginRight: 0, marginLeft: 1 }} />} onClick={onDialogOpen} sx={{ margin: '2px', backgroundColor: theme.palette.warning.main }} size="small">
                {trans.document_form.cancel_button}
              </Button>
            </Box>
          </Paper>
          <YesNoDialog />

        </>)}
    </Container>
  );
}