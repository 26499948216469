import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../../app/store';
import api from '../services/api'
// import getBrowserFingerprint from 'get-browser-fingerprint';
import { ClientJS } from 'clientjs';

import { db } from "../../db";
import Product from '../../components/product/Product';
import { ProductCatalog } from '../../models/ProductCatalog';
import { StoreHouse } from '../../models/StoreHouse';




export interface FinanceState {
  expensesTypeList: any[];
  finaceStoreList: any[];
  statment: any[];
  error: string;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
}

const initialState: FinanceState = {

  expensesTypeList: [],
  finaceStoreList: [],
  statment: [],
  error: "",
  status: 'idle',
};


export const financeSlice = createSlice({
  name: 'finance',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {

    resetFinanceData: (state) => {
      state.status = 'idle';
    },

    addRequest: (state, action: PayloadAction<number>) => {
      state.status = 'loading';
    },


    updateFinanceSucceeded: (state) => {
      state.status = 'succeeded';
    },

    loadFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.status = 'failed';
    },

    getAllFinanceSucceeded: (state, action: PayloadAction<any[]>) => {
      state.status = 'succeeded';
    },

    getExpensesTypeListSucceeded: (state, action: PayloadAction<any[]>) => {
      state.expensesTypeList = action.payload
      state.status = 'succeeded';
    },

    getAddExpensesAmountSucceeded: (state) => {
      state.status = 'succeeded';
    },

    getFinanceStoreListSucceeded: (state, action: PayloadAction<any[]>) => {
      state.finaceStoreList = action.payload
      state.status = 'succeeded';
    },

    clearStatment: (state) => {
      state.statment = []
    },

    getFinanceStatmentSucceeded: (state, action: PayloadAction<any[]>) => {
      state.statment = action.payload;
      state.status = 'succeeded';
    },


    setFinanceData: (state: any, action: any) => {
      state.finance = action.payload
    },
    setInitialBalance: (state: any, action: PayloadAction<number>) => {
      state.initialBalance = action.payload
    },

  },
});

export const { addRequest, getExpensesTypeListSucceeded, getFinanceStoreListSucceeded, updateFinanceSucceeded, loadFailure, getAllFinanceSucceeded, setFinanceData, setInitialBalance, resetFinanceData, clearStatment, getFinanceStatmentSucceeded } = financeSlice.actions;
export const expensesTypeList = (state: RootState) => state.finance.expensesTypeList;
export const finaceStoreList = (state: RootState) => state.finance.finaceStoreList;
export const financeStatment = (state: RootState) => state.finance.statment;
export const financeCallStatus = (state: RootState) => state.finance.status;


export const getAllFinance = (): AppThunk => async (dispatch, getState) => {
  dispatch(addRequest(0));
  try {

    const response = await api.get(
      '/expense/type',
      { headers: { "Content-Type": "application/json" } }
    )

    if (response.status === 200) {
      dispatch(getAllFinanceSucceeded(response.data['finances']));
    } else {
      dispatch(loadFailure(response.statusText));
    }

  } catch (err: any) {
    // throw new Error(err);
    dispatch(loadFailure(err.message));
  }
};


export const getExpensesTypeList = (): AppThunk => async (dispatch, getState) => {
  // const expensesType = expensesTypeList(getState());
  // if (expensesType.length === 0) {
    dispatch(addRequest(0));
    try {

      const response = await api.get(
        '/expense/type',
        { headers: { "Content-Type": "application/json" } }
      )

      if (response.status === 200) {
        dispatch(getExpensesTypeListSucceeded(response.data['types']));
      } else {
        dispatch(loadFailure(response.statusText));
      }

    } catch (err: any) {
      // throw new Error(err);
      dispatch(loadFailure(err.message));
    }
  // }
}


export const getFinaceStoreList = (): AppThunk => async (dispatch, getState) => {
  // const expensesType = expensesTypeList(getState());
  // if (expensesType.length === 0) {
    dispatch(addRequest(0));
    try {

      const response = await api.get(
        '/finance/store/all',
        { headers: { "Content-Type": "application/json" } }
      )

      if (response.status === 200) {
        dispatch(getFinanceStoreListSucceeded(response.data['financestores']));
      } else {
        dispatch(loadFailure(response.statusText));
      }

    } catch (err: any) {
      // throw new Error(err);
      dispatch(loadFailure(err.message));
    }
  // }
}

export const getFinanceStatment = (accountId: number): AppThunk => async (dispatch, getState) => {
  dispatch(addRequest(0));
  dispatch(clearStatment());
  try {

    const response = await api.get(
      `/finance/statment/${accountId}`,
      { headers: { "Content-Type": "application/json" } }
    )
    // console.log(data);
    // console.log(response);

    if (response.status === 200) {
      dispatch(getFinanceStatmentSucceeded(response.data['statment']));
    } else {
      dispatch(loadFailure(response.statusText));
    }

  } catch (err: any) {
    // throw new Error(err);
    dispatch(loadFailure(err.message));
  }
};


export default financeSlice.reducer;
