import { useState, useEffect } from 'react';
import { Colors, ProductCatalog, Sizes } from '../../../models/ProductCatalog';
import { db } from '../../../db'
import { StoreHouse } from '../../../models/StoreHouse';


const useProductListByColorSize = (invoiceType: number) => {

  const [productCatalogs, setProductCatalogs] = useState<ProductCatalog[]>([])
  const [storeList, setStoreList] = useState<StoreHouse[]>([])
  const [invoiceStore, setInvoiceStore] = useState<StoreHouse | null>(null);
  const [colors, setColors] = useState<Colors[]>([])
  const [sizes, setSizes] = useState<Sizes[]>([])



  const getList = async () => {
    // const list = await db.productCatalog.filter((item => item.product_type !== 2)).toArray() // remove service from list
    const list = invoiceType === 1 ? await db.productCatalog.toArray() : await db.productCatalog.filter(item => item.product_type !==2).toArray() // remove service from list
    const stores = await db.storeHouse.toArray()
    const colorsList = await db.colors.toArray()
    const sizesList = await db.sizes.toArray()
    setProductCatalogs(list)
    setInvoiceStore(stores[0])
    setStoreList(stores)
    setColors(colorsList)
    setSizes(sizesList)
  }

  useEffect(() => {
    getList()
  }, [])
  
  return {productCatalogs, storeList, invoiceStore, colors, sizes, setInvoiceStore}

}




// export { useProductList }
export default useProductListByColorSize
