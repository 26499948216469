import React, { useMemo, useState } from 'react'

import { Autocomplete, AutocompleteChangeReason, Button, DialogActions, DialogContent, DialogContentText, DialogTitle, LinearProgress, Slide, TextField, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom';
import { selectDirection, selectTranslations } from '../../../features/i18n/i18nSlice';
import { useAppSelector } from '../../../app/hooks'
import api from '../../../features/services/api';
import { db } from '../../../db';
import { Newspaper } from '@mui/icons-material';
import { AppConfig } from '../../../models/AppConfig';

var bcrypt = require('bcryptjs');

interface ApiStatus {
  error: string;
  status: 'idle' | 'loading' | 'succeeded' | 'failed'
}



export default function NumberDialog({openDialog, setOpenDialog, lable, title, parentId, onShowNumberDialogYes}:{openDialog:boolean , setOpenDialog: Function, lable: string, title: string, parentId: number, onShowNumberDialogYes: Function}) {
  // const [openDialog, setOpenDialog] = useState(false);
  const trans = useAppSelector(selectTranslations);
  const langDirection = useAppSelector(selectDirection);

  const [dialogReturn, setDialogReturn] = useState('');

  const [apiCallStatus, setApiCallStatus] = React.useState<ApiStatus>({ error: "", status: "idle" })
  const isLoading = () => apiCallStatus.status === "loading";
  const isSucceeded = () => apiCallStatus.status === "succeeded";
  const isIdle = () => apiCallStatus.status === "idle";
  const isFailed = () => apiCallStatus.status === "failed";


  const [inputValue, setInputValue] = useState(0);



  const onInputValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()
    setInputValue(parseFloat(event.target.value))
  }

  // const onNewPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setNewPassword(event.target.value)
  // }

  // const onConfirmNewPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setConfirmNewPassword(event.target.value)
  // }

  // const isValidNewPassword = (): boolean => {
  //   var regularExpression = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/;
  //   const isOk = regularExpression.test(newPassword)
  //   const isValid = confirmNewPassword === newPassword && regularExpression.test(newPassword)
  //   return isValid
  // }

  const onDialogClickYes = async () => {
    setOpenDialog(false);
    onShowNumberDialogYes(parentId, inputValue)
    // if(isValidNewPassword()){
    //   await resetPassword()
    // }
  };


  const onDialogClickNo = () => {
    setOpenDialog(false);

  };

  const onDialogOpen = async () => {
    // setDialogReturn('')
    setInputValue(0)
    setOpenDialog(true);
    
  };

return (
      <>
        <DialogTitle>{title}</DialogTitle>
        {isLoading() && <LinearProgress />}
        {(isSucceeded() || isIdle() || isFailed()) && (
          <>
            <DialogContent>
              <TextField
                key = "input_value"
                type = 'number'
                margin="normal"
                required
                fullWidth
                name="input_value"
                label={lable}
                id="input_value"
                value={inputValue}
                onChange={onInputValueChange}
              />
              
            </DialogContent>
            <DialogActions>
              <Button onClick={onDialogClickNo} >{trans.new_invoice.dialog.no_button}</Button>
              <Button onClick={onDialogClickYes}>{trans.new_invoice.dialog.yes_button}</Button>
            </DialogActions>
          </>)}
      </>
    )


  // return {
  //   openDialog,
  //   setOpenDialog,
  //   dialogReturn,
  //   SelectDialog,
  //   onDialogOpen: () => onDialogOpen()
  // };
};