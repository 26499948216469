import React, {useState } from 'react'

import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { selectDirection, selectTranslations } from "../../features/i18n/i18nSlice";

import { OrderLine } from '../../models/OrderLine';
import { InvoiceItem } from '../../models/InvoiceItem';
import { InvoiceDiscountModeEnum, InvoiceTaxMode, InvoiceTaxModeEnum } from '../../models/Invoice';
import { InvoiceItemWithHash } from './useDraft';
import { InvoiceType } from '../../models/Invoice02';


const useGrideColumnFormat = () => {

  const [gridColumns, setGridColumns] = useState<any[]>([]);
  const langDirection = useAppSelector(selectDirection);
  const trans = useAppSelector(selectTranslations);



  const formatGrideColumn = (invoiceType: InvoiceType, taxPrecent: number, orderDiscount: number, taxMode: InvoiceTaxModeEnum, discountMode: InvoiceDiscountModeEnum) => {
    return [
      // {
      //   field: 'selected',
      //   headerName: '',
      //   headerCheckboxSelection: true,
      //   checkboxSelection: true,
      //   // showDisabledCheckboxes: true,
      //   width: 40,
      //   // onCellValueChanged: function (params:any) {
      //   //   console.log('changed:', params.data);
      //   //   // Handle the checkbox value change
      //   //   if (params.oldValue !== params.newValue) {
      //   //     console.log('Checkbox value changed:', params.data);
      //   //     // Add your custom logic here
      //   //   }
      //   // },
      // },
      {
        field: "product_name",
        headerName: trans.new_invoice.table.product,
        width: 450,
        sortable: false,
      },
      {
        field: 'quantity',
        headerName: trans.new_invoice.table.count,
        // type: 'numberColumn',
        width: 80,
        // editable: true,
        sortable: false,
      },
      {
        field: 'unit_name',
        headerName: trans.new_invoice.table.unit,
        width: 100,
        // editable: true,

        sortable: false,
      },
     
      {
        field: 'unit_price',
        headerName: trans.new_invoice.table.price,
        // type: 'numberColumn',
        width: 120,
        // editable: true,
        sortable: false,
      },

      {
        field: 'discount',
        headerName: trans.new_invoice.table.discount,
        // type: 'numberColumn',
        width: 120,
        // editable: true,
        sortable: false,
      },
      {
        field: 'tax',
        headerName: trans.new_invoice.table.tax,
        // type: 'numberColumn',
        width: 70,
        // editable: true,
        sortable: false,
      },
      {
        field: 'total',
        headerName: trans.new_invoice.table.total,
        // type: 'numberColumn',
        sortable: false,
        width: 120,
      },
      {
        field: "store_name",
        headerName: trans.new_invoice.table.store,
        width: 250,
        sortable: false,
      },

      // { key: 'dateTimeIssued', name: trans.nsi_table.date_time_issued ,resizable: true, sortable: true, width: 200},

    ].filter(item => {
      if (item.field === "discount" &&  discountMode === InvoiceDiscountModeEnum.PerInvoice) {
        return false;
      }

      if (item.field === "tax" && (taxPrecent === 0 || taxMode === InvoiceTaxModeEnum.PerInvoice)) {
        return false;
      }

      if((invoiceType === InvoiceType.AdjustRemove || invoiceType === InvoiceType.AdjustAdd) && (item.field === "unit_price" || item.field === "discount" || item.field === "tax" || item.field === "total")) {
        return false;
      }

      if(invoiceType === InvoiceType.TransferStock) {
        if(item.field === "unit_price" || item.field === "discount" || item.field === "tax" || item.field === "total") 
        {
          return false;
        }
      }else{
        if(item.field === "store_name" ) 
          {
            return false;
          }
      }

      

      return true;
    })


  }



  const adjustGridColumns = (invoiceType: InvoiceType, taxPrecent: number, orderDiscount: number, taxMode: InvoiceTaxModeEnum, discountMode: InvoiceDiscountModeEnum) => { 
    setGridColumns(formatGrideColumn(invoiceType, taxPrecent, orderDiscount, taxMode, discountMode))
  }

  return { gridColumns, adjustGridColumns }
}


export default useGrideColumnFormat