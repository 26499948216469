
import React, { useEffect, useState } from 'react'
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { selectPaletteMode } from '../../features/appconfig/appConfigSlice';

import { useNavigate } from "react-router-dom";

import api from '../../features/services/api';
import { invoicesAGFilter, setInvoicesAGFilter, invoicesAGPages, setInvoicesAGPage, invoicesAGVersion, invoicesAGPagesCount } from '../../features/reports/reportsSlice';
import RepoAGPg from '../../features/reports/RepoAGPg';
import TablePagination from '../../features/reports/TablePagination';
import { LinearProgress, Paper, Button, Box, Container, Typography, AppBar, Toolbar } from '@mui/material';
import { selectDirection, selectTranslations } from '../../features/i18n/i18nSlice';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import * as XLSX from 'xlsx';
import { db } from '../../db';
import { isOnline } from '../../features/login/loginSlice';

var CryptoJS = require("crypto-js");



interface ApiStatus {
  error: string;
  status: 'idle' | 'loading' | 'succeeded' | 'failed'
}


interface Row {
  id: number;
  invoice_type: string;
  customer_name: string;
  invoice_date: string;
  subtotal: number;
  discount: number;
  salesTotal: number;
  tax: number;
  additions: number;
  freight: number;
  total: number;
  broker_name: string;
  sales_person_name: string;
  invoice_comment: string;
  invcustomer_name: string;
  invcustomer_phone: string;
}



export default function InvoicesWithSearchAGPg() {

  const navigate = useNavigate();
  const dispatch = useAppDispatch()

  const [rows, setRows] = useState<Row[]>([])
  const [pageNumber, setPageNumber] = useState<number>(1)
  // const [pagesCount, setPagesCount] = useState<number>(0)

  const langDirection = useAppSelector(selectDirection);
  const trans = useAppSelector(selectTranslations);
  const onlineMode = useAppSelector(isOnline);

  const [apiCallStatus, setApiCallStatus] = React.useState<ApiStatus>({ error: "", status: "idle" })
  const isLoading = () => apiCallStatus.status === "loading";
  const isSucceeded = () => apiCallStatus.status === "succeeded";
  const isIdle = () => apiCallStatus.status === "idle";
  const isFailed = () => apiCallStatus.status === "failed";

  const columnsInfo: {field:string, [key: string]: any;}[] = [
    { field: "id", type: "number", pinned: true },
    { field: "invoice_type", type: "text" },
    { field: "customer_name", type: "text" },
    { field: "invoice_date", type: "date" },
    { field: "subtotal", type: "number", summary: "sum" },
    { field: "salesTotal", type: "number" },
    { field: "discount", type: "number", summary: "sum" },
    { field: "tax", type: "number" },
    { field: "additions", type: "number" },
    { field: "freight", type: "number" },
    { field: "total", type: "number", summary: "sum" },
    { field: "broker_name", type: "text" },
    { field: "sales_person_name", type: "text" },
    { field: "invoice_comment", type: "text" },
    { field: "invcustomer_name", type: "text" },
    { field: "invcustomer_phone", type: "text" },
  ]
  const transRep='invoice_rep1'




  const savedFilter = useAppSelector(invoicesAGFilter);
  const saveFilterModel = (filterModel: any) => {
    dispatch(setInvoicesAGFilter(filterModel))
  }

  const pages = useAppSelector(invoicesAGPages);
  const dataVersion = useAppSelector(invoicesAGVersion);
  const pagesCount = useAppSelector(invoicesAGPagesCount);


  const getOfflineInvoices = async ()  =>  {
        const allInvoices = await db.encInvoice.toArray()

        // const rows = useDraftList().map( item => ({
        //   id: item.draft_id,
        //   invoice_type: item.invoice_type,
        //   invoice_type_name: trans.new_invoice.type['title_name' + item.invoice_type],
        //   customer_name: item.customer_name,
        //   invoice_date: item.invoice_date,
        //   subtotal: item.subtotal,
        //   discount: item.discount,
        //   salesTotal: item.salesTotal,
        //   tax: item.tax,
        //   additions: item.additions,
        //   freight: item.freight,
        //   total: item.total,
        //   invoice_comment: item.invoice_comment
        // } as Row))


        const invoices =  await Promise.all(allInvoices.map(async (invoice) => {
          const bytes = CryptoJS.AES.decrypt(invoice.data, "asdflkower@kS990#$sdfsdf");
          const inv = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
          const customerObj= await db.customer.get({id: inv.customer_id})
          const invmod = {...inv, invoice_type: trans.new_invoice.type['title_name' + inv.invoice_type], customer_name: customerObj?.fullname, type:"offline", id: inv.draft_uid}
          return invmod
        }))
        return invoices
  }
  
  
  


  const getReportPagination = async (pageVersion:number) => {
    if(!onlineMode){
      setApiCallStatus({ error: "", "status": "loading" })
      const invoices = await getOfflineInvoices()
      setRows(invoices)
      setApiCallStatus({ error: "", "status": "succeeded" })

    }else{

      setApiCallStatus({ error: "", "status": "loading" })
      try {
        const response = await api.get(
          `/invoice/pg/${pageNumber}/${pageVersion}`,
          { headers: { "Content-Type": "application/json" } }
        ) 
  
        if (response.status === 200) {
          if(response.data.responseData.newVersion){
            setPageNumber(response.data.responseData.page)
            dispatch(setInvoicesAGPage({
              invoicesAGVersion: response.data.responseData.versionId,
              page: `pg${response.data.responseData.page}`,
              rows: response.data.responseData.records,
              pagesCount: response.data.responseData.pages
            }))
            setRows(response.data.responseData.records)
          }else{
            setRows((pages as any)[`pg${pageNumber}`])
          }
          
          setApiCallStatus({ error: "", "status": "succeeded" })
        } else {
          setApiCallStatus({ error: response.statusText, "status": "failed" })
        }
  
      } catch (err: any) {
        setApiCallStatus({ error: err.message, "status": "failed" })
      }
    }
  };


  const onOpenClick = (id: number) => {
    navigate("/invoice/" + id.toString());
  }

  const onPageChange = (page: number) => {
    setPageNumber(page)
  }

  const getPrintedData = () => {
    return rows.map(item => (
      columnsInfo.reduce((accumulator, column) => {
        return { ...accumulator, [trans[transRep][column.field]]: (item as any)[column.field] }
      }, {})
    ))
  }

  const downloadExcel = (data: any) => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "DataSheet1.xlsx");
  };
  

  useEffect(() => {
    if((pages as any).hasOwnProperty(`pg${pageNumber}`)){
      getReportPagination(dataVersion)
    }else{
      getReportPagination(-1)
    }
  }, [pageNumber])


  return (

    <Container maxWidth={false} sx={{ margin: 0, padding: 0 }}>
      {isLoading() && <LinearProgress />}
      {isSucceeded() && (
        <>

          <Box sx={{ display: 'flex', flexDirection: 'column', height: `calc(100vh - 128px)` }}>
            <Box>

              {rows.length != 0 ?
                <Paper elevation={3} sx={{ marginBottom: 2, padding: 2, direction: langDirection }}>
                  <Button variant="contained" startIcon={<FileDownloadIcon sx={{ marginRight: 0, marginLeft: 1 }} />} sx={{ marginX: 1 }} onClick={() => downloadExcel(getPrintedData())}>
                    Download
                  </Button>
                </Paper>
                : null}
            </Box>
            <Box>
              <AppBar position="static" sx={{ backgroundColor: "#283593" }}>
                <Toolbar component="nav" variant="dense" sx={{ justifyContent: 'space-between', overflowX: 'auto' }}  >
                  <Typography component="h2" variant="h5">
                    {trans.stock_rep1.title}
                  </Typography>
                </Toolbar>
              </AppBar>

            </Box>

            <RepoAGPg
            
              columnsInfo={columnsInfo}
              rows = {rows}
              transRep={transRep}
              onOpenClick={onOpenClick}
              openParameterName='id'
              filterModel={savedFilter}
              saveFilterModel={saveFilterModel} />

            <TablePagination direction={langDirection === "rtl" ? "rtl" : "ltr"} currentPage={pageNumber} numberOfPages={pagesCount} onPageChange={onPageChange} />
          </Box>
        </>
         )}
         </Container>
      )

      }


