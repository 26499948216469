import React, { useEffect, useMemo, useState } from 'react'

import { Autocomplete, AutocompleteChangeReason, Button, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, InputLabel, LinearProgress, MenuItem, Select, SelectChangeEvent, Slide, TextField } from '@mui/material'
import { useNavigate } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from "../../../app/hooks";
import { selectDirection, selectTranslations } from "../../../features/i18n/i18nSlice";
import api from '../../../features/services/api';
import { resetReports } from '../../../features/reports/reportsSlice';

interface ApiStatus {
  error: string;
  status: 'idle' | 'loading' | 'succeeded' | 'failed'
}

interface BranchItem {
  id: number;
  branch_name: string;
}

export const useSelectBranchDialog = (onBranchSelected: Function) => {
  const dispatch = useAppDispatch()
  const [apiCallStatus, setApiCallStatus] = React.useState<ApiStatus>({ error: "", status: "idle" })
  const isLoading = () => apiCallStatus.status === "loading";
  const isSucceeded = () => apiCallStatus.status === "succeeded";
  const isIdle = () => apiCallStatus.status === "idle";
  const isFailed = () => apiCallStatus.status === "failed";

  const [openDialog, setOpenDialog] = useState(false);
  const trans = useAppSelector(selectTranslations);
  const [dialogReturn, setDialogReturn] = useState('');
  const [branchList, setBranchList] = useState<BranchItem[]>([]);
  const [selectedBranchId, setSelectedBranchId] = React.useState<number>();


  const getBranchList = async () => {
    setApiCallStatus({ error: "", "status": "loading" })
    try {
      const response = await api.get(
        '/user/branches',
        { headers: { "Content-Type": "application/json" } }
      )

      if (response.status === 200) {

        setBranchList(response.data["branches"])

        setApiCallStatus({ error: "", "status": "succeeded" })
      } else {
        setApiCallStatus({ error: response.statusText, "status": "failed" })
      }

    } catch (err: any) {
      setApiCallStatus({ error: err.message, "status": "failed" })
    }
  };


  // useEffect(() => {
  //   getBranchList()
  // }, [])

  const onSelectChange = (event: SelectChangeEvent<number>, child: React.ReactNode) => {
    setSelectedBranchId(parseInt(event.target.value as string))
  };





  const onDialogClickYes = () => {
    dispatch(resetReports())
    setOpenDialog(false);
    onBranchSelected(selectedBranchId)
  };


  const onDialogClickNo = () => {
    setOpenDialog(false);

  };

  const onDialogOpen = async () => {
    await getBranchList()
    setDialogReturn('')
    setOpenDialog(true);
  };


  const SelectDialog = useMemo(() => {
    return () => (
      <>
        <DialogTitle>{trans.dashboards.change_branch_title}</DialogTitle>
        {isLoading() && <LinearProgress />}
        {isSucceeded() && (
          <>
            <DialogContent>

              {branchList.length > 0 ?
                <FormControl variant="standard" fullWidth>
                  <InputLabel id="demo-simple-select-standard-label" >{trans.dashboards.branch_name}</InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="branch"
                    // defaultValue={'P'}
                    value={selectedBranchId}
                    name="branch"

                    label="Type"
                    onChange={onSelectChange}

                    size='small'
                    sx={{ width: "100%" }}
                  >
                    {branchList.map(branch => (<MenuItem value={branch.id} key={branch.id}>{branch.branch_name}</MenuItem>))}
                  </Select>
                </FormControl>
                : <></>}


            </DialogContent>
          </>)}

        <DialogActions>
          <Button onClick={onDialogClickNo}>{trans.new_invoice.dialog.no_button}</Button>
          <Button onClick={onDialogClickYes}>{trans.new_invoice.dialog.yes_button}</Button>
        </DialogActions>
      </>
    )
  }, [openDialog, onDialogClickNo, onDialogClickYes])


  return {
    openDialog,
    setOpenDialog,
    dialogReturn,
    SelectDialog,
    onDialogOpen: () => onDialogOpen()
  };
};