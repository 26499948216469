import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../../app/store';
import api from '../services/api'
// import getBrowserFingerprint from 'get-browser-fingerprint';
import { ClientJS } from 'clientjs';

import { db } from "../../db";
import Product from '../../components/product/Product';
import { PriceItem, PriceList, ProductCatalog, ProductCategory, ProductType, ProductUnit } from '../../models/ProductCatalog';
import { StoreHouse } from '../../models/StoreHouse';
import { report } from 'process';
import { stat } from 'fs';




export interface ReportsState {
  invoicesReportFilter: {};
  invoicesAGFilter: {};
  invoicesAGPages:{};
  invoicesAGVersion:number;
  invoicesAGPagesCount: number;
  componentFilter:{ [key: string]: {[key: string]: any}; };
}



const initialState: ReportsState = {
  invoicesReportFilter: {
    id: undefined,
    invoice_type: '',
    customer_name: '',
    invoice_date: '',
    subtotal: undefined,
    salesTotal: undefined,
    discount: undefined,
    tax: undefined,
    additions: undefined,
    freight: undefined,
    total: undefined,
    broker_name: '',
    sales_person_name: '',
    invoice_comment: '',
    invcustomer_name: '',
    invcustomer_phone: '',
    enabled: true
  },
  invoicesAGFilter:{},
  invoicesAGPages:{},
  invoicesAGVersion:-1,
  invoicesAGPagesCount:0,
  componentFilter:{},
};



export const reportsSlice = createSlice({
  name: 'reports',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
 
    resetReports: () => initialState,

    setInvoicesReportFilter: (state, action: PayloadAction<{}>) => {
      state.invoicesReportFilter = action.payload
    },
    setInvoicesAGFilter: (state, action: PayloadAction<{}>) => {
      state.invoicesAGFilter = action.payload
    },
    setComponentFilter: (state, action: PayloadAction<{componentName: string, componentFilterValue: {}}>) => {
      state.componentFilter = {...state.componentFilter, [action.payload.componentName]: action.payload.componentFilterValue}
    },
    clearComponentFilter: (state, action: PayloadAction<{componentName: string}>) => {
      const { [action.payload.componentName]: removed, ...rest } = state.componentFilter
      state.componentFilter = rest
    },
    setInvoicesAGPage: (state, action: PayloadAction<any>) => {
      if(action.payload.invoicesAGVersion !== state.invoicesAGVersion){
        state.invoicesAGPages = {[action.payload.page]:  action.payload.rows}
        state.invoicesAGVersion = action.payload.invoicesAGVersion
        state.invoicesAGPagesCount = action.payload.pagesCount
      }else{
        state.invoicesAGPages = {...state.invoicesAGPages, [action.payload.page]:  action.payload.rows}
      }
    },

  },
});

export const { resetReports, setInvoicesReportFilter, setInvoicesAGFilter, setInvoicesAGPage, setComponentFilter, clearComponentFilter } = reportsSlice.actions;
export const invoicesReportFilter = (state: RootState) => state.reports.invoicesReportFilter;
export const invoicesAGFilter = (state: RootState) => state.reports.invoicesAGFilter;
export const invoicesAGPages = (state: RootState) => state.reports.invoicesAGPages;
export const invoicesAGVersion = (state: RootState) => state.reports.invoicesAGVersion;
export const invoicesAGPagesCount = (state: RootState) => state.reports.invoicesAGPagesCount;
export const componentSavedFilter = (state: RootState) => state.reports.componentFilter;






export default reportsSlice.reducer;
