import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid2 from '@mui/material/Grid2';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { selectDirection, selectTranslations } from "../../features/i18n/i18nSlice";
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { getCustomer, customerCallStatus, customerData, setCustomerData, updateCustomerData } from './customerSlice'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux';
import { height } from '@mui/system';
import LinearProgress from '@mui/material/LinearProgress';
import { useParams } from 'react-router-dom';
import SaveIcon from '@mui/icons-material/Save';
import { AppBar, Dialog, Divider, Paper, Toolbar } from '@mui/material';
import { useSelectDateRangeDialog } from './useSelectDateRangeDialog';
import DateRangeDialog from './DateRangeDialog';




type Props = {}
const theme = createTheme();

export default function CustomerForm() {
  const navigate = useNavigate();
  const params = useParams();
  const { customerId } = params

  const [changesNotSaved, setChangesNotSaved] = React.useState<boolean>(false);

  // const isOnline = navigator.onLine
  const dispatch = useAppDispatch();
  const apiCallStatus = useAppSelector(customerCallStatus);
  const customerObject = useAppSelector(customerData);
  const isLoading = (status: any) => status === "loading";
  const isSucceeded = (status: any) => status === "succeeded";
  const isIdle = (status: any) => status === "idle";
  const isFailed = (status: any) => status === "failed";
  const langDirection = useAppSelector(selectDirection);
  const trans = useAppSelector(selectTranslations);

  const [showDateRangeDialog, setShowDateRangeDialog] = React.useState(false);

  const onDateRangeDialogYes = (parentId: number, result: any[]) => {
    navigate(`/customer/statmentdetail/${customerObject.account_id}/${result[0]}/${result[1]}`)
   console.log(result)
  }

  const openDateRangeDialog = (parentId: number) => {
    setShowDateRangeDialog(true)
  }


  const onInputValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setCustomerData({ ...customerObject, [event.target.name]: event.target.value }))
    setChangesNotSaved(true)
  }

  const onUpdate = () => {
    dispatch(updateCustomerData())
    if (isSucceeded(apiCallStatus)) setChangesNotSaved(false)
  }


  const onDateRangeChanged = (dateRange: string[]) => {
    console.log(dateRange)
  }

  const onNewDateRangeSelected = (value: string[]) => {
    onDateRangeChanged(value)
  }
  const {
    openDialog: showSelectDateRangeDialog,
    setOpenDialog: setShowSelectDateRangeDialog,
    dialogReturn: dialogSelectDateRangeReturn,
    SelectDialog: SelectDateRangeDialog,
    onDialogOpen: onSelectDateRangeDialogOpen } = useSelectDateRangeDialog(onNewDateRangeSelected)

  const onSelectDateRangeButtonClicked = () => {
    onSelectDateRangeDialogOpen()
  }








  React.useEffect(() => {
    dispatch(getCustomer(parseInt(customerId!)))
  }, [customerId]);



  return (
    <Container>
      <AppBar position="static" sx={{ backgroundColor: "#283593" }}>
        {/* <Toolbar sx={{ borderBottom: 1, borderColor: 'divider' }} variant="dense" > */}
        <Toolbar component="nav" variant="dense" sx={{ justifyContent: 'space-between', overflowX: 'auto' }}  >
          {/* <Typography variant='h4'>{trans.customer_form.title}</Typography> */}
          {/* <Typography component="h2" variant="h5" color="primary" gutterBottom> */}
          <Typography component="h2" variant="h5">
            {trans.customer_form.title}
          </Typography>
        </Toolbar>
      </AppBar>
      {/* <Divider /> */}
      {isLoading(apiCallStatus) && <LinearProgress />}
      {(isSucceeded(apiCallStatus) || isIdle(apiCallStatus) || isFailed(apiCallStatus)) && (
        <>
          <Grid2 container>
          <Grid2 size={{ sm: 12, md: 6, lg:6 }}  padding={2}>
              <Box sx={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                <TextField id="customer_id" name="id" label={trans.customer_form.id} variant="standard" size='small' value={customerId} />
                <TextField id="customer_name" name="fullname" label={trans.customer_form.fullname} variant="standard" size='small' onChange={onInputValueChange} value={customerObject.fullname} />
                <TextField id="mobile" name="mobile" label={trans.customer_form.mobile} variant="standard" size='small' onChange={onInputValueChange} value={customerObject.mobile} />
                <TextField id="phone" name="phone" label={trans.customer_form.phone} variant="standard" size='small' onChange={onInputValueChange} value={customerObject.phone} />
                <TextField id="email" name="email" type='email' label={trans.customer_form.email} variant="standard" size='small' onChange={onInputValueChange} value={customerObject.email} />
                <TextField id="address" name="address" label={trans.customer_form.address} variant="standard" size='small' onChange={onInputValueChange} value={customerObject.address} />
                <TextField id="customer_type" name="customer_type" label={trans.customer_form.type} variant="standard" size='small' value={customerObject.customer_type_name} />
                {/* <TextField id="initial_balance" name="initial_balance" type='number' label={trans.customer_form.initial_balance} variant="standard" size='small' onChange={onInitialBalanceValueChange} value={initialBalanceValue} /> */}
              </Box>
            </Grid2>
            <Grid2 size={{ sm: 12, md: 6, lg:6 }}  padding={2}>
              <Box sx={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                <Button variant="contained" sx={{ margin: '2px', backgroundColor: theme.palette.warning.main }} size="medium" onClick={() => navigate('/customer/newdoc/12')}>
                  {trans.customer_form.pay_button}
                </Button>
                <Button variant="contained" sx={{ margin: '2px' }} size="medium" onClick={() => navigate('/customer/newdoc/11')}>
                  {trans.customer_form.collect_button}
                </Button>
                <Button variant="contained" sx={{ margin: '2px', backgroundColor: "#f44336" }} size="medium" onClick={() => navigate('/customer/newdoc/13')} >
                  {trans.customer_form.sales_discount_button}
                </Button>
                <Button variant="contained" sx={{ margin: '2px', backgroundColor: "#00897b" }} size="medium" onClick={() => navigate('/customer/newdoc/14')}>
                  {trans.customer_form.purchase_discount_button}
                </Button>

                <Button variant="contained" sx={{ margin: '2px', backgroundColor: "#4527a0" }} size="medium" onClick={() => navigate(`/customer/statment/${customerObject.account_id}`)}>
                  {trans.customer_form.statment_button}
                </Button>

                <Button variant="contained" sx={{ margin: '2px', backgroundColor: "#4527a0" }} size="medium" onClick={() => openDateRangeDialog(0)}>
                  {trans.customer_form.statment_detailed_button}
                </Button>


              </Box>
            </Grid2>
          </Grid2>

          <Paper elevation={1} sx={{ marginY: 2 }}>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', padding: 2, justifyContent: 'space-around' }}>
              <Button variant="contained" startIcon={<SaveIcon sx={{ marginRight: 0, marginLeft: 1 }} />} onClick={onUpdate} sx={{ margin: '2px' }} size="small" disabled={!changesNotSaved}>
                {trans.customer_form.update_button}
              </Button>
            </Box>
          </Paper>

        </>)}


        <Dialog
            open={showSelectDateRangeDialog}
            keepMounted
            onClose={() => setShowSelectDateRangeDialog(false)}
            aria-describedby="alert-dialog-slide-description"
            sx={{ direction: langDirection }}
          >

            <SelectDateRangeDialog />
          </Dialog>


          <Dialog
            open={showDateRangeDialog}
            keepMounted
            onClose={() => setShowDateRangeDialog(false)}
            aria-describedby="alert-dialog-slide-description"
            dir={langDirection }
          >
            <DateRangeDialog openDialog = {showDateRangeDialog} setOpenDialog = {setShowDateRangeDialog} lable={"Date Range"} title={trans.date_range_dialog.title} parentId={1} onDialogYes={onDateRangeDialogYes} />
          </Dialog>


    </Container>
  );
}