import React, { useEffect, useState } from 'react'

import { useRepoAG } from '../reports/useRepoAG _v4';

import { ApiStatus } from '../../models/ApiStatus';
import { LinearProgress } from '@mui/material';
import api from '../services/api';
import { selectTranslations } from '../i18n/i18nSlice';
import { useAppSelector } from '../../app/hooks';



//*1-add model
interface Row {
  category_name: string;
  total: number;
}



export default function EmployeesReport() {


  const trans = useAppSelector(selectTranslations);

  const [rows, setRows] = useState<Row[]>([])

  const [apiCallStatus, setApiCallStatus] = React.useState<ApiStatus>({ error: "", status: "idle" })
  const isLoading = apiCallStatus.status === "loading";
  const isSucceeded = apiCallStatus.status === "succeeded";
  const isIdle = apiCallStatus.status === "idle";
  const isFailed = apiCallStatus.status === "failed";



  //*2- set on click open
  const onOpenClick = (id: number) => {
    // navigate("/employee/" + id.toString());
  }





  //*3- define get report
  const getReport = async () => {
    setApiCallStatus({ error: "", "status": "loading" })
    try {
      const response = await api.get(
        '/productcategory/rep01',
        { headers: { "Content-Type": "application/json" } }
      )

      if (response.status === 200) {

        setRows(response.data["product_category"])

        setApiCallStatus({ error: "", "status": "succeeded" })
      } else {
        setApiCallStatus({ error: response.statusText, "status": "failed" })
      }

    } catch (err: any) {
      setApiCallStatus({ error: err.message, "status": "failed" })
    }
  };

  useEffect(() => {
    getReport()
  }, [])


  const additinalColumns: any[] = rows.length > 0 ? Object.keys(rows[0]).filter(item => !['id', 'product_name', 'category_name', 'total'].includes(item)) : []

  //*4- default column data
  const col = [
    { field: 'category_name', type: 'string' },
    { field: 'total', type: 'number' },
    ...additinalColumns.map(item => ({ field: item, type: 'number' }))
    // ...additinalColumns.reduce((o, key) => ({ ...o, [key]: 0}), {})
  ]



  //*5- generate report
  // const genRep = useRepoAG<Row>(col, 'product_rep1', getReport, onOpenClick, 'id')
  const genRep = useRepoAG<Row>(col, 'stock_rep1', 'stock_rep1', trans.dashboards.categories_sales, rows)


  return (
    <>
      {isLoading && <LinearProgress />}
      {(isSucceeded || isIdle || isFailed) && (
        <>
          {genRep}
        </>

      )}
    </>
  );


}


