import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
// import AppBar from '@mui/material/AppBar';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';

import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputBase, { InputBaseProps } from '@mui/material/InputBase';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MailIcon from '@mui/icons-material/Mail';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MoreIcon from '@mui/icons-material/MoreVert';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import OnlinePredictionIcon from '@mui/icons-material/OnlinePrediction';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';


import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { openDrawer } from "./primaryAppBarSlice";
import { selectPaletteMode, setPalleteMode } from "../appconfig/appConfigSlice";
import { selectDrawerOpen, setDrawerOpen } from "../drawer/miniDrawerSlice";

import { selectDirection, selectTranslations } from "../i18n/i18nSlice";
import {
  Link,
  NavLink,
  useNavigate,
} from "react-router-dom";
import { FormControlLabel, FormGroup, Switch } from '@mui/material';
import { selectDraftsCount, updateDraftCount, isOnline } from '../login/loginSlice';
import { db } from '../../db';
import { isConnected } from '../connection/connectionSlice';
const drawerWidth = 240;


interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}


interface MyInputBaseProps extends InputBaseProps {
}

// const AppBar = styled(MuiAppBar, {
//   shouldForwardProp: (prop) => prop !== 'open',
// })<AppBarProps>(({ theme, open, isrtl }) => ({
//   zIndex: theme.zIndex.drawer + 1,
//   transition: theme.transitions.create(['width', 'margin'], {
//     easing: theme.transitions.easing.sharp,
//     duration: theme.transitions.duration.leavingScreen,
//   }),
//   ...(open && {
//     marginLeft: isrtl? 0:drawerWidth ,
//     marginRight: isrtl? drawerWidth:0 ,
//     width: `calc(100% - ${drawerWidth}px)`,
//     transition: theme.transitions.create(['width', 'margin'], {
//       easing: theme.transitions.easing.sharp,
//       duration: theme.transitions.duration.enteringScreen,
//     }),
//   }),
// }));


const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: 62,
  height: 34,
  padding: 7,
  '& .MuiSwitch-switchBase': {
    margin: 1,
    padding: 0,
    transform: 'translateX(6px)',
    '&.Mui-checked': {
      color: '#fff',
      transform: 'translateX(22px)',
      '& .MuiSwitch-thumb:before': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          '#fff',
        )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
      },
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: theme.palette.mode === 'dark' ? '#003892' : '#001e3c',
    width: 32,
    height: 32,
    '&:before': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
        '#fff',
      )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
    },
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
    borderRadius: 20 / 2,
  },
}));



const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {

    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));


export default function PrimaryAppBar() {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const open = useAppSelector(selectDrawerOpen);
  const langDirection = useAppSelector(selectDirection);
  const trans = useAppSelector(selectTranslations);
  const paletteMode = useAppSelector(selectPaletteMode);
  const draftCount = useAppSelector(selectDraftsCount);
  const onlineMode = useAppSelector(isOnline);
  const connected = useAppSelector(isConnected)
  // const [draftCount, setDraftCount] = React.useState<number>(0);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
    React.useState<null | HTMLElement>(null);
    const [currentBranch, setCurrentBranch] = React.useState("");

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleDrawerOpen = () => {
    dispatch(setDrawerOpen(true));
  };

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    // setAnchorEl(event.currentTarget);
    setAnchorEl(null);
    handleMobileMenuClose();
    navigate("/myaccount")
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const onDraftsClicked = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
    navigate("/drafts")
  };


  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const getCurrentBranch = async () => {
    const currentBranchData = await db.appConfig.get({ id: 4 })
    if (currentBranchData) {
        setCurrentBranch(currentBranchData?.config_value!)
    }
}

  React.useEffect(() => {
    dispatch(updateDraftCount())
    getCurrentBranch()
  }, [])

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >


      <MenuItem>
        {onlineMode && connected ?
          <OnlinePredictionIcon sx={{ fontSize: 40, color: '#00c853' }} ></OnlinePredictionIcon>
          :
          <FilterListOffIcon sx={{ fontSize: 40, color: '#ff0000' }} ></FilterListOffIcon>
        }
      </MenuItem>

      <MenuItem>
        <IconButton
          size="large"
          aria-label="Drafts count"
          color="inherit"
          // onClick={() => navigate("/drafts")}
          onClick={onDraftsClicked}
        >
          <Badge badgeContent={draftCount} color="error">
            <TextSnippetIcon />
          </Badge>
        </IconButton>
        <p>{trans.drawer_drafts_button}</p>
      </MenuItem>
      {onlineMode &&
        <MenuItem onClick={handleProfileMenuOpen}>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="primary-search-account-menu"
            aria-haspopup="true"
            color="inherit"
            onClick={handleProfileMenuOpen}
          >
            <AccountCircle />
          </IconButton>
          <p>Profile</p>
        </MenuItem>
      }
    </Menu>
  );

  return (

    <AppBar position="absolute" open={open} >
      <Toolbar>
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
        >
          <MenuIcon />
        </IconButton>
        <NavLink style={{
          fontWeight: "bold",
          color: "white",
          textDecoration: 'none',
        }} to="/">
          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>

            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ display: { xs: 'none', sm: 'block' } }}
            >

              SoftPuzzles
            </Typography>
            <img src="logonewa.png" height={24} style={{ margin: 4 }} />
          </Box>
        </NavLink >
        <FormGroup>
          <FormControlLabel
            control={<MaterialUISwitch sx={{ m: 1 }} checked={paletteMode === "dark"} onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch(setPalleteMode(event.target.checked ? 'dark' : 'light'))} />}
            label="Light Mode"
          /></FormGroup>
        <Box sx={{ flexGrow: 1 }} />
        <Box sx={{ display: { xs: 'none', md: 'flex' } }}>

        <Typography
          variant="h6"
          noWrap
          component="div"
          sx={{ marginY: 1, marginX: 1, display: { xs: 'none', sm: 'block' } }}
        >
          {currentBranch}
        </Typography>


          {onlineMode && connected ?
            <OnlinePredictionIcon sx={{ marginY: 2, fontSize: 30, color: '#00ff00' }} ></OnlinePredictionIcon>
            :
            <FilterListOffIcon sx={{ marginY: 2, fontSize: 30, color: '#ff0000' }} ></FilterListOffIcon>
          }
          {/* {onlineMode ?
         <IconButton
         size="large"
         aria-label="Online"
         sx={{color: '#00c853' }}       >
         <Badge badgeContent={draftCount} color="error">
         <OnlinePredictionIcon sx={{color: '#00c853' }} ></OnlinePredictionIcon>
         </Badge>
       </IconButton>
         
          :

          <IconButton
          size="large"
          aria-label="Offline"
          sx={{color: '#ff000' }}     >
          <Badge badgeContent={draftCount} color="error">
          <FilterListOffIcon sx={{color: '#ff0000' }} ></FilterListOffIcon>
          </Badge>
        </IconButton>
          
        } */}

        

          <IconButton
            size="large"
            aria-label="Draft count"
            color="inherit"
            onClick={() => navigate("/drafts")}
          >
            <Badge badgeContent={draftCount} color="error">
              <TextSnippetIcon />
            </Badge>
          </IconButton>
          {onlineMode &&
            <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
          }
        </Box>
        <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
          <IconButton
            size="large"
            aria-label="show more"
            aria-controls={mobileMenuId}
            aria-haspopup="true"
            onClick={handleMobileMenuOpen}
            color="inherit"
          >
            <MoreIcon />
          </IconButton>
        </Box>
      </Toolbar>
      {renderMobileMenu}
    </AppBar>

  );
}
