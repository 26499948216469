import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { selectDirection, selectTranslations } from "../i18n/i18nSlice";
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { useNavigate } from 'react-router-dom'
import LinearProgress from '@mui/material/LinearProgress';
import SaveIcon from '@mui/icons-material/Save';
import { AppBar, Divider, FormControl, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, Toolbar } from '@mui/material';
import api from '../services/api';
import { reSyncData } from '../login/loginSlice';
import { ProductCategory } from '../../models/ProductCatalog';
import { type } from 'os';


interface ApiStatus {
  error: string;
  status: 'idle' | 'loading' | 'succeeded' | 'failed'
}


type Props = {}
const theme = createTheme();

export default function SetExpenseToPrice() {

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  
  const d = new Date();
  const month = d.getMonth();
  const [accountingMonth, setAccountingMonth] = React.useState<number>(month + 1)
  const [expense, setExpense] = React.useState<number>(0)
  const [salesCost, setSalesCost] = React.useState<number>(0)
  const [expensePerPrice, setExpensePerPrice] = React.useState<number>(0)
  const [changesNotSaved, setChangesNotSaved] = React.useState<boolean>(false);

  const [apiCallStatus, setApiCallStatus] = React.useState<ApiStatus>({ error: "", status: "idle" })
  const isLoading = () => apiCallStatus.status === "loading";
  const isSucceeded = () => apiCallStatus.status === "succeeded";
  const isIdle = () => apiCallStatus.status === "idle";
  const isFailed = () => apiCallStatus.status === "failed";

  const trans = useAppSelector(selectTranslations);


  const onSelectAccountingMonthsChange =(event: SelectChangeEvent<number>, child: React.ReactNode)  => {
    setChangesNotSaved(true)
    setAccountingMonth(parseInt(event.target.value as string))
    getExpenseAndSalesCost(parseFloat(event.target.value as string))
  };

  const onInputValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {

    if(event.target.name === 'expense'){
      setExpense(parseFloat(event.target.value as string))
    }
    if(event.target.name === 'sales_cost'){
      setExpense(parseFloat(event.target.value as string))
    }
    if(event.target.name === 'expense_per_price'){
      setExpensePerPrice(parseFloat(event.target.value as string))
    }

    setChangesNotSaved(true)
  }

  const onAdd = () => {
    // getExpenseAndSalesCost()
    setExpenseToPrice()
  }

  React.useEffect(() => {
    getExpenseAndSalesCost(accountingMonth)
    setChangesNotSaved(true)
  }, []);


  const getExpenseAndSalesCost = async (monthNumber:number) => {
    setApiCallStatus({ error: "", "status": "loading" })
    try {
      const response = await api.post(
        '/expense/pricecost',
        {
          month_number: monthNumber
        },
        { headers: { "Content-Type": "application/json" } }
      )

      if (response.status === 200) {
        setExpense(parseFloat(response.data["amounts"]["expense"] as string) - parseFloat(response.data["amounts"]["sales_cost"] as string) )
        setSalesCost(parseFloat(response.data["amounts"]["sales_cost"] as string))
        if(parseFloat(response.data["amounts"]["sales_cost"] as string) > 0){
          setExpensePerPrice(parseFloat(((parseFloat(response.data["amounts"]["expense"] as string) - parseFloat(response.data["amounts"]["sales_cost"] as string))/parseFloat(response.data["amounts"]["sales_cost"] as string) as Number).toFixed(2)))
        }else{
          setExpensePerPrice(0)
        }
        setApiCallStatus({ error: "", "status": "succeeded" })
      } else {
        setApiCallStatus({ error: response.statusText, "status": "failed" })
      }

    } catch (err: any) {
      setApiCallStatus({ error: err.message, "status": "failed" })
    }
  };


  const setExpenseToPrice = async () => {
    setApiCallStatus({ error: "", "status": "loading" })
    try {
      const response = await api.put(
        '/expense/pricecost',
        {
          amount: expensePerPrice
        },
        { headers: { "Content-Type": "application/json" } }
      )

      if (response.status === 200) {
        setChangesNotSaved(false)
        setApiCallStatus({ error: "", "status": "succeeded" })
      } else {
        setApiCallStatus({ error: response.statusText, "status": "failed" })
      }

    } catch (err: any) {
      setApiCallStatus({ error: err.message, "status": "failed" })
    }
  };



  return (
    <ThemeProvider theme={theme}>
      <Container>
      <AppBar position="static" sx={{ backgroundColor: "#283593" }}>
        <Toolbar component="nav" variant="dense" sx={{ justifyContent: 'space-between', overflowX: 'auto' }}  >
          <Typography component="h2" variant="h5">
          {trans.document_form.title_expense_per_price}
          </Typography>
        </Toolbar>
      </AppBar>

        
        {(isLoading()) && <LinearProgress />}
        {(isSucceeded() || isIdle() || isFailed()) && (
          <>
            <Grid container>
              <Grid item sm={12} md={6} lg={6} padding={2}>
                <Box sx={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                <FormControl variant="standard" fullWidth>
                    <InputLabel htmlFor="accounting_months" >{trans.document_form.accounting_months}</InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="accounting_months"
                      // defaultValue={'P'}
                      value={accountingMonth}
                      name="accounting_months"
                  
                      label="Monthes"
                      // onChange={handleChange}
                      onChange={onSelectAccountingMonthsChange}

                      size='small'
                      sx={{ width: "100%" }}
                    >
                      <MenuItem value={1}>1</MenuItem>
                      <MenuItem value={2}>2</MenuItem>
                      <MenuItem value={3}>3</MenuItem>
                      <MenuItem value={4}>4</MenuItem>
                      <MenuItem value={5}>5</MenuItem>
                      <MenuItem value={6}>6</MenuItem>
                      <MenuItem value={7}>7</MenuItem>
                      <MenuItem value={8}>8</MenuItem>
                      <MenuItem value={9}>9</MenuItem>
                      <MenuItem value={10}>10</MenuItem>
                      <MenuItem value={11}>11</MenuItem>
                      <MenuItem value={12}>12</MenuItem>
                    </Select>

                  </FormControl>    

                  <TextField id="expense" name="expense" type='number' label={trans.document_form.expense} variant="standard" size='small' onChange={onInputValueChange} value={expense} required />
                  <TextField id="sales_cost" name="sales_cost" type='number' label={trans.document_form.sales_cost} variant="standard" size='small' onChange={onInputValueChange} value={salesCost} required />
                  <TextField id="expense_per_price" name="expense_per_price" type='number' label={trans.document_form.expense_per_price} variant="standard" size='small' onChange={onInputValueChange} value={expensePerPrice} required />
                </Box>
              </Grid>
              <Grid item sm={12} md={6} lg={6} padding={2}>
                <Box sx={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                </Box>
              </Grid>
            </Grid>

            <Paper elevation={1} sx={{ marginY: 2 }}>
              <Box sx={{ display: 'flex', flexWrap: 'wrap', padding: 2, justifyContent: 'space-around' }}>
                <Button variant="contained" startIcon={<SaveIcon sx={{ marginRight: 0, marginLeft: 1 }} />} onClick={onAdd} sx={{ margin: '2px' }} size="small" disabled={!changesNotSaved}>
                  {trans.document_form.update_button}
                </Button>
              </Box>
            </Paper>

          </>)}
      </Container>
    </ThemeProvider>
  );
}