import {createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import type { Property } from "csstype";
import primaryAppBarSlice from '../appBar/primaryAppBarSlice';




export interface MiniDrawerConfigration {
    drawerOpen: boolean;
    drawerWidth: number;
  }

const initialState: MiniDrawerConfigration = {
    drawerOpen: true,
    drawerWidth: 240,
}



export const miniDrawerSlice = createSlice({
    name: 'miniDrawer',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        setDrawerOpen: (state: any, action: PayloadAction<boolean>) => {
            state.drawerOpen = action.payload;
        },
    },
    // extraReducers: (builder) => {
    //     builder.addCase(primaryAppBarSlice.)
    // }
    // extraReducers: {
    //     "primaryAppBar/openDrawer":(state: any, action:any)=>{
    //         state.drawerOpen = true;
    //     }
    // }
});
  
export const { setDrawerOpen} = miniDrawerSlice.actions;
export const selectDrawerOpen = (state: RootState) => state.miniDrawer.drawerOpen;
export const selectDrawerWdith = (state: RootState) => state.miniDrawer.drawerWidth;
  
export default miniDrawerSlice.reducer;
  