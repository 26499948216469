import {createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import type { Property } from "csstype";




export interface AppConfigtate {
    paletteMode: string;
  }

const initialState: AppConfigtate = {
    paletteMode: 'light',
}



export const appConfigSlice = createSlice({
    name: 'appConfig',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        setPalleteMode: (state: any, action: PayloadAction<string>) => {
            state.paletteMode = action.payload;
        },
    },
});
  
export const { setPalleteMode} = appConfigSlice.actions;
export const selectPaletteMode = (state: RootState) => state.appConfig.paletteMode;
  
export default appConfigSlice.reducer;
  