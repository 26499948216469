import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { selectDirection, selectTranslations } from "../../features/i18n/i18nSlice";
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { useNavigate } from 'react-router-dom'
import LinearProgress from '@mui/material/LinearProgress';
import { useParams } from 'react-router-dom';
import SaveIcon from '@mui/icons-material/Save';
import { AppBar, Paper, Toolbar } from '@mui/material';
import api from '../services/api';
import { Broker } from '../../models/Customer';
import { reSyncData } from '../login/loginSlice';
import { Colors } from '../../models/ProductCatalog';



interface ApiStatus {
  error: string;
  status: 'idle' | 'loading' | 'succeeded' | 'failed'
}

type Props = {}
const theme = createTheme();

export default function ColorForm() {
  const params = useParams();
  const { itemId } = params
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const [lookupObject, setLookupObject] = React.useState<Colors>()
  const [changesNotSaved, setChangesNotSaved] = React.useState<boolean>(false);

  const [apiCallStatus, setApiCallStatus] = React.useState<ApiStatus>({ error: "", status: "idle" })
  const isLoading = () => apiCallStatus.status === "loading";
  const isSucceeded = () => apiCallStatus.status === "succeeded";
  const isIdle = () => apiCallStatus.status === "idle";
  const isFailed = () => apiCallStatus.status === "failed";

  const trans = useAppSelector(selectTranslations);




  const onInputValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLookupObject({...lookupObject, [event.target.name]: event.target.value} as Colors)
    setChangesNotSaved(true)
  }

  const onUpdate = () => {
    updateLookup()
    if (isSucceeded()) setChangesNotSaved(false)
  }




  const getLookupObject = async () => {
    setApiCallStatus({ error: "", "status": "loading" })
    try {
      const response = await api.get(
        `/colors/${itemId}`,
        { headers: { "Content-Type": "application/json" } }
      )

      if (response.status === 200) {

        setLookupObject(response.data["color"])
        setApiCallStatus({ error: "", "status": "succeeded" })
      } else {
        setApiCallStatus({ error: response.statusText, "status": "failed" })
      }

    } catch (err: any) {
      setApiCallStatus({ error: err.message, "status": "failed" })
    }
  };

  const updateLookup = async () => {
    setApiCallStatus({ error: "", "status": "loading" })
    try {
      const response = await api.put(
        '/colors',
        lookupObject,
        { headers: { "Content-Type": "application/json" } }
      )

      if (response.status === 200) {
        dispatch(reSyncData())
        setLookupObject(response.data["color"])
        setChangesNotSaved(false)
        setApiCallStatus({ error: "", "status": "succeeded" })
      } else {
        alert(response.data['msg'])
        setApiCallStatus({ error: response.statusText, "status": "failed" })
      }

    } catch (err: any) {
      alert(err.response.data['msg'])
      setApiCallStatus({ error: err.message, "status": "failed" })
    }
  };



  React.useEffect(() => {
    getLookupObject()
  }, []);



  return (
      <Container>
      <AppBar position="static" sx={{backgroundColor:"#283593"}}>
      {/* <Toolbar sx={{ borderBottom: 1, borderColor: 'divider' }} variant="dense" > */}
      <Toolbar component="nav" variant="dense" sx={{ justifyContent: 'space-between', overflowX: 'auto' }}  >
        {/* <Typography variant='h4'>{trans.lookup_form.title}</Typography> */}
        {/* <Typography component="h2" variant="h5" color="primary" gutterBottom> */}
        <Typography component="h2" variant="h5">
          {trans.lookup_form.color_title}
        </Typography>
        </Toolbar>
        </AppBar>
        {/* <Divider /> */}
        {isLoading() && <LinearProgress />}
        {(isSucceeded() || isIdle() || isFailed())&& (
          <>
            <Grid container>
              <Grid item sm={12} md={6} lg={6} padding={2}>
                <Box sx={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                  <TextField id="id" name="id"  label={trans.lookup_form.id} variant="standard" size='small'  value={itemId} />
                  <TextField id="color_name" name="color_name" label={trans.lookup_form.color_name} variant="standard" size='small' onChange={onInputValueChange} value={lookupObject?.color_name} required />
                </Box>
              </Grid>
            </Grid>

            <Paper elevation={1} sx={{ marginY: 2 }}>
              <Box sx={{ display: 'flex', flexWrap: 'wrap', padding: 2, justifyContent: 'space-around' }}>
                <Button variant="contained" startIcon={<SaveIcon sx={{ marginRight: 0, marginLeft: 1 }} />} onClick={onUpdate} sx={{ margin: '2px' }} size="small" disabled={!changesNotSaved}>
                  {trans.lookup_form.update_button}
                </Button>
              </Box>
            </Paper>

          </>)}
      </Container>
  );
}