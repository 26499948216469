import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { selectDirection, selectTranslations } from "../i18n/i18nSlice";
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { addCollectedAmount, customerCallStatus, customerData, setCustomerData, updateCustomerData } from './customerSlice'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux';
import { height } from '@mui/system';
import LinearProgress from '@mui/material/LinearProgress';
import { useParams } from 'react-router-dom';
import SaveIcon from '@mui/icons-material/Save';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import { AppBar, Dialog, Divider, Paper, Toolbar } from '@mui/material';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateField } from '@mui/x-date-pickers';
import api from '../services/api';
import YesNoDialog2 from './YesNoDialog2';


interface ApiStatus {
  error: string;
  status: 'idle' | 'loading' | 'succeeded' | 'failed'
}

interface DocumentData {
  id: number;
  document_date: string;
  document_type: string;
  document_type_id: number;
  customer_account_id: number;
  customer_type_id: number;
  extended_docuemnt_id: number;
  amount: number;
  comment: string;
  fullname: string;
  update_date: number;
}

type Props = {}
const theme = createTheme();
//{customerAccountId, customerType}: {customerAccountId: number, customerType: number}
export default function CustomerDocumentForm() {
  const params = useParams();
  const { documentId } = params
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [apiCallStatus, setApiCallStatus] = React.useState<ApiStatus>({ error: "", status: "idle" })
  const isLoading = () => apiCallStatus.status === "loading";
  const isSucceeded = () => apiCallStatus.status === "succeeded";
  const isIdle = () => apiCallStatus.status === "idle";
  const isFailed = () => apiCallStatus.status === "failed";
  const trans = useAppSelector(selectTranslations);
  const langDirection = useAppSelector(selectDirection);

  // const [amount, setAmount] = React.useState(0)
  // const [note, setNote] = React.useState('')
  // const [docDate, setDocDate] = React.useState(Date());
  // const [customerFullname, setCustomerFullname] = React.useState(0)

  // const [documentType, setDocumentType] = React.useState(0)
  // const [customerAccountId, setCustomerAccountId] = React.useState(0)

  const [documentData, setDocumentData] = React.useState<DocumentData>()
  const [changesNotSaved, setChangesNotSaved] = React.useState<boolean>(false);
  const [showYesNoDialog, setShowYesNoDialog] = React.useState(false);
  const onShowYesNoDialogYes = async (parentId: number, result: number) => {
    await onCancel()
  }



  const onAmountValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const amountValue: number = Number(event.target.value)
    // setAmount(amountValue)
    if(amountValue === 0){
      setChangesNotSaved(false)
    }else  if(amountValue !== documentData?.amount){
      setChangesNotSaved(true)
    }
    setDocumentData({ ...documentData!, amount: amountValue })
  }

  const onNoteValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // setNote(event.target.value)
    if(event.target.value !== documentData?.comment) setChangesNotSaved(true)
    setDocumentData({ ...documentData!, comment: event.target.value })
  }

  const onDateChange = (newValue: Date | null) => {
    // setDocDate(newValue!.toISOString().split('.')[0] + "Z")
    const newDateValue = newValue!.toISOString().split('.')[0] + "Z"
    if(newDateValue !== documentData?.document_date) setChangesNotSaved(true)
    setDocumentData({ ...documentData!, document_date: newValue!.toISOString().split('.')[0] + "Z" })
  };

  const onUpdate = () => {

    updateDocument()
  }

  const onCancel = async () => {
    await cancelDocument()
    if (isSucceeded()) navigate(-1)
  }


  const getDocument = async () => {
    setApiCallStatus({ error: "", "status": "loading" })
    try {
      const response = await api.get(
        `/customer/document/${documentId}`,
        { headers: { "Content-Type": "application/json" } }
      )

      if (response.status === 200) {

        setDocumentData(response.data["document"])

        // setAmount(response.data["document"].amount)
        // setNote(response.data["document"].note)
        // setDocDate(response.data["document"].date)
        // setCustomerFullname(response.data["document"].fullname)
        // setDocumentType(response.data["document"].document_type)
        // setCustomerAccountId(response.data["document"].customer_account_id)

        setApiCallStatus({ error: "", "status": "succeeded" })
      } else {
        setApiCallStatus({ error: response.statusText, "status": "failed" })
      }

    } catch (err: any) {
      setApiCallStatus({ error: err.message, "status": "failed" })
    }
  };

  const updateDocument = async () => {
    setApiCallStatus({ error: "", "status": "loading" })
    try {
      const response = await api.put(
        '/customer/document',
        documentData,
        { headers: { "Content-Type": "application/json" } }
      )

      if (response.status === 200) {
        setDocumentData(response.data["document"])
        setChangesNotSaved(false)
        setApiCallStatus({ error: "", "status": "succeeded" })
      } else {
        alert(response.data['msg'])
        setApiCallStatus({ error: response.statusText, "status": "failed" })
      }

    } catch (err: any) {
      alert(err.response.data['msg'])
      setApiCallStatus({ error: err.message, "status": "failed" })
    }
  };


  const cancelDocument = async () => {
    setApiCallStatus({ error: "", "status": "loading" })
    try {
      const response = await api.delete(
        `/customer/document/${documentData?.id}`,
        { headers: { "Content-Type": "application/json" } }
      )

      if (response.status === 200) {

        setApiCallStatus({ error: "", "status": "succeeded" })
        navigate(-1)
      } else {
        setApiCallStatus({ error: response.statusText, "status": "failed" })
      }

    } catch (err: any) {
      setApiCallStatus({ error: err.message, "status": "failed" })
    }
  };


  React.useEffect(() => {
    getDocument()
  }, [])

  const getDocumentTitle = () => {

    if(documentData?.document_type_id === 11) {
      return trans.document_form.title_collect + documentData.fullname
    }else if(documentData?.document_type_id === 12) {
      return trans.document_form.title_pay + documentData.fullname
    }else if(documentData?.document_type_id === 13) {
      return trans.document_form.title_sales_discount + documentData.fullname
    }else if(documentData?.document_type_id === 14) {
      return trans.document_form.title_purchase_discount + documentData.fullname
    }else{
      return ""
    }

  }

  return (
    <Container>

      <AppBar position="static" sx={{ backgroundColor: "#283593" }}>
        <Toolbar component="nav" variant="dense" sx={{ justifyContent: 'space-between', overflowX: 'auto' }}  >
          <Typography component="h2" variant="h5">
            {getDocumentTitle()}
          </Typography>
        </Toolbar>
      </AppBar>
      {(isLoading()) && <LinearProgress />}
      {(isSucceeded() || isIdle() || isFailed()) && (
        <>
          <Grid container>
            <Grid item sm={12} md={6} lg={6} padding={2}>
              <Box sx={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                <TextField id="amount" name="amount" type='number' label={trans.document_form.amount} variant="standard" size='small' onChange={onAmountValueChange} value={documentData?.amount} />
                <TextField id="note" name="note" label={trans.document_form.note} variant="standard" size='small' onChange={onNoteValueChange} value={documentData?.comment} />
                <LocalizationProvider dateAdapter={AdapterDateFns} >
                  <DateField
                    label={trans.document_form.date}
                    format="dd/MM/yyyy"
                    value={documentData ? new Date(documentData?.document_date) : new Date()}
                    onChange={onDateChange}
                    // slotProps={{ textField: { variant: 'standard' } }}
                    sx={{ marginX: "6px" }}
                  />
                </LocalizationProvider>
              </Box>
            </Grid>

          </Grid>

          <Paper elevation={1} sx={{ marginY: 2 }}>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', padding: 2, justifyContent: 'flex-start' }}>
              <Button variant="contained" startIcon={<SaveIcon sx={{ marginRight: 0, marginLeft: 1 }} />} onClick={onUpdate} sx={{ margin: '2px' }} size="small" disabled={!changesNotSaved}>
                {trans.document_form.update_button}
              </Button>
              <Button variant="contained" startIcon={<DeleteForeverIcon sx={{ marginRight: 0, marginLeft: 1 }} />} onClick={() => setShowYesNoDialog(true)} sx={{ margin: '2px', backgroundColor: theme.palette.warning.main }} size="small">
                {trans.document_form.cancel_button}
              </Button>
            </Box>
          </Paper>

        </>)}

        <Dialog
            open={showYesNoDialog}
            keepMounted
            onClose={() => setShowYesNoDialog(false)}
            aria-describedby="alert-dialog-slide-description"
            sx={{ direction: langDirection }}
          >
            <YesNoDialog2 openDialog = {showYesNoDialog} setOpenDialog = {setShowYesNoDialog} lable={trans.document_form.cancel_message} title={trans.document_form.cancel_title} parentId={0} onShowYesNoDialogYes={onShowYesNoDialogYes} />
          </Dialog>
          
    </Container>
  );
}