import React, { useEffect, useState } from 'react'

import { useNavigate, useParams } from "react-router-dom";

import { ApiStatus } from '../../models/ApiStatus';
import { useAppSelector } from '../../app/hooks';
import { LinearProgress } from '@mui/material';
import { selectTranslations } from '../i18n/i18nSlice';
import RepoAG from '../reports/RepoAG _v5';
import api from '../services/api';

import dayjs from 'dayjs';
import TablePagination from './TablePagination';



//*1-add model
interface ReqBody {
  page_size: number;
  page_number: number;
  asc: boolean;
}



export default function UsersLogs() {
 const params = useParams();
  const { pageNumber } = params
  const navigate = useNavigate();
  const trans = useAppSelector(selectTranslations);
  

  const [apiCallStatus, setApiCallStatus] = React.useState<ApiStatus>({ error: "", status: "idle" })
  const isLoading = apiCallStatus.status === "loading";
  const isSucceeded = apiCallStatus.status === "succeeded";
  const isIdle = apiCallStatus.status === "idle";
  const isFailed = apiCallStatus.status === "failed";

  const [rows, setRows] = useState<any[]>([])

  const [totalRecords, setTotalRecords] = useState<number>(0)
  const [pageSize, setPageSize] = useState<number>(50)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const setPageNumber = (page: number) => {
    navigate(`/userlogs/${page}`)
  }

  //*2- set on click open
  const onOpenClick = (item: any) => {
    const {object_id: id, log_object_type_id: objectType} = item
    if (objectType === 1 || objectType === 2 || objectType === 3 || objectType === 4 || objectType === 5 || objectType === 6 || objectType === 7 || objectType === 8 || objectType === 9 || objectType === 10) {
      navigate("/invoice/" + id.toString());
    }
    if (objectType === 11 || objectType === 12 || objectType === 13 || objectType === 14) {
      navigate("/customer/document/" + id.toString());
    }
    if (objectType === 15) {
      navigate("/expense/" + id.toString());
    }
    if (objectType === 16) {
      navigate("/customer/" + id.toString());
    }
    if (objectType === 17) {
      navigate("/store/" + id.toString());
    }
    if (objectType === 18) {
      navigate("/productcategory/" + id.toString());
    }
    if (objectType === 19) {
      navigate("/expensetype/" + id.toString());
    }
    if (objectType === 20) {
      navigate("/broker/" + id.toString());
    }
    if (objectType === 21) {
      navigate("/salesperson/" + id.toString());
    }
    if (objectType === 22) {
      navigate("/product/" + id.toString());
    }
    // if(objectType === 23){
    //   navigate("/expense/" + id.toString());
    // }
    if (objectType === 24) {
      navigate("/colors/" + id.toString());
    }
    if (objectType === 25) {
      navigate("/sizes/" + id.toString());
    }
  }

  //*3- define get report
  const getReport = async (body: ReqBody) => {
    setApiCallStatus({ error: "", "status": "loading" })
    try {


      const response = await api.post(
        '/userlogs',
        body,
        { headers: { "Content-Type": "application/json" } }
      )

      if (response.status === 200) {

        setRows(response.data["userlogs"]["data"].map((item: any) => ({ ...item, log_date: dayjs((new Date(item.log_date)).toLocaleString()).format('YYYY-MM-DD hh:mm:ss A') })))
        setTotalRecords(response.data["userlogs"]["total_records"])
        // setCurrentPage(response.data["userlogs"]["page_number"])

        setApiCallStatus({ error: "", "status": "succeeded" })
      } else {
        setApiCallStatus({ error: response.statusText, "status": "failed" })
      }

    } catch (err: any) {
      setApiCallStatus({ error: err.message, "status": "failed" })
    }
  };

  useEffect(() => {
    getReport({
      page_size: pageSize,
      page_number: parseInt(pageNumber!),
      asc: false,
    })
  }, [])


  //*4- default column data

  const columns = [
    { field: 'id', type: 'number' },
    { field: 'log_date', type: 'date' },
    { field: 'log_action_type_name', type: 'string' },
    { field: 'log_object_type_name', type: 'string' },
    // { field: 'log_object_type_id', type: 'number' },
    { field: 'object_id', type: 'number' },
    { field: 'username', type: 'string' },
  ]




  //*5- generate report
  
  return (
    <>
      {isLoading && <LinearProgress />}
      {(isSucceeded || isIdle || isFailed) && (
        <>
          <RepoAG columns={columns} componentName={trans.lookup_form.store_title} transRep='users_logs' title={trans.lookup_form.store_title} rows={rows} onOpenClick={onOpenClick} />
          <TablePagination currentPage={parseInt(pageNumber!)} numberOfPage={Math.floor(totalRecords / pageSize) + (totalRecords % pageSize > 0 ? 1 : 0)} setPageNumber={setPageNumber} />
        </>

      )}
    </>
  );


}


