import React, { useContext, useEffect, createContext, useMemo, useState, useCallback } from 'react'
import { LinearProgress, Paper, Button, Box, Container, Typography, Divider, AppBar, Toolbar, TextField } from '@mui/material';
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { selectDirection, selectTranslations } from "../i18n/i18nSlice";

import CloudSyncIcon from '@mui/icons-material/CloudSync';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useNavigate, Link, useParams } from "react-router-dom";


import DataGrid, { Column, SortColumn, HeaderRendererProps, useFocusRef, headerRenderer as HeaderRenderer } from 'react-data-grid';
import { DraggableHeaderRenderer } from './DraggableHeaderRenderer'
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useDrag, useDrop } from 'react-dnd';



import { selectPaletteMode } from '../appconfig/appConfigSlice';
import { Customer } from '../../models/Customer';
import { db } from '../../db';
import api from '../services/api';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import * as XLSX from 'xlsx';
import { productData } from './productSlice';

interface ApiStatus {
  error: string;
  status: 'idle' | 'loading' | 'succeeded' | 'failed'
}



//*1-add model
interface Row {
  id: number;
  invoice_date: string;
  invoice_type: string;
  store_name: string;
  main_unit_price: number;
  in: number;
  out: number;
  stock: number;
  fullname: string
  invoice_comment: string;
}



//*2- add filter
interface Filter extends Omit<Row, 'id' | 'main_unit_price' | 'in' | 'out' | 'stock'> {
  main_unit_price: number | undefined;
  in: number | undefined;
  out: number | undefined;
  stock: number | undefined;
  enabled: boolean;
}
const FilterContext = createContext<Filter | undefined>(undefined);


export default function ProductAllTrans01() {
  const params = useParams();
  const { productId } = params

  const navigate = useNavigate();
  const paletteMode = useAppSelector(selectPaletteMode);

  const dispatch = useAppDispatch()

  const langDirection = useAppSelector(selectDirection);
  const trans = useAppSelector(selectTranslations);
  // const rows = useAppSelector(customersReport);
  const [rows, setRows] = useState<Row[]>([])
  const [sortColumns, setSortColumns] = useState<readonly SortColumn[]>([]);
  const productObject = useAppSelector(productData);

  const [apiCallStatus, setApiCallStatus] = React.useState<ApiStatus>({ error: "", status: "idle" })
  const isLoading = () => apiCallStatus.status === "loading";
  const isSucceeded = () => apiCallStatus.status === "succeeded";
  const isIdle = () => apiCallStatus.status === "idle";
  const isFailed = () => apiCallStatus.status === "failed";


  //*4- define get report
  const getReport = async () => {
    setApiCallStatus({ error: "", "status": "loading" })
    try {
      const response = await api.get(
        `/product/trans/${productId}`,
        { headers: { "Content-Type": "application/json" } }
      )

      if (response.status === 200) {

        setRows(response.data["transactions"])

        setApiCallStatus({ error: "", "status": "succeeded" })
      } else {
        setApiCallStatus({ error: response.statusText, "status": "failed" })
      }

    } catch (err: any) {
      setApiCallStatus({ error: err.message, "status": "failed" })
    }
  };


  const classes = {
    filterColumnClassName: 'filter-cell',
  }


  const filterColumnClassName = 'filter-cell';
  const filterContainerClassname = `
    .${filterColumnClassName} {
      line-height: 35px;
      padding: 0;
      > div {
        padding-block: 0;
        padding-inline: 8px;
        &:first-child {
          border-block-end: 1px solid var(--rdg-border-color);
        }
      }
    }
  `;
  const filterClassname = `
    inline-size: 100%;
    padding: 4px;
    font-size: 14px;
  `;





  function inputStopPropagation(event: React.KeyboardEvent<HTMLInputElement>) {
    if (['ArrowLeft', 'ArrowRight'].includes(event.key)) {
      event.stopPropagation();
    }
  }

  //*5- set default values
  const [filters, setFilters] = useState<Filter>({
    invoice_date: '',
    invoice_type: '',
    store_name: '',
    main_unit_price: 0,
    in: 0,
    out: 0,
    stock: undefined,
    fullname: '',
    invoice_comment: '',
    enabled: true
  });


  //*6- set fitlers and ordering
  const filteredRows = useMemo((): readonly Row[] => {

    if (sortColumns.length === 0) {
      let sortedRows: Row[] = rows.filter((r) => {
        return (
          (filters.invoice_date ? r.invoice_date.toLowerCase().includes(filters.invoice_date.toLowerCase()) : true) &&
          (filters.invoice_type ? r.invoice_type.toLowerCase().includes(filters.invoice_type.toLowerCase()) : true) &&
          (filters.store_name ? r.store_name.toLowerCase().includes(filters.store_name.toLowerCase()) : true) &&
          (filters.main_unit_price !== undefined ? r.main_unit_price >= filters.main_unit_price : true) &&
          (filters.in !== undefined ? r.in >= filters.in : true) &&
          (filters.out !== undefined ? r.out >= filters.out : true) &&
          (filters.stock !== undefined ? r.stock >= filters.stock : true) &&
          (filters.fullname ? r.fullname.toLowerCase().includes(filters.fullname.toLowerCase()) : true) &&
          (filters.invoice_comment ? r.invoice_comment.toLowerCase().includes(filters.invoice_comment.toLowerCase()) : true)


        );
      });
      return sortedRows
    }
    const { columnKey, direction } = sortColumns[0];

    let sortedRows: Row[] = rows.filter((r) => {
      return (
        (filters.invoice_date ? r.invoice_date.toLowerCase().includes(filters.invoice_date.toLowerCase()) : true) &&
        (filters.invoice_type ? r.invoice_type.toLowerCase().includes(filters.invoice_type.toLowerCase()) : true) &&
        (filters.store_name ? r.store_name.toLowerCase().includes(filters.store_name.toLowerCase()) : true) &&
        (filters.main_unit_price !== undefined ? r.main_unit_price >= filters.main_unit_price : true) &&
        (filters.in !== undefined ? r.in >= filters.in : true) &&
        (filters.out !== undefined ? r.out >= filters.out : true) &&
        (filters.stock !== undefined ? r.stock >= filters.stock : true) &&
        (filters.fullname ? r.fullname.toLowerCase().includes(filters.fullname.toLowerCase()) : true) &&
        (filters.invoice_comment ? r.invoice_comment.toLowerCase().includes(filters.invoice_comment.toLowerCase()) : true)

      );
    });


    switch (columnKey) {
      case 'invoice_date':
        sortedRows = sortedRows.sort((a, b) => a[columnKey].localeCompare(b[columnKey]));
        break;
      case 'invoice_type':
        sortedRows = sortedRows.sort((a, b) => a[columnKey].localeCompare(b[columnKey]));
        break;
      case 'store_name':
        sortedRows = sortedRows.sort((a, b) => a[columnKey].localeCompare(b[columnKey]));
        break;
      case 'main_unit_price':
        sortedRows = sortedRows.sort((a, b) => a[columnKey] - b[columnKey]);
        break;
      case 'in':
        sortedRows = sortedRows.sort((a, b) => a[columnKey] - b[columnKey]);
        break;
      case 'out':
        sortedRows = sortedRows.sort((a, b) => a[columnKey] - b[columnKey]);
        break;
      case 'stock':
        sortedRows = sortedRows.sort((a, b) => a[columnKey] - b[columnKey]);
        break;
      case 'fullname':
        sortedRows = sortedRows.sort((a, b) => a[columnKey].localeCompare(b[columnKey]));
        break;
      case 'invoice_comment':
        sortedRows = sortedRows.sort((a, b) => a[columnKey].localeCompare(b[columnKey]));
        break;
      default:
        sortedRows = (sortedRows as any[]).sort((a, b) => a[columnKey] - b[columnKey]);
    }

    return direction === 'DESC' ? sortedRows.reverse() : sortedRows;
  }, [rows, filters, sortColumns]);


  const [columnsChanged, setColumnsChanged] = useState(false);

  const filteredColumns = useMemo((): readonly any[] => {

    function HeaderRenderer(props: HeaderRendererProps<Row>) {
      return <DraggableHeaderRenderer {...props} onColumnsReorder={handleColumnsReorder} />;
    }

    function handleColumnsReorder(sourceKey: string, targetKey: string) {
      const sourceColumnIndex = columns.findIndex((c) => c.key === sourceKey);
      const targetColumnIndex = columns.findIndex((c) => c.key === targetKey);
      const reorderedColumns = [...columns];

      reorderedColumns.splice(
        targetColumnIndex,
        0,
        reorderedColumns.splice(sourceColumnIndex, 1)[0]
      );

      setColumns(reorderedColumns);
      setColumnsChanged(!columnsChanged)
    }


    //*7- set colums
    return [
      { key: 'id', name: 'id', resizable: true, sortable: true, frozen: true, width: 20 },
      { key: 'open', name: trans.nsi_table.open, resizable: true, sortable: true, frozen: true, width: 20, formatter({ row }: { row: any }) { return <Button style={{ width: 32, minWidth: 32 }} onClick={() => onOpenClick(row.id!)}>{trans.nsi_table.open}</Button> } },
      {
        key: 'invoice_date', name: trans.product_trans.invoice_date, resizable: true, sortable: true, frozen: true, width: 'max-content',
        headerCellClass: filterColumnClassName,
        headerRenderer: (p: HeaderRendererProps<Row>) => (

          <FilterRenderer<Row, unknown, HTMLInputElement> {...p} onColumnsReorder={handleColumnsReorder}>

            {({ filters, ...rest }) => (
              <>
                <HeaderRenderer {...p}></HeaderRenderer>
                <input
                  {...rest}
                  className={filterClassname!}
                  // variant='outlined'
                  style={{ inlineSize: "100%", padding: "4px", fontSize: "14px" }}
                  value={filters.invoice_date}
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      invoice_date: e.target.value
                    })
                  }
                  onKeyDown={inputStopPropagation}
                />
              </>
            )}
          </FilterRenderer>
        )
      },
      {
        key: 'invoice_type', name: trans.product_trans.invoice_type, resizable: true, sortable: true, width: 'max-content',
        headerCellClass: filterColumnClassName,
        headerRenderer: (p: HeaderRendererProps<Row>) => (

          <FilterRenderer<Row, unknown, HTMLInputElement> {...p} onColumnsReorder={handleColumnsReorder}>

            {({ filters, ...rest }) => (
              <>
                <HeaderRenderer {...p}></HeaderRenderer>
                <input
                  {...rest}
                  className={filterClassname!}
                  // variant='outlined'
                  style={{ inlineSize: "100%", padding: "4px", fontSize: "14px" }}
                  value={filters.invoice_type}
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      invoice_type: e.target.value
                    })
                  }
                  onKeyDown={inputStopPropagation}
                />
              </>
            )}
          </FilterRenderer>
        )
      },
      {
        key: 'store_name', name: trans.product_trans.store_name, resizable: true, sortable: true, width: 'max-content',
        headerCellClass: filterColumnClassName,
        headerRenderer: (p: HeaderRendererProps<Row>) => (

          <FilterRenderer<Row, unknown, HTMLInputElement> {...p} onColumnsReorder={handleColumnsReorder}>

            {({ filters, ...rest }) => (
              <>
                <HeaderRenderer {...p}></HeaderRenderer>
                <input
                  {...rest}
                  className={filterClassname!}
                  // variant='outlined'
                  style={{ inlineSize: "100%", padding: "4px", fontSize: "14px" }}
                  value={filters.store_name}
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      store_name: e.target.value
                    })
                  }
                  onKeyDown={inputStopPropagation}
                />
              </>
            )}
          </FilterRenderer>
        )
      },

      {
        key: 'main_unit_price', name: trans.product_trans.main_unit_price, resizable: true, sortable: true, width: 100,
        headerCellClass: filterColumnClassName,
        headerRenderer: (p: HeaderRendererProps<Row>) => (
          <FilterRenderer<Row, unknown, HTMLInputElement> {...p} onColumnsReorder={handleColumnsReorder}>
            {({ filters, ...rest }) => (
              <>
                <HeaderRenderer {...p}></HeaderRenderer>
                <input
                  {...rest}
                  type="number"
                  style={{ inlineSize: "100%", padding: "4px", fontSize: "14px" }}
                  value={filters.main_unit_price}
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      main_unit_price: Number.isFinite(e.target.valueAsNumber)
                        ? e.target.valueAsNumber
                        : undefined
                    })
                  }
                  onKeyDown={inputStopPropagation}
                />
              </>
            )}
          </FilterRenderer>)
      },
      {
        key: 'in', name: trans.product_trans.in, resizable: true, sortable: true, width: 100,
        headerCellClass: filterColumnClassName,
        headerRenderer: (p: HeaderRendererProps<Row>) => (
          <FilterRenderer<Row, unknown, HTMLInputElement> {...p} onColumnsReorder={handleColumnsReorder}>
            {({ filters, ...rest }) => (
              <>
                <HeaderRenderer {...p}></HeaderRenderer>
                <input
                  {...rest}
                  type="number"
                  style={{ inlineSize: "100%", padding: "4px", fontSize: "14px" }}
                  value={filters.in}
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      in: Number.isFinite(e.target.valueAsNumber)
                        ? e.target.valueAsNumber
                        : undefined
                    })
                  }
                  onKeyDown={inputStopPropagation}
                />
              </>
            )}
          </FilterRenderer>)
      },
      {
        key: 'out', name: trans.product_trans.out, resizable: true, sortable: true, width: 100,
        headerCellClass: filterColumnClassName,
        headerRenderer: (p: HeaderRendererProps<Row>) => (
          <FilterRenderer<Row, unknown, HTMLInputElement> {...p} onColumnsReorder={handleColumnsReorder}>
            {({ filters, ...rest }) => (
              <>
                <HeaderRenderer {...p}></HeaderRenderer>
                <input
                  {...rest}
                  type="number"
                  style={{ inlineSize: "100%", padding: "4px", fontSize: "14px" }}
                  value={filters.out}
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      out: Number.isFinite(e.target.valueAsNumber)
                        ? e.target.valueAsNumber
                        : undefined
                    })
                  }
                  onKeyDown={inputStopPropagation}
                />
              </>
            )}
          </FilterRenderer>)
      },
      {
        key: 'stock', name: trans.product_trans.stock, resizable: true, sortable: true, width: 100,
        headerCellClass: filterColumnClassName,
        headerRenderer: (p: HeaderRendererProps<Row>) => (
          <FilterRenderer<Row, unknown, HTMLInputElement> {...p} onColumnsReorder={handleColumnsReorder}>
            {({ filters, ...rest }) => (
              <>
                <HeaderRenderer {...p}></HeaderRenderer>
                <input
                  {...rest}
                  type="number"
                  style={{ inlineSize: "100%", padding: "4px", fontSize: "14px" }}
                  value={filters.stock}
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      stock: Number.isFinite(e.target.valueAsNumber)
                        ? e.target.valueAsNumber
                        : undefined
                    })
                  }
                  onKeyDown={inputStopPropagation}
                />
              </>
            )}
          </FilterRenderer>)
      },
      {
        key: 'fullname', name: trans.product_trans.fullname, resizable: true, sortable: true, width: 'max-content',
        headerCellClass: filterColumnClassName,
        headerRenderer: (p: HeaderRendererProps<Row>) => (

          <FilterRenderer<Row, unknown, HTMLInputElement> {...p} onColumnsReorder={handleColumnsReorder}>

            {({ filters, ...rest }) => (
              <>
                <HeaderRenderer {...p}></HeaderRenderer>
                <input
                  {...rest}
                  className={filterClassname!}
                  // variant='outlined'
                  style={{ inlineSize: "100%", padding: "4px", fontSize: "14px" }}
                  value={filters.fullname}
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      fullname: e.target.value
                    })
                  }
                  onKeyDown={inputStopPropagation}
                />
              </>
            )}
          </FilterRenderer>
        )
      },
      {
        key: 'invoice_comment', name: trans.product_trans.invoice_comment, resizable: true, sortable: true, width: 100,
        headerCellClass: filterColumnClassName,
        headerRenderer: (p: HeaderRendererProps<Row>) => (

          <FilterRenderer<Row, unknown, HTMLInputElement> {...p} onColumnsReorder={handleColumnsReorder}>

            {({ filters, ...rest }) => (
              <>
                <HeaderRenderer {...p}></HeaderRenderer>
                <input
                  {...rest}
                  className={filterClassname!}
                  // variant='outlined'
                  style={{ inlineSize: "100%", padding: "4px", fontSize: "14px" }}
                  value={filters.invoice_comment}
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      invoice_comment: e.target.value
                    })
                  }
                  onKeyDown={inputStopPropagation}
                />
              </>
            )}
          </FilterRenderer>
        )
      },


    ]
  }, [columnsChanged])


  const [columns, setColumns] = useState(filteredColumns);


  //*8- set on click open
  const onOpenClick = (id: number) => {
    navigate("/invoice/" + id.toString());
  }

  useEffect(() => {
    getReport()
  }, [])


  const onSortColumnsChange = useCallback((sortColumns: SortColumn[]) => {
    setSortColumns(sortColumns.slice(-1));
  }, []);


  const draggableColumns = useMemo((): readonly Column<Row>[] => {
    function HeaderRenderer(props: HeaderRendererProps<Row>) {
      return <DraggableHeaderRenderer {...props} onColumnsReorder={handleColumnsReorder} />;
    }

    function handleColumnsReorder(sourceKey: string, targetKey: string) {
      const sourceColumnIndex = columns.findIndex((c) => c.key === sourceKey);
      const targetColumnIndex = columns.findIndex((c) => c.key === targetKey);
      const reorderedColumns = [...columns];

      reorderedColumns.splice(
        targetColumnIndex,
        0,
        reorderedColumns.splice(sourceColumnIndex, 1)[0]
      );

      setColumns(reorderedColumns);
    }

    return columns.map((c) => {
      if (c.key === 'id') return c;
      return { ...c, headerRenderer: HeaderRenderer };
    });
  }, [columns]);




  
  //*3-add download mapping
  const getPrintedData = () => {
    return filteredRows.map(item => {

      return ({
        id: item.id,
        [trans.product_trans.invoice_date]: item["invoice_date"],
        [trans.product_trans.invoice_type]: item["invoice_type"],
        [trans.product_trans.store_name]: item["store_name"],
        [trans.product_trans.main_unit_price]: item["main_unit_price"],
        [trans.product_trans.in]: item["in"],
        [trans.product_trans.out]: item["out"],
        [trans.product_trans.stock]: item["stock"],
        [trans.product_trans.fullname]: item["fullname"],
        [trans.product_trans.invoice_comment]: item["invoice_comment"],
      })
    })
  }

  const downloadExcel = (data: any) => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "DataSheet1.xlsx");
  };



  return (
    <Container maxWidth={false}>
      {isLoading() && <LinearProgress />}
      {isSucceeded() && (
        <>
          <Box sx={{ display: 'flex', flexDirection: 'column', height: `calc(100vh - 128px)` }}>
            <Box>

              {rows.length != 0 ?
                <Paper elevation={3} sx={{ marginBottom: 2, padding: 2, direction: langDirection }}>
                  <Button variant="contained" startIcon={<FileDownloadIcon sx={{ marginRight: 0, marginLeft: 1 }} />} sx={{ marginX: 1 }} onClick={() => downloadExcel(getPrintedData())}>
                    Download
                  </Button>
                </Paper>
                : null}

            </Box>
            <Box>

              <AppBar position="static" sx={{ backgroundColor: "#283593" }}>
                <Toolbar component="nav" variant="dense" sx={{ justifyContent: 'space-between', overflowX: 'auto' }}  >
                  <Typography component="h2" variant="h5">
                    {trans.product_trans.title + productObject.product_name}
                  </Typography>
                </Toolbar>
              </AppBar>
            </Box>
            <DndProvider backend={HTML5Backend}>
              <FilterContext.Provider value={filters}>
                <DataGrid
                  className={`${paletteMode === 'light' ? 'rdg-light' : 'rdg-dark'} ${filters.enabled ? filterContainerClassname : undefined}`}
                  columns={filteredColumns}
                  rows={filteredRows}
                  headerRowHeight={filters.enabled ? 70 : undefined}
                  direction={langDirection === "rtl" ? "rtl" : "ltr"}

                  style={{ flexGrow: 1, overflow: "auto" }}

                  sortColumns={sortColumns}
                  onSortColumnsChange={onSortColumnsChange}
                />
              </FilterContext.Provider>
            </DndProvider>
          </Box>
        </>
      )}
    </Container>
  )
}



interface DraggableHeaderRendererProps<R, SR> extends HeaderRendererProps<R, SR> {
  onColumnsReorder: (sourceKey: string, targetKey: string) => void;
}


function FilterRenderer<R, SR, T extends HTMLOrSVGElement>({
  onColumnsReorder,
  isCellSelected,
  column,
  children,
  ...props
}: DraggableHeaderRendererProps<R, SR> & {
  children: (args: {
    ref: React.RefObject<T>;
    tabIndex: number;
    filters: Filter;
  }) => React.ReactElement;
}) {
  const filters = useContext(FilterContext)!;
  const { ref, tabIndex } = useFocusRef<T>(isCellSelected);
  const [{ isDragging }, drag] = useDrag({
    type: 'COLUMN_DRAG',
    item: { key: column.key },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  });

  const [{ isOver }, drop] = useDrop({
    accept: 'COLUMN_DRAG',
    drop({ key }: { key: string }) {
      onColumnsReorder(key, column.key);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  });

  return (
    <>
      <div
        ref={(ref) => {
          drag(ref);
          drop(ref);
        }}
        style={{
          opacity: isDragging ? 0.5 : 1,
          backgroundColor: isOver ? '#ececec' : undefined,
          cursor: 'move'
        }}
      >
        {filters!.enabled && <div>{children({ ref, tabIndex, filters })}</div>}
      </div>
    </>
  );
}


