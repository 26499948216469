import React, { useEffect, useState } from 'react'

import { useNavigate, useParams } from "react-router-dom";

import { ApiStatus } from '../../models/ApiStatus';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { LinearProgress } from '@mui/material';
import { selectTranslations } from '../i18n/i18nSlice';
import RepoAG from '../reports/RepoAG _v6';
import api from '../services/api';



//*1-add model
interface Row {
  id: number;
  fullname: string;
  balance: number;
}



export default function CustomerStatment() {

  const params = useParams();
  const { accountId, startDate, endDate } = params

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const trans = useAppSelector(selectTranslations);
  // const apiCallStatus = useAppSelector(commissionCallStatus);
  // const apiStatusBusyCount = useAppSelector(commissionCallBusyCount);

  // const isLoading = React.useMemo(() => (apiCallStatus === "loading" || apiStatusBusyCount > 0), [apiCallStatus, apiStatusBusyCount]);
  // const isSucceeded = React.useMemo(() => (apiCallStatus === "succeeded" && apiStatusBusyCount === 0), [apiCallStatus, apiStatusBusyCount]);
  // const isIdle = React.useMemo(() => (apiCallStatus === "idle" && apiStatusBusyCount === 0), [apiCallStatus, apiStatusBusyCount]);
  // const isFailed = React.useMemo(() => (apiCallStatus === "failed" && apiStatusBusyCount === 0), [apiCallStatus, apiStatusBusyCount]);

  // const employeesReportData = useAppSelector(employeesReport);


  const [apiCallStatus, setApiCallStatus] = React.useState<ApiStatus>({ error: "", status: "idle" })
    const isLoading = apiCallStatus.status === "loading";
    const isSucceeded = apiCallStatus.status === "succeeded";
    const isIdle = apiCallStatus.status === "idle";
    const isFailed = apiCallStatus.status === "failed";
  
    const [rows, setRows] = useState<any[]>([])
  
  // const [rows, setRows] = useState<any[]>([])


  //*2- set on click open
 

const onOpenClick = (item: any) => {
  const {id, document_type_id: docType, extended_docuemnt_id:ext_id} = item
  if (docType < 10) {

    navigate("/invoice/" + ext_id.toString());
}
if (docType === 11 || docType === 12 || docType === 13 || docType === 14) {
    navigate("/customer/document/" + id.toString());
}
}



  
  //*4- define get report
  const getReport = async () => {
    setApiCallStatus({ error: "", "status": "loading" })
    try {
      const response = await api.get(
        `/customer/statmentdetail/${accountId}/${startDate}/${endDate}/${trans.customer_statment.previouse_balance}`,
        { headers: { "Content-Type": "application/json" } }
      )

      if (response.status === 200) {

        setRows(response.data["statment"])

        setApiCallStatus({ error: "", "status": "succeeded" })
      } else {
        setApiCallStatus({ error: response.statusText, "status": "failed" })
      }

    } catch (err: any) {
      setApiCallStatus({ error: err.message, "status": "failed" })
    }
  };

  useEffect(() => {
    getReport()
  }, [])




  //*3- define get report
  // const getReport = async (setRowsFun: React.Dispatch<React.SetStateAction<Row[]>>, setApiCallStatusFun: React.Dispatch<React.SetStateAction<ApiStatus>>) => {
  //   setApiCallStatusFun({ error: "", "status": "loading" })

  //   // setRows(newRep as Row[])
  //   setRowsFun(employeesReportData as Row[])
  //   setApiCallStatusFun({ error: "", "status": "succeede  d" })
  // }


  //*4- default column data
  // const columns = {
  //   id: 0,
  //   fullname: '',
  //   balance: 0,
  // }
  const columns = [
    { field: 'id', type: 'number' },
    { field: 'document_date', type: 'string' },
    { field: 'item_name', type: 'string' },
    { field: 'quantity', type: 'number' },
    { field: 'unit_price', type: 'number' },
    { field: 'credit', type: 'number', summary: "sum" },
    { field: 'debit', type: 'number', summary: "sum" },
    { field: 'comment', type: 'string' },
  ]
  
  


  //*5- generate report
  // const genRep = useRepoAG<Row>(columns, 'product_rep1', getReport, onOpenClick, 'id')
  // const genRep = useRepoAG<Row>(columns, 'all_employee_report', 'all_employee_report', trans.commission_dashboard.employees, employeesReportData, onOpenClick, 'id')


  return (
    <>
      {isLoading && <LinearProgress />}
      {(isSucceeded) && (
        <>
          <RepoAG columns={columns} componentName={trans.customer_statment.title} transRep='customer_statment' title={trans.customer_statment.title} rows={rows} onOpenClick={onOpenClick}  />
        </>

      )}
    </>
  );


}


