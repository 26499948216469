import { Alert, AppBar, Autocomplete, Box, Button, Container, Dialog, DialogContent, DialogContentText, DialogTitle, FormControl, Grid2, IconButton, InputAdornment, InputLabel, LinearProgress, MenuItem, NativeSelect, Paper, Select, SelectChangeEvent, Slide, Snackbar, TextField, Toolbar, useTheme } from '@mui/material'
import { Invoice02 } from '../../models/Invoice02';
import { useEffect, useState } from 'react';
import { useAppSelector } from '../../app/hooks';
import { selectDirection, selectTranslations } from '../../features/i18n/i18nSlice';

type Props = {
  currentDraft: Invoice02;
  update: (invoice: Invoice02) => Promise<void>;

};

const EinvCustomer = ({ currentDraft, update, }: Props) => {

  const langDirection = useAppSelector(selectDirection);
  const trans = useAppSelector(selectTranslations);

  const [data, setData] = useState(
    {
      type: "B",
      id: "",
      name: "",
      address: {
        country: "EG",
        governate: "",
        regionCity: "",
        street: "",
        buildingNumber: "",
        postalCode: "",
        floor: "",
        room: "",
        landmark: "",
        additionalInformation: ""
      }
    })

  const onCustomerTypeChange = (event: SelectChangeEvent) => {
    setData({ ...data, [event.target.name]: event.target.value })

    update(({
      ...currentDraft,
      einv_customer: { ...data, [event.target.name]: event.target.value }
    } as Invoice02))
  }

  const onMainItemChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setData({ ...data, [event.target.name]: event.target.value })
    update(({
      ...currentDraft,
      einv_customer: { ...data, [event.target.name]: event.target.value }
    } as Invoice02))
  };

  const onAddressItemChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setData({
      ...data,
      address: {
        ...data.address,
        [event.target.name]: event.target.value
      }
    })

    update(({
      ...currentDraft,
      einv_customer: {
        ...data,
        address: {
          ...data.address,
          [event.target.name]: event.target.value
        }
      }
    } as Invoice02))
  };

  useEffect(() => {

    if (Object.keys(currentDraft.einv_customer).length !== 0) {
      setData(currentDraft.einv_customer as {
        type: "B",
        id: "",
        name: "",
        address: {
          country: "EG",
          governate: "",
          regionCity: "",
          street: "",
          buildingNumber: "",
          postalCode: "",
          floor: "",
          room: "",
          landmark: "",
          additionalInformation: ""
        }
      })
    }

  }, [])


  return (
    <>
      <Paper elevation={1} sx={{ marginY: 2, paddingY: 2 }}>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
          <Grid2 container>
            <Grid2 size={{ sm: 12, md: 6, lg: 6 }} padding={2}>
              <Box sx={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                <FormControl variant="standard" fullWidth>
                  <InputLabel id="demo-simple-select-standard-label" >{trans.new_invoice.tax_customer.type}</InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="type"
                    name="type"
                    // defaultValue={'P'}
                    value={data.type!}

                    label="Type"
                    // onChange={handleChange}
                    onChange={onCustomerTypeChange}

                    size='small'
                    sx={{ width: "100%" }}
                  >
                    <MenuItem value={'B'}>Compay</MenuItem>
                    <MenuItem value={'P'}>Person</MenuItem>
                    <MenuItem value={'F'}>Forign</MenuItem>
                  </Select>

                </FormControl>
                <TextField id="id" name="id" label={trans.new_invoice.tax_customer.id} variant="standard" size='small' onChange={onMainItemChange} value={data.id} />
                <TextField id="name" name="name" label={trans.new_invoice.tax_customer.name} variant="standard" size='small' onChange={onMainItemChange} value={data.name} />
                <TextField id="country" name="country" label={trans.new_invoice.tax_customer.country} variant="standard" size='small' onChange={onAddressItemChange} value={data.address.country} />
                <TextField id="governate" name="governate" label={trans.new_invoice.tax_customer.gov} variant="standard" size='small' onChange={onAddressItemChange} value={data.address.governate} />
                <TextField id="regionCity" name="regionCity" label={trans.new_invoice.tax_customer.city} variant="standard" size='small' onChange={onAddressItemChange} value={data.address.regionCity} />
                <TextField id="street" name="street" label={trans.new_invoice.tax_customer.street} variant="standard" size='small' onChange={onAddressItemChange} value={data.address.street} />
              </Box>
            </Grid2>
            <Grid2 size={{ sm: 12, md: 6, lg: 6 }} padding={2}>
              <Box sx={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                <TextField id="buildingNumber" name="buildingNumber" label={trans.new_invoice.tax_customer.building_no} variant="standard" size='small' onChange={onAddressItemChange} value={data.address.buildingNumber} />
                <TextField id="postalCode" name="postalCode" label={trans.new_invoice.tax_customer.zip_code} variant="standard" size='small' onChange={onAddressItemChange} value={data.address.postalCode} />
                <TextField id="floor" name="floor" label={trans.new_invoice.tax_customer.floor} variant="standard" size='small' onChange={onAddressItemChange} value={data.address.floor} />
                <TextField id="room" name="room" label={trans.new_invoice.tax_customer.room} variant="standard" size='small' onChange={onAddressItemChange} value={data.address.room} />
                <TextField id="landmark" name="landmark" label={trans.new_invoice.tax_customer.landmark} variant="standard" size='small' onChange={onAddressItemChange} value={data.address.landmark} />
                <TextField id="additionalInformation" name="additionalInformation" label={trans.new_invoice.tax_customer.additional} variant="standard" size='small' onChange={onAddressItemChange} value={data.address.additionalInformation} />
              </Box>
            </Grid2>
          </Grid2>
        </Box>
      </Paper>
    </>
  );
}

export default EinvCustomer;