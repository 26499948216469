import {createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import type { Property } from "csstype";




export interface LanguageState {
    lang: string;
    direction: Property.Direction;
    textAlign: string;
  }

const initialState: LanguageState = {
    lang: 'ar',
    direction: 'rtl',
    textAlign: 'right'
}


// const initialState: LanguageState = {
//     lang: 'en',
//     direction: 'ltr',
//     textAlign: 'left'
// }


export const languageSlice = createSlice({
    name: 'language',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        setLanguage: (state: any, action: PayloadAction<string>) => {
            state.lang = action.payload;
        },
        setDirection: (state: any, action: PayloadAction<string>) => {
            state.direction = action.payload;
        },
        setTextAlign: (state: any, action: PayloadAction<string>) => {
            state.textAlign = action.payload;
        },
    }
});
  
export const { setLanguage, setDirection, setTextAlign } = languageSlice.actions;
export const selectLanguage = (state: RootState) => state.language.lang;
export const selectDirection = (state: RootState) => state.language.direction;
export const selectTextAlign = (state: RootState) => state.language.textAlign;

  
export default languageSlice.reducer;
  