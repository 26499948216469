import Dexie, { Table } from "dexie";
import { Branch } from "../models/Branch";
import { Order } from "../models/Order";
import { Colors, PriceList, ProductCatalog, ProductCategory, ProductType, Sizes } from "../models/ProductCatalog";
import { SyncData } from "../models/SyncData";
import { Broker, Customer, CustomerType, SalesPerson } from "../models/Customer";
import { StoreHouse } from "../models/StoreHouse";
import { EncInvoice, Invoice02 } from "../models/Invoice02";
import { InvoiceDiscountMode, InvoiceTaxMode } from "../models/Invoice";
import { AppConfig, AppConfiguration } from "../models/AppConfig";
import { POSInfo } from "../models/POS";
import { Shift } from "../models/shift";

class ErpDB extends Dexie {
  appConfig!: Table<AppConfig>;
  appConfiguration!: Table<AppConfiguration>;
  productCatalog!: Table<ProductCatalog>;
  syncData!: Table<SyncData>;
  customer!: Table<Customer>;
  storeHouse!: Table<StoreHouse>;
  branch!: Table<Branch>;
  orderDraft!: Table<Order>;
  priceList!: Table<PriceList>;
  productType!: Table<ProductType>;
  productCategory!: Table<ProductCategory>;
  invoiceDraft!: Table<Invoice02>;
  invoiceDiscountMode!: Table<InvoiceDiscountMode>;
  invoiceTaxMode!: Table<InvoiceTaxMode>;
  customerType!: Table<CustomerType>;
  broker!: Table<Broker>;
  salesPerson!: Table<SalesPerson>;
  colors!: Table<Colors>;
  sizes!: Table<Sizes>;
  posInfo!: Table<POSInfo>;
  shift!: Table<Shift>;
  encInvoice!: Table<EncInvoice>;

  constructor() {
    super("erpDB");
    this.version(1).stores({
      appConfig:`++id, config_name, config_value`,
      appConfiguration:`id, configuration_name, configuration_value`,
      productCatalog:
        `id,
        international_code,
        user_defined_code,
        product_name,
        product_type,
        product_category,
        has_expire_date,
        is_medicine,
        is_freez,
        is_returnable,
        default_expiry_days,
        color_id,
        size_id,
        min_quantity,
        max_quantity,
        einv_code,
        created_on,
        updated_on,
        update_timestamp,
        pricing,
        components`,
      syncData: `id, table_name, last_update_timestamp`,
      customer: `id, fullname, mobile, phone, email, address, account_id, customer_type, customer_type_name, cancelled`,
      storeHouse: `id, name, type, cancelled`,
      branch: `id, branch_name, update_timestamp`,
      orderDraft: `++id,uid,date,branch,sales_total,discount_precent,discount_value,tax_precent,tax_value,net_amount,total_amount,order_lines`,
      priceList: `id, list_name, default_list`,
      productType: 'id, type_name',
      productCategory: 'id, category_name',
      invoiceDraft: `++draft_id, id, uid, invoice_type, payment_method, customer_name, customer_id, customer_type, customer_account_id, invoice_date, invoice_discount_mode, discount_percentage, invoice_tax_mode, tax_percentage, subtotal, discount, salesTotal, tax, additions, freight, total, payment_amount, store, broker_id, sales_person_id, invcustomer_id, invcustomer_phone, invcustomer_name, invcustomer_company, invcustomer_address, invcustomer_field, insert_date, cancelled, invoice_items`,
      invoiceDiscountMode: `id, discount_mode_name`,
      invoiceTaxMode: `id, tax_mode_name`,
      customerType: `id, customer_type_name`,
      broker: `id, broker_name, phone, email, comment`,
      salesPerson: `id, sales_person_name, phone, email, comment`,
      colors: `id, color_name`,
      sizes: `id, size_name`,
      // posInfo: `id, pos_name, point_name, assigned, update_timestamp`,
      posInfo: `id, info`,
      shift: `shift_id, id, shift_uid, start_time, end_time, start_cash, end_cash, closed, pos_id, branch_id, shift_user`,
      encInvoice: `id, data`,
    });
  }

  async RemoveAll()  {

    // await this.delete()

    await db.delete().then(()=> db.open());

// await this.delete("productCatalog");
// this.delete("syncData");
// this.delete("customer");
// this.delete("storeHouse");
// this.delete("branch");
// this.delete("orderDraft");
// this.delete("priceList");
// this.delete("productType");
// this.delete("productCategory");
// this.delete("invoiceDraft");
// this.delete("invoiceDiscountMode");
// this.delete("invoiceTaxMode");
// this.delete("customerType");
// this.delete("broker");
// this.delete("salesPerson");
// this.delete("colors");
// this.delete("sizes");
  }
}

export const db = new ErpDB();

export async function recreateDB(db: ErpDB) {
  return db.delete().then (()=>db.open());
} 
