import { AppBar, Box, Card, CardActionArea, CardContent, Container, Grid, Paper, Toolbar, Typography } from '@mui/material'
import React from 'react'
import { useAppSelector } from '../../../app/hooks'
import { selectTranslations } from '../../../features/i18n/i18nSlice'
import FinanceDashboardCard from './FinanceDashboardCard';
import { useNavigate } from 'react-router-dom';
import CategoryIcon from '@mui/icons-material/Category';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import PaymentsIcon from '@mui/icons-material/Payments';

// import { selectDirection, selectTranslations } from "../../features/i18n/i18nSlice";
// import { useAppSelector, useAppDispatch } from "../../app/hooks";


export default function FinanceDashboard() {

    const trans = useAppSelector(selectTranslations)
    const navigate = useNavigate();



    return (
        <>
        <Paper>

            <Grid container>
                <FinanceDashboardCard itemColor={trans.new_invoice.type.title_color1} itemName={trans.dashboards.add_expense} onClick={() => navigate("/expense/add")} >
                    <MoneyOffIcon sx={{ fontSize: 70, color: 'whitesmoke' }} ></MoneyOffIcon>
                </FinanceDashboardCard>
                <FinanceDashboardCard itemColor="#ff1744" itemName={trans.dashboards.expenses_report} onClick={() => navigate("/expenses")} >
                    <img src="expenses.png" height={60} style={{ margin: 5 }} />
                    {/* <CategoryIcon sx={{ fontSize: 80, color: 'whitesmoke' }} ></CategoryIcon> */}
                </FinanceDashboardCard>
            </Grid>
        </Paper>
        <Paper sx={{ marginY: 3 }}>
                <Grid container>

                <FinanceDashboardCard itemColor="#536dfe" itemName={trans.dashboards.add_expense_type} onClick={() => navigate("/expensetype/add")} >
                    <PriceCheckIcon sx={{ fontSize: 70, color: 'whitesmoke' }} ></PriceCheckIcon>
                </FinanceDashboardCard>

                <FinanceDashboardCard itemColor="#69f0ae" itemName={trans.dashboards.expense_type_list} onClick={() => navigate("/expensetype/all")} >
                    <PaymentsIcon sx={{ fontSize: 70, color: 'whitesmoke' }} ></PaymentsIcon>
                </FinanceDashboardCard>

              
                </Grid>
            </Paper>
        </>
    )
}
