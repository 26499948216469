import React, { useEffect } from 'react'

import { useAppSelector } from "../../app/hooks";
import { selectDirection, selectTranslations } from '../../features/i18n/i18nSlice';
import api from '../../features/services/api';


import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';


interface ReportItem {
  id: number;
  account_id: number;
  account_type_name: string;
  account_name: string;
  current_amount: number;
  total_change: number;
  [key: string]: any;

}
interface ApiStatus {
  error: string;
  status: 'idle' | 'loading' | 'succeeded' | 'failed'
}

export default function FinanceMonthlyRep() {

  const langDirection = useAppSelector(selectDirection);
  const trans = useAppSelector(selectTranslations);

  const [report, setReport] = React.useState<ReportItem[]>([]);
  const [apiCallStatus, setApiCallStatus] = React.useState<ApiStatus>({ error: "", status: "idle" })
  const isLoading = () => apiCallStatus.status === "loading";
  const isSucceeded = () => apiCallStatus.status === "succeeded";
  const isIdle = () => apiCallStatus.status === "idle";
  const isFailed = () => apiCallStatus.status === "failed";



  const adjustProfitExpese = (reportData: ReportItem[]) => {

    const expense = reportData.find(item => item.id === 5)

    const newRep = reportData.map(item => {
      if(item.id === 4 || item.id === 5) {
        return {...item, current_amount: item.total_change}
      }

      return item
    })

    return newRep

    // let newRep = report.map(item => item.id !== 5)

  }

  const getReport = async () => {
    setApiCallStatus({ error: "", "status": "loading" })
    try {
      const response = await api.get(
        `/finance/rep01/${new Date().getFullYear()}`,
        { headers: { "Content-Type": "application/json" } }
      )

      if (response.status === 200) {

        setReport(adjustProfitExpese(response.data["report"]))

        setApiCallStatus({ error: "", "status": "succeeded" })
      } else {
        setApiCallStatus({ error: response.statusText, "status": "failed" })
      }

    } catch (err: any) {
      setApiCallStatus({ error: err.message, "status": "failed" })
    }
  };

  useEffect(() => {
    getReport()
  }, [])

  return (
    <>    
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table" aria-labelledby="tableTitle">
        <TableHead>
          <TableRow>
            <TableCell>Type</TableCell>
            <TableCell align="right">Account</TableCell>
            <TableCell align="right">Current Amount</TableCell>
            <TableCell align="right">Total Change</TableCell>
            {report.length > 0 &&
              Object.keys(report[0]).map((key) => key.toLowerCase().includes(" change") ? <TableCell align="right">{key}</TableCell> : null)
            }

          </TableRow>
        </TableHead>
        <TableBody>
          {report.map((row) => ( 
            <TableRow 
              key={row.account_id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.account_type_name}
              </TableCell>
              <TableCell align="right">{row.account_name}</TableCell>
              <TableCell align="right">{row.current_amount}</TableCell>
              <TableCell align="right">{row.total_change}{row.total_change > 0 ? <ArrowUpwardIcon sx={{ color: '#388e3c' }} fontSize='small' /> : null}{row.total_change < 0 ? <ArrowDownwardIcon sx={{ color: '#ff0000' }} fontSize='small' /> : null}</TableCell>

              {Object.keys(row).map((key) => key.toLowerCase().includes(" change") ? <TableCell align="right">{row[key]}{row[key] > 0 ? <ArrowUpwardIcon sx={{ color: '#388e3c' }} fontSize='small' /> : null}{row[key] < 0 ? <ArrowDownwardIcon sx={{ color: '#ff0000' }} fontSize='small' /> : null}</TableCell> : null)}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>



    </>

  )
}
