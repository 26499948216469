import { ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import React from 'react'


interface MyProps { }

// export default function MiniDrawerMenuItem(props: React.PropsWithChildren<MyProps>) {
export default function MiniDrawerMenuItem({ title, color, isOpen, onClick, children}: { title: string, isOpen: boolean, onClick: Function, children: React.ReactNode, color?: string}) {
    return (
        <ListItem  disablePadding sx={{ display: 'block' }}>
            <ListItemButton onClick={() => onClick()}
                sx={{
                    minHeight: 48,
                    justifyContent: isOpen ? 'initial' : 'center',
                    px: 2.5,
                }}

            >
                <ListItemIcon
                    sx={{
                        minWidth: 0,
                        mr: isOpen ? 3 : 'auto',
                        justifyContent: 'center',
                        color: color,
                    }}
                >
                    {children}
                </ListItemIcon>
                <ListItemText primary={title} sx={{ opacity: isOpen ? 1 : 0 }} />
            </ListItemButton>
        </ListItem>
    )
}
