import React, { useCallback, useEffect } from 'react'

import { useNavigate, useParams } from "react-router-dom";
import RepoAG  from '../reports/RepoAG _v5';
import { useRepoAG } from '../reports/useRepoAG _v4';

import { changeCommissionAmounts, commissionCallBusyCount, commissionCallStatus, commissionCatAmountsReportData, commissionCatReportData, getAllCommissionCat, getCommissionAmounts, updateCommissionAmounts } from './commissionSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { LinearProgress } from '@mui/material';
import { selectTranslations } from '../i18n/i18nSlice';


//*1-add model
interface Row {
  product_id: number;
  product_name: string;
  commission: number;
}



export default function CommissionAmountsReport() {

  const navigate = useNavigate();
  const params = useParams();
  const { categoryId } = params


  const dispatch = useAppDispatch();
  const trans = useAppSelector(selectTranslations);
  const apiCallStatus = useAppSelector(commissionCallStatus);
  const apiStatusBusyCount = useAppSelector(commissionCallBusyCount);

  const isLoading = React.useMemo(() => (apiCallStatus === "loading" || apiStatusBusyCount > 0), [apiCallStatus, apiStatusBusyCount]);
  const isSucceeded = React.useMemo(() => (apiCallStatus === "succeeded" && apiStatusBusyCount === 0), [apiCallStatus, apiStatusBusyCount]);
  const isIdle = React.useMemo(() => (apiCallStatus === "idle" && apiStatusBusyCount === 0), [apiCallStatus, apiStatusBusyCount]);
  const isFailed = React.useMemo(() => (apiCallStatus === "failed" && apiStatusBusyCount === 0), [apiCallStatus, apiStatusBusyCount]);

  const commissionCatAmountsReport = useAppSelector(commissionCatAmountsReportData);
// console.log(commissionCatAmountsReport)


  //*2- set on click open
  const onOpenClick = (id: number) => {
  }

  
  useEffect(() => {
    if(categoryId !== undefined) dispatch(getCommissionAmounts(parseInt(categoryId)))
  }, [])



  //*3- define get report
  // const getReport = async (setRowsFun: React.Dispatch<React.SetStateAction<Row[]>>, setApiCallStatusFun: React.Dispatch<React.SetStateAction<ApiStatus>>) => {
  //   setApiCallStatusFun({ error: "", "status": "loading" })

  //   // setRows(newRep as Row[])
  //   setRowsFun(employeesReportData as Row[])
  //   setApiCallStatusFun({ error: "", "status": "succeede  d" })
  // }


  //*4- default column data
  // const col = {
  //   id: 0,
  //   fullname: '',
  //   balance: 0,
  // }
  const col = [
    // {field: 'product_id', type: 'number'},
    {field: 'product_name', type: 'string'},
    {field: 'commission', type: 'number', editable: true},
  ]


  const onRowsChange = useCallback( ({id, field, value}:{id: number, field: string, value: any}) => {
    // dispatch(changeCommissionAmounts(
    //   rows.map( (item: any) => item.product_id !== rows[rowIndex].product_id ? item : {...item, commission: newValue})

    console.log({id, field, value})
    // ))
    dispatch(changeCommissionAmounts({id, field, value: Number(value)}))
  },[])

  const onUpdateComission = () => {
    if(categoryId !== undefined) dispatch(updateCommissionAmounts(parseInt(categoryId)))
  }

  //*5- generate report
  // const genRep = useRepoAG<Row>(col, 'product_rep1', getReport, onOpenClick, 'id')
  const genRep = useRepoAG<Row>(col, 'commission_amounts_report', 'commission_amounts_report', trans.commission_dashboard.commission_categories, [...commissionCatAmountsReport.map((row:any)=> row)], undefined, undefined,onRowsChange, onUpdateComission)


  return (
    <>
      {isLoading && <LinearProgress />}
      {(isSucceeded || isIdle || isFailed) && (
        <>
          {genRep}
        </>

      )}
    </>
  );

  // return (
  //   <>
  //     {isLoading && <LinearProgress />}
  //     {(isSucceeded || isIdle || isFailed) && (
        
  //         <RepoAG 
  //         currentVal={col} 
  //         componentName='commission_amounts_report'
  //         transRep='commission_amounts_report'
  //         title={trans.commission_dashboard.commission_categories}
  //         rows={ [...commissionCatAmountsReport.map((row:any)=> row)]}
  //         onRowsChange={onRowsChange} />

  //     )}
  //   </>
  // );


}

