import { AppBar, Box, Card, CardActionArea, CardContent, Container, Dialog, Grid, LinearProgress, Paper, Toolbar, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useAppSelector, useAppDispatch } from '../../../app/hooks'
import { selectDirection, selectTranslations } from '../../../features/i18n/i18nSlice'
import DashboardCard from './DashboardCard';
import { useNavigate } from 'react-router-dom';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import { refreshDB, actionCallStatus } from '../../../features/login/loginSlice';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';






// import { selectDirection, selectTranslations } from "../../features/i18n/i18nSlice";
// import { useAppSelector, useAppDispatch } from "../../app/hooks";

const theme = createTheme();

export default function ReportsDashboard() {

    const trans = useAppSelector(selectTranslations)
    const langDirection = useAppSelector(selectDirection);

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const apiCallStatus = useAppSelector(actionCallStatus);
    const isLoading = (status: any) => status === "loading";
    const isSucceeded = (status: any) => status === "succeeded";
    const isIdle = (status: any) => status === "idle";
    const isFailed = (status: any) => status === "failed";

    const [showResetPasswordDialog, setShowResetPasswordDialog] = useState(false);

    const onRefreshDB = () => {
        dispatch(refreshDB())
        console.log("finished refresh")
    }


    // Reset password  Dialog

    //   const {
    //     openDialog: showResetPasswordDialog,
    //     setOpenDialog: setShowResetPasswordDialog,
    //     SelectDialog: ResetPasswordDialog,
    //     onDialogOpen: onResetPasswordDialogOpen } = useResetPasswordDialog()

    const onResePasswordClicked = () => {
        setShowResetPasswordDialog(true)
    }



    return (
        <ThemeProvider theme={theme}>
            <Container>


                {(isLoading(apiCallStatus)) && <LinearProgress />}
                {(isSucceeded(apiCallStatus) || isIdle(apiCallStatus)) && (
                    <>
                        <Paper>

                            <Grid container>
                                <DashboardCard itemColor="#ffab00" itemName={trans.dashboards.finance_indicators} onClick={() => navigate("/finance/rep01")} >
                                    <img src="finance03.png" height={60} style={{ margin: 5 }} />
                                </DashboardCard>
                                <DashboardCard itemColor="#00bfa5" itemName={trans.dashboards.finance_indicators_profit} onClick={() => navigate("/finance/rep02")} >
                                    <img src="finance02.png" height={60} style={{ margin: 5 }} />
                                </DashboardCard>

                                <DashboardCard itemColor="#3d5afe" itemName={trans.dashboards.set_expense_per_price} onClick={() => navigate("/expense/expenseperprice")} >
                                    <PointOfSaleIcon sx={{ fontSize: 70, color: 'whitesmoke' }} ></PointOfSaleIcon>
                                </DashboardCard>

                                <DashboardCard itemColor="#ff9800" itemName={trans.dashboards.users_logs} onClick={() => navigate("/userlogs/1")} >
                                    <WorkHistoryIcon sx={{ fontSize: 70, color: 'whitesmoke' }} ></WorkHistoryIcon>
                                </DashboardCard>

                            

                            </Grid>
                        </Paper>
                    </>
                )}
            </Container>
        </ThemeProvider>

    )
}
