import { Alert, AppBar, Box, Button, Container, Dialog, FormControl, Grid2, IconButton, InputLabel, LinearProgress, MenuItem, Paper, Select, Snackbar, TextField, Toolbar, useTheme } from '@mui/material'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import ItemSelect from './select_item/ItemSelect'
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { selectDirection, selectTranslations } from "../../features/i18n/i18nSlice";


import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import SaveIcon from '@mui/icons-material/Save';

import Typography from '@mui/material/Typography';


import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import 'dayjs/locale/ar';
import dayjs, { Dayjs } from 'dayjs';

import { useBlocker, useNavigate, useParams } from 'react-router-dom';

import { useYesNoDialog } from './useYesNoDialog';

import { DateTimeField } from '@mui/x-date-pickers';
import { Broker, SalesPerson } from '../../models/Customer';
import { useSelectCustomerDialog } from './useSelectCustomerDialog';
import { Invoice02, InvoiceType } from '../../models/Invoice02';
import { useSelectBrokerDialog } from './useSelectBrokerDialog';
import { useSelectSalesPersonDialog } from './useSelectSalesPersonDialog';
import ItemSelectByColorSize from './select_item/ItemSelectByColorSize';
import PrintIcon from '@mui/icons-material/Print';
import ReactToPrint from "react-to-print";
import PrintForm from './PrintForm';
import CloseIcon from '@mui/icons-material/Close';
import MultipleStopIcon from '@mui/icons-material/MultipleStop';
import YesNoDialog2 from './YesNoDialog2';
import InvoiceAGDatagride from './InvoiceAGDatagride';
import TotalPanel from './TotalPanel';
import ItemSelect3 from './select_item/ItemSelect3';
import ItemSelect4 from './select_item/ItemSelect4';
import { permCashierOnly } from '../../features/login/loginSlice';
import {
  invoiceData, selectionModel,
  addNewInvoiceItem,
  onDeleteBillItems,
  onInputValueChange,
  onRowSelectionChanged,

  onDateChange,
  updateInvoiceData,
  cancelInvoice,
  onTransferStoreChanged,


  onBrokerChanged,

  onSalesPersonChanged,
  onNewCustomerSelected,
  onRowsChange,
  onStoreChanged,
  invoiceCallStatus,
  invoiceCallBusyCount,
  transferStoreData,
  unSavedChanges,
  loadInvoice,
  stores,
  discountModes,
  taxModes,
  broker,
  salesPerson,
  getBrokerObject,
  getSalesPersonObject,
  onloadTotals,
  AdjustInvoiceStock,
  navigateAction
} from './invoiceSlice';

import { InvoiceItemWithHash } from '../../models/InvoiceItem';
import { InvoiceDiscountMode, InvoiceDiscountModeEnum, InvoiceTaxMode, InvoiceTaxModeEnum } from '../../models/Invoice';


function Prompt(props: any) {
  const block = props.when

  useBlocker(() => {
    if (block) {
      return !window.confirm(props.message)
    }
    return false
  })

  return (
    <div key={block} />
  )
}

export default function InvoiceDraft() {

  const params = useParams();
  const { invoiceId } = params
  const navigate = useNavigate();

  const printDivRef = useRef(null);
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const langDirection = useAppSelector(selectDirection);
  const trans = useAppSelector(selectTranslations);
  const isCashierOnly = useAppSelector(permCashierOnly)


  // const columnsDef = useAppSelector(gridColumns)
  const currentDraft = useAppSelector(invoiceData)
  const selModel = useAppSelector(selectionModel)
  const storeList = useAppSelector(stores)
  const discountModeList = useAppSelector(discountModes)
  const taxModeList = useAppSelector(taxModes)
  const brokerData = useAppSelector(broker)
  const salesPersonData = useAppSelector(salesPerson)
  const invoiceTransferStore = useAppSelector(transferStoreData)
  const changesNotSaved = useAppSelector(unSavedChanges)
  const onNavigateAction = useAppSelector(navigateAction)



  const apiCallStatus = useAppSelector(invoiceCallStatus);
  const apiStatusBusyCount = useAppSelector(invoiceCallBusyCount);

  const isLoading = React.useMemo(() => (apiCallStatus === "loading" || apiStatusBusyCount > 0), [apiCallStatus, apiStatusBusyCount]);
  const isSucceeded = React.useMemo(() => (apiCallStatus === "succeeded" && apiStatusBusyCount === 0), [apiCallStatus, apiStatusBusyCount]);
  const isIdle = React.useMemo(() => (apiCallStatus === "idle" && apiStatusBusyCount === 0), [apiCallStatus, apiStatusBusyCount]);
  const isFailed = React.useMemo(() => (apiCallStatus === "failed" && apiStatusBusyCount === 0), [apiCallStatus, apiStatusBusyCount]);


  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState('');
  const handleCloseSnackbar = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackbar(false);
  }



  const columnsDef = useMemo(() => {

    return [
      {
        field: "product_name",
        headerName: trans.new_invoice.table.product,
        width: 450,
        sortable: false,
        cellStyle: (items: any) => {
          console.log(items)
          if (!items.data.withdrawn && "withdrawn" in items.data) {
            //mark police cells as red
            return { backgroundColor: '#ef9a9a' };
            // return {color: 'red', backgroundColor: '#ff98f8'};
          }
          return null;
        },
      },
      {
        field: 'quantity',
        headerName: trans.new_invoice.table.count,
        // type: 'numberColumn',
        width: 80,
        editable: true,
        sortable: false,
        cellStyle: (items: any) => {
          if (items.data.update_info?.includes("quantity_changed")) {
            //mark police cells as red
            return { backgroundColor: '#ff98f8' };
            // return {color: 'red', backgroundColor: '#ff98f8'};
          }
          return null;
        },

      },
      {
        field: 'unit_name',
        headerName: trans.new_invoice.table.unit,
        width: 100,
        // editable: true,

        sortable: false,
      },

      {
        field: 'unit_price',
        headerName: trans.new_invoice.table.price,
        // type: 'numberColumn',
        width: 120,
        editable: true,
        sortable: false,
        cellStyle: (items: any) => {
          if (items.data.update_info?.includes("price_changed")) {
            //mark police cells as red
            return { backgroundColor: '#ff98f8' };
            // return {color: 'red', backgroundColor: '#ff98f8'};
          }
          return null;
        },
        valueSetter: (params: any) => { 
          dispatch(onRowsChange({rows: thisDraft?.invoice_items as InvoiceItemWithHash[], id: params.data.id!, field: params.colDef.field!, newValue: params.newValue}))
          return true; 
        },
      },

      {
        field: 'discount_display',
        headerName: trans.new_invoice.table.discount,
        // type: 'numberColumn',
        width: 120,
        // editable: true,
        sortable: false,
      },
      {
        field: 'tax',
        headerName: trans.new_invoice.table.tax,
        // type: 'numberColumn',
        width: 70,
        // editable: true,
        sortable: false,
      },
      {
        field: 'total',
        headerName: trans.new_invoice.table.total,
        // type: 'numberColumn',
        sortable: false,
        width: 120,
      },
      {
        field: "store_name",
        headerName: trans.new_invoice.table.store,
        width: 250,
        sortable: false,
      },


    ].filter(item => {
      if (item.field === "discount_display" && (currentDraft?.discount === 0 || currentDraft?.invoice_discount_mode === InvoiceDiscountModeEnum.PerInvoice)) {
        return false;
      }

      if (item.field === "tax" && (currentDraft?.tax === 0 || currentDraft?.invoice_tax_mode === InvoiceTaxModeEnum.PerInvoice)) {
        return false;
      }

      if ((currentDraft?.invoice_type === InvoiceType.AdjustRemove || currentDraft?.invoice_type === InvoiceType.AdjustAdd) && (item.field === "unit_price" || item.field === "discount" || item.field === "tax" || item.field === "total")) {
        return false;
      }

      if (currentDraft?.invoice_type === InvoiceType.TransferStock) {
        if (item.field === "unit_price" || item.field === "discount" || item.field === "tax" || item.field === "total") {
          return false;
        }
      } else {
        if (item.field === "store_name") {
          return false;
        }
      }


      return true;
    })

  }, [currentDraft])


  const unDrawnItems: boolean = useMemo(() => {
    if(currentDraft === undefined) return false
    return currentDraft?.invoice_items.some(item => item.withdrawn === false)
  }, [currentDraft])

  const thisDraft = useMemo(() => currentDraft, [currentDraft])
  const updateDraft = useCallback(async (invoice: Invoice02) => {
    updateDraft(invoice)
  }, [])

  const Loading = useMemo(() => isLoading, [])
  const Succeeded = useMemo(() => isSucceeded, [])
  const Idle = useMemo(() => isIdle, [])
  const Failed = useMemo(() => isFailed, [])

  console.log("------------------")
  console.log(thisDraft)
  const [decimalScale, setDecimalScale] = useState<number>(2)


  const { YesNoDialog, onDialogOpen } = useYesNoDialog()


  const [value, setValue] = React.useState<Dayjs | null>(dayjs('2022-04-17T15:30'));

  const [showYesNoDialog, setShowYesNoDialog] = useState(false);
  const [showYesNoDialogParentId, setShowYesNoDialogParentId] = useState(0);
  // const [showYesNoDialogTitle, setShowYesNoDialogTitle] = useState('');
  // const [showYesNoDialogLable, setShowYesNoDialogLable] = useState('');

  // const columns = useMemo(()=>{ 
  //   if(gridColumns!== undefined){

  //     const cols = [...gridColumns]
  //     return cols
  //   }
  //   return []
  // },[thisDraft])

  const onShowYesNoDialogYes =  (parentId: number, result: number) => {
    dispatch(cancelInvoice())
  }


  const {
    openDialog: showSelectCustomerDialog,
    setOpenDialog: setShowSelectCustomerDialog,
    dialogReturn: dialogSelectCustomerReturn,
    SelectDialog: SelectCustomerDialog,
    onDialogOpen: onSelectCustomerDialogOpen } = useSelectCustomerDialog(onNewCustomerSelected)

  const onSelectCustomerButtonClicked = () => {
    onSelectCustomerDialogOpen(thisDraft?.customer_id!)
  }


  // useBeforeUnload(
  //   React.useCallback((event: BeforeUnloadEvent) => {
  //     event.preventDefault();
  //     event.returnValue = "";
  //     // localStorage.stuff = state;
  //     alert("hello")
  //   }, [changesNotSaved])
  // );

  // useEffect(() => {

  //   const handler = (event: BeforeUnloadEvent) => {
  //     event.preventDefault();
  //     event.returnValue = "";
  //   };

  //   window.addEventListener("beforeunload", handler);
  //   return () => {
  //     window.removeEventListener("beforeunload", handler);
  //   };
  // }, []);

  // const handleUnload = (e: BeforeUnloadEvent) => {
  //   const message = "o/";
  //   // (e || window.event).returnValue = message; //Gecko + IE
  //   return message;
  // };

  // // useBeforeUnload((event: any) => {
  // //   if (changesNotSaved) {
  // //     event.preventDefault();
  // //   }
  // // });


  //   const location = useLocation();
  // useEffect(() => {
  //    console.log("url changed")
  //    alert("url changed")
  // }, [location]);

  // Broker select Dialog
  const onNewBrokerSelected = (value: Broker) => {
    onBrokerChanged(value)
  }
  const {
    openDialog: showSelectBrokerDialog,
    setOpenDialog: setShowSelectBrokerDialog,
    dialogReturn: dialogSelectBrokerReturn,
    SelectDialog: SelectBrokerDialog,
    onDialogOpen: onSelectBrokerDialogOpen } = useSelectBrokerDialog(onNewBrokerSelected)

  const onSelectBrokerButtonClicked = () => {
    onSelectBrokerDialogOpen(thisDraft?.broker_id!)
  }


  // SalesPerson select Dialog
  const onNewSalesPersonSelected = (value: SalesPerson) => {
    onSalesPersonChanged(value)
  }
  const {
    openDialog: showSelectSalesPersonDialog,
    setOpenDialog: setShowSelectSalesPersonDialog,
    dialogReturn: dialogSelectSalesPersonReturn,
    SelectDialog: SelectSalesPersonDialog,
    onDialogOpen: onSelectSalesPersonDialogOpen } = useSelectSalesPersonDialog(onNewSalesPersonSelected)

  const onSelectSalesPersonButtonClicked = () => {
    onSelectSalesPersonDialogOpen(thisDraft?.sales_person_id!)
  }



  useEffect(() => {
    dispatch(loadInvoice(parseInt(invoiceId!)))

  }, [])


  useEffect(() => {
    if (!brokerData && currentDraft !== null && currentDraft !== undefined) {
      dispatch(getBrokerObject())
    }
  }, [currentDraft?.broker_id])

  useEffect(() => {
    if (!salesPersonData && currentDraft !== null && currentDraft !== undefined) {
      dispatch(getSalesPersonObject())
    }

  }, [currentDraft?.sales_person_id])


  useEffect(() => {
    if (currentDraft !== undefined) dispatch(onloadTotals())
  }, [currentDraft?.invoice_items])


  useEffect(() => {
    if(onNavigateAction === 'navigate back'){
      navigate(-1)
    }
  }, [onNavigateAction, navigate])

  const action = (
    <>
      <Button color="secondary" size="small" onClick={handleCloseSnackbar}>
        Close
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseSnackbar}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );


  return (
    <Container maxWidth={false} sx={{ margin: 0, padding: 0 }}>
      {(isLoading || thisDraft === undefined) && <LinearProgress />}
      {(isSucceeded || isIdle || isFailed) && thisDraft !== undefined && (
        <>
          <Prompt when={changesNotSaved} message={trans.new_invoice.data_not_saved_msg} />

          <Snackbar
            open={openSnackbar}
            autoHideDuration={6000}
            onClose={handleCloseSnackbar}
            // message={snackbarMessage}
            action={action}
          >
            <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%', backgroundColor: '#f06292' }}>
              {snackbarMessage}
            </Alert>
          </Snackbar>

          {thisDraft ?
            <AppBar position="static" sx={{ backgroundColor: trans.new_invoice.type['title_color' + thisDraft.invoice_type] }}>
              <Toolbar>
                <Typography variant="h6" component="div">
                  {trans.new_invoice.type['title_name' + thisDraft.invoice_type] + ": " + thisDraft.id}
                </Typography>
              </Toolbar>
            </AppBar> : null}

          {/* <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography>{trans.new_invoice.accordion2.title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'left' }}>
                {discountModeList.length > 0 && thisDraft?.invoice_discount_mode ?
                  <FormControl  >
                    <InputLabel htmlFor="invoice_discount_mode">{trans.new_invoice.invoice_discount_mode}</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="invoice_discount_mode"
                      // defaultValue={unitSelected?.id}
                      value={thisDraft?.invoice_discount_mode!}
                      name="invoice_discount_mode"

                      label="Type"
                      onChange={onSelectValueChange}

                      size='small'
                      sx={{ width: 200, margin: "4px" }}
                      disabled={thisDraft?.invoice_items.length > 0}
                    >
                      {discountModeList?.map(mods => (<MenuItem value={mods.id} key={mods.id}>{mods.discount_mode_name}</MenuItem>))}
                    </Select>
                  </FormControl> : <></>}

                {taxModeList.length > 0 && thisDraft?.invoice_tax_mode ?
                  <FormControl  >
                    <InputLabel htmlFor="invoice_tax_mode">{trans.new_invoice.invoice_tax_mode}</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="invoice_tax_mode"
                      // defaultValue={unitSelected?.id}
                      value={thisDraft?.invoice_tax_mode!}
                      name="invoice_tax_mode"

                      label="Type"
                      onChange={onSelectValueChange}

                      size='small'
                      sx={{ width: 200, margin: "4px" }}
                      disabled={thisDraft?.invoice_items.length > 0}
                    >
                      {taxModeList?.map(mods => (<MenuItem value={mods.id} key={mods.id}>{mods.tax_mode_name}</MenuItem>))}
                    </Select>
                  </FormControl> : <></>}

                <IconButton color="secondary" aria-label="add an alarm" sx={{ paddingY: 0 }} onClick={onSelectBrokerButtonClicked}>
                  <EditIcon />
                </IconButton>
                <TextField id="broker_name" name="broker_name" label={trans.new_invoice.invoice_broker} variant="standard" size='small' value={broker?.broker_name || ''} sx={{ margin: "4px" }} />


                <IconButton color="secondary" aria-label="add an alarm" sx={{ paddingY: 0 }} onClick={onSelectSalesPersonButtonClicked}>
                  <EditIcon />
                </IconButton>
                <TextField id="sales_person_name" name="sales_person_name" label={trans.new_invoice.invoice_sales_person} variant="standard" size='small' value={salesPerson?.sales_person_name || ''} sx={{ margin: "4px" }} />

              </Box>

              <Paper>
                <TextField id="invcustomer_phone" name="invcustomer_phone" label={trans.new_invoice.invcustomer_phone} variant="standard" size='small' value={thisDraft?.invcustomer_phone || ''} onChange={onInputValueChange} sx={{ margin: "4px" }} />
                <TextField id="invcustomer_name" name="invcustomer_name" label={trans.new_invoice.invcustomer_name} variant="standard" size='small' value={thisDraft?.invcustomer_name || ''} onChange={onInputValueChange} sx={{ margin: "4px" }} />
                <TextField id="invcustomer_company" name="invcustomer_company" label={trans.new_invoice.invcustomer_company} variant="standard" size='small' value={thisDraft?.invcustomer_company || ''} onChange={onInputValueChange} sx={{ margin: "4px" }} />
                <TextField id="invcustomer_address" name="invcustomer_address" label={trans.new_invoice.invcustomer_address} variant="standard" size='small' value={thisDraft?.invcustomer_address || ''} onChange={onInputValueChange} sx={{ margin: "4px" }} />
                <TextField id="invcustomer_field" name="invcustomer_field" label={trans.new_invoice.invcustomer_field} variant="standard" size='small' value={thisDraft?.invcustomer_field || ''} onChange={onInputValueChange} sx={{ margin: "4px" }} />
              </Paper>

            </AccordionDetails>

          </Accordion> */}

          <Paper elevation={1} sx={{ marginY: 2, paddingY: 2 }}>
            {/* {thisDraft?.customer_id && customers.length>0? */}
            <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-end' }}>
              {storeList.length > 0 && thisDraft?.store! > 0 ?
                <FormControl  >
                  <InputLabel htmlFor="store">{trans.invoice_item.store}</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="store"
                    // defaultValue={unitSelected?.id}
                    value={thisDraft?.store}
                    name="store"

                    label="Type"
                    onChange={onStoreChanged}

                    size='small'
                    sx={{ width: 200, margin: "4px" }}
                  >
                    {storeList?.map(store => (<MenuItem value={store.id} key={store.id}>{store.name}</MenuItem>))}
                  </Select>
                </FormControl> : <></>
              }
              <IconButton color="secondary" aria-label="add an alarm" sx={{ paddingY: 0 }} onClick={onSelectCustomerButtonClicked}>
                <EditIcon />
              </IconButton>
              <TextField id="customer_name" name="customer_name" label={trans.new_invoice.invoice_customer} variant="standard" size='small' value={thisDraft?.customer_name || ''} sx={{ margin: "4px" }} />

              <LocalizationProvider dateAdapter={AdapterDateFns} >
                {/* <MobileDatePicker
                label={trans.new_invoice.invoice_date}
                views={['day', 'month', 'year']} 
                format="dd/MM/yyyy"
                value={new Date()}
                // value={new Date(thisDraft?.invoice_date)}
                onChange={onDateChange}
                // slotProps={(params) => <TextField {...params} size="small" />}
                // slotProps={}
                
                // componentsProps={{ textField: { variant: 'outlined' }}
                
              /> */}

                <DateTimeField
                  label={trans.new_invoice.invoice_date}
                  format="dd/MM/yyyy hh:mm:ss aa"
                  value={thisDraft?.invoice_date ? new Date(thisDraft?.invoice_date) : new Date()}
                  onChange={onDateChange}
                  // slotProps={{ textField: { variant: 'standard' } }}
                  sx={{ marginX: "6px" }}

                />
                {/* <DateTimeField
  label="Uncontrolled field"
  defaultValue={dayjs('2022-04-17T15:30')}
/> */}
              </LocalizationProvider>
              <TextField id="invoice_comment" name="invoice_comment" label={trans.new_invoice.invoice_comment} variant="standard" size='small' value={thisDraft?.invoice_comment || ''} onChange={onInputValueChange} sx={{ margin: "4px" }} />
            </Box>
          </Paper>
          <Paper elevation={1} sx={{ marginY: 2, paddingY: 2 }}>


            {thisDraft !== null && thisDraft !== undefined && thisDraft?.invoice_type !== undefined ?
              (thisDraft?.invoice_type === InvoiceType.Sale || thisDraft?.invoice_type === InvoiceType.Purchase || thisDraft?.invoice_type === InvoiceType.SalesReturn || thisDraft?.invoice_type === InvoiceType.PurchaseReturn) ?
                <ItemSelect3 priceList={1} addNewInvoiceItem={addNewInvoiceItem} taxPrecent={thisDraft?.tax ?? 0} discountMode={thisDraft?.invoice_discount_mode!} taxMode={thisDraft?.invoice_tax_mode!} store={storeList?.find(store => (thisDraft?.invoice_type === 8 ? store.id === invoiceTransferStore : store.id === thisDraft?.store))} currentItems={thisDraft?.invoice_items} invoiceType={thisDraft.invoice_type} />
                :
                <ItemSelect4 priceList={1} addNewInvoiceItem={addNewInvoiceItem} taxPrecent={thisDraft?.tax ?? 0} discountMode={thisDraft?.invoice_discount_mode!} taxMode={thisDraft?.invoice_tax_mode!} store={storeList?.find(store => (thisDraft?.invoice_type === 8 ? store.id === invoiceTransferStore : store.id === thisDraft?.store))} sourceStoreId={thisDraft?.store!} currentItems={thisDraft?.invoice_items!} invoiceType={thisDraft?.invoice_type} />
              :
              <></>
            }
          </Paper>
          <Grid2 container>
            <Grid2 size={{ sm: 12, md: 8, lg: 9 }} width={"100%"}>

              {(selModel.length > 0) && <IconButton aria-label="delete" sx={{ marginX: 2, color: "#ff0000" }}
                onClick={() => dispatch(onDeleteBillItems())}
              >
                <DeleteIcon fontSize="small" />
              </IconButton>}
              {/* <InvoiceDatagride rows={((thisDraft?.invoice_items as InvoiceItemWithHash[]) ?? []).filter(item => !item.cancelled)} columns={gridColumns} summaryRows={summaryRows} onRowSelectionChanged={onRowSelectionChanged} selectedRows={selectionModel} direction={langDirection === "rtl" ? "rtl" : "ltr"} onRowsChange={onRowsChange} /> */}
              {/* <InvoiceAGDatagride rows={((thisDraft?.invoice_items as InvoiceItemWithHash[]) ?? []).filter(item => !item.cancelled)} columns={gridColumns} onRowSelectionChanged={onRowSelectionChanged} onRowsChange={onRowsChange}/> */}
              {/* {columns !== undefined ? <InvoiceAGDatagride rows={thisDraft?.invoice_items as InvoiceItemWithHash[]} columns={columns} onRowSelectionChanged={onRowSelectionChanged} onRowsChange={onRowsChange}/> : <></>} */}
              <InvoiceAGDatagride rows={thisDraft?.invoice_items as InvoiceItemWithHash[]} columns={columnsDef} onRowSelectionChanged={onRowSelectionChanged} />

              {/* <InvoiceDatagride rows={orderDraft?.order_lines ?? []} columns={gridColumns} summaryRows={summaryRows} onRowSelectionChanged={onRowSelectionChanged} selectedRows={selectionModel} direction={langDirection === "rtl" ? "rtl" : "ltr"} /> */}
              {/* {orderDraft?.order_lines && orderDraft?.order_lines[0]? <RepDataGride direction={langDirection === "rtl"? "rtl": "ltr"} report={(orderDraft?.order_lines as Object[])}/>: null} */}
            </Grid2>
            <Grid2 size={{ sm: 12, md: 4, lg: 3 }} paddingLeft={2} width={"100%"}>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>

                {(thisDraft.invoice_type === InvoiceType.Sale || thisDraft.invoice_type === InvoiceType.Purchase || thisDraft.invoice_type === InvoiceType.SalesReturn || thisDraft.invoice_type === InvoiceType.PurchaseReturn) ?

                  <TotalPanel currentDraft={thisDraft} update={updateDraft} decimalScale={decimalScale} />

                  :
                  <></>
                }


                <Paper elevation={1} sx={{ marginY: 2 }}>
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', padding: 2, justifyContent: 'space-around' }}>
                    {!isCashierOnly ?
                      <Button variant="contained" startIcon={<DeleteForeverIcon sx={{ marginRight: 0, marginLeft: 1 }} />} onClick={() => setShowYesNoDialog(true)} sx={{ margin: '2px', backgroundColor: theme.palette.warning.main }} size="small">
                        {trans.new_invoice.cancel_button}
                      </Button>
                      : <></>}
                    {!isCashierOnly ?
                      <Button variant="contained" startIcon={<SaveIcon sx={{ marginRight: 0, marginLeft: 1 }} />} onClick={() => dispatch(updateInvoiceData())} sx={{ margin: '2px' }} size="small" disabled={!changesNotSaved}>
                        {trans.new_invoice.update_button}
                      </Button>
                      : <></>}
                    {thisDraft !== undefined ?
                      <>
                        <ReactToPrint
                          trigger={() => <Button variant="contained" startIcon={<PrintIcon sx={{ marginRight: 0, marginLeft: 1 }} />} onClick={() => dispatch(updateInvoiceData())} sx={{ margin: '2px', backgroundColor: "#00e676" }} size="small">
                            {trans.new_invoice.print_button}
                          </Button>}
                          content={() => printDivRef.current}
                        />
                        <div className='print-source' ref={printDivRef} style={{ width: '100%', height: window.innerHeight }}>
                          <PrintForm invoice={thisDraft!} />
                        </div>
                      </> : <></>

                    }

                    {unDrawnItems ?
                      <Button variant="contained" startIcon={<MultipleStopIcon sx={{ marginRight: 0, marginLeft: 1 }} />} onClick={() => dispatch(AdjustInvoiceStock())} sx={{ margin: '2px', backgroundColor: "#3949ab" }} size="small">
                        {trans.new_invoice.draw_button}
                      </Button>
                      : <></>}


                  </Box>
                </Paper>
              </Box>


            </Grid2>
          </Grid2>


          <YesNoDialog />

          <Dialog
            open={showSelectCustomerDialog}
            keepMounted
            onClose={() => setShowSelectCustomerDialog(false)}
            aria-describedby="alert-dialog-slide-description"
            sx={{ direction: langDirection }}
          >

            <SelectCustomerDialog />
          </Dialog>




          <Dialog
            open={showSelectBrokerDialog}
            keepMounted
            onClose={() => setShowSelectBrokerDialog(false)}
            aria-describedby="alert-dialog-slide-description"
            sx={{ direction: langDirection }}
          >

            <SelectBrokerDialog />
          </Dialog>


          <Dialog
            open={showSelectSalesPersonDialog}
            keepMounted
            onClose={() => setShowSelectSalesPersonDialog(false)}
            aria-describedby="alert-dialog-slide-description"
            sx={{ direction: langDirection }}
          >

            <SelectSalesPersonDialog />
          </Dialog>


          <Dialog
            open={showYesNoDialog}
            keepMounted
            onClose={() => setShowYesNoDialog(false)}
            aria-describedby="alert-dialog-slide-description"
            sx={{ direction: langDirection }}
          >
            <YesNoDialog2 openDialog={showYesNoDialog} setOpenDialog={setShowYesNoDialog} lable={trans.new_invoice.dialog.cancel_message} title={trans.new_invoice.dialog.cancel_title} parentId={showYesNoDialogParentId} onShowYesNoDialogYes={onShowYesNoDialogYes} />
          </Dialog>

        </>
      )}

    </Container>
  )
}
