import React, { useMemo, useState } from 'react'

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide } from '@mui/material'
import { TransitionProps } from '@mui/material/transitions';
import { useNavigate } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { selectDirection, selectTranslations } from "../../features/i18n/i18nSlice";
import { db } from '../../db';
import { updateDraftCount } from '../../features/login/loginSlice';





const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});


export const useYesNoDialog = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const langDirection = useAppSelector(selectDirection);
  const trans = useAppSelector(selectTranslations);
  const [draftId, setDraftId] = useState(0);
  const [invoiceType, setInvoiceType] = useState(0);
  const [dialogReturn, setDialogReturn] = useState('');




  const onDialogClickYes = () => {
    setOpenDialog(false);
    // dispatch(deleteCurrentDraftRequest());
    db.invoiceDraft.delete(draftId).then(() => {
      dispatch(updateDraftCount())
      setOpenDialog(false);
      setDialogReturn('deleted')
      navigate(`/draft/${invoiceType}/0`)
    }).catch(error => {
      setOpenDialog(false);
    })
  };


  const onDialogClickNo = () => {
    setOpenDialog(false);

  };

  const onDialogOpen = (draft_id: number, invoice_type: number) => {
    setDialogReturn('')
    setDraftId(draft_id)
    setInvoiceType(invoice_type)
    setOpenDialog(true);
  };



  const YesNoDialog = useMemo(() => {
    return () => (<Dialog
      open={openDialog}
      TransitionComponent={Transition}
      keepMounted
      onClose={onDialogClickNo}
      aria-describedby="alert-dialog-slide-description"
      sx={{ direction: langDirection }}
    >
      <DialogTitle>{trans.new_invoice.dialog.delete_title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          {trans.new_invoice.dialog.delete_message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onDialogClickNo}>{trans.new_invoice.dialog.no_button}</Button>
        <Button onClick={onDialogClickYes}>{trans.new_invoice.dialog.yes_button}</Button>
      </DialogActions>
    </Dialog>)
  }, [openDialog, onDialogClickNo, onDialogClickYes])


  return {
    dialogReturn,
    YesNoDialog,
    onDialogOpen: (draft_id: number, invoice_type: number) => onDialogOpen(draft_id, invoice_type)
  };
};