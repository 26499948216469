import { AppBar, Box, Card, CardActionArea, CardContent, Container, Grid, Paper, Toolbar, Typography } from '@mui/material'
import React from 'react'
import { useAppSelector } from '../../../app/hooks'
import { selectTranslations } from '../../../features/i18n/i18nSlice'
import CustomerDashboardCard from './CustomerDashboardCard';
import { useNavigate } from 'react-router-dom';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import GroupIcon from '@mui/icons-material/Group';
import BarDashboardCard from './BarDashboardCard';
import SummarizeIcon from '@mui/icons-material/Summarize';


// import { selectDirection, selectTranslations } from "../../features/i18n/i18nSlice";
// import { useAppSelector, useAppDispatch } from "../../app/hooks";


export default function CustomerDashboard() {

    const trans = useAppSelector(selectTranslations)
    const navigate = useNavigate();



    return (
        <>
            <Paper>
                <Toolbar>
                    <Typography component="h5" variant="h5" >
                        {trans.customers_dashboards.customers_section}
                    </Typography>
                </Toolbar>

                <Grid container>
                    <CustomerDashboardCard itemColor={trans.new_invoice.type.title_color1} itemName={trans.customers_dashboards.customers_add} onClick={() => navigate("/customer/add")} >
                        <GroupAddIcon sx={{ fontSize: 70, color: 'whitesmoke' }} ></GroupAddIcon>
                    </CustomerDashboardCard>
                    <CustomerDashboardCard itemColor={trans.new_invoice.type.title_color2} itemName={trans.customers_dashboards.customers_list} onClick={() => navigate("/customers/rep01")} >
                        <GroupIcon sx={{ fontSize: 70, color: 'whitesmoke' }} ></GroupIcon>
                    </CustomerDashboardCard>
                    <CustomerDashboardCard itemColor="#42a5f5" itemName={trans.customers_dashboards.customers_with_balance} onClick={() => navigate("/customers/rep02")} >
                        <img src="balancesheet.png" height={60} style={{ margin: 5 }} />
                    </CustomerDashboardCard>
                </Grid>
            </Paper>

            <Paper sx={{ marginY: 3 }}>
                <Toolbar>
                    <Typography component="h5" variant="h5" >
                        {trans.customers_dashboards.brokers_section}
                    </Typography>
                </Toolbar>

                <Grid container>
                    <CustomerDashboardCard itemColor="#69f0ae" itemName={trans.customers_dashboards.brokers_add} onClick={() => navigate("/broker/add")} >
                        <img src="addbroker.png" height={60} style={{ margin: 5 }} />
                    </CustomerDashboardCard>
                    <CustomerDashboardCard itemColor="#42a5f5" itemName={trans.customers_dashboards.brokers_list} onClick={() => navigate("/broker/rep01")} >
                        <img src="brokers.png" height={60} style={{ margin: 5 }} />
                    </CustomerDashboardCard>
                </Grid>
            </Paper>

            <Paper sx={{ marginY: 3 }}>
                <Toolbar>
                    <Typography component="h5" variant="h5" >
                        {trans.customers_dashboards.sales_persons_section}
                    </Typography>
                </Toolbar>

                <Grid container>
                    <CustomerDashboardCard itemColor="#cddc39" itemName={trans.customers_dashboards.sales_persons_add} onClick={() => navigate("/salesperson/add")} >
                        <img src="addsalesperson.png" height={60} style={{ margin: 5 }} />
                    </CustomerDashboardCard>
                    <CustomerDashboardCard itemColor="#ffca28" itemName={trans.customers_dashboards.sales_persons_list} onClick={() => navigate("/salesperson/rep01")} >
                        <img src="salesperson.png" height={60} style={{ margin: 5 }} />
                    </CustomerDashboardCard>
                </Grid>
            </Paper>


            <Paper sx={{ marginY: 3 }}>
                <Grid container>
                    <BarDashboardCard itemColor="#90a4ae" itemName={trans.dashboards.brokers_sales} onClick={() => navigate("/broker/rep02")} >
                        <SummarizeIcon sx={{ fontSize: 70, color: '#651fff' }} ></SummarizeIcon>
                    </BarDashboardCard>
                    <BarDashboardCard itemColor="#90a4ae" itemName={trans.dashboards.sales_persons_sales} onClick={() => navigate("/salesperson/rep02")} >
                        <SummarizeIcon sx={{ fontSize: 70, color: '#651fff' }} ></SummarizeIcon>
                    </BarDashboardCard>

                    <BarDashboardCard itemColor="#90a4ae" itemName={trans.dashboards.customers_sales} onClick={() => navigate("/customers/rep03")} >
                        <SummarizeIcon sx={{ fontSize: 70, color: 'whitesmoke' }} ></SummarizeIcon>
                    </BarDashboardCard>
                    <BarDashboardCard itemColor="#90a4ae" itemName={trans.dashboards.vendors_purchases} onClick={() => navigate("/customers/rep04")} >
                        <SummarizeIcon sx={{ fontSize: 70, color: 'whitesmoke' }} ></SummarizeIcon>
                    </BarDashboardCard>
                    <BarDashboardCard itemColor="#90a4ae" itemName={trans.dashboards.customers_profits} onClick={() => navigate("/customers/rep05")} >
                        <SummarizeIcon sx={{ fontSize: 70, color: 'whitesmoke' }} ></SummarizeIcon>
                    </BarDashboardCard>
                    
                </Grid>
            </Paper>

        </>
    )
}
