import React, { useEffect, useState } from 'react'

import { useNavigate } from "react-router-dom";

import { ApiStatus } from '../../models/ApiStatus';
import { useAppSelector } from '../../app/hooks';
import { LinearProgress } from '@mui/material';
import { selectTranslations } from '../i18n/i18nSlice';
import RepoAG from '../reports/RepoAG _v5';
import api from '../services/api';



//*1-add model


export default function ProductCategoryList() {

  const navigate = useNavigate();
  const trans = useAppSelector(selectTranslations);


  const [apiCallStatus, setApiCallStatus] = React.useState<ApiStatus>({ error: "", status: "idle" })
  const isLoading = apiCallStatus.status === "loading";
  const isSucceeded = apiCallStatus.status === "succeeded";
  const isIdle = apiCallStatus.status === "idle";
  const isFailed = apiCallStatus.status === "failed";

  const [rows, setRows] = useState<any[]>([])


  //*2- set on click open
  const onOpenClick = (id: number) => {
    navigate("/productcategory/" + id.toString());
  }

  //*3- define get report
  const getReport = async () => {
    setApiCallStatus({ error: "", "status": "loading" })
    try {
      const response = await api.get(
        '/productcategory/all',
        { headers: { "Content-Type": "application/json" } }
      )

      if (response.status === 200) {

        setRows(response.data["product_category"])

        setApiCallStatus({ error: "", "status": "succeeded" })
      } else {
        setApiCallStatus({ error: response.statusText, "status": "failed" })
      }

    } catch (err: any) {
      setApiCallStatus({ error: err.message, "status": "failed" })
    }
  };

  useEffect(() => {
    getReport()
  }, [])


  //*4- default column data
  const columns = [
    { field: 'id', type: 'number' },
    { field: 'category_name', type: 'string' },
  ]


  //*5- generate report
  return (
    <>
      {isLoading && <LinearProgress />}
      {(isSucceeded || isIdle || isFailed) && (
        <>
          <RepoAG columns={columns} componentName={trans.lookup_form.product_category_title} transRep='lookup_form' title={trans.lookup_form.product_category_title} rows={rows} onOpenClick={onOpenClick} openParameterName='id' />
        </>

      )}
    </>
  );


}


