import React, { useEffect, useMemo, useState, useCallback, useRef } from 'react';


import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { selectDirection, selectTranslations } from "../../features/i18n/i18nSlice";

import { selectPaletteMode } from '../../features/appconfig/appConfigSlice'
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import * as XLSX from 'xlsx';
import { AgGridReact } from 'ag-grid-react'; // React Grid Logic
import "ag-grid-community/styles/ag-grid.css"; // Core CSS
import "ag-grid-community/styles/ag-theme-quartz.css"; // Theme
// import "ag-grid-community/styles/ag-theme-quartz-dark.css"; // Theme
import "./styles.css"
import { ColDef, Column, GridOptions, CellValueChangedEvent, SelectionChangedEvent,SizeColumnsToContentStrategy, GridReadyEvent, ValueFormatterFunc, IRowNode, RowStyle } from 'ag-grid-community';
// import {
//   ColDef,
//   ColGroupDef,
//   GridApi,
//   GridOptions,
//   ModuleRegistry,
//   RowSelectionOptions,
//   createGrid,  
// } from "@ag-grid-community/core";

import { InvoiceItemWithHash } from '../../models/InvoiceItem';
import { onRowsChange, selectionModel } from './invoiceSlice';

import _ from 'lodash';


type Props = {
  rows: InvoiceItemWithHash[];
  columns: any[];
  onRowSelectionChanged: Function;
  // onRowsChange: (rows: InvoiceItemWithHash[], rowIndex: number, field: string, newValue: any) => void

};


export default function InvoiceAGDatagride({ rows, columns, onRowSelectionChanged }: Props) {

  // const useRepoAG = <Row extends Record<string, any>>(columnsInfo: Record<string, any>[], transRep: string, getReport: (setRowsFun: React.Dispatch<React.SetStateAction<Row[]>>, setApiCallStatusFun: React.Dispatch<React.SetStateAction<ApiStatus>>) => Promise<void>, onOpenClick?: (id: number) => void, openParameterName?: string) => {
  const gridRef = useRef<AgGridReact>(null);
  const dispatch = useAppDispatch();
  const [grColumns, setGrColumns] = useState<Column[]>([])

  const paletteMode = useAppSelector(selectPaletteMode);

  const langDirection = useAppSelector(selectDirection);
  const trans = useAppSelector(selectTranslations);
  const selectedRowIds = useAppSelector(selectionModel)


  // set background colour on every row, this is probably bad, should be using CSS classes
  const rowStyle = { background: '' };

  // set background colour on even rows again, this looks bad, should be using CSS classes
  const getRowStyle = useCallback((params: any): RowStyle => {
    // if (params.node.rowIndex % 2 === 0) {
    //     return { background: 'red' };
    // }
    // return { border: '1px solid #bdc1c6' };

    if (!params.node.isRowPinned()) {
      return { borderBottom: '1px solid #bdc1c6' };
    } else {
      return { borderBottom: '1px solid #bdc1c6', background: paletteMode === 'light' ? '#e8eaf6' : '#283593' };
    }
  },[])

  // function formatNumber (number: number):ValueFormatterFunc {
  //   // this puts commas into the number eg 1000 goes to 1,000,
  //   // i pulled this from stack overflow, i have no idea how it works
  //   return Math.floor(number)
  //     .toString()
  //     .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  // }

  function formatNumber(params: { value: number }): string {
    // this puts commas into the number eg 1000 goes to 1,000,
    // i pulled this from stack overflow, i have no idea how it works
    return (params.value?.toString() || '')
      // .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
      // .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
      // .replace(/\B(?=(\d{3})+(?!\d))/g, '$1,');
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  }

  function customValueFormatter(params: { value: any }): string {
    // You can perform any custom formatting logic here
    const formattedValue = `$${params.value.toFixed(2)}`; // Example: Format as currency

    return formattedValue;
  }

 
  // Column Definitions: Defines & controls grid columns.
  const [colDefs, setColDefs] = useState<ColDef[]>(columns);
  // const defaultColDef = {
  //   resizable: true,
  //   initialWidth: 200,
  //   wrapHeaderText: true,
  //   autoHeaderHeight: true,
  // }

  const defaultColDef = useMemo(() => ({
    // floatingFilter: true,
    // cellStyle: { borderRight: '1px solid #bdc1c6' },
    autoHeaderHeight: true,
  }), [])

  const currentRows = useMemo(() => {
    return (rows ?? []).filter(item => !item.cancelled)
  },[rows])

  const autoSizeStrategy: SizeColumnsToContentStrategy = {
    type: 'fitCellContents'
  };


  const onGridReady = useCallback((event: GridReadyEvent) => {
    event.api.hideOverlay();
    setGrColumns(event.api.getAllGridColumns()); 

    // event.api.forEachNode((node) => {
    //   if (selectedRowIds.includes(node.data.id)) {
    //     node.setSelected(true); 
    //   } 
    // });
    
  }, []);

const onSelectionChanged =  useCallback((event: SelectionChangedEvent) => {
  const selectedRows = gridRef.current!.api.getSelectedRows();
  // const selectedRows = event.api.getSelectedRows();
  const selectedRowsSet: ReadonlySet<string> =  new Set(selectedRows.map(item => item.itemHash));

  dispatch(onRowSelectionChanged(Array.from(selectedRowsSet)))

  // console.log('Selected rows:', selectedRows);
  // Add your custom logic here
},[])


// const calculatePinnedBottomData = (target: any) => {
//   //console.log(target);
//   //**list of columns fo aggregation**
//   let columnsWithAggregation = ['quantity', 'total', 'discount']
//   columnsWithAggregation.forEach(element => {
//     // console.log('element', element);
//     gridRef.current!.api.forEachNodeAfterFilter((rowNode: IRowNode<any>, index: number) => {
//         //if(rowNode.index < 10){
//           //console.log(rowNode);
//         //}
//           if (rowNode.data[element])
//               target[element] += Number(parseFloat(rowNode.data[element]).toFixed(2));
//       });
//       if (target[element])
//           target[element] =  Number(target[element].toFixed(2));
//   })
//   //console.log(target);
//   return target;
//   }

const onCellValueChanged = useCallback((item: CellValueChangedEvent) => {
  // dispatch(onRowsChange({rows: currentRows, rowIndex: item.rowIndex!, field: item.colDef.field!, newValue: item.newValue}))
  // console.log('Cell value changed:', item);
  // console.log('Cell value changed:', item.colDef.field);
  // Handle the updated value here
}, [currentRows]);



// function generatePinnedBottomData(){
//   // generate a row-data with null values
//   let result: Record<string, any>  = {};

//   if( gridRef.current !== null){
//     gridRef.current!.api.getAllGridColumns().forEach(item => {
//         result[item.getId()] = null;
//     });
//     return calculatePinnedBottomData(result);
//   }else{
//     return {}
//   }

// }

// useEffect(() => {
//    if (gridRef.current !== undefined && gridRef.current?.api !== undefined ) {
//     if( gridRef.current!.api.getAllGridColumns() !== undefined){
//       setGrColumns(gridRef.current?.api.getAllGridColumns()); 
//     }
   
//   } 
//   }, [gridRef.current]);

const calculatePinnedBottomData = (target: any) => {
  //console.log(target);
  //**list of columns fo aggregation**
  let columnsWithAggregation = ['quantity', 'total', 'discount']
  columnsWithAggregation.forEach(element => {
    // console.log('element', element);
    gridRef.current!.api.forEachNodeAfterFilter((rowNode: IRowNode<any>, index: number) => {
        //if(rowNode.index < 10){
          //console.log(rowNode);
        //}
          if (rowNode.data[element])
              target[element] += Number(parseFloat(rowNode.data[element]).toFixed(2));
      });
      if (target[element])
          target[element] =  Number(target[element].toFixed(2));
  })
  //console.log(target);
  return target;
  }

const pinnedData = useMemo( () => {
  // generate a row-data with null values
  let result: Record<string, any>  = {};

  if( grColumns.length > 0){
    grColumns.forEach(item => {
        result[item.getId()] = null;
    });
    return calculatePinnedBottomData(result);
  }else{
    return {}
  }

},[grColumns])

const deepCopy = (obj: any) => { return _.cloneDeep(obj); };




  return (
    <div className={`${paletteMode === 'light' ? 'ag-theme-quartz' : 'ag-theme-quartz-dark'}`} style={{ height: "100%", width: "100%", minHeight: "400px" }}>
      <AgGridReact
      // debug
        ref={gridRef}
        rowData={currentRows}
        columnDefs={colDefs}
        defaultColDef={defaultColDef}
        enableRtl={langDirection === "rtl" ? true : false}
        // enableRangeSelection={true}
        enableCellTextSelection={true}
        // rowStyle={rowStyle}
        getRowStyle={getRowStyle}

        // autoSizeStrategy={autoSizeStrategy}
        onGridReady={onGridReady}
        // pinnedBottomRowData = {[...generatePinnedBottomData()]}
        selection={{
          mode: 'multiRow',
          headerCheckbox: true,
          checkboxes:true,
          // enableClickSelection: true,
          
        }}
        // onCellValueChanged={onCellValueChanged}

        pinnedBottomRowData = {[pinnedData]}

        onRowDataUpdated={() => {
          let pinnedBottomData =pinnedData;
          // gridRef.current!.api.setPinnedBottomRowData([pinnedBottomData]);
          gridRef.current!.api.updateGridOptions({pinnedBottomRowData:[pinnedBottomData]});
        }}

        // gridOptions = {gridOptions}
        // rowSelection={'multiple'}
        // suppressRowClickSelection={true}
        onSelectionChanged={onSelectionChanged}
      />
    </div>
  )

}