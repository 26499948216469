import React, { useMemo, useState } from 'react'

import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle, LinearProgress, Slide, TextField, Typography } from '@mui/material'
import { selectDirection, selectTranslations } from '../../features/i18n/i18nSlice';
import { useAppSelector } from '../../app/hooks'

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import dayjs, { Dayjs } from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateField } from '@mui/x-date-pickers/DateField';

var bcrypt = require('bcryptjs');

interface ApiStatus {
  error: string;
  status: 'idle' | 'loading' | 'succeeded' | 'failed'
}



export default function DateRangeDialog({openDialog, setOpenDialog, lable, title, parentId, onDialogYes}:{openDialog:boolean , setOpenDialog: Function, lable: string, title: string, parentId: number, onDialogYes: Function}) {
  // const [openDialog, setOpenDialog] = useState(false);
  const trans = useAppSelector(selectTranslations);
  const langDirection = useAppSelector(selectDirection);

  const [dialogReturn, setDialogReturn] = useState('');

  const [apiCallStatus, setApiCallStatus] = React.useState<ApiStatus>({ error: "", status: "idle" })
  const isLoading = () => apiCallStatus.status === "loading";
  const isSucceeded = () => apiCallStatus.status === "succeeded";
  const isIdle = () => apiCallStatus.status === "idle";
  const isFailed = () => apiCallStatus.status === "failed";


  const [startDate, setStartDate] = React.useState<Dayjs | null>(dayjs());
  const [endDate, setEndDate] = React.useState<Dayjs | null>(dayjs());


  const [inputValue, setInputValue] = useState(0);



  const onInputValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()
    setInputValue(parseFloat(event.target.value))
  }

 
  const onDialogClickYes = async () => {
    setOpenDialog(false);
    onDialogYes(parentId, [dayjs(startDate).format('YYYY-MM-DD'), dayjs(endDate).format('YYYY-MM-DD')])
    // if(isValidNewPassword()){
    //   await resetPassword()
    // }
  };


  const onDialogClickNo = () => {
    setOpenDialog(false);

  };

  const onDialogOpen = async () => {
    // setDialogReturn('')
    setInputValue(0)
    setOpenDialog(true);
    
  };

return (
      <>
        <DialogTitle >{title}</DialogTitle>
        {isLoading() && <LinearProgress />}
        {(isSucceeded() || isIdle() || isFailed()) && (
          <>
            <DialogContent>


             <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['DateField', 'DateField']}>
              <DateField
                label={trans.date_range_dialog.from}
                value={startDate}
                onChange={(newValue) => setStartDate(newValue)}
                // format="MM-DD-YYYY"
                format="DD-MM-YYYY"
              />
              <DateField
                label={trans.date_range_dialog.to}
                value={endDate}
                onChange={(newValue) => setEndDate(newValue)}
                // format="LL"
                format="DD-MM-YYYY"
              />
            </DemoContainer>
          </LocalizationProvider>

              
            </DialogContent>
            <DialogActions>
              <Button onClick={onDialogClickNo} >{trans.new_invoice.dialog.no_button}</Button>
              <Button onClick={onDialogClickYes}>{trans.new_invoice.dialog.yes_button}</Button>
            </DialogActions>
          </>)}
      </>
    )


  // return {
  //   openDialog,
  //   setOpenDialog,
  //   dialogReturn,
  //   SelectDialog,
  //   onDialogOpen: () => onDialogOpen()
  // };
};