import React, { useMemo, useState } from 'react'

import { Autocomplete, AutocompleteChangeReason, Button, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide, TextField } from '@mui/material'
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../../app/hooks';
import { selectTranslations } from '../../../features/i18n/i18nSlice';



export const useGetNumValueDialog = (onValueSelected: Function) => {
  const [openDialog, setOpenDialog] = useState(false);
  const trans = useAppSelector(selectTranslations);
  const [dialogReturn, setDialogReturn] = useState('');
  const [value, setValue] = React.useState<number>(0);
  





  const onDialogClickYes = () => {
    setOpenDialog(false);
    onValueSelected(value)
  };


  const onDialogClickNo = () => {
    setOpenDialog(false);

  };

  const onChangeText = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(parseFloat(event.target.value));
    // console.log('onProductIdChanged')
  };

  const onDialogOpen = async (initValue: number) => {
    setValue(initValue)
    setDialogReturn('')
    setOpenDialog(true);
  };


  const SelectDialog = useMemo(() => {
    return () => (
      <>
      <DialogTitle>{trans.get_num_dialog.title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          {trans.new_invoice.dialog.delete_message}
        </DialogContentText>
        <TextField 
          type="number"
          placeholder="Initial Value"
          value={value}
          onChange={onChangeText}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onDialogClickNo}>{trans.new_invoice.dialog.no_button}</Button>
        <Button onClick={onDialogClickYes}>{trans.new_invoice.dialog.yes_button}</Button>
      </DialogActions>
      </>
   )
  }, [openDialog, onDialogClickNo, onDialogClickYes])


  return {
    openDialog,
    setOpenDialog,
    dialogReturn,
    SelectDialog,
    onDialogOpen: (initValue: number) => onDialogOpen(initValue)
  };
};