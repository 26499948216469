import { AppBar, Box, Card, CardActionArea, CardContent, Container, Grid, Paper, Toolbar, Typography } from '@mui/material'
import React from 'react'
import { useAppSelector } from '../../../app/hooks'
import { selectTranslations } from '../../../features/i18n/i18nSlice'
import ProductDashboardCard from './ProductDashboardCard';
import { useNavigate } from 'react-router-dom';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import BarDashboardCard from './BarDashboardCard';
import SummarizeIcon from '@mui/icons-material/Summarize';

import ColorLensIcon from '@mui/icons-material/ColorLens';
import ColorizeIcon from '@mui/icons-material/Colorize';
import CategoryIcon from '@mui/icons-material/Category';
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import StraightenIcon from '@mui/icons-material/Straighten';
import TextIncreaseIcon from '@mui/icons-material/TextIncrease';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';

// import { selectDirection, selectTranslations } from "../../features/i18n/i18nSlice";
// import { useAppSelector, useAppDispatch } from "../../app/hooks";


export default function ProductDashboard() {

    const trans = useAppSelector(selectTranslations)
    const navigate = useNavigate();



    return (
        <>
            <Paper>
                <Grid container>
                    <ProductDashboardCard itemColor="#536dfe" itemName={trans.dashboards.products_add} onClick={() => navigate("/product/add")} >
                        <AddShoppingCartIcon sx={{ fontSize: 70, color: 'whitesmoke' }} ></AddShoppingCartIcon>
                    </ProductDashboardCard>
                    <ProductDashboardCard itemColor="#69f0ae" itemName={trans.dashboards.products_List} onClick={() => navigate("/products/rep01")} >
                        <img src="products.png" height={60} style={{ margin: 5 }} />
                        {/* <CategoryIcon sx={{ fontSize: 80, color: 'whitesmoke' }} ></CategoryIcon> */}
                    </ProductDashboardCard>
                    <ProductDashboardCard itemColor="#e65100" itemName={trans.dashboards.products_edit_pricing} onClick={() => navigate("/products/rep02")} >
                        <LocalOfferIcon sx={{ fontSize: 70, color: 'whitesmoke' }} ></LocalOfferIcon>
                    </ProductDashboardCard>
                </Grid>
            </Paper>

            <Paper sx={{ marginY: 3 }}>
                <Grid container>

                    <ProductDashboardCard itemColor="#536dfe" itemName={trans.dashboards.add_product_category} onClick={() => navigate("/productcategory/add")} >
                        <AddToPhotosIcon sx={{ fontSize: 70, color: 'whitesmoke' }} ></AddToPhotosIcon>
                    </ProductDashboardCard>
                    <ProductDashboardCard itemColor="#69f0ae" itemName={trans.dashboards.product_category_list} onClick={() => navigate("/productcategory/all")} >
                        <CategoryIcon sx={{ fontSize: 70, color: 'whitesmoke' }} ></CategoryIcon>
                    </ProductDashboardCard>

                    <ProductDashboardCard itemColor="#536dfe" itemName={trans.dashboards.add_color} onClick={() => navigate("/colors/add")} >
                        <ColorizeIcon sx={{ fontSize: 70, color: 'whitesmoke' }} ></ColorizeIcon>
                    </ProductDashboardCard>
                    <ProductDashboardCard itemColor="#69f0ae" itemName={trans.dashboards.color_list} onClick={() => navigate("/colors/all")} >
                        <ColorLensIcon sx={{ fontSize: 70, color: 'whitesmoke' }} ></ColorLensIcon>
                    </ProductDashboardCard>



                    <ProductDashboardCard itemColor="#536dfe" itemName={trans.dashboards.add_size} onClick={() => navigate("/sizes/add")} >
                        <TextIncreaseIcon sx={{ fontSize: 70, color: 'whitesmoke' }} ></TextIncreaseIcon>
                    </ProductDashboardCard>
                    <ProductDashboardCard itemColor="#69f0ae" itemName={trans.dashboards.size_list} onClick={() => navigate("/sizes/all")} >
                        <StraightenIcon sx={{ fontSize: 70, color: 'whitesmoke' }} ></StraightenIcon>
                    </ProductDashboardCard>
                    
                </Grid>
            </Paper>

        </>
    )
}
