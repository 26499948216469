import 'react-data-grid/lib/styles.css';
import {useNavigate, Link} from "react-router-dom";
import DataGrid, {Column, SortColumn, HeaderRendererProps, textEditor } from 'react-data-grid';

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';


import { useAppSelector, useAppDispatch } from "../app/hooks";

import { selectDirection, selectTranslations } from "../features/i18n/i18nSlice";
import {selectPaletteMode } from "../features/appconfig/appConfigSlice";
import { width } from '@mui/system';

type Direction = 'ltr' | 'rtl';
interface Props {
  direction: Direction;
  report: Object[];
  columns?: Column<any>[];
}

export default function RepDataGride({ direction, report, columns }: Props) {
  const navigate = useNavigate();
  const langDirection = useAppSelector(selectDirection);
  const trans = useAppSelector(selectTranslations);
  const dispatch = useAppDispatch();
  const paletteMode = useAppSelector(selectPaletteMode);



  
  const usedColumns = columns? columns: Object.keys(report[0] as Object).map(item => ({key: item, name: item, width: 200,resizable: true})) 

  
  return (
    <DndProvider backend={HTML5Backend}>
      <DataGrid
        columns={usedColumns}
        rows={report}
        direction={direction}
        className={paletteMode === 'light'? 'rdg-light': 'rdg-dark'}
        style={{height:'100%'}}
      />
      </DndProvider>
  );
}
