import { AppBar, Box, Card, CardActionArea, CardContent, Container, Dialog, Grid2, LinearProgress, Paper, Toolbar, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useAppSelector, useAppDispatch } from '../../../app/hooks'
import { selectDirection, selectTranslations } from '../../../features/i18n/i18nSlice'
import DashboardCard from './DashboardCard';
import { useNavigate } from 'react-router-dom';
import CategoryIcon from '@mui/icons-material/Category';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import PasswordIcon from '@mui/icons-material/Password';
import LockResetIcon from '@mui/icons-material/LockReset';
import HomeIcon from '@mui/icons-material/Home';
import { refreshDB, actionCallStatus, changeBranch, isUseShift, currentShift, permCashierOnly } from '../../../features/login/loginSlice';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import ResetPasswordDialog from './ResetPasswordDialog';
import { db } from '../../../db';
import { useSelectBranchDialog } from './useSelectBranchDialog';




// import { selectDirection, selectTranslations } from "../../features/i18n/i18nSlice";
// import { useAppSelector, useAppDispatch } from "../../app/hooks";

const theme = createTheme();

export default function MyAccountDashboard() {

    const trans = useAppSelector(selectTranslations)
    const langDirection = useAppSelector(selectDirection);

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const apiCallStatus = useAppSelector(actionCallStatus);
    const isLoading = (status: any) => status === "loading";
    const isSucceeded = (status: any) => status === "succeeded";
    const isIdle = (status: any) => status === "idle";
    const isFailed = (status: any) => status === "failed";

    const useShift = useAppSelector(isUseShift);
    const shift = useAppSelector(currentShift);
    const isCashierOnly = useAppSelector(permCashierOnly)

    const [showResetPasswordDialog, setShowResetPasswordDialog] = useState(false);
    const [currentBranch, setCurrentBranch] = useState("");



    const onNewBranchSelected = (branchId: number) => {
        dispatch(changeBranch(branchId))
    }

    const {
        openDialog: showSelectBranchDialog,
        setOpenDialog: setShowSelectBranchDialog,
        dialogReturn: dialogSelectBranchReturn,
        SelectDialog: SelectBranchDialog,
        onDialogOpen: onSelectBranchDialogOpen } = useSelectBranchDialog(onNewBranchSelected)

    const onSelectBranchButtonClicked = async () => {

        const counfOfDraft = await db.invoiceDraft.count()
        if (counfOfDraft === 0) {
            onSelectBranchDialogOpen()
        } else {
            alert(trans.dashboards.can_not_update_dueto_draft)
        }
    }


    const onRefreshDB = async () => {
        const counfOfDraft = await db.invoiceDraft.count()
        if (counfOfDraft === 0 && (!useShift || (useShift && shift ===null))) {
            dispatch(refreshDB())
        } else {
            alert(trans.dashboards.can_not_update_dueto_draft)
        }
    }

    const getCurrentBranch = async () => {
        const currentBranchData = await db.appConfig.get({ id: 4 })
        if (currentBranchData) {
            setCurrentBranch(currentBranchData?.config_value!)
        }
    }
    useEffect(() => {
        getCurrentBranch()
    }, [])


    // Reset password  Dialog

    //   const {
    //     openDialog: showResetPasswordDialog,
    //     setOpenDialog: setShowResetPasswordDialog,
    //     SelectDialog: ResetPasswordDialog,
    //     onDialogOpen: onResetPasswordDialogOpen } = useResetPasswordDialog()

    const onResePasswordClicked = () => {
        setShowResetPasswordDialog(true)
    }



    return (
        <ThemeProvider theme={theme}>
            <Container>


                {(isLoading(apiCallStatus)) && <LinearProgress />}
                {(isSucceeded(apiCallStatus) || isIdle(apiCallStatus)) && (
                    <>
                        <Paper>
                            <Toolbar     >
                                <Typography component="h5" variant="h5" >
                                    {trans.dashboards.branch_name + currentBranch}
                                </Typography>
                            </Toolbar>

                            <Grid2 container>
                                {!isCashierOnly?
                                <DashboardCard itemColor="#00c853" itemName={trans.dashboards.sync_data} onClick={onRefreshDB} >
                                    <CloudSyncIcon sx={{ fontSize: 70, color: 'whitesmoke' }} ></CloudSyncIcon>
                                </DashboardCard>
                                :<></>}
                                <DashboardCard itemColor="#00796b" itemName={trans.dashboards.change_password} onClick={() => onResePasswordClicked()} >
                                    <LockResetIcon sx={{ fontSize: 70, color: 'whitesmoke' }} ></LockResetIcon>
                                </DashboardCard>

                                {!isCashierOnly?
                                <DashboardCard itemColor="#ff1744" itemName={trans.dashboards.change_branch_title} onClick={() => onSelectBranchButtonClicked()} >
                                    <HomeIcon sx={{ fontSize: 70, color: 'whitesmoke' }} ></HomeIcon>
                                </DashboardCard>
                                :<></>}

                            </Grid2>
                        </Paper>
                    </>
                )}
            </Container>


            <Dialog
                open={showResetPasswordDialog}
                keepMounted
                onClose={() => setShowResetPasswordDialog(false)}
                aria-describedby="alert-dialog-slide-description"
                sx={{ direction: langDirection }}
            >

                <ResetPasswordDialog openDialog={showResetPasswordDialog} setOpenDialog={setShowResetPasswordDialog} />
            </Dialog>


            <Dialog
                open={showSelectBranchDialog}
                keepMounted
                onClose={() => setShowSelectBranchDialog(false)}
                aria-describedby="alert-dialog-slide-description"
                sx={{ direction: langDirection }}
            >
                <SelectBranchDialog />
            </Dialog>

        </ThemeProvider>

    )
}
