import React from 'react'
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Stack from '@mui/material/Stack';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useAppSelector } from "../../app/hooks";
import { selectDirection } from '../i18n/i18nSlice';

interface Props {
  currentPage: number;
  numberOfPage: number;
  setPageNumber: Function;
}

export default function TablePagination({ currentPage, numberOfPage, setPageNumber }: Props) {
  const langDirection = useAppSelector(selectDirection);

    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPageNumber(value)
      };
    
    

  return (
  
    <Stack spacing={2}>
    <Pagination
        color="primary"
        count={numberOfPage}
        page={currentPage}
        onChange={handleChange}
        renderItem={(item) => (
        <PaginationItem
        components={{ previous: langDirection==='ltr'? ArrowBackIcon:ArrowForwardIcon , next: langDirection==='ltr'? ArrowForwardIcon:ArrowBackIcon }}
          {...item}
        />
      )}
    />
  </Stack>
    
  )  
}  

