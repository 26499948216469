import React from 'react'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import AddBoxIcon from '@mui/icons-material/AddBox';
import DeleteIcon from '@mui/icons-material/Delete'; 
import { PriceItem } from '../../models/ProductCatalog';
import { Box, FormControlLabel, Paper, TextField } from '@mui/material';
import { selectTranslations } from "../i18n/i18nSlice";
import { useAppSelector, useAppDispatch } from '../../app/hooks';

export default function ProductPricing({ priceList, updatePricing }: { priceList: { [key: string]: PriceItem }, updatePricing: (pricing: { [key: string]: PriceItem }) => void}) {
    const trans = useAppSelector(selectTranslations);


    
    


    const onCheckChanged = (event: React.ChangeEvent<HTMLInputElement>, modifiedkey: string) => {


        
        // // console.log(event.target.checked)

        // const newList = prodctUnits.map((unit, index) => {
        //     if (index === modifiedIndex) {

        //         return { ...unit, [event.target.name]: true}

        //     }else{
        //         return { ...unit, [event.target.name]: false }
        //     }
        //     return unit;
        // })
        // setNewProductUnits(newList)
    };



    const onInputValueChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, modifiedKey: string) => {
        // const newList = prodctUnits.filter((item) => item.id !== id);
        // const newList = priceList.map((pl, index) => {
        //     if (index === modifiedIndex) {
        //         if (event.target.name === "main_unit_quantity" && unit.is_main === true) {
        //             return unit;
        //         } else {
        //             return { ...unit, [event.target.name]: event.target.value }
        //         }
        //     }
        //     return unit;
        // })
        // setNewProductUnits(newList)
        const newPricing = {...priceList, [modifiedKey]:{...priceList[modifiedKey], [event.target.name]: event.target.value}}
        updatePricing(newPricing)
    }

  

    return (
        <Paper elevation={1} sx={{ marginY: 2 }}>
            
            <Box sx={{ display: 'flex', flexWrap: 'wrap', padding: 2, justifyContent: 'space-around' }}>

                <List sx={{ bgcolor: 'background.paper' }}>
                    {Object.keys(priceList).map((key) => {
                        return (
                            <ListItem
                                key={key}

                                disablePadding
                            >

                                <TextField id="list_name" name="list_name" label={trans.product_form.price_list.list_name} variant="standard" size="small"  value={priceList[key].list_name || ''}  />
                                <TextField id="price" name="price" label={trans.product_form.price_list.price} type="number" variant="standard" size="small" onChange={(event) => onInputValueChange(event, key)} value={priceList[key].price || ''} required />
                                <FormControlLabel labelPlacement="top" label={trans.product_form.price_list.default_list} control={<Checkbox name="default_list" edge="start"  checked={priceList[key].default_list} tabIndex={-1} disableRipple  />} />
                                
                            </ListItem>
                        );
                    })}
                </List>
            </Box>
        </Paper>
    );
}
