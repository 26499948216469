import React, { useEffect, useState } from 'react'

import { useNavigate, useParams } from "react-router-dom";
import { useRepoAG } from '../reports/useRepoAG _v4';

import { ApiStatus } from '../../models/ApiStatus';
import { db } from '../../db';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { LinearProgress } from '@mui/material';
import { selectTranslations } from '../i18n/i18nSlice';
import RepoAG from '../reports/RepoAG _v5';
import { ro } from 'date-fns/locale';
import api from '../services/api';



//*1-add model
interface Row {
  id: number;
  fullname: string;
  balance: number;
}



export default function CustomerRep05() {

  const params = useParams();
  const { productId, startDate, endDate } = params

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const trans = useAppSelector(selectTranslations);
  // const apiCallStatus = useAppSelector(commissionCallStatus);
  // const apiStatusBusyCount = useAppSelector(commissionCallBusyCount);

  // const isLoading = React.useMemo(() => (apiCallStatus === "loading" || apiStatusBusyCount > 0), [apiCallStatus, apiStatusBusyCount]);
  // const isSucceeded = React.useMemo(() => (apiCallStatus === "succeeded" && apiStatusBusyCount === 0), [apiCallStatus, apiStatusBusyCount]);
  // const isIdle = React.useMemo(() => (apiCallStatus === "idle" && apiStatusBusyCount === 0), [apiCallStatus, apiStatusBusyCount]);
  // const isFailed = React.useMemo(() => (apiCallStatus === "failed" && apiStatusBusyCount === 0), [apiCallStatus, apiStatusBusyCount]);

  // const employeesReportData = useAppSelector(employeesReport);


  const [apiCallStatus, setApiCallStatus] = React.useState<ApiStatus>({ error: "", status: "idle" })
  const isLoading = apiCallStatus.status === "loading";
  const isSucceeded = apiCallStatus.status === "succeeded";
  const isIdle = apiCallStatus.status === "idle";
  const isFailed = apiCallStatus.status === "failed";

  const [rows, setRows] = useState<any[]>([])


  //*2- set on click open
  const onOpenClick = (id: number) => {
    navigate("/customer/" + id.toString());
  }


  const getReport = async () => {
    setApiCallStatus({ error: "", "status": "loading" })
    try {
      const response = await api.get(
        '/customer/rep05',
        { headers: { "Content-Type": "application/json" } }
      )

      if (response.status === 200) {

        setRows(response.data["customers"])

        setApiCallStatus({ error: "", "status": "succeeded" })
      } else {
        setApiCallStatus({ error: response.statusText, "status": "failed" })
      }

    } catch (err: any) {
      setApiCallStatus({ error: err.message, "status": "failed" })
    }
  };

  // const getReport = async () => {
  //   setApiCallStatus({ error: "", "status": "loading" })
  //     const products = await db.productCatalog.toArray()
  //     const productCat = await db.productCategory.toArray()
  //     // const newItem =  {...item, account_type_name: trans.finance_eports.profit, account_name: trans.finance_eports.profit , ...Object.assign({},{},...Object.keys(item).map( (key) =>  key.toLowerCase().includes("change") ? {[key]: (item[key]- expense![key]).toFixed(2)}:null))}

  //     const productCatObj = { ...Object.assign({}, {}, ...productCat.map(item => ({ [item.id]: item.category_name }))) }

  //     const newRep = products.map(item => ({ id: item.id, product_name: item.product_name, product_category_name: productCatObj[item.product_category], international_code: item.international_code  , user_defined_code: item.user_defined_code?.toString() + '-' + item.size_id.toString() + '-' + item.color_id.toString() }))
  //     // setRows(newRep as Row[])
  //     setRows(newRep)
  //     setApiCallStatus({ error: "", "status": "succeeded" })
  //     console.log(newRep)
  //   }

  useEffect(() => {
    getReport()
  }, [])




  //*3- define get report
  // const getReport = async (setRowsFun: React.Dispatch<React.SetStateAction<Row[]>>, setApiCallStatusFun: React.Dispatch<React.SetStateAction<ApiStatus>>) => {
  //   setApiCallStatusFun({ error: "", "status": "loading" })

  //   // setRows(newRep as Row[])
  //   setRowsFun(employeesReportData as Row[])
  //   setApiCallStatusFun({ error: "", "status": "succeede  d" })
  // }


  //*4- default column data
  // const columns = {
  //   id: 0,
  //   fullname: '',
  //   balance: 0,
  // }
  const initColumns = [
    { field: 'id', type: 'number' },
    { field: 'fullname', type: 'string' },
    { field: 'phone', type: 'string' },
    { field: 'total', type: 'number' },
  ]

    var additinalColumns: string[] = []
    if (rows.length > 0) {
      additinalColumns = Object.keys(rows[0]).filter(item => !['id', 'fullname', 'phone', 'total'].includes(item))
    }
    const addedClm = additinalColumns.map(clm => ({ field: clm, type: 'number' }))
    const columns = [...initColumns, ...addedClm]
  
  
  


  //*5- generate report
  // const genRep = useRepoAG<Row>(columns, 'product_rep1', getReport, onOpenClick, 'id')
  // const genRep = useRepoAG<Row>(columns, 'all_employee_report', 'all_employee_report', trans.commission_dashboard.employees, employeesReportData, onOpenClick, 'id')


  return (
    <>
      {isLoading && <LinearProgress />}
      {(isSucceeded || isIdle || isFailed) && (
        <>
          <RepoAG columns={columns} componentName={trans.dashboards.customers_profits} transRep='customer_rep1' title={trans.dashboards.customers_profits} rows={rows} onOpenClick={onOpenClick} openParameterName='id' />
        </>

      )}
    </>
  );


}


