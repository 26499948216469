import React from 'react';
import { Box, Button, InputAdornment, Paper, TextField } from '@mui/material'
import { useAppSelector } from "../../app/hooks";
import { selectDirection, selectTranslations } from "../../features/i18n/i18nSlice";
import { InvoiceDiscountModeEnum, InvoiceTaxModeEnum } from '../../models/Invoice';
import { Invoice02 } from '../../models/Invoice02';



type Props = {
    currentDraft: Invoice02;
    update: (invoice: Invoice02) => Promise<void>;
    decimalScale: number;
};



export default function TotalPanel({ currentDraft, update, decimalScale }: Props) {

    const langDirection = useAppSelector(selectDirection);
    const trans = useAppSelector(selectTranslations);

    const onGotFocus = (e: any) => {
        // priceRef.current?.select()
        setTimeout(function () {
            e.target.select();
        }, 0);
    }


    const onDiscountPercentageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        // updateInvoiceDraft(event.target.name, event.target.value)

        update(({
            ...currentDraft,
            discount_percentage: parseFloat(event.target.value),
            discount: currentDraft?.subtotal! * parseFloat(event.target.value) / 100,
            salesTotal: currentDraft?.subtotal! * (1 - parseFloat(event.target.value) / 100),
            tax: (currentDraft?.subtotal! - currentDraft?.subtotal! * parseFloat(event.target.value) / 100) * currentDraft?.tax_percentage! / 100,
            // tax_percentage: parseFloat(event.target.value),
            total: currentDraft?.subtotal! * (1 - parseFloat(event.target.value) / 100) * (1 + currentDraft?.tax_percentage! / 100)
        } as Invoice02))
    }
    const onDiscountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        // updateInvoiceDraft(event.target.name, event.target.value)

        //avoid adding discount greater than invoice total that lead to null salestotal
        var newDiscount = parseFloat(event.target.value)
        var newDiscountPrecentage = 0;

        if (newDiscount > currentDraft?.subtotal) {
            newDiscount = 0;
        } else {
            newDiscountPrecentage = (newDiscount * 100) / currentDraft?.subtotal!
        }

        update(({
            ...currentDraft,
            discount_percentage: newDiscountPrecentage,
            discount: newDiscount,
            salesTotal: currentDraft?.subtotal! - newDiscount,
            tax: (currentDraft?.subtotal! - newDiscount) * currentDraft?.tax_percentage! / 100,
            // tax_percentage: parseFloat(event.target.value),
            total: (currentDraft?.subtotal! - newDiscount) * (1 + currentDraft?.tax_percentage! / 100)
            // discount_percentage: (parseFloat(event.target.value) * 100) / currentDraft?.subtotal!,
            // discount: parseFloat(event.target.value),
            // tax: (currentDraft?.subtotal! - parseFloat(event.target.value)) * currentDraft?.tax_percentage! / 100,
            // // tax_percentage: parseFloat(event.target.value),
            // total: (currentDraft?.subtotal! - parseFloat(event.target.value)) * (1 + currentDraft?.tax_percentage! / 100)
        } as Invoice02))
    }

    const onTaxPercentageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        // updateInvoiceDraft(event.target.name, event.target.value)

        update(({
            ...currentDraft,
            tax_percentage: parseFloat(event.target.value),
            tax: (currentDraft?.subtotal! - currentDraft?.discount!) * parseFloat(event.target.value) / 100,
            total: currentDraft?.subtotal! - currentDraft?.discount! + ((currentDraft?.subtotal! - currentDraft?.discount!) * parseFloat(event.target.value) / 100)
        } as Invoice02))
    }

    const onPaymentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        update(({
            ...currentDraft,
            payment_amount: parseFloat(event.target.value),
        } as Invoice02))
    }


    return (
        <>
            <Paper elevation={1} sx={{ marginBottom: 2, paddingY: 2 }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', padding: 2 }}>
                    <TextField id="order_sales_total" label={trans.new_invoice.totals.salesTotal} type="number" variant="standard" size='small' value={currentDraft?.subtotal.toFixed(decimalScale) || ''} />
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'left' }}>

                        {currentDraft?.invoice_discount_mode === InvoiceDiscountModeEnum.PerItem ?
                            <>
                                <TextField id="order_discount" label={trans.new_invoice.totals.discount} type="number" variant="standard" size='small' value={currentDraft?.discount.toFixed(decimalScale) || 0} sx={{ width: '50%', margin: "4px" }}
                                    onFocus={onGotFocus}
                                />
                                <TextField id="discount_percentage" label={trans.new_invoice.totals.discount_percentage} type="number" variant="standard" size='small' value={currentDraft?.discount_percentage.toFixed(decimalScale) || 0} sx={{ width: '40%', margin: "4px" }}

                                    onFocus={onGotFocus}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="start">%</InputAdornment>,
                                        // startAdornment: <InputAdornment position="start">%</InputAdornment>,
                                    }}
                                /> </> :
                            <>
                                <TextField id="order_discount" label={trans.new_invoice.totals.discount} type="number" variant="standard" size='small' value={currentDraft?.discount || 0} onChange={onDiscountChange} sx={{ width: '50%', margin: "4px" }}
                                    onFocus={onGotFocus}
                                />
                                <TextField id="discount_percentage" label={trans.new_invoice.totals.discount_percentage} type="number" variant="standard" size='small' value={currentDraft?.discount_percentage || 0} sx={{ width: '40%', margin: "4px" }}
                                    onChange={onDiscountPercentageChange}
                                    onFocus={onGotFocus}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="start">%</InputAdornment>,
                                        // startAdornment: <InputAdornment position="start">%</InputAdornment>,
                                    }}
                                />
                            </>

                        }
                    </Box>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'left' }}>
                        {currentDraft?.invoice_tax_mode === InvoiceTaxModeEnum.PerItem ?
                            <>
                                <TextField id="order_tax" label={trans.new_invoice.totals.tax} type="number" variant="standard" size='small' value={currentDraft?.tax.toFixed(decimalScale) || 0} sx={{ width: '50%', margin: "4px" }} />
                                <TextField id="tax_percentage" label={trans.new_invoice.totals.tax_percentage} type="number" variant="standard" size='small' value={currentDraft?.tax_percentage.toFixed(decimalScale) || 0} sx={{ width: '40%', margin: "4px" }}
                                    onFocus={onGotFocus}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="start">%</InputAdornment>,
                                        // startAdornment: <InputAdornment position="start">%</InputAdornment>,
                                    }}
                                />
                            </> :
                            <>
                                <TextField id="order_tax" label={trans.new_invoice.totals.tax} type="number" variant="standard" size='small' value={currentDraft?.tax.toFixed(decimalScale) || 0} sx={{ width: '50%', margin: "4px" }} />
                                <TextField id="tax_percentage" label={trans.new_invoice.totals.tax_percentage} type="number" variant="standard" size='small' value={currentDraft?.tax_percentage || 0} sx={{ width: '40%', margin: "4px" }}
                                    onChange={onTaxPercentageChange}
                                    onFocus={onGotFocus}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="start">%</InputAdornment>,
                                        // startAdornment: <InputAdornment position="start">%</InputAdornment>,
                                    }}
                                />
                            </>
                        }
                    </Box>
                    <TextField id="order_total" label={trans.new_invoice.totals.total} type="number" variant="standard" size='small' value={currentDraft?.total.toFixed(decimalScale) || ''} />
                </Box>
            </Paper>


            {currentDraft && currentDraft?.customer_id != 100 ?

                <Paper elevation={1} sx={{ marginY: 2, paddingY: 2 }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', padding: 2 }}>
                        <TextField id="payment_amount" name="payment_amount" label={(currentDraft?.invoice_type === 2 || currentDraft?.invoice_type === 3) ? trans.new_invoice.payment_amount_pay : trans.new_invoice.payment_amount_collect} type="number" variant="standard" size='small' value={currentDraft?.payment_amount} onChange={onPaymentChange} />
                    </Box>
                </Paper>
                : <></>
            }
        </>

    )
}
