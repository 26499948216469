import React, { useMemo, useState } from 'react'

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide } from '@mui/material'
import { TransitionProps } from '@mui/material/transitions';
import { useNavigate } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { selectDirection, selectTranslations } from "../../features/i18n/i18nSlice";




const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
  ) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  

export const useYesNoDialog = (onYesSelected: Function) => {
    const [openDialog, setOpenDialog] = useState(false);
    const navigate = useNavigate();
    const langDirection = useAppSelector(selectDirection);
    const trans = useAppSelector(selectTranslations);




    const onDialogClickYes = () => {
        setOpenDialog(false);
        onYesSelected()
    };
    
    
      const onDialogClickNo = () => {
        setOpenDialog(false);
    
      };
    
      const onDialogOpen = () => {
        setOpenDialog(true);
      };



      const YesNoDialog = useMemo(() => {
      return () => (<Dialog
        open={openDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={onDialogClickNo}
        aria-describedby="alert-dialog-slide-description"
        sx={{ direction: langDirection }}
      >
        <DialogTitle>{trans.document_form.cancel_dialog_title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {trans.document_form.cancel_dialog_message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onDialogClickNo}>{trans.new_invoice.dialog.no_button}</Button>
          <Button onClick={onDialogClickYes}>{trans.new_invoice.dialog.yes_button}</Button>
        </DialogActions>
      </Dialog>)
      },[openDialog, onDialogClickNo, onDialogClickYes])

    
    return {
        YesNoDialog,
        onDialogOpen: () => onDialogOpen()
    };
  };