import React, { useEffect, useMemo, useRef, useState } from 'react'
import { LinearProgress, Paper, Button, Box, Container, Typography, Divider, AppBar, Toolbar } from '@mui/material';
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { selectDirection, selectTranslations } from "../i18n/i18nSlice";

import { selectPaletteMode } from '../appconfig/appConfigSlice';

import CloudSyncIcon from '@mui/icons-material/CloudSync';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useNavigate, Link } from "react-router-dom";


import { AgGridReact } from 'ag-grid-react'; // React Grid Logic
import "ag-grid-community/styles/ag-grid.css"; // Core CSS
import "ag-grid-community/styles/ag-theme-quartz.css"; // Theme
// import "ag-grid-community/styles/ag-theme-quartz-dark.css"; // Theme
// import "./styles.css"
import { ColDef, SizeColumnsToContentStrategy, GridReadyEvent, ValueFormatterFunc, IRowNode } from 'ag-grid-community';

import api from '../services/api';
import { Column } from 'react-data-grid';


interface ApiStatus {
    error: string;
    status: 'idle' | 'loading' | 'succeeded' | 'failed'
  }


export default function POSList() {

    const navigate = useNavigate();
    const dispatch = useAppDispatch()

    const gridRef = useRef<AgGridReact>(null);
    const paletteMode = useAppSelector(selectPaletteMode);

    const langDirection = useAppSelector(selectDirection);
    const trans = useAppSelector(selectTranslations);

    const [posList, setPosList] = React.useState([]);
    const [apiCallStatus, setApiCallStatus] = React.useState<ApiStatus>({ error: "", status: "idle" })
    const isLoading = () => apiCallStatus.status === "loading";
    const isSucceeded = () => apiCallStatus.status === "succeeded";
    const isIdle = () => apiCallStatus.status === "idle";
    const isFailed = () => apiCallStatus.status === "failed";

    var re = /T\d{2}:\d{2}:\d{2}Z/g;

    const onAssignClick = (id: number, posName: string) => {
        navigate(`/pos/${id}/${posName}`);
    }

    const onUnAssignClick = (id: number, posName: string) => {
      navigate(`pos/${id}/${posName}`);
  }
    


    const getPOSList = async () => {
        setApiCallStatus({ error: "", "status": "loading" })
        try {
          const response = await api.get(
            '/pos/notassigned',
            { headers: { "Content-Type": "application/json" } }
          )
    
          if (response.status === 200) {
    
            setPosList(response.data["pos_list"])
    
            setApiCallStatus({ error: "", "status": "succeeded" })
          } else {
            setApiCallStatus({ error: response.statusText, "status": "failed" })
          }
    
        } catch (err: any) {
          setApiCallStatus({ error: err.message, "status": "failed" })
        }
      };

    useEffect(() => {
      getPOSList()
    }, [])



    const CustomActionCellRenderer = (props: any) => {
      const { assigned, id, pos_name } = props.data;
      
      return (
        // <div style={{ color: assigned ? 'green' : 'red' }}>
        //   hello
        // </div>
        <Box> {!assigned ? (<Button  onClick={() => onAssignClick(id, pos_name)}>Assign</Button>): (<Button onClick={() => onUnAssignClick(id, pos_name)}>Unassign</Button>)}</Box>
      );
    };

    const components = useMemo(() => ({
      'CustomActionCellRenderer': CustomActionCellRenderer  }), []);



    const [colDefs, setColDefs] = useState<ColDef[]>(
      [

        { field: 'id', headerName: 'id' },
        { field: 'pos_name', headerName: 'POS Name'},
        { field: 'point_name', headerName: 'Point Name'},
        { field: 'assigned', headerName: "Assigned", },
        { field: 'action', headerName: 'Action', cellRenderer: CustomActionCellRenderer }
      ]
    );
  
    const defaultColDef = useMemo(() => ({
      cellStyle: { borderRight: '1px solid #bdc1c6' },
    }), [])
  
  
    const autoSizeStrategy: SizeColumnsToContentStrategy = {
      type: 'fitCellContents'
    };

  



    return (
        <Container maxWidth={false} sx={{margin:0, padding:0}}>
             {(isLoading()) && <LinearProgress />}
            {(isSucceeded() || isIdle() || isFailed()) && (
                <>
                <Box sx={{ display: 'flex', flexDirection: 'column', height: `calc(100vh - 128px)` }}>
                   

                    <div className={`${paletteMode === 'light' ? 'ag-theme-quartz' : 'ag-theme-quartz-dark'}`} style={{ height: "100%", width: "100%" }}>
              <AgGridReact
                ref={gridRef}
                rowData={posList}
                columnDefs={colDefs}
                defaultColDef={defaultColDef}
                enableRtl={langDirection === "rtl" ? true : false}
              />
            </div>

                    </Box>
                </>
            )}
        </Container>
    )
}
