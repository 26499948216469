import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { selectDirection, selectTranslations } from "../i18n/i18nSlice";
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { useLocation, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux';
import { height } from '@mui/system';
import LinearProgress from '@mui/material/LinearProgress';
import { useParams } from 'react-router-dom';
import SaveIcon from '@mui/icons-material/Save';
import PasswordIcon from '@mui/icons-material/Password';

import { AppBar, Dialog, Divider, FormControl, IconButton, InputLabel, List, ListItem, ListItemButton, ListItemIcon, ListItemText, MenuItem, Paper, Select, SelectChangeEvent, Toolbar } from '@mui/material';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateField } from '@mui/x-date-pickers';
import api from '../services/api';
import ResetPasswordDialog from './ResetPasswordDialog';


interface ApiStatus {
  error: string;
  status: 'idle' | 'loading' | 'succeeded' | 'failed'
}

interface UserData {
  id: number;
  username: string;
  mobile_phone: string;
  email: string;
  first_name: string;
  last_name: string;
  role: number;
}

interface UserRole {
  id: number;
  role_name: string;
}

type Props = {}
const theme = createTheme();
//{customerAccountId, customerType}: {customerAccountId: number, customerType: number}
export default function UserForm() {
  const params = useParams();
  const { userId } = params
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const [apiCallStatus, setApiCallStatus] = React.useState<ApiStatus>({ error: "", status: "idle" })
  const isLoading = () => apiCallStatus.status === "loading";
  const isSucceeded = () => apiCallStatus.status === "succeeded";
  const isIdle = () => apiCallStatus.status === "idle";
  const isFailed = () => apiCallStatus.status === "failed";
  const trans = useAppSelector(selectTranslations);
  const langDirection = useAppSelector(selectDirection);

  const [userData, setUserData] = React.useState<UserData>()
  const [userRoles, setUserRoles] = React.useState<UserRole[]>()
  
  const [changesNotSaved, setChangesNotSaved] = React.useState<boolean>(false);

  const [checked, setChecked] = React.useState([0]);
  const [showResetPasswordDialog, setShowResetPasswordDialog] = React.useState(false);
  const onResePasswordClicked = () => {
    setShowResetPasswordDialog(true)
  }


 

  const onInputValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserData({...userData, [event.target.name]: event.target.value} as UserData)
    setChangesNotSaved(true)
}

const onSelectChange = (event: SelectChangeEvent<number>, child: React.ReactNode) => {
setUserData({ ...userData, [event.target.name]: event.target.value }as UserData)
  setChangesNotSaved(true)
};



  const onUpdate = () => {

    updateUser()
  }


  const getUser = async () => {
    setApiCallStatus({ error: "", "status": "loading" })
    try {
      const response = await api.get(
        `/users/user/${userId}`,
        { headers: { "Content-Type": "application/json" } }
      )

      if (response.status === 200) {

        setUserData(response.data["user"])
        setUserRoles(response.data["roles"])

        setApiCallStatus({ error: "", "status": "succeeded" })
      } else {
        setApiCallStatus({ error: response.statusText, "status": "failed" })
      }

    } catch (err: any) {
      setApiCallStatus({ error: err.message, "status": "failed" })
    }
  };

  const updateUser = async () => {
    setApiCallStatus({ error: "", "status": "loading" })
    try {
      const response = await api.put(
        '/users/user',
        userData
        ,
        { headers: { "Content-Type": "application/json" } }
      )

      if (response.status === 200) {
        // setDocumentData(response.data["document"])
        setChangesNotSaved(false)
        setApiCallStatus({ error: "", "status": "succeeded" })
      } else {
        alert(response.data['msg'])
        setApiCallStatus({ error: response.statusText, "status": "failed" })
      }

    } catch (err: any) {
      alert(err.response.data['msg'])
      setApiCallStatus({ error: err.message, "status": "failed" })
    }
  };



  React.useEffect(() => {
    getUser()
  }, [])

  return (
    <Container>
  <AppBar position="static" sx={{ backgroundColor: "#283593" }}>
        <Toolbar component="nav" variant="dense" sx={{ justifyContent: 'space-between', overflowX: 'auto' }}  >
          <Typography component="h2" variant="h5">
            {`${trans.user_form.title} ${userData?.username}` }
          </Typography>
        </Toolbar>
      </AppBar>
      {(isLoading()) && <LinearProgress />}
      {(isSucceeded() || isIdle() || isFailed()) && (
        <>
          <Grid container>
              <Box sx={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                <TextField id="username" name="username"  label={trans.user_form.username} variant="standard" size='small'  value={userData?.username} />
                <TextField id="mobile_phone" name="mobile_phone" label={trans.user_form.mobile_phone} variant="standard" size='small' onChange={onInputValueChange} value={userData?.mobile_phone} />
                <TextField id="email" name="email" type='email' label={trans.user_form.email} variant="standard" size='small' onChange={onInputValueChange} value={userData?.email} />
                <TextField id="first_name" name="first_name" label={trans.user_form.first_name} variant="standard" size='small' onChange={onInputValueChange} value={userData?.first_name} />
                <TextField id="last_name" name="last_name" label={trans.user_form.last_name} variant="standard" size='small' onChange={onInputValueChange} value={userData?.last_name} />

              {userRoles && userRoles.length > 0 && userData?.role &&
                <FormControl variant="standard" fullWidth>
                    <InputLabel id="role" >{trans.user_form.role}</InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="role"
                      // defaultValue={'P'}
                      value={userData?.role}
                      name="role"
                      
                      label="Type"
                      onChange={onSelectChange}
                      
                      size='small'
                      sx={{ width: "100%" }}
                      >
                      {userRoles.map(userRole => (<MenuItem value={userRole.id} key={userRole.id}>{userRole.role_name}</MenuItem>))}
                    </Select>
                  </FormControl>
                    }
                  
              </Box>

          </Grid>

          <Paper elevation={1} sx={{ marginY: 2 }}>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', padding: 2, justifyContent: 'space-around' }}>
              <Button variant="contained" startIcon={<SaveIcon sx={{ marginRight: 0, marginLeft: 1 }} />} onClick={onUpdate} sx={{ margin: '2px' }} size="small" disabled={!changesNotSaved}>
                {trans.user_form.update_button}
              </Button>
              <Button variant="contained" startIcon={<PasswordIcon sx={{ marginRight: 0, marginLeft: 1 }} />} onClick={onResePasswordClicked} sx={{ margin: '2px' }} size="small" >
                {trans.user_form.reset_password}
              </Button>
            </Box>
          </Paper>

        </>)}


        <Dialog
            open={showResetPasswordDialog}
            keepMounted
            onClose={() => setShowResetPasswordDialog(false)}
            aria-describedby="alert-dialog-slide-description"
            sx={{ direction: langDirection }}
          >

            <ResetPasswordDialog openDialog = {showResetPasswordDialog} setOpenDialog = {setShowResetPasswordDialog} userId={userData?.id!} />
          </Dialog>

    </Container>
);
}