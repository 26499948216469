import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { selectDirection, selectTranslations } from "../i18n/i18nSlice";
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { useNavigate } from 'react-router-dom'
import LinearProgress from '@mui/material/LinearProgress';
import SaveIcon from '@mui/icons-material/Save';
import { AppBar, Divider, FormControl, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, Toolbar } from '@mui/material';
import api from '../services/api';
import { reSyncData } from '../login/loginSlice';
import { Store } from 'redux';
import { StoreHouse } from '../../models/StoreHouse';


interface ApiStatus {
  error: string;
  status: 'idle' | 'loading' | 'succeeded' | 'failed'
}


type Props = {}
const theme = createTheme();

export default function AddStore() {

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
   
  const [lookupObject, setLookupObject] = React.useState<StoreHouse>({
    id: 0,
    name: '',
    type: '1',
    cancelled: false
  } as StoreHouse)

  const [changesNotSaved, setChangesNotSaved] = React.useState<boolean>(false);

  const [apiCallStatus, setApiCallStatus] = React.useState<ApiStatus>({ error: "", status: "idle" })
  const isLoading = () => apiCallStatus.status === "loading";
  const isSucceeded = () => apiCallStatus.status === "succeeded";
  const isIdle = () => apiCallStatus.status === "idle";
  const isFailed = () => apiCallStatus.status === "failed";

  const trans = useAppSelector(selectTranslations);




  const onInputValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLookupObject({ ...lookupObject, [event.target.name]: event.target.value })
    setChangesNotSaved(true)
  }

  const onAdd = () => {
    addBroker()
  }


  const addBroker = async () => {
    setApiCallStatus({ error: "", "status": "loading" })
    try {
      const response = await api.post(
        '/store',
        lookupObject,
        { headers: { "Content-Type": "application/json" } }
      )

      if (response.status === 200) {
        dispatch(reSyncData())
        navigate(-1)
        setApiCallStatus({ error: "", "status": "succeeded" })
      } else {
        setApiCallStatus({ error: response.statusText, "status": "failed" })
      }

    } catch (err: any) {
      setApiCallStatus({ error: err.message, "status": "failed" })
    }
  };



  return (
    <ThemeProvider theme={theme}>
      <Container>
      <AppBar position="static" sx={{ backgroundColor: "#283593" }}>
        <Toolbar component="nav" variant="dense" sx={{ justifyContent: 'space-between', overflowX: 'auto' }}  >
          <Typography component="h2" variant="h5">
          {trans.lookup_form.store_add_title}
          </Typography>
        </Toolbar>
      </AppBar>

        
        {(isLoading()) && <LinearProgress />}
        {(isSucceeded() || isIdle() || isFailed()) && (
          <>
            <Grid container>
              <Grid item sm={12} md={6} lg={6} padding={2}>
                <Box sx={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                  <TextField id="name" name="name" label={trans.lookup_form.name} variant="standard" size='small' onChange={onInputValueChange} value={lookupObject.name} required />
                </Box>
              </Grid>
              <Grid item sm={12} md={6} lg={6} padding={2}>
                <Box sx={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                </Box>
              </Grid>
            </Grid>

            <Paper elevation={1} sx={{ marginY: 2 }}>
              <Box sx={{ display: 'flex', flexWrap: 'wrap', padding: 2, justifyContent: 'space-around' }}>
                <Button variant="contained" startIcon={<SaveIcon sx={{ marginRight: 0, marginLeft: 1 }} />} onClick={onAdd} sx={{ margin: '2px' }} size="small" disabled={!changesNotSaved}>
                  {trans.lookup_form.add_button}
                </Button>
              </Box>
            </Paper>

          </>)}
      </Container>
    </ThemeProvider>
  );
}