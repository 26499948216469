import React, { useContext, useEffect, createContext, useMemo, useState, useCallback } from 'react'
import { LinearProgress, Paper, Button, Box, Container, Typography, Divider, AppBar, Toolbar, TextField } from '@mui/material';
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { selectDirection, selectTranslations } from "../i18n/i18nSlice";

import CloudSyncIcon from '@mui/icons-material/CloudSync';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useNavigate, Link, useParams } from "react-router-dom";


import DataGrid, { Column, SortColumn, HeaderRendererProps, useFocusRef, headerRenderer as HeaderRenderer } from 'react-data-grid';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useDrag, useDrop } from 'react-dnd';



import { selectPaletteMode } from '../appconfig/appConfigSlice';
import { Customer } from '../../models/Customer';
import { db } from '../../db';
import api from '../services/api';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import * as XLSX from 'xlsx';
import { DraggableHeaderRenderer } from '../customer/DraggableHeaderRenderer';

interface ApiStatus {
  error: string;
  status: 'idle' | 'loading' | 'succeeded' | 'failed'
}


//*1-add model
interface Row {
  id: number;
  extended_docuemnt_id: number;
  document_date: string;
  document_type_name: string;
  document_type_id: number;
  amount: number;
}



//*2- add filter
interface Filter extends Omit<Row, 'id' | 'extended_docuemnt_id' | 'document_type_id' | 'amount'> {
  amount: number | undefined;
  enabled: boolean;
}
const FilterContext = createContext<Filter | undefined>(undefined);


export default function FinanceRep003() {
  const params = useParams();
  const { accountId, month, year } = params
  const navigate = useNavigate();
  const paletteMode = useAppSelector(selectPaletteMode);

  const dispatch = useAppDispatch()

  const langDirection = useAppSelector(selectDirection);
  const trans = useAppSelector(selectTranslations);
  // const rows = useAppSelector(customersReport);
  const [rows, setRows] = useState<Row[]>([])
  const [sortColumns, setSortColumns] = useState<readonly SortColumn[]>([]);

  const [apiCallStatus, setApiCallStatus] = React.useState<ApiStatus>({ error: "", status: "idle" })
  const isLoading = () => apiCallStatus.status === "loading";
  const isSucceeded = () => apiCallStatus.status === "succeeded";
  const isIdle = () => apiCallStatus.status === "idle";
  const isFailed = () => apiCallStatus.status === "failed";




  //*4- define get report
  const getReport = async () => {
    setApiCallStatus({ error: "", "status": "loading" })
    try {


      const response = await api.post(
        '/finance/rep02',
        {
          account_id: parseInt(accountId!),
          month: parseInt(month!),
          year: parseInt(year!)
        },
        { headers: { "Content-Type": "application/json" } }
      )

      if (response.status === 200) {

        setRows(response.data["report"])
        // setCurrentPage(response.data["userlogs"]["page_number"])

        setApiCallStatus({ error: "", "status": "succeeded" })
      } else {
        setApiCallStatus({ error: response.statusText, "status": "failed" })
      }

    } catch (err: any) {
      setApiCallStatus({ error: err.message, "status": "failed" })
    }
  };



  const classes = {
    filterColumnClassName: 'filter-cell',
  }


  const filterColumnClassName = 'filter-cell';
  const filterContainerClassname = `
    .${filterColumnClassName} {
      line-height: 35px;
      padding: 0;
      > div {
        padding-block: 0;
        padding-inline: 8px;
        &:first-child {
          border-block-end: 1px solid var(--rdg-border-color);
        }
      }
    }
  `;
  const filterClassname = `
    inline-size: 100%;
    padding: 4px;
    font-size: 14px;
  `;





  function inputStopPropagation(event: React.KeyboardEvent<HTMLInputElement>) {
    if (['ArrowLeft', 'ArrowRight'].includes(event.key)) {
      event.stopPropagation();
    }
  }

  //*5- set default values
  const [filters, setFilters] = useState<Filter>({
    document_date: '',
    document_type_name: '',
    amount: undefined,
    enabled: true
  });



  //*6- set fitlers and ordering
  const filteredRows = useMemo((): readonly Row[] => {

    if (sortColumns.length === 0) {
      let sortedRows: Row[] = rows.filter((r) => {
        return (
          (filters.document_date ? r.document_date.toLowerCase().includes(filters.document_date.toLowerCase()) : true) &&
          (filters.document_type_name ? r.document_type_name.toLowerCase().includes(filters.document_type_name.toLowerCase()) : true) &&
          (filters.amount !== undefined ? r.amount >= filters.amount : true)
        );
      });
      return sortedRows
    }
    const { columnKey, direction } = sortColumns[0];

    let sortedRows: Row[] = rows.filter((r) => {
      return (
        (filters.document_date ? r.document_date.toLowerCase().includes(filters.document_date.toLowerCase()) : true) &&
          (filters.document_type_name ? r.document_type_name.toLowerCase().includes(filters.document_type_name.toLowerCase()) : true) &&
          (filters.amount !== undefined ? r.amount >= filters.amount : true)

      );
    });


    switch (columnKey) {
      case 'document_date':
        sortedRows = sortedRows.sort((a, b) => a[columnKey].localeCompare(b[columnKey]));
        break;
      case 'document_type_name':
        sortedRows = sortedRows.sort((a, b) => a[columnKey].localeCompare(b[columnKey]));
        break;

      case 'amount':
        sortedRows = sortedRows.sort((a, b) => a[columnKey] - b[columnKey]);
        break;
      default:
        sortedRows = (sortedRows as any[]).sort((a, b) => a[columnKey] - b[columnKey]);
    }

    return direction === 'DESC' ? sortedRows.reverse() : sortedRows;
  }, [rows, filters, sortColumns]);


  const [columnsChanged, setColumnsChanged] = useState(false);

  const filteredColumns = useMemo((): readonly any[] => {

    function HeaderRenderer(props: HeaderRendererProps<Row>) {
      return <DraggableHeaderRenderer {...props} onColumnsReorder={handleColumnsReorder} />;
    }

    function handleColumnsReorder(sourceKey: string, targetKey: string) {
      const sourceColumnIndex = columns.findIndex((c) => c.key === sourceKey);
      const targetColumnIndex = columns.findIndex((c) => c.key === targetKey);
      const reorderedColumns = [...columns];

      reorderedColumns.splice(
        targetColumnIndex,
        0,
        reorderedColumns.splice(sourceColumnIndex, 1)[0]
      );

      setColumns(reorderedColumns);
      setColumnsChanged(!columnsChanged)
    }

    var additinalColumns: string[] = []
    if (rows.length > 0) {
      additinalColumns = Object.keys(rows[0]).filter(item => !['id', 'document_date', 'document_type_name', 'main_unit_quantity'].includes(item))
    }
    const addedClm = additinalColumns.map(clm => ({ key: clm, name: clm, resizable: true, sortable: true, width: 'max-content' }))


    //*7- set colums
    const fc = [
      // { key: 'id', name: 'id', resizable: true, sortable: true, width: 20 },
      { key: 'open', name: trans.nsi_table.open, resizable: true, sortable: true, width: 20, formatter({ row }: { row: any }) { return <Button style={{ width: 32, minWidth: 32 }} onClick={() => onOpenClick(row.id!, row.extended_docuemnt_id!, row.document_type_id!)}>{trans.nsi_table.open}</Button> } },
      {
        key: 'document_date', name: trans.expense_rep1.document_date, resizable: true, sortable: true, width: 'max-content',
        headerCellClass: filterColumnClassName,
        headerRenderer: (p: HeaderRendererProps<Row>) => (

          <FilterRenderer<Row, unknown, HTMLInputElement> {...p} onColumnsReorder={handleColumnsReorder}>

            {({ filters, ...rest }) => (
              <>
                <HeaderRenderer {...p}></HeaderRenderer>
                <input
                  {...rest}
                  className={filterClassname!}
                  // variant='outlined'
                  style={{ inlineSize: "100%", padding: "4px", fontSize: "14px" }}
                  value={filters.document_date}
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      document_date: e.target.value
                    })
                  }
                  onKeyDown={inputStopPropagation}
                />
              </>
            )}
          </FilterRenderer>
        )
      },
      {
        key: 'document_type_name', name: trans.expense_rep1.document_type_name, resizable: true, sortable: true, width: 'max-content',
        headerCellClass: filterColumnClassName,
        headerRenderer: (p: HeaderRendererProps<Row>) => (

          <FilterRenderer<Row, unknown, HTMLInputElement> {...p} onColumnsReorder={handleColumnsReorder}>

            {({ filters, ...rest }) => (
              <>
                <HeaderRenderer {...p}></HeaderRenderer>
                <input
                  {...rest}
                  className={filterClassname!}
                  // variant='outlined'
                  style={{ inlineSize: "100%", padding: "4px", fontSize: "14px" }}
                  value={filters.document_type_name}
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      document_type_name: e.target.value
                    })
                  }
                  onKeyDown={inputStopPropagation}
                />
              </>
            )}
          </FilterRenderer>
        )
      },
      {
        key: 'amount', name: trans.expense_rep1.amount, resizable: true, sortable: true, width: 100,
        headerCellClass: filterColumnClassName,
        summaryFormatter({ row }: { row: any; }): JSX.Element {
          return <>{row.amount}</>;
        },
        headerRenderer: (p: HeaderRendererProps<Row>) => (
          <FilterRenderer<Row, unknown, HTMLInputElement> {...p} onColumnsReorder={handleColumnsReorder}>
            {({ filters, ...rest }) => (
              <>
                <HeaderRenderer {...p}></HeaderRenderer>
                <input
                  {...rest}
                  type="number"
                  style={{ inlineSize: "100%", padding: "4px", fontSize: "14px" }}
                  value={filters.amount}
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      amount: Number.isFinite(e.target.valueAsNumber)
                        ? e.target.valueAsNumber
                        : undefined
                    })
                  }
                  onKeyDown={inputStopPropagation}
                />
              </>
            )}
          </FilterRenderer>)
      },
     
    ]
    return fc
  }, [columnsChanged, rows])


  const [columns, setColumns] = useState(filteredColumns);


  //*8- set on click open
  const onOpenClick = (id: number, extended_docuemnt_id: number, objectType: number) => {
    if (objectType === 1 || objectType === 2 || objectType === 3 || objectType === 4 || objectType === 5 || objectType === 6 || objectType === 7 || objectType === 8 || objectType === 9 || objectType === 10) {
      navigate("/invoice/" + extended_docuemnt_id.toString());
    }
    if (objectType === 11 || objectType === 12 || objectType === 13 || objectType === 14) {
      navigate("/customer/document/" + id.toString());
    }
    if (objectType === 15) {
      navigate("/expense/" + id.toString());
    }
    if (objectType === 16) {
      navigate("/customer/" + id.toString());
    }
    if (objectType === 17) {
      navigate("/store/" + id.toString());
    }
    if (objectType === 18) {
      navigate("/productcategory/" + id.toString());
    }
    if (objectType === 19) {
      navigate("/expensetype/" + id.toString());
    }
    if (objectType === 20) {
      navigate("/broker/" + id.toString());
    }
    if (objectType === 21) {
      navigate("/salesperson/" + id.toString());
    }
    if (objectType === 22) {
      navigate("/product/" + id.toString());
    }
    // if(objectType === 23){
    //   navigate("/expense/" + id.toString());
    // }
    if (objectType === 24) {
      navigate("/colors/" + id.toString());
    }
    if (objectType === 25) {
      navigate("/sizes/" + id.toString());
    }
  }

  useEffect(() => {
    getReport()
  }, [])


  const onSortColumnsChange = useCallback((sortColumns: SortColumn[]) => {
    setSortColumns(sortColumns.slice(-1));
  }, []);


  const draggableColumns = useMemo((): readonly Column<Row>[] => {
    function HeaderRenderer(props: HeaderRendererProps<Row>) {
      return <DraggableHeaderRenderer {...props} onColumnsReorder={handleColumnsReorder} />;
    }

    function handleColumnsReorder(sourceKey: string, targetKey: string) {
      const sourceColumnIndex = columns.findIndex((c) => c.key === sourceKey);
      const targetColumnIndex = columns.findIndex((c) => c.key === targetKey);
      const reorderedColumns = [...columns];

      reorderedColumns.splice(
        targetColumnIndex,
        0,
        reorderedColumns.splice(sourceColumnIndex, 1)[0]
      );

      setColumns(reorderedColumns);
    }

    return columns.map((c) => {
      if (c.key === 'id') return c;
      return { ...c, headerRenderer: HeaderRenderer };
    });
  }, [columns]);




  //*3-add download mapping
  const getPrintedData = () => {
    return filteredRows.map(item => {

      return ({
        id: item.id,
        [trans.expense_rep1.document_date]: item["document_date"],
        [trans.expense_rep1.document_type_name]: item["document_type_name"],
        [trans.expense_rep1.amount]: item["amount"],
      })
    })
  }

  const downloadExcel = (data: any) => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "DataSheet1.xlsx");
  };

  const summaryRows = useMemo(() => {
    const summaryRow: any = {
      amount: (filteredRows.reduce((acc, item) => parseFloat(acc.toString()) + parseFloat(item.amount.toString()), 0)).toFixed(2)
      // balance: filteredRows.reduce((acc, item) => acc + item.balance, 0)
    };
    return [summaryRow];
  }, [filteredRows]);

  return (
    <Container maxWidth={false} sx={{margin:0, padding:0}}>
      {isLoading() && <LinearProgress />}
      {isSucceeded() && (
        <>
          <Box sx={{ display: 'flex', flexDirection: 'column', height: `calc(100vh - 128px)` }}>
            <Box>

            </Box>
            <Box>

              <AppBar position="static" sx={{ backgroundColor: "#283593" }}>
                <Toolbar component="nav" variant="dense" sx={{ justifyContent: 'space-between', overflowX: 'auto' }}  >
                  <Typography component="h2" variant="h5">
                    {trans.expense_rep1.transactions}
                  </Typography>
                </Toolbar>
              </AppBar>
            </Box>
            <DndProvider backend={HTML5Backend}>
              <FilterContext.Provider value={filters}>
                <DataGrid
                  className={`${paletteMode === 'light' ? 'rdg-light' : 'rdg-dark'} ${filters.enabled ? filterContainerClassname : undefined}`}
                  columns={filteredColumns}
                  rows={filteredRows}
                  headerRowHeight={filters.enabled ? 70 : undefined}
                  direction={langDirection === "rtl" ? "rtl" : "ltr"}

                  style={{ flexGrow: 1, overflow: "auto" }}
                  bottomSummaryRows={summaryRows}
                  sortColumns={sortColumns}
                  onSortColumnsChange={onSortColumnsChange}
                />
              </FilterContext.Provider>
            </DndProvider>
          </Box>
          {rows.length != 0 ?
            <Paper elevation={3} sx={{ marginBottom: 1, padding: 1, direction: langDirection }}>
              <Button variant="contained" startIcon={<FileDownloadIcon sx={{ marginRight: 0, marginLeft: 1 }} />} sx={{ marginX: 1 }} onClick={() => downloadExcel(getPrintedData())} size="small">
                Download
              </Button>
            </Paper>
            : null}
        </>
      )}
    </Container>
  )
}



interface DraggableHeaderRendererProps<R, SR> extends HeaderRendererProps<R, SR> {
  onColumnsReorder: (sourceKey: string, targetKey: string) => void;
}


function FilterRenderer<R, SR, T extends HTMLOrSVGElement>({
  onColumnsReorder,
  isCellSelected,
  column,
  children,
  ...props
}: DraggableHeaderRendererProps<R, SR> & {
  children: (args: {
    ref: React.RefObject<T>;
    tabIndex: number;
    filters: Filter;
  }) => React.ReactElement;
}) {
  const filters = useContext(FilterContext)!;
  const { ref, tabIndex } = useFocusRef<T>(isCellSelected);
  const [{ isDragging }, drag] = useDrag({
    type: 'COLUMN_DRAG',
    item: { key: column.key },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  });

  const [{ isOver }, drop] = useDrop({
    accept: 'COLUMN_DRAG',
    drop({ key }: { key: string }) {
      onColumnsReorder(key, column.key);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  });

  return (
    <>
      <div
        ref={(ref) => {
          drag(ref);
          drop(ref);
        }}
        style={{
          opacity: isDragging ? 0.5 : 1,
          backgroundColor: isOver ? '#ececec' : undefined,
          cursor: 'move'
        }}
      >
        {filters!.enabled && <div>{children({ ref, tabIndex, filters })}</div>}
      </div>
    </>
  );
}


