import React from 'react'
import PrimaryAppBar from '../features/appBar/PrimaryAppBar'
import MiniDrawer from '../features/drawer/MiniDrawer'
import { Box, Container, CssBaseline, PaletteMode, ThemeProvider, Toolbar } from '@mui/material'
import { useAppSelector, useAppDispatch } from "../app/hooks";
import { selectDirection } from "../features/i18n/i18nSlice";
import {selectPaletteMode } from "../features/appconfig/appConfigSlice";
import { createTheme } from "@mui/material/styles";
import { Outlet } from 'react-router-dom';

export default function Page2(): JSX.Element {
  const langDirection = useAppSelector(selectDirection);
  const paletteMode = useAppSelector(selectPaletteMode);

  const theme = createTheme({
    palette: {
      mode: paletteMode as PaletteMode,
    },
  });

  return (
    <ThemeProvider theme={theme}>
    <Box sx={{ direction: langDirection, display: "flex" }}>
        <CssBaseline />
    <PrimaryAppBar />
    <MiniDrawer />
    <Box
          component="main"
          sx={{
            // backgroundColor: (theme) =>
            //   theme.palette.mode === "light"
            //     ? theme.palette.grey[100]
            //     : theme.palette.grey[900],
              flexGrow: 1,
              height: '100vh',
              overflow: 'auto',
           
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          <Outlet />
  
            
          </Container>
        </Box>
    </Box>
    </ThemeProvider>
  )
}
