import * as React from 'react';
import Box from '@mui/material/Box';
// import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { Theme, styled } from '@mui/material/styles';
import {selectPaletteMode } from "../../features/appconfig/appConfigSlice";


import 'react-data-grid/lib/styles.css';
import DataGrid, {Column, SortColumn, HeaderRendererProps, textEditor, RowsChangeData } from 'react-data-grid';
import { useAppSelector } from '../../app/hooks';



type Direction = 'ltr' | 'rtl';
interface SummaryRow {
  id: string;
  amount: number;
}

interface ExpenseData {
  itemHash: string;
  finance_account_id: number;
  finance_account_name: string;
  amount: number;
  description: string;
}



type Props = {
  rows: ExpenseData[];
  columns: Column<ExpenseData, SummaryRow>[];
  onRowSelectionChanged: Function;
  direction: Direction;
  selectedRows: ReadonlySet<string>;
  onRowsChange: (rows: ExpenseData[], data: RowsChangeData<ExpenseData, SummaryRow>) => void;
};



export default function ExpenseDatagrideEditable({ rows, columns, onRowSelectionChanged, direction, selectedRows, onRowsChange}: Props) {

  // const [selectedRows, setSelectedRows] = React.useState<ReadonlySet<string>>(() => new Set());
  const paletteMode = useAppSelector(selectPaletteMode);

  const onRowSelected = (newSelection: any) => {
    onRowSelectionChanged(newSelection)
    // setSelectedRows(newSelection)
  }

  function rowKeyGetter(row: ExpenseData) {
    return row.itemHash;
  }
  

  return (
    <Box >
      <DataGrid
        rows={rows}
        columns={columns}
        direction={direction}
        onRowsChange={onRowsChange}

        rowKeyGetter={rowKeyGetter}
        selectedRows={selectedRows}
        onSelectedRowsChange={onRowSelected}
        className={paletteMode === 'light'? 'rdg-light': 'rdg-dark'}
        style={{fontSize:22}}
        rowHeight={44}                
        
      />
    </Box>
  );
}
