import axios from "axios";
import {store} from '../../app/store';
import {updateToken, setAppOffline} from '../login/loginSlice'


const instance = axios.create({
  // baseURL: "http://localhost:3000",
  // baseURL: "https://msh.softpuzzles.com/speinv",
  // baseURL: "https://sp.softpuzzles.com:8082",
  baseURL: process.env.REACT_APP_APU_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

instance.interceptors.request.use(
  (config) => {
    const state = store.getState();
    const token = state.login.accessToken;
    config.headers["Accept-Language"] = state.i18n.lang;

    if(config.url.includes("/user/") || config.url.includes('/users/')){
      config.baseURL = process.env.REACT_APP_APU_URL;
    }else{
      config.baseURL = state.login.envUrl.slice(0, -1);
    }
    
    if (token) {
      // if(config.url === '/refresh'){
      //   config.headers["Authorization"] = 'Bearer ' + state.login.refreshToken;  // for Spring Boot back-end
      // }else{
        config.headers["Authorization"] = 'Bearer ' + token;  // for Spring Boot back-end
      // }
      

    //   config.headers["x-access-token"] = token; // for Node.js Express back-end
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;
    const state = store.getState();
    if (!originalConfig.url.endsWith("/user/login") && err.response) {
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;
        const refreshToken = state.login.refreshToken;

        try {
          const rs = await axios.get(originalConfig.baseURL + "/refresh/new"
          ,{
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + refreshToken
            }
          }
          );

          const { access_token } = rs.data;
            store.dispatch(updateToken(access_token))
        //   TokenService.updateLocalAccessToken(accessToken);

          return instance(originalConfig);
        } catch (_error) {
          return Promise.reject(_error);
        }
      }
    }

    // if ((err.code === 'ERR_NETWORK' || err.code === 'ERR_BAD_REQUEST') && err.status !== 409) {
    if (err.code === 'ERR_NETWORK') {
      if(state.login.online){
        store.dispatch(setAppOffline());
      }
    }

    return Promise.reject(err);
  }
);

export default instance;