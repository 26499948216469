import React, { useEffect } from 'react'
import { LinearProgress, Paper, Button, Box, Container, Typography, Divider, AppBar, Toolbar } from '@mui/material';
import { useAppSelector, useAppDispatch } from "../../app/hooks";

import CloudSyncIcon from '@mui/icons-material/CloudSync';
import RefreshIcon from '@mui/icons-material/Refresh';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

import { useNavigate, Link } from "react-router-dom";


import { Column } from 'react-data-grid';
import { Order } from '../../models/Order';
import RepDataGride from '../../components/RepDataGride';
import * as XLSX from 'xlsx';
import { selectDirection, selectTranslations } from '../../features/i18n/i18nSlice';
import { draftStock } from '../../features/product/productSlice';


interface ApiStatus {
  error: string;
  status: 'idle' | 'loading' | 'succeeded' | 'failed'
}


export default function DraftAvailableStock() {

  const navigate = useNavigate();
  const dispatch = useAppDispatch()

  const langDirection = useAppSelector(selectDirection);
  const trans = useAppSelector(selectTranslations);

  const report = useAppSelector(draftStock);

  const [apiCallStatus, setApiCallStatus] = React.useState<ApiStatus>({ error: "", status: "idle" })
  const isLoading = () => apiCallStatus.status === "loading";
  const isSucceeded = () => apiCallStatus.status === "succeeded";
  const isIdle = () => apiCallStatus.status === "idle";
  const isFailed = () => apiCallStatus.status === "failed";


  const getPrintedData = () => {
    return report.map(item => ({
      id: item["id"],
      [trans.stock_rep1.product_name]: item["product_name"],
      [trans.stock_rep1.store_name]: item["store_name"],
      [trans.stock_rep1.main_unit_quantity]: item["main_unit_quantity"],
      [trans.stock_rep1.available_stock]: item["available_stock"]
    }))
  }
  const downloadExcel = (data: any) => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "DataSheet1.xlsx");
  };





  const getColumns = (): Column<Order>[] => {
    return [
      { key: 'id', name: 'id', resizable: true, width: 20 },
      { key: 'product_name', name: trans.stock_rep1.product_name, resizable: true, width: 350 },
      { key: 'store_name', name: trans.stock_rep1.store_name, resizable: true, width: 150 },
      { key: 'main_unit_quantity', name: trans.stock_rep1.main_unit_quantity, resizable: true, width: 150 },
      { key: 'available_stock', name: trans.stock_rep1.available_stock, resizable: true, width: 150 },
    ];
  }



  return (
    <Container maxWidth={false} sx={{margin:0, padding:0}}>
      {(isLoading()) && <LinearProgress />}
      {(isSucceeded() || isIdle() || isFailed()) && (
        <>
          

          <AppBar position="static" sx={{ backgroundColor: "#283593" }}>
            <Toolbar component="nav" variant="dense" sx={{ justifyContent: 'space-between', overflowX: 'auto' }}  >
              <Typography component="h2" variant="h5">
                {trans.stock_rep1.title_draft_available_stock}
              </Typography>
            </Toolbar>
          </AppBar>

          <RepDataGride report={report} columns={getColumns()} direction={langDirection === "rtl" ? "rtl" : "ltr"} />
          {report.length != 0 ?
                <Paper elevation={3} sx={{ marginBottom: 1, padding: 1, direction: langDirection }}>
                <Button variant="contained" startIcon={<FileDownloadIcon sx={{ marginRight: 0, marginLeft: 1 }}/>} sx={{ marginX: 1 }} onClick={() => downloadExcel(getPrintedData())} size="small">
                Download
              </Button>
            </Paper>
            : null}
        </>
      )}
    </Container>
  )
}
