import React, { useEffect, useMemo, useState, useCallback, useRef } from 'react';


import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { selectDirection, selectTranslations } from "../../features/i18n/i18nSlice";

import { selectPaletteMode } from '../../features/appconfig/appConfigSlice'
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import * as XLSX from 'xlsx';
import { AgGridReact } from 'ag-grid-react'; // React Grid Logic
import "ag-grid-community/styles/ag-grid.css"; // Core CSS
import "ag-grid-community/styles/ag-theme-quartz.css"; // Theme
// import "ag-grid-community/styles/ag-theme-quartz-dark.css"; // Theme
import "./styles.css"
import { ColDef, GridOptions, SizeColumnsToContentStrategy, GridReadyEvent, ValueFormatterFunc, IRowNode, RowStyle } from 'ag-grid-community';
import { InvoiceItemWithHash } from './useDraft';
import { Box } from '@mui/material';



type Props = {
  rows: InvoiceItemWithHash[];
  columns: any[];
  onRowSelectionChanged: Function;
};


export default function InvoiceAGDatagride({ rows, columns, onRowSelectionChanged }: Props) {

  // const useRepoAG = <Row extends Record<string, any>>(columnsInfo: Record<string, any>[], transRep: string, getReport: (setRowsFun: React.Dispatch<React.SetStateAction<Row[]>>, setApiCallStatusFun: React.Dispatch<React.SetStateAction<ApiStatus>>) => Promise<void>, onOpenClick?: (id: number) => void, openParameterName?: string) => {

  const gridRef = useRef<AgGridReact>(null);
  const paletteMode = useAppSelector(selectPaletteMode);

  const langDirection = useAppSelector(selectDirection);
  const trans = useAppSelector(selectTranslations);

  // set background colour on every row, this is probably bad, should be using CSS classes
  const rowStyle = { background: '' };

  // set background colour on even rows again, this looks bad, should be using CSS classes
  const getRowStyle = (params: any): RowStyle => {
    // if (params.node.rowIndex % 2 === 0) {
    //     return { background: 'red' };
    // }
    // return { border: '1px solid #bdc1c6' };

    if (!params.node.isRowPinned()) {
      return { borderBottom: '1px solid #bdc1c6' };
    } else {
      return { borderBottom: '1px solid #bdc1c6', background: paletteMode === 'light' ? '#e8eaf6' : '#283593' };
    }
  };

  // function formatNumber (number: number):ValueFormatterFunc {
  //   // this puts commas into the number eg 1000 goes to 1,000,
  //   // i pulled this from stack overflow, i have no idea how it works
  //   return Math.floor(number)
  //     .toString()
  //     .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  // }

  function formatNumber(params: { value: number }): string {
    // this puts commas into the number eg 1000 goes to 1,000,
    // i pulled this from stack overflow, i have no idea how it works
    return (params.value?.toString() || '')
      // .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
      // .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
      // .replace(/\B(?=(\d{3})+(?!\d))/g, '$1,');
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  }

  function customValueFormatter(params: { value: any }): string {
    // You can perform any custom formatting logic here
    const formattedValue = `$${params.value.toFixed(2)}`; // Example: Format as currency

    return formattedValue;
  }



  // Column Definitions: Defines & controls grid columns.
  const [colDefs, setColDefs] = useState<ColDef[]>(columns);
  // const defaultColDef = {
  //   resizable: true,
  //   initialWidth: 200,
  //   wrapHeaderText: true,
  //   autoHeaderHeight: true,
  // }

  const [gridOptions] = useState<GridOptions<any> | undefined>({
    rowSelection: 'multiple',
    suppressRowClickSelection: true,
  });

  const defaultColDef = useMemo(() => ({
    // floatingFilter: true,
    // cellStyle: { borderRight: '1px solid #bdc1c6' },
    autoHeaderHeight: true,
  }), [])


  const autoSizeStrategy: SizeColumnsToContentStrategy = {
    type: 'fitCellContents'
  };


  const onGridReady = useCallback((event: GridReadyEvent) => {
    event.api.hideOverlay();

    // setTimeout(()=>{
    //   let pinnedBottomData = generatePinnedBottomData();
    //   // event.api.setPinnedBottomRowData([pinnedBottomData]);
    //   gridRef.current!.api.updateGridOptions({pinnedBottomRowData:[pinnedBottomData]});
    // }, 500)

  }, []);

const onSelectionChanged =  () => {
  const selectedRows = gridRef.current!.api.getSelectedRows();
  const selectedRowsSet: ReadonlySet<string> =  new Set(selectedRows.map(item => item.itemHash));

  onRowSelectionChanged(selectedRowsSet)
  // console.log('Selected rows:', selectedRows);
  // Add your custom logic here
}


function generatePinnedBottomData(){
  // generate a row-data with null values
  let result: Record<string, any>  = {};

  if( gridRef.current !== null){
    gridRef.current!.api.getAllGridColumns().forEach(item => {
        result[item.getId()] = null;
    });
    return calculatePinnedBottomData(result);
  }else{
    return {}
  }

}


const calculatePinnedBottomData = (target: any) => {
//console.log(target);
//**list of columns fo aggregation**
let columnsWithAggregation = ['quantity', 'total', 'discount']
columnsWithAggregation.forEach(element => {
  // console.log('element', element);
  gridRef.current!.api.forEachNodeAfterFilter((rowNode: IRowNode<any>, index: number) => {
      //if(rowNode.index < 10){
        //console.log(rowNode);
      //}
        if (rowNode.data[element])
            target[element] += Number(parseFloat(rowNode.data[element]).toFixed(2));
    });
    if (target[element])
        target[element] =  Number(target[element].toFixed(2));
})
//console.log(target);
return target;
}

  return (
    <div className={`${paletteMode === 'light' ? 'ag-theme-quartz' : 'ag-theme-quartz-dark'}`} style={{ height: "100%", width: "100%", minHeight: "400px" }}>
      <AgGridReact
        ref={gridRef}
        rowData={rows}
        columnDefs={colDefs}
        defaultColDef={defaultColDef}
        enableRtl={langDirection === "rtl" ? true : false}
        // enableRangeSelection={true}
        enableCellTextSelection={true}
        // rowStyle={rowStyle}
        getRowStyle={getRowStyle}

        // autoSizeStrategy={autoSizeStrategy}
        onGridReady={onGridReady}
        pinnedBottomRowData = {[{...generatePinnedBottomData()}]}

        onRowDataUpdated={() => {
          let pinnedBottomData = generatePinnedBottomData();
          // gridRef.current!.api.setPinnedBottomRowData([pinnedBottomData]);
          gridRef.current!.api.updateGridOptions({pinnedBottomRowData:[pinnedBottomData]});
        }}

        selection={{
          mode: 'multiRow',
          headerCheckbox: true,
          checkboxes:true,
          enableClickSelection: true,
          
        }}
        // rowSelection={'multiple'}
        // suppressRowClickSelection={true}
        onSelectionChanged={onSelectionChanged}
      />
    </div>
  )

}