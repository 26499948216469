import React, { useEffect } from 'react'

import { useAppSelector } from "../../app/hooks";
import { selectDirection, selectTranslations } from '../../features/i18n/i18nSlice';
import api from '../../features/services/api';


import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { NavLink } from 'react-router-dom';

import { AgGridReact } from 'ag-grid-react'; // React Grid Logic
import "ag-grid-community/styles/ag-grid.css"; // Core CSS
import "ag-grid-community/styles/ag-theme-quartz.css"; // Theme
import { ColDef, SizeColumnsToContentStrategy, GridReadyEvent, ValueFormatterFunc, IRowNode, RowStyle } from 'ag-grid-community';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { YearCalendar } from '@mui/x-date-pickers/YearCalendar';

import { selectPaletteMode } from '../../features/appconfig/appConfigSlice'
import { Box, Container } from '@mui/material';
import { convertLength } from '@mui/material/styles/cssUtils';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';


interface ReportItem {
  id: number;
  account_id: number;
  account_type_name: string;
  account_name: string;
  current_amount: number;
  total_change: number;
  [key: string]: any;

}
interface ApiStatus {
  error: string;
  status: 'idle' | 'loading' | 'succeeded' | 'failed'
}

export default function FinanceMonthlyRepProfitAg() {

  const langDirection = useAppSelector(selectDirection);
  const trans = useAppSelector(selectTranslations);
  const paletteMode = useAppSelector(selectPaletteMode);

  const [repDate, setRepDate] = React.useState(new Date());

  const [report, setReport] = React.useState<ReportItem[]>([]);
  // const [columns, setColumns] = React.useState<{ field: string, [key: string]: any; }[]>([]);
  const [colDefs, setColDefs] = React.useState<ColDef[]>()
  const defaultColDef = React.useMemo(() => {
    return {
      sortable: false
    };
  }, []);
  const [apiCallStatus, setApiCallStatus] = React.useState<ApiStatus>({ error: "", status: "idle" })
  const isLoading = () => apiCallStatus.status === "loading";
  const isSucceeded = () => apiCallStatus.status === "succeeded";
  const isIdle = () => apiCallStatus.status === "idle";
  const isFailed = () => apiCallStatus.status === "failed";


  const onDateChange = (newValue: Date | null) => {
    setRepDate(newValue!)
    getReport(newValue!.getFullYear())
  };

  const setProfit = (reportData: ReportItem[]) => {

    const expense = reportData.find(item => item.id === 5)
    const income = reportData.find(item => item.id === 4)
    const newItem = { ...income, account_type_name: trans.finance_eports.profit, account_name: trans.finance_eports.profit, ...Object.assign({}, {}, ...Object.keys(income!).map((key) => key.toLowerCase().includes("change") ? { [key]: (income![key] - expense![key]).toFixed(2) } : null)) }

    // const newRep = reportData.map(item => {
    //   if (item.id === 5) return
    //   if (item.id === 4) {
    //     const newItem = { ...item, account_type_name: trans.finance_eports.profit, account_name: trans.finance_eports.profit, ...Object.assign({}, {}, ...Object.keys(item).map((key) => key.toLowerCase().includes("change") ? { [key]: (item[key] - expense![key]).toFixed(2) } : null)) }
    //     return { ...newItem, current_amount: newItem.total_change }
    //   }

    //   return item
    // }).filter(item => item !== undefined)

   

    const newRep = reportData.map(item => {
      if (item.id === 5) return
      if (item.id === 4) return
      return item
    }).filter(item => item !== undefined).concat(

      [{ ...newItem, current_amount: newItem.total_change }]
    )


    return newRep

    // let newRep = report.map(item => item.id !== 5)

  }

  const getReport = async (reportYear:number) => {
    setApiCallStatus({ error: "", "status": "loading" })
    try {
      const response = await api.get(
        `/finance/rep01/${reportYear}`,
        { headers: { "Content-Type": "application/json" } }
      )

      if (response.status === 200) {
        //create column before set data to make gride not loading forever
        createRepColumns(response.data["report"][0])
        console.log(colDefs)
        setReport(setProfit(response.data["report"]) as ReportItem[])

        setApiCallStatus({ error: "", "status": "succeeded" })
      } else {
        setApiCallStatus({ error: response.statusText, "status": "failed" })
      }

    } catch (err: any) {
      setApiCallStatus({ error: err.message, "status": "failed" })
    }
  };

  const createRepColumns = (reportItem: any) => {
    console.log(reportItem)
    const cols = [
      { field: "account_type_name", headerName: "Type" },
      { field: "account_name", headerName: "Account" },
      {
        field: "current_amount", headerName: "Current Amount"
        , cellStyle: function (params: any) {
          if (parseFloat(params.node.data.current_amount) > 0) {
            return { backgroundColor: '#33cc3344' };
          }
          if (parseFloat(params.node.data.current_amount) < 0) {
            return { backgroundColor: '#cc222244' };
          }
        }
      },
      {
        field: "total_change", headerName: "Total Change", cellStyle: function (params: any) {
          if (parseFloat(params.node.data.total_change) > 0) {
            return { backgroundColor: '#33cc3344' };
          }
          if (parseFloat(params.node.data.total_change) < 0) {
            return { backgroundColor: '#cc222244' };
          }
        }
      },
    ]
      .concat(
        Object.keys(reportItem).filter((key) => key.toLowerCase().includes("change") && !key.toLowerCase().includes("total_change")).map((key) => ({
          field: key, headerName: (key.replace("_", " "))
          , cellStyle: function (params: any) {
            if (parseFloat(params.node.data[key]) > 0) {
              return { backgroundColor: '#33cc3344' };
            }
            if (parseFloat(params.node.data[key]) < 0) {
              return { backgroundColor: '#cc222244' };
            }
          }
          , cellRenderer: (params: any) => {
            // put the value in bold
            return <NavLink
              to={`/finance/rep03/${params.node.data.account_id}/${key.toLowerCase().replace('change', '').trim()}/${repDate.getFullYear()}`}>
              {params.node.data[key]}
            </NavLink>
          }
        }))
      )

    setColDefs(
      cols
    )


  }


  useEffect(() => {
    getReport(repDate.getFullYear())
  }, [])

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: `calc(100vh - 128px)` }}>
      <Box>
        <LocalizationProvider dateAdapter={AdapterDateFns} >
          <DatePicker
            views={["year"]}
            label="Year"
            value={repDate}
            onChange={onDateChange}

          />
        </LocalizationProvider>
      </Box>
      <div className={`${paletteMode === 'light' ? 'ag-theme-quartz' : 'ag-theme-quartz-dark'}`} style={{ height: "100%", width: "100%" }}>

        <AgGridReact
          defaultColDef={defaultColDef}
          rowData={report}
          columnDefs={colDefs}
          enableRtl={langDirection === "rtl" ? true : false}
        />
      </div>
    </Box>


  )
}
