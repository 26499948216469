import React, { useMemo, useState } from 'react'

import { Autocomplete, AutocompleteChangeReason, Button, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide, TextField } from '@mui/material'
import { useNavigate } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { selectDirection, selectTranslations } from "../../features/i18n/i18nSlice";
import { db } from '../../db';

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import dayjs, { Dayjs } from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateField } from '@mui/x-date-pickers/DateField';



export const useSelectDateRangeDialog = (onDateRangeSelected: Function) => {
  const [openDialog, setOpenDialog] = useState(false);
  const trans = useAppSelector(selectTranslations);
  const [dialogReturn, setDialogReturn] = useState('');
  const [dateRange, setDateRange] = React.useState<Dayjs | null>(dayjs('2022-04-17'));
  const [selectedDateRangeId, setSelectedDateRangeId] = React.useState<number>();
  const [selectedDateRange, setSelectedDateRange] = React.useState();



  const onDialogClickYes = () => {
    setOpenDialog(false);
    onDateRangeSelected(selectedDateRange)
  };


  const onDialogClickNo = () => {
    setOpenDialog(false);

  };

  const onDialogOpen = async () => {
    setDialogReturn('')
    setOpenDialog(true);
  };


  const SelectDialog = useMemo(() => {
    return () => (
      <>
        <DialogTitle>{trans.new_invoice.dialog.delete_title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {trans.new_invoice.dialog.delete_message}
          </DialogContentText>


          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['DateField', 'DateField']}>
              <DateField
                label="Dash separator"
                value={dateRange}
                onChange={(newValue) => setDateRange(newValue)}
                format="MM-DD-YYYY"
              />
              <DateField
                label="Full letter month"
                value={dateRange}
                onChange={(newValue) => setDateRange(newValue)}
                format="LL"
              />
            </DemoContainer>
          </LocalizationProvider>


        </DialogContent>
        <DialogActions>
          <Button onClick={onDialogClickNo}>{trans.new_invoice.dialog.no_button}</Button>
          <Button onClick={onDialogClickYes}>{trans.new_invoice.dialog.yes_button}</Button>
        </DialogActions>
      </>
    )
  }, [openDialog, onDialogClickNo, onDialogClickYes])


  return {
    openDialog,
    setOpenDialog,
    dialogReturn,
    SelectDialog,
    onDialogOpen: () => onDialogOpen()
  };
};