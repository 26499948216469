import React from 'react'
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Stack from '@mui/material/Stack';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
// import {setPaginationPage, selectpaginationPage, selectpaginationNumberOfPage, getSentInvoices} from "../features/invoices/invoicesSlice";
import { useAppDispatch, useAppSelector } from '../../app/hooks';

type Direction = 'ltr' | 'rtl';
interface Props {
  direction: Direction;
  numberOfPages: number;
  currentPage: number;
  onPageChange: Function;

}

export default function TablePagination({ direction, numberOfPages, currentPage, onPageChange }: Props) {
    
    // const handleChangePage = (
    //     event: React.MouseEvent<HTMLButtonElement> | null,
    //     newPage: number,
    //   ) => {
    //     setPaginationPage(newPage);
    //   };
    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
      onPageChange(value)
        // dispatch(setPaginationPage(value));
        // dispatch(getSentInvoices());
    };
    
    

  return (
    // <Stack spacing={2}>
    //   {/* <Pagination count={10} color="primary"/> */}
    //   <Pagination
    //     count={10}
    //     size="large"
    //     // page={3}
    //     variant="outlined"
    //     shape="rounded"
    //     // onChange={handleChange}
    //   />
    // </Stack>
    <Stack spacing={2}>
    <Pagination
        color="primary"
        count={numberOfPages}
        page={currentPage}
        onChange={handleChange}
        renderItem={(item) => (
        <PaginationItem
        components={{ previous: direction==='ltr'? ArrowBackIcon:ArrowForwardIcon , next: direction==='ltr'? ArrowForwardIcon:ArrowBackIcon }}
          {...item}
        />
      )}
    />
  </Stack>
    
  )  
}  

