import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../../app/store';
import api from '../services/api'
// import getBrowserFingerprint from 'get-browser-fingerprint';
import { ClientJS } from 'clientjs';

import { db } from "../../db";
import Product from '../../components/product/Product';
import { ProductCatalog } from '../../models/ProductCatalog';
import { Customer, CustomerType } from '../../models/Customer';
import { StoreHouse } from '../../models/StoreHouse';
import { reSyncData } from '../login/loginSlice';




export interface CustomerState {
  customer: Customer;
  customerTypeList: CustomerType[];
  initialBalance: number;
  customersReport: any[];
  statment: any[];
  error: string;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
}

const initialState: CustomerState = {
  customer: {
    id: undefined,
    fullname: "",
    mobile: "",
    phone: "",
    email: "",
    address: "",
    account_id:0,
    customer_type:1,
    customer_type_name: "",
    cancelled: false,
  },
  customerTypeList: [],
  initialBalance: 0,
  customersReport: [],
  statment: [], 
  error: "",
  status: 'idle',
};


export const customerSlice = createSlice({
  name: 'customer',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {

    resetCustomerData: (state) => {
      state.customer = initialState.customer
      state.initialBalance = 0
      state.status = 'idle';
    },

    addRequest: (state, action: PayloadAction<number>) => {
      state.customer.id = action.payload;
      state.status = 'loading';
    },

    getCustomerSucceeded: (state, action: PayloadAction<Customer>) => {
      state.customer.id = action.payload.id;
      state.customer.fullname = action.payload.fullname;
      state.customer.mobile = action.payload.mobile;
      state.customer.phone = action.payload.phone;
      state.customer.email = action.payload.email;
      state.customer.address = action.payload.address;
      state.customer.customer_type_name = action.payload.customer_type_name;
      state.customer.account_id = action.payload.account_id
      state.customer.customer_type = action.payload.customer_type
      state.customer.cancelled = action.payload.cancelled;
      state.status = 'succeeded';
    },

    updateCustomerSucceeded: (state) => {
      state.status = 'succeeded';
    },

    loadFailure: (state, action: PayloadAction<string>) => {

      state.error = action.payload;
      state.status = 'failed';
    },

    getAllCustomerSucceeded: (state, action: PayloadAction<any[]>) => {
      state.customersReport = action.payload;
      state.status = 'succeeded';
    },

    getCustomerTypeListSucceeded: (state, action: PayloadAction<CustomerType[]>) => {
      state.customerTypeList = action.payload
      state.status = 'succeeded';
    },

    getAddCollectedAmountSucceeded: (state) => {
      state.status = 'succeeded';
    },

    clearStatment: (state) => {
      state.statment = []
    },

    getCustomerStatmentSucceeded: (state, action: PayloadAction<any[]>) => {
      state.statment = action.payload;
      state.status = 'succeeded';
    },


    setCustomerData: (state: any, action: PayloadAction<Customer>) => {
      state.customer = action.payload
    },
    setInitialBalance: (state: any, action: PayloadAction<number>) => {
      state.initialBalance = action.payload
    },

  },
});

export const { addRequest, getCustomerSucceeded, updateCustomerSucceeded, loadFailure, getAllCustomerSucceeded, getCustomerTypeListSucceeded, setCustomerData, setInitialBalance, resetCustomerData, getAddCollectedAmountSucceeded, clearStatment, getCustomerStatmentSucceeded } = customerSlice.actions;
export const customerData = (state: RootState) => state.customer.customer;
export const customerTypeList = (state: RootState) => state.customer.customerTypeList;
export const customerStatment = (state: RootState) => state.customer.statment;
export const initialBalance = (state: RootState) => state.customer.initialBalance;
export const customersReport = (state: RootState) => state.customer.customersReport;
export const customerCallStatus = (state: RootState) => state.customer.status;


export const getCustomer = (customerId: number): AppThunk => async (dispatch, getState) => {
  dispatch(addRequest(customerId));
  try {

    const response = await api.get(
      `/customer/${customerId}`,
      { headers: { "Content-Type": "application/json" } }
    )
    // console.log(data);
    // console.log(response);

    if (response.status === 200) {
      dispatch(getCustomerSucceeded(response.data['customer']));
    } else {
      dispatch(loadFailure(response.statusText));
    }

  } catch (err: any) {
    // throw new Error(err);
    dispatch(loadFailure(err.message));
  }
};



export const updateCustomerData = (): AppThunk => async (dispatch, getState) => {
  const customer = customerData(getState());

  dispatch(addRequest(customer.id!));
  try {

    

    const response = await api.put(
      '/customer',
      customer,
      { headers: { "Content-Type": "application/json" } }
    )
    // console.log(data);
    // console.log(response);

    if (response.status === 200) {
      dispatch(reSyncData())
      dispatch(updateCustomerSucceeded());
    } else {
      dispatch(loadFailure(response.statusText));
    }

  } catch (err: any) {
    // throw new Error(err);
    dispatch(loadFailure(err.message));
  }
};


export const addCustomerData = (): AppThunk => async (dispatch, getState) => {
  dispatch(addRequest(0));
  const customer = customerData(getState());
  const initBalance = initialBalance(getState());

  try {
    

    const response = await api.post(
      '/customer',
      {...customer, initial_balance: initBalance},
      { headers: { "Content-Type": "application/json" } }
    )
    // console.log(data);
    // console.log(response);

    if (response.status === 200) {
      dispatch(reSyncData())
      dispatch(getCustomerSucceeded(response.data['customer']));
    } else {
      dispatch(loadFailure(response.statusText));
    }

  } catch (err: any) {
    // throw new Error(err);
    dispatch(loadFailure(err.message));
  }
};


export const getAllCustomer = (): AppThunk => async (dispatch, getState) => {
  dispatch(addRequest(0));
  try {

    const response = await api.get(
      '/customer/all',
      { headers: { "Content-Type": "application/json" } }
    )
   
    if (response.status === 200) {
      dispatch(getAllCustomerSucceeded(response.data['customers']));
    } else {
      dispatch(loadFailure(response.statusText));
    }

  } catch (err: any) {
    // throw new Error(err);
    dispatch(loadFailure(err.message));
  }
};


export const getCustomerTypeList = (): AppThunk => async (dispatch) => {
  dispatch(addRequest(0));

  const typeList = await db.customerType.toArray()

  if (typeList) {
    dispatch(getCustomerTypeListSucceeded(typeList));
  }
  else {
    dispatch(loadFailure("Customer Type List not exist"));

  }
}



export const addCollectedAmount = (collectionData: any): AppThunk => async (dispatch) => {
  dispatch(addRequest(0));
  try {
    const response = await api.post(
      '/customer/document',
      collectionData,
      { headers: { "Content-Type": "application/json" } }
    )
    if (response.status === 200) {
      dispatch(getAddCollectedAmountSucceeded());
    } else {
      dispatch(loadFailure(response.statusText));
    }

  } catch (err: any) {
    // throw new Error(err);
    dispatch(loadFailure(err.message));
  }
};


export const getCustomerStatment = (accountId: number): AppThunk => async (dispatch, getState) => {
  dispatch(addRequest(0));
  dispatch(clearStatment());
  try {

    const response = await api.get(
      `/customer/statment/${accountId}`,
      { headers: { "Content-Type": "application/json" } }
    )
    // console.log(data);
    // console.log(response);

    if (response.status === 200) {
      dispatch(getCustomerStatmentSucceeded(response.data['statment']));
    } else {
      dispatch(loadFailure(response.statusText));
    }

  } catch (err: any) {
    // throw new Error(err);
    dispatch(loadFailure(err.message));
  }
};

export const getCustomerStatmentDetail = (accountId: number, startDate: string, endDate: string): AppThunk => async (dispatch, getState) => {
  dispatch(addRequest(0));
  dispatch(clearStatment());
  try {

    const response = await api.get(
      `/customer/statmentdetail/${accountId}/${startDate}/${endDate}`,
      { headers: { "Content-Type": "application/json" } }
    )
    // console.log(data);
    // console.log(response);

    if (response.status === 200) {
      dispatch(getCustomerStatmentSucceeded(response.data['statment']));
    } else {
      dispatch(loadFailure(response.statusText));
    }

  } catch (err: any) {
    // throw new Error(err);
    dispatch(loadFailure(err.message));
  }
};


export default customerSlice.reducer;
