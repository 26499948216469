import React, { useEffect, useRef } from "react";
import { Container, TextField, Autocomplete, Box, LinearProgress, IconButton, AutocompleteChangeReason, FormControl, InputLabel, Select, MenuItem, SelectChangeEvent, NativeSelect } from "@mui/material";
import AddBoxIcon from '@mui/icons-material/AddBox';


import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateField } from '@mui/x-date-pickers';
import { isNumber } from "@mui/x-data-grid/internals";
import { db } from "../../db";
import { selectTranslations } from "../i18n/i18nSlice";
import { useAppSelector } from "../../app/hooks";
import { ProductCatalog } from "../../models/ProductCatalog";



// import {addBillItem} from '../actions/billActions'
type Props = {
  addNewComponentItem: Function;
};

export interface Component {
  component_id: number;
  component_name: string;
  main_unit_quantity: number;
  unite_id: number;
}


// with all options
export default function ComponentSelect({ addNewComponentItem }: Props) {
  const countRef = useRef<HTMLInputElement>(null);
  const codeRef = useRef<HTMLInputElement>(null);
  const descriptionRef = useRef<HTMLInputElement>(null);


  const trans = useAppSelector(selectTranslations);
  //   const formLables = useSelector(state => state.formLangLables.itemSelect)[lang.lang]
  const codeMode: number = 1
  const [productIdValue, setProductIdValue] = React.useState<number | null>(null);
  const [productUserCodeValue, setProductUserCodeValue] = React.useState<string | null>(null);
  const [itemCount, setItemCount] = React.useState(1);

  const [itemSelected, setItemSelected] = React.useState<ProductCatalog | null>(null);

  const [productQty, setProductQty] = React.useState<string>('');

  const [productCatalogs, setProductCatalogs] = React.useState<ProductCatalog[]>([])
  const defaultProps = {
    options: productCatalogs,
    getOptionLabel: (option: ProductCatalog) => option.product_name,
  };




  const getList = async () => {
    const list = await db.productCatalog.filter(item => item.product_type === 1).toArray() // remove service from list
    setProductCatalogs(list)
  }

  useEffect(() => {
    getList()
  }, [])




  const onAutoCompleteChange = (event: React.SyntheticEvent<Element, Event>, value: ProductCatalog | null, reason: AutocompleteChangeReason) => {

    setItemSelected(value);

    if (value !== null) {
      setItemCount(1)
      setProductIdValue(value.id);

    } else {
      setProductIdValue(null);
      setProductUserCodeValue(null);
    }

  };



  const onProductIdChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setProductIdValue(parseFloat(event.target.value));
  };

  const onItemCountChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setItemCount(parseFloat(event.target.value));
  };




  const onCodekeyPress = (e: any) => {
    if (e.keyCode === 13) {
      const foundItem = productCatalogs!.filter((item) => codeMode === 1 ? item.id == e.target.value : item.user_defined_code == e.target.value);
      // console.log(foundItem)
      if (foundItem !== undefined && foundItem.length > 0) {
        // setItemCount(1)
        // console.log('before select')


        setItemSelected(foundItem[0]);
        setItemCount(1)
        // setitemPrice(parseFloat((foundItem[0].pricing[priceList].price * (1 + expenseToPrice)).toFixed(2)));
        // calculateItemTotal(parseFloat((foundItem[0].pricing[priceList].price * (1 + expenseToPrice)).toFixed(2)), 1, discountPrecent, taxPrecent);
        if (codeMode === 1) {
          setProductIdValue(foundItem[0].id)
        } else {
          setProductUserCodeValue(foundItem[0].user_defined_code)
        }


        // Next item if click enter
        countRef.current!.focus();

      } else {
        setItemSelected(null);
      }
    }
  };



  const onDescriptionPress = (e: any) => {
    // console.log('onDescriptionPress')
    if (e.keyCode === 13) {
      countRef.current?.focus()
    }

  }

  const onDescriptionFocus = (e: any) => {
    descriptionRef.current?.select()
    // console.log('onDescriptionFocus')
  }

  const onCodeFocus = (e: any) => {
    // codeRef.current?.select()
    setTimeout(function () {
      e.target.select();
    }, 0);
  }

  const onCountFocus = (e: any) => {
    // sizeCodeRef.current?.select()
    setTimeout(function () {
      e.target.select();
    }, 0);
  }


  const onCountkeyPress = (e: any) => {
    if (e.keyCode === 13) {
      addCurrentLine()
    }
  };



  const addCurrentLine = () => {
    if (itemSelected !== null && itemCount > 0 && itemSelected.id > 0) {

      if (codeMode === 1) {
        if (productIdValue?.toString().trim() === '' || productIdValue === undefined || productIdValue === null) return
      } else {
        if (productUserCodeValue?.toString().trim() === '' || productUserCodeValue === undefined || productUserCodeValue === null) return
      }

      if (itemCount.toString().trim() === '' || itemCount === undefined || itemCount === null) return


      let componentItem = {} as Component;
      componentItem.component_name = itemSelected.product_name ?? '';
      componentItem.component_id = itemSelected.id;
      componentItem.main_unit_quantity = itemCount;
      componentItem.unite_id = itemSelected.product_units.filter(item => item.is_main)[0].id ?? 0;

      addNewComponentItem(componentItem)
      codeRef.current!.focus();


    } else {
      codeRef.current!.focus();
    }


  }



  return (
    <Container maxWidth={false} sx={{ margin: 0, padding: 0 }}>
      {!productCatalogs && <LinearProgress />}
      {productCatalogs && (
        <>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>

          </Box>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>







           
                <TextField
                  sx={{ width: 100, margin: "4px" }}
                  id="item_code"
                  label={trans.invoice_item.code}
                  inputRef={codeRef}
                  onKeyDown={onCodekeyPress}
                  onChange={onProductIdChanged}
                  onFocus={onCodeFocus}
                  value={codeMode === 1 ? productIdValue || '' : productUserCodeValue || ''}
                  size="small"
                />
            
                <Autocomplete
                  sx={{ flexGrow: 4, margin: "4px" }}
                  {...defaultProps}
                  id="Products"
                  autoHighlight
                  limitTags={10}
                  onChange={onAutoCompleteChange}
                  getOptionLabel={(option) => {
                    return option.product_name || ""
                  }}
                  value={itemSelected}
                  isOptionEqualToValue={(option, value) => {
                    // console.log(value)
                    if (value !== null) {
                      return option!.id === value?.id;
                    } else {
                      return false;
                    }
                  }}
                  // isOptionEqualToValue={(option, value) =>
                  //   option.iso === value.iso
                  // }
                  renderInput={(params) => (
                    <TextField {...params} label={trans.invoice_item.product} size="small" inputRef={descriptionRef} onKeyDown={onDescriptionPress} onFocus={onDescriptionFocus} />

                  )}
                />

            









            <TextField
              sx={{ width: 100, margin: "4px" }}
              id="item_count"
              label={trans.invoice_item.count}
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={onItemCountChanged}
              onKeyDown={onCountkeyPress}
              onFocus={onCountFocus}
              inputRef={countRef}
              value={itemCount}
              size="small"
            />


            {(productIdValue || productUserCodeValue) && <IconButton aria-label="delete" onClick={addCurrentLine} sx={{ width: 45, color: "#1976d2" }}>
              <AddBoxIcon />
            </IconButton>}

          </Box>

        </>)}
    </Container>
  );
}

