import React, { useEffect, useMemo, useState, useCallback, useRef } from 'react';
import { LinearProgress, Paper, Button, Box, Container, Typography, AppBar, Toolbar } from '@mui/material';


import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { selectDirection, selectTranslations } from "../i18n/i18nSlice";

import { selectPaletteMode } from '../appconfig/appConfigSlice';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import * as XLSX from 'xlsx';
import { AgGridReact } from 'ag-grid-react'; // React Grid Logic
import "ag-grid-community/styles/ag-grid.css"; // Core CSS
import "ag-grid-community/styles/ag-theme-quartz.css"; // Theme
// import "ag-grid-community/styles/ag-theme-quartz-dark.css"; // Theme
// import "./styles.css"
import { ColDef, SizeColumnsToContentStrategy, GridReadyEvent, ValueFormatterFunc, IRowNode } from 'ag-grid-community';
import { invoicesAGFilter, setInvoicesAGFilter } from './reportsSlice';



interface ApiStatus {
  error: string;
  status: 'idle' | 'loading' | 'succeeded' | 'failed'
}


const downloadExcel = (data: any) => {
  const worksheet = XLSX.utils.json_to_sheet(data);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  XLSX.writeFile(workbook, "DataSheet1.xlsx");
};


const useRepoAG = <Row extends Record<string, any>>(currentVal: Record<string, any>, transRep: string, getReport: (setRowsFun: React.Dispatch<React.SetStateAction<Row[]>>, setApiCallStatusFun: React.Dispatch<React.SetStateAction<ApiStatus>>) => Promise<void>, onOpenClick?: (id: number) => void, openParameterName?: string) => {

  const dispatch = useAppDispatch()
  const savedFilter = useAppSelector(invoicesAGFilter);

  const gridRef = useRef<AgGridReact>(null);

  const [rows, setRows] = useState<Row[]>([])
  const paletteMode = useAppSelector(selectPaletteMode);

  const [apiCallStatus, setApiCallStatus] = React.useState<ApiStatus>({ error: "", status: "idle" })
  const isLoading = () => apiCallStatus.status === "loading";
  const isSucceeded = () => apiCallStatus.status === "succeeded";
  const isIdle = () => apiCallStatus.status === "idle";
  const isFailed = () => apiCallStatus.status === "failed";

  const langDirection = useAppSelector(selectDirection);
  const trans = useAppSelector(selectTranslations);

  // set background colour on every row, this is probably bad, should be using CSS classes
  const rowStyle = { background: '' };

  // set background colour on even rows again, this looks bad, should be using CSS classes
  const getRowStyle = (params: any) => {
    // if (params.node.rowIndex % 2 === 0) {
    //     return { background: 'red' };
    // }
    // return { border: '1px solid #bdc1c6' };
    return { borderBottom: '1px solid #bdc1c6' };
  };

  // function formatNumber (number: number):ValueFormatterFunc {
  //   // this puts commas into the number eg 1000 goes to 1,000,
  //   // i pulled this from stack overflow, i have no idea how it works
  //   return Math.floor(number)
  //     .toString()
  //     .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  // }

  function formatNumber (params: { value: number }): string {
    // this puts commas into the number eg 1000 goes to 1,000,
    // i pulled this from stack overflow, i have no idea how it works
    return (params.value?.toString() || '')
      // .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
      // .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
      // .replace(/\B(?=(\d{3})+(?!\d))/g, '$1,');
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  }

  function customValueFormatter(params: { value: any }): string {
    // You can perform any custom formatting logic here
    const formattedValue = `$${params.value.toFixed(2)}`; // Example: Format as currency
  
    return formattedValue;
  }
  
  

  const openColumn: ColDef[] = (typeof onOpenClick !== 'undefined' && typeof openParameterName !== 'undefined') ?
    [{
      field: "open",
      headerName: trans.nsi_table.open,
      pinned: langDirection === "rtl" ? 'right' : 'left',
      floatingFilter: false,
      cellRenderer: typeof onOpenClick !== 'undefined' ? (params: any) => {
        // put the value in bold
        // return <Button style={{ width: 32, minWidth: 32 }} onClick={() => onOpenClick(params.data[openParameterName])}>{trans.nsi_table.open}</Button>;
        // to remove from pinned added !params.node.isRowPinned()
        return !params.node.isRowPinned() && <Button style={{ width: 32, minWidth: 32 }} onClick={() => onOpenClick(params.data[openParameterName])}>{trans.nsi_table.open}</Button>;
      } : null
    },
    ] : []

  // Column Definitions: Defines & controls grid columns.
  const [colDefs, setColDefs] = useState<ColDef[]>(
    [
      ...openColumn,
      ...Object.keys(currentVal).filter((k: string) => {
        if (k in trans[transRep]) {
          return true
        } else {
          return false
        }
      }
      ).map((k: string) => (typeof (currentVal as Record<string, any>)[k] === 'string' ?
        {
          field: k,
          headerName: trans[transRep][k],
          floatingFilter: true,
          // filter: k==='invoice_type'?'بيع': true,
          filter: true,
          filterParams: {},
        } :
        {
          field: k,
          headerName: trans[transRep][k],
          floatingFilter: true,
          filter: 'agNumberColumnFilter',
          filterParams: {},
          // valueFormatter: '"$" + value.toLocaleString()',
          valueFormatter: formatNumber,
          pinned: k === 'id' ? langDirection === "rtl" ? 'right' as const : 'left' as const : null,
        }
      ))
    ]
  );

  const defaultColDef = useMemo(() => ({
    floatingFilter: true,
    cellStyle: { borderRight: '1px solid #bdc1c6' },
  }), [])


  const autoSizeStrategy: SizeColumnsToContentStrategy = {
    type: 'fitCellContents'
  };

  function generatePinnedBottomData(){
    // generate a row-data with null values
    let result: Record<string, any>  = {};

    gridRef.current!.api.getAllGridColumns().forEach(item => {
        result[item.getId()] = null;
    });
    return calculatePinnedBottomData(result);
}


function calculatePinnedBottomData(target: any){
  //console.log(target);
  //**list of columns fo aggregation**
  let columnsWithAggregation = ['subtotal', 'total']
  columnsWithAggregation.forEach(element => {
    // console.log('element', element);
    gridRef.current!.api.forEachNodeAfterFilter((rowNode: IRowNode<any>, index: number) => {
        //if(rowNode.index < 10){
          //console.log(rowNode);
        //}
          if (rowNode.data[element])
              target[element] += Number(parseFloat(rowNode.data[element]).toFixed(2));
      });
      if (target[element])
          target[element] = `${target[element].toFixed(2)}`;
  })
  //console.log(target);
  return target;
}


  const onGridReady = useCallback((event: GridReadyEvent) => {
    event.api.setFilterModel(savedFilter);

    setTimeout(()=>{
      let pinnedBottomData = generatePinnedBottomData();
      // event.api.setPinnedBottomRowData([pinnedBottomData]);
      gridRef.current!.api.updateGridOptions({pinnedBottomRowData:[pinnedBottomData]});
    }, 500)

  }, []);


  const getPrintedData = () => {
    return rows.map(item => (
      Object.keys(currentVal).reduce((accumulator, key) => {
        return { ...accumulator, [trans[transRep][key]]: item[key] }
      }, {})
    ))
  }

  useEffect(() => {
    getReport(setRows, setApiCallStatus)
  }, [])



  return (
    <Container maxWidth={false} sx={{ margin: 0, padding: 0 }}>
      {isLoading() && <LinearProgress />}
      {isSucceeded() && (
        <>

          <Box sx={{ display: 'flex', flexDirection: 'column', height: `calc(100vh - 128px)` }}>
            <Box>

              {rows.length != 0 ?
                <Paper elevation={3} sx={{ marginBottom: 2, padding: 2, direction: langDirection }}>
                  <Button variant="contained" startIcon={<FileDownloadIcon sx={{ marginRight: 0, marginLeft: 1 }} />} sx={{ marginX: 1 }} onClick={() => downloadExcel(getPrintedData())}>
                    Download
                  </Button>
                </Paper>
                : null}
            </Box>
            <Box>
              <AppBar position="static" sx={{ backgroundColor: "#283593" }}>
                <Toolbar component="nav" variant="dense" sx={{ justifyContent: 'space-between', overflowX: 'auto' }}  >
                  <Typography component="h2" variant="h5">
                    {trans.stock_rep1.title}
                  </Typography>
                </Toolbar>
              </AppBar>

            </Box>
            <div className={`${paletteMode === 'light' ? 'ag-theme-quartz' : 'ag-theme-quartz-dark'}`} style={{ height: "100%", width: "100%" }}>
              <AgGridReact
                ref={gridRef}
                rowData={rows}
                columnDefs={colDefs}
                defaultColDef={defaultColDef}
                enableRtl={langDirection === "rtl" ? true : false}
                // rowStyle={rowStyle}
                getRowStyle={getRowStyle}
                autoSizeStrategy={autoSizeStrategy}
                onGridReady={onGridReady}
                onFilterChanged={() => {
                  dispatch(setInvoicesAGFilter(gridRef.current!.api.getFilterModel()))
                  let pinnedBottomData = generatePinnedBottomData();
                  // gridRef.current!.api.setPinnedBottomRowData([pinnedBottomData]);
                  gridRef.current!.api.updateGridOptions({pinnedBottomRowData:[pinnedBottomData]});
                }}
              />
            </div>
          </Box>
        </>
      )}
    </Container>
  )


}


export { useRepoAG };
export type { ApiStatus };
